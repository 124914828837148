import React from "react";
import { Route, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Badge } from "react-bootstrap";
import { 
  Home, 
  BizLeads, 
  ConLeads, 
  ComparePlansTable, 
  DatabaseLander, 
  ListShackDesktop, 
  CcpaOptout, 
  Ccpa, 
  Faq, 
  Contact, 
  Privacy, 
  Terms, 
  DatabasesOverview, 
  DatabaseRouter, 
  GeographySearch, 
  MapSearch, 
  SearchTools,
  SearchFilters,
  SampleList,
  WeWorkWith,
  WatchDemo,
  ListCrm
} from "./interfaceLsStatic";
import Pricing from "./pricing";
import ResourcesLs from "./resourcesLs";
import { SupportCenter } from "./supportCenter";
import consumerEmails from "./dataDict_consumer_emails.jsx";
import businessEmails from "./dataDict_bizEmails.jsx";
import consumerJul2021 from "./dataDict_consumerJul2021";
import bizDec2018 from "./dataDict_bizDec2018";
import vinconsumer from "./dataDict_vinconsumer";
import SmryLinkRouter from "./smryLinkContent";

const RoutesLsStatic = props => {
	return(
		<React.Fragment>
			<Route exact path="/">
        <Helmet>
          <title>
            {process.env.REACT_APP_site_name} | Unlimited Sales Leads
          </title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Home user={null} />
      </Route>

      <Route exact path="/home/">
        <Helmet>
          <title>
            {process.env.REACT_APP_site_name} | Unlimited Sales Leads
          </title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Home user={null} />
      </Route>

      <Route exact path="/databases/">
        <Helmet>
          <title>Databases</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <DatabasesOverview 
          dicts={[
            consumerJul2021,
            bizDec2018,
            vinconsumer,
            consumerEmails,
            businessEmails
          ]}
        />
      </Route>
      <Route exact path="/databases/:dictid/">
        <DatabaseRouter
          {...props}
          dicts={[
            consumerJul2021,
            bizDec2018,
            vinconsumer,
            consumerEmails,
            businessEmails
          ]}
        />
      </Route>
      <Route exact path="/consumer-databases/">
        <Helmet>
          <title>Consumer Databases</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <DatabasesOverview 
          dicts={[
            consumerJul2021,
            consumerEmails
          ]}
        />
      </Route>
      <Route exact path="/business-databases/">
        <Helmet>
          <title>Business Databases</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <DatabasesOverview 
          dicts={[
            bizDec2018,
            businessEmails
          ]}
        />
      </Route>
      <Route exact path="/vin-databases/">
        <Helmet>
          <title>Vehicle Owner Databases</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <DatabasesOverview 
          dicts={[
            vinconsumer
          ]}
        />
      </Route>
      <Route exact path="/email-databases/">
        <Helmet>
          <title>Vehicle Owner Databases</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <DatabasesOverview 
          dicts={[
            consumerEmails,
            businessEmails,
            vinconsumer
          ]}
        />
      </Route>

      <Route path="/business-sales-leads/">
        <Helmet>
          <title>Business Sales Leads</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <BizLeads />
      </Route>

      <Route path="/consumer-sales-leads/">
        <Helmet>
          <title>Consumer Sales Leads</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <ConLeads />
      </Route>
      
      <Route path="/search-tools/">
        <Helmet>
          <title>Search Tools</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <SearchTools />
      </Route>

      <Route path="/search-filters/">
        <Helmet>
          <title>Search Tools</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <SearchFilters 
          dicts={[
            consumerJul2021,
            bizDec2018,
            vinconsumer,
            consumerEmails,
            businessEmails
          ]}
        />
      </Route>

      <Route path="/sample-list/">
        <Helmet>
          <title>Sample List</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <SampleList 
          dicts={[
            consumerJul2021,
            bizDec2018,
            vinconsumer,
            consumerEmails,
            businessEmails
          ]}
        />
      </Route>

      <Route path="/geography-search/">
        <Helmet>
          <title>Geography Search</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <GeographySearch />
      </Route>

      <Route path="/map-search/">
        <Helmet>
          <title>Map Search</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <MapSearch />
      </Route>

      <Route path="/listcrm/">
        <Helmet>
          <title>ListCrm</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <ListCrm />
      </Route>

      <Route path="/who-we-work-with/">
        <Helmet>
          <title>Who We Work With</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <WeWorkWith />
      </Route>

      <Route path="/watch-demo/">
        <Helmet>
          <title>ListShack Demo</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <WatchDemo />
      </Route>

			<Route path="/pricing/">
        <Helmet>
          <title>Pricing</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Pricing {...props} selectPlan={() => {}} />
      </Route>

      <Route path="/compare_plans/">
        <Helmet>
          <title>Compare Plans</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <ComparePlansTable
          {...props}
          title={
            <h1 className="mt-5">
              Compare {process.env.REACT_APP_site_name} Plans
            </h1>
          }
        />
      </Route>

      <Route path="/faq/">
        <Helmet>
          <title>FAQs</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Faq />
      </Route>

      <Route path="/contact/">
        <Helmet>
          <title>Contact us</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Contact />
      </Route>

      <Route path="/privacy/">
        <Helmet>
          <title>Privacy policy</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Privacy />
      </Route>

      <Route path="/terms/">
        <Helmet>
          <title>Terms of service</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Terms />
      </Route>

      {/*<Route path="/resources/">
        <Helmet>
          <title>Resources</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <ResourcesLs />
      </Route> */}

      <Route path={["/resources/"]}>
        <Helmet>
          <title>Resources</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <SmryLinkRouter {...props} 
          accountId="w4vKu1k5sLS8gkC27ZifDsVGuks2" 
          blogpath="/resources" 
          view={["blog-published"]}
        />
      </Route>

      <Route path={["/smry/"]}>
        <Helmet>
          <title>Resources</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <SmryLinkRouter {...props} 
          accountId="w4vKu1k5sLS8gkC27ZifDsVGuks2" 
          blogpath="/smry" 
          view={["blog-published", "blog-draft", "blog"]}
        />
      </Route>

      <Route path={["/help/"]}>
        <Helmet>
          <title>Resources</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <SmryLinkRouter {...props} 
          accountId="w4vKu1k5sLS8gkC27ZifDsVGuks2" 
          blogpath="/help" 
          view={["help-published"]}
          indexHeading="Help"
          indexLead="Find answers to commonly asked questions, watch video tutorials, and get help from ListShack customer support."
          showSearch={true}
          showTableOfContents={true}
        />
      </Route>

      <Route path={["/help-smry/"]}>
        <Helmet>
          <title>Help</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <SmryLinkRouter {...props} 
          accountId="w4vKu1k5sLS8gkC27ZifDsVGuks2" 
          blogpath="/help-smry"
          view={["help-published", "help-draft", "help"]}
          indexHeading="Help"
          indexLead="Find answers to commonly asked questions, watch video tutorials, and get help from ListShack customer support."
          showSearch={true}
        />
      </Route>

      <Route path="/support/">
        <Helmet>
          <title>Support center</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <SupportCenter {...props} />
      </Route>

      <Route exact path="/emails/">
        <Helmet>
          <title>Email leads</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <DatabaseLander
          {...props}
          title={<React.Fragment>
            Email Leads{" "}
            <Badge pill variant="light">
              <i>Beta</i>
            </Badge>
          </React.Fragment>}
          lead={`Search ${process.env.REACT_APP_site_name} for compiled email leads.`}
          subtitle="Compiled email leads"
          subPath="emails"
          description={
            <p>
              {process.env.REACT_APP_site_name} provides{" "}
              <Link to="/support/use-compiled-emails">compiled email</Link>{" "}
              leads for use in your marketing and customer communications. We
              currently offer only consumer emails. These emails can be searched
              by a variety of filters so you can run the most effective
              campaigns for your business. Read more about using compiled email
              lists <Link to="/support/use-compiled-emails">here</Link>.
            </p>
          }
          databases={[consumerEmails]}
        />
      </Route>

      <Route exact path="/emails/:dictid/">
        <DatabaseRouter
          {...props}
          dicts={[consumerEmails, businessEmails]}
        />
      </Route>

      <Route exact path="/listshack_desktop/">
        <Helmet>
          <title>ListShack Desktop</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>

        <ListShackDesktop {...props} />
      </Route>

      <Route exact path="/ccpa_optout/">
        <Helmet>
          <title>California Consumer Optout</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <CcpaOptout {...props} />
      </Route>

      <Route exact path="/ccpa/">
        <Helmet>
          <title>CCPA Disclosures</title>
          <meta name="HandheldFriendly" content="true" />
          <meta name="apple-mobile-web-app-capable" content="YES" />
        </Helmet>
        <Ccpa />
      </Route>
		</React.Fragment>
	)
}

export default RoutesLsStatic;