import React from "react";
import { Link } from "react-router-dom";
import sharedDict from "./sharedDict.jsx";
import image from "./media/emails.svg";

export default {
  dictId: "bizEmails",
  index: "bizoct2021",
  type: "Email",
  path: "/search/bizEmails/geography",
  apiUrl: "/api/v2/data/bizoct2021",
  title: "US Business Emails",
  subText: <small>Read how to use compiled emails <Link to="/support/use-compiled-emails" target="_blank" rel="noopener noreferrer">here</Link>. For business direct mail search <Link to="/search/businesses">here</Link>.</small>,
  description: `${process.env.REACT_APP_site_name}'s business email database, consisting of compiled (not opt-in) email addresses by company name.`,
  overview: "A comprehensive US business database that includes name, TITLE, company, and email address.  This databases is great for B2B prospecting and research.",
  themeColor: "info",
  image,
  geography: {
    AREA_CODE: sharedDict.geography.AREA_CODE,
    COUNTY: sharedDict.geography.COUNTY,
    CITY: sharedDict.geography.CITY, 
    ZIP: sharedDict.geography.ZIP, 
    ST: sharedDict.geography.ST,
    nationwide: sharedDict.geography.nationwide
  },
  cols: {
    EMAIL: {
      name: "Has email",
      displayHeader: true,
      inputType: "select",
      filterCategory: "Email preferences",
      items: [
        {value: "A", name: "Yes", query: [`(EMAIL<>'')`]},
      ]
    },
    SICNAICS: {
      name: "Industry",
      formText: "Search by SIC or NAICS industry classification codes or descriptions.",
      displayHeader: true,
      inputType: "search",
      items: []
    },
    NUM_EMPLOYEES: {
      name: "Number of employees",
      displayHeader: true,
      inputType: "checkbox",
      items: [
        {index: 0, value: "1 to 4", name: "1 to 4", query: [`(NUM_EMPLOYEES = '1 to 4')`]},
        {index: 1, value: "5 to 9", name: "5 to 9", query: [`(NUM_EMPLOYEES = '5 to 9')`]},
        {index: 2, value: "10 to 19", name: "10 to 19", query: [`(NUM_EMPLOYEES = '10 to 19')`]},
        {index: 3, value: "20 to 49", name: "20 to 49", query: [`(NUM_EMPLOYEES = '20 to 49')`]},
        {index: 4, value: "50 to 99", name: "50 to 99", query: [`(NUM_EMPLOYEES = '50 to 99')`]},
        {index: 5, value: "100 to 249", name: "100 to 249", query: [`(NUM_EMPLOYEES = '100 to 249')`]},
        {index: 6, value: "250 to 499", name: "250 to 499", query: [`(NUM_EMPLOYEES = '250 to 499')`]},
        {index: 7, value: "500 to 999", name: "500 to 999", query: [`(NUM_EMPLOYEES = '500 to 999')`]},
        {index: 8, value: "1,000 to 4,999", name: "1,000 to 4,999", query: [`(NUM_EMPLOYEES = '1,000 to 4,999')`]},
        {index: 9, value: "5,000 to 9,999", name: "5,000 to 9,999", query: [`(NUM_EMPLOYEES = '5,000 to 9,999')`]},
      ]
    },
    SALES_VOL: {
      name: "Sales volume",
      displayHeader: true,
      inputType: "checkbox",
      items: [
        {index: 0, query: [`(SALES_VOL in ('LESS THAN $500,000'))`], value: "A", name: "LESS THAN $500,000"},
        {index: 1, query: [`(SALES_VOL in ('$500,000 TO $1 MILLION'))`], value: "B", name: "$500,000 TO $1 MILLION"},
        {index: 2, query: [`(SALES_VOL in ('$1 TO 2.5 MILLION'))`], value: "C", name: "$1 TO 2.5 MILLION"},
        {index: 3, query: [`(SALES_VOL in ('$2.5 TO 5 MILLION'))`], value: "D", name: "$2.5 TO 5 MILLION"},
        {index: 4, query: [`(SALES_VOL in ('$5 TO 10 MILLION'))`], value: "E", name: "$5 TO 10 MILLION"},
        {index: 5, query: [`(SALES_VOL in ('$10 TO 20 MILLION'))`], value: "F", name: "$10 TO 20 MILLION"},
        {index: 6, query: [`(SALES_VOL in ('$20 TO 50 MILLION'))`], value: "G", name: "$20 TO 50 MILLION"},
        {index: 7, query: [`(SALES_VOL in ('$50 TO 100 MILLION'))`], value: "H", name: "$50 TO 100 MILLION"},
        {index: 8, query: [`(SALES_VOL in ('$100 mil to less than $25', '$100 mil to less than $250 mil', '$100 to 500 million'))`], value: "I", name: "$100 TO 500 MILLION"},
        {index: 9, query: [`(SALES_VOL in ('$500 MILLION TO $1 BILLION'))`], value: "J", name: "$500 MILLION TO $1 BILLION"},
        {index: 10, query: [`(SALES_VOL in ('Over $1 BILLION'))`], value: "K", name: "Over $1 BILLION"},
      ]
    },
    WEB_ADDRESS: {
      name: "Web address",
      displayHeader: true,
      inputType: "select",
      formText: "The website address may be incomplete. Selecting businesses without a web address does not guarantee that the business will not have a web address.",
      items: [
        {index: 0, value: "", name: "No preference"},
        {index: 1, query: `(WEB_ADDRESS <>"")`, value: "WWA", name: "Businesses with web addresses"},
        {index: 2, query: ['(WEB_ADDRESS = "")'], value: "WOWA", name: "Businesses without web addresses"}
      ]
    },
    TITLE : {
      name: "Title",
      displayHeader: true,
      inputType: "select",
      formText: "We do a cursory search on titles to match them up to specific types of positions.  Please double check the preview.",
      items: [
        {index: 0, value: "", name: "No preference"},
        {index: 50, value: "owner", name: "Owner", query: [`(TITLE like '%owner%')`]},
        {index: 100, value: "ceo", name: "CEO", query: [`(TITLE like '%ceo') or (TITLE like '%chief executive%')`]},
        {index: 200, value: "founder", name: "Founder", query: [`(TITLE like '%founder')`]},
        {index: 300, value: "Partner", name: "Partner", query: [`(TITLE like '%partner')`]},
        {index: 400, value: "manager", name: "Manager", query: [`(TITLE like '%manager%')`]},
        {index: 500, value: "director", name: "Director", query: [`(TITLE like '%director')`]},
        {index: 600, value: "sales", name: "Sales", query: [`(TITLE like '%sales%')`]},
        {index: 700, value: "technical", name: "Technical", query: [`(TITLE like '%techn%') or (TITLE like '%information%') or (TITLE like '%engineer%')`]},
        {index: 800, value: "president", name: "President", query: [`(TITLE like "%president%")`]},
        {index: 900, value: "officer", name: "Executives and officers", query: [`(TITLE in ('cfo', 'chief financial officer', 'ceo', 'chief executive office', 'cmo', 'chief marketing officer', 'coo', 'chief operating officer', 'cto', 'chief technology officer', 'chief technical officer', 'cio', 'chief information officer', 'cdo', 'chief data officer', 'cao', 'chief analytics officer', 'vp', 'vice president'))`]},
        {index: 1000, value: "marketing", name: "Marketing", query: [`(TITLE like '%marketing%')`]},
        {index: 1100, value: "legal", name: "Legal", query: [`(TITLE like '%counsel%') or (TITLE like '%legal%') or (TITLE like '%attorney%')`]},
        {index: 1200, value: "education", name: "Learning and education", meta: "Includes teachers, professors, deans, and administrators", query: [`(TITLE like '%educat%') or (TITLE like '%teacher%') or (TITLE like '%learning%') or (TITLE like '%professor%') or (TITLE like '%counselor%') or (TITLE like '%alumni%') or (TITLE like '%dean%') or (TITLE like '%student%')`]},
        {index: 1300, value: "accounting", name: "Finance and accounting (all)", meta: "Includes acccounting, finance, controllers, and treasurers", query: [`(TITLE like '%accounting%') or (TITLE like '%accountant%') or (TITLE like 'controller') or (TITLE like '%financ%') or (TITLE like 'cfo') or (TITLE like '%treasurer%')`]},
        {index: 1400, value: "hr", name: "Human resources", meta: "Includes human resources, recruiting, and personnel", query: [`(TITLE like '%human%') or (TITLE like '%recruit%') or (TITLE like '%personnel%')`]},
        {index: 1500, value: "medical", name: "Medical professionals and staff", meta: "Includes doctors, surgeons, nurses, and physicians", query: [`(TITLE like '%medical%') or (TITLE like '%doctor%') or (TITLE like '%nurs%') or (TITLE like '%physician%') or (TITLE like '%surge%')`]},
        {index: 1600, value: "finance", name: "Finance", query: [`(TITLE like '%financ%') or (TITLE like 'cfo') or (TITLE like '%treasurer%')`]}
      ]
    },
    phonePref: {
      name: "Contact Preferences",
      displayHeader: true,
      inputType: "checkbox",
      formText: "Click or tap to add this filter if you'd like all records to have a phone number.",
      items: [
        {index: 0, value: "0", name: "Must have Phone number", query: [`(PHONE <> '')`]}
      ]
    },
    addrPref: {
      name: "Address",
      displayHeader: false,
      inputType: "checkbox",
      formText: "Click or tap to add this filter if you'd like all records to have an address.",
      items: [
        {index: 1, value: "1", name: "Must have Address", query: [`(ADDR <> '')`]}
      ]
    },
    ownerPref: {
      name: "Business Contact",
      displayHeader: false,
      inputType: "checkbox",
      formText: "Click or tap to add this filter if you'd like all records to an owner or business contact name.",
      items: [
        {index: 2, value: "2", name: "Must have Business Contact", query: [`(FULL_NAME <> '')`]},
      ]
    },
    AREA_CODE: sharedDict.geography.AREA_CODE,
    COUNTY: sharedDict.geography.COUNTY, 
    CITY: sharedDict.geography.CITY, 
    ZIP: sharedDict.geography.ZIP, 
    ST: sharedDict.geography.ST,
    nationwide: sharedDict.geography.nationwide
  },
  keyOrder: {
    ZIP: 100,
    CITY: 200,
    COUNTY: 300,
    AREA_CODE: 400,
    ST: 500,
    SICNAICS: 725,
    EMAIL: 750,
    NUM_EMPLOYEES: 800,
    SALES_VOL: 900,
    WEB_ADDRESS: 1000,
    nationwide: 3000
  },
  defaultSearchParams: {
    EMAIL: [{value: "A", name: "Yes", query: [`(EMAIL<>'')`]}],
    SICNAICS: [],
    NUM_EMPLOYEES: [], 
    SALES_VOL: [],
    WEB_ADDRESS: [],
    TITLE: [],
    phonePref: [],
    addrPref: [],
    ownerPref: [],
    AREA_CODE: [],
    COUNTY: [],
    CITY: [],
    ZIP: [],
    ST: [],
    nationwide: []
  },
  previewCols: {
    display: [
      {label: "Company name", content: "CO_NAME"},
      {label: "Contact Name", content: "FULL_NAME"},
      {label: "Title", content: "TITLE"},
      {label: "Email", content: "EMAIL"},
      {label: "Phone", content: "PHONE"},
      //{label: "Sales volume", content: "SALES_VOL"}
    ],
    popover: [
      {label: "Company name", content: "CO_NAME"},
      {label: "Address", content: "ADDR"},
      {label: "City", content: "CITY"},
      {label: "State", content: "ST"},
      {label: "Zip", content: "ZIP"},
      {label: "Email", content: "EMAIL"},
      {label: "Website", content: "WEB_ADDRESS"},
      {label: "Phone", content: "PHONE"},
      //{label: "Sales volume", content: "SALES_VOL"}
    ]
  },
  dlCols: [
    {value: "CO_NAME", name: "Business name", default: true},
    {value: "FULL_NAME", name: "Contact name", default: true},
    {value: "TITLE", name: "Title", default: true},
    {value: "EMAIL", name: "Email", default: true},
    {value: "PHONE", name: "Phone", default: true},
    {value: "WEB_ADDRESS", name: "Web address", default: true},
    {value: "ADDR", name: "Address", default: true},
    {value: "CITY", name: "City", default: true},
    {value: "ST", name: "State", default: true},
    {value: "ZIP", name: "Zip", default: true},
    {value: "COUNTY", name: "County", default: true},
    //{value: "SALES_VOL", name: "Sales volume", default: true},
    //{value: "NUM_EMPLOYEES", name: "Number of employees", default: true}
  ],
  selectFilterValidation: ["SALES_VOL", "NUM_EMPLOYEES"],
  leadsMultiplier: 1,
  access: "Any subscription",
  summaryStats: {
    totalCount: 78012054,
    totalEmailCount: 78012054,
    totalWebAddressCount: 65857227,
    dateReleased: "2021-11-19"
  }
}