import React, { useState, useEffect, useRef } from "react";
import { Switch, Route, useParams, Link, Redirect, NavLink } from "react-router-dom";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  Col,
  Button,
  Jumbotron,
  Card,
  Modal,
  Form,
  Image,
  Table,
  ButtonGroup,
  InputGroup,
  ProgressBar,
  Dropdown,
  Tab
} from "react-bootstrap";
import logo from "./media/icon_192.png";
import {Collection, CollectionContext} from "./firebaseView.jsx";
import {db, storeMedia, archiveDoc, getDoc, getDocs} from "./firebase.jsx";
import {object_equals, makeid, formatPhoneNumber, removeItemOnce} from "./interfaceListShackPro.jsx";
import AutoComplete from "./autoComplete.jsx";
import { CopyToClipboard } from "react-copy-to-clipboard";
import {UserProfile} from "./userProfile.jsx";
import UpdateEmail from "./updateEmail.jsx";
import ChangePass from "./changePassword.jsx";
import {
  Trash,
  Pencil,
  PlusCircle,
  ArrowBarDown,
  ArrowClockwise,
  CardHeading,
  CardText,
  GearWideConnected,
  Eye,
  EyeSlash,
  ArrowUpCircle,
  ArrowUpCircleFill,
  Link as LinkIcon,
  CodeSlash
} from "react-bootstrap-icons";

import MDEditor from '@uiw/react-md-editor';
import ImportForumBee from "./forumbee.jsx";
import ImportHeadway from "./headway";
import ImportWordPress from "./wordpress";
import {TrelloBoardHeader, TrelloBoardList} from "./likeTrello.jsx";
import JSONPretty from "react-json-pretty";
import AppAlerts from "./appAlerts.jsx";
import { ComposeEmail } from "./email";
// STATIC CONTENT
const Topnav = props => {
  let { isApp, style, className, hide } = props;
  //console.log("TopnavLss rendered.");
  if (hide) { return null }
  return (
    <Navbar expand="lg"
      className={ className ? className : ""}
      style={ style ? style : null }
    >
      <Container>
        <Navbar.Brand>
          <Link id="lss_nav_text" style={{textDecoration: "none"}} to="/">
            {/*<img src={logo} alt="Digests" height="75" width="auto"/>*/}
            {!isApp && <span className="text-dark ml-2" style={{fontSize: "1.6rem"}} >{process.env.REACT_APP_site_name}</span>}
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse>
          <Nav className="mr-auto">
            {false && (
              <Nav.Item className="navbar-text">
                <Nav.Link href={`tel:${process.env.REACT_APP_contact_sms}`}>
                  <i className="fa fa-phone"></i> {formatPhoneNumber(process.env.REACT_APP_contact_sms)}
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
          <UserNav {...props}>
            <Nav.Item className="d-inline-block">
              <NavLink
                to="/settings"
                className="nav-link"
                activeClassName="activeSidebar"
              >
                <i className="fa fa-cog fa-lg" aria-hidden="true"></i>
              </NavLink>
            </Nav.Item>
            {/*<Nav.Item className="d-inline-block">
              <NavLink
                to="/support"
                className="nav-link"
                activeClassName="activeSidebar"
              >
                <i className="fa fa-question-circle fa-lg"></i>
              </NavLink>
            </Nav.Item>*/}
            <span id="hw" className="d-inline-block"></span>
          </UserNav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const UserNav = props => {
  const {user, loaded, signOut, className, style} = props;
  return(
    <Nav
      className={className ? className : ""}
      style={style ? style : null}
    >
      {user === null && loaded && (
        <React.Fragment>
          <Nav.Item>
            <Link to="/login" className="mr-3">
              <Button variant="link">login</Button>
            </Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/signup">
              <Button variant="success" size="sm">Get Started for Free</Button>
            </Link>
          </Nav.Item>
        </React.Fragment>

      )}
      {user !== null && user !== "" && loaded && (
        <React.Fragment>
          {props.children &&
            props.children
          }
          <NavDropdown id="usernav_dropdown" title={user.email} style={{ zIndex: "1031" }}
            className="d-inline-block"
          >
            <Link className="dropdown-item" to="/smries">
              Smries
            </Link>
            <Link className="dropdown-item" to="/content">
              Content
            </Link>
            <NavDropdown.Divider />
              <Link className="dropdown-item" to="/settings">
                Settings
              </Link>
              <Link className="dropdown-item" to="/support">
                Help
              </Link>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={e => signOut(e, user)}>
              <i className="fa fa-sign-out" aria-hidden="true"></i>&nbsp;Sign out
            </NavDropdown.Item>
          </NavDropdown>
        </React.Fragment>
      )}
    </Nav>
  )
}

const SideBar = props => {
  let { isApp } = props;
  const [sideBarIsOpen, setSideBarIsOpen] = useState(true);
  let isSideBarOpen = props.sideBarIsOpen;
  useEffect( () => {
    if (isSideBarOpen) {
      setSideBarIsOpen(isSideBarOpen);
    }
  }, [isSideBarOpen]);

  let sideBarWidth = sideBarIsOpen ? "16.66%" : "50px";
  return (
    <div
      className="d-none d-md-flex flex-column justify-content-between bg-light"
      style={{
        width: sideBarWidth,
        maxHeight: "100vh",
        minWidth: sideBarIsOpen ? "265px" : ""
      }}
    >
      <div>
        <div style={{ display: "block" }}>
          <Button
            onClick={e => setSideBarIsOpen(!sideBarIsOpen)}
            variant="link"
            style={{ display: "inline-block" }}
            className="pull-right"
          >
            {!sideBarIsOpen && (
              <i className="fa fa-chevron-right" aria-hidden="true"></i>
            )}
            {sideBarIsOpen && (
              <i
                className="fa fa-chevron-left"
                aria-hidden="true"
                style={{ color: "#00000080" }}
              ></i>
            )}
          </Button>
        </div>
        {sideBarIsOpen && (
          <Nav defaultActiveKey="/" className="flex-column mt-4 sideBar ml-3">
            <NavLink id="hw" className="lss_nav_text" to="/">
              {isApp && <span className="text-dark ml-2" style={{fontSize: "1.6rem"}} >{process.env.REACT_APP_site_name}</span>}
            </NavLink>
            <NavLink
              to="/smries"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <CardHeading size={20} />&nbsp;Smries
            </NavLink>

            <NavLink
              to="/content"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <CardText size={20} />&nbsp;Content
            </NavLink>

            <NavLink
              to="/import"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <ArrowBarDown size={20} />&nbsp;Import
            </NavLink>

            <NavLink
              to="/send"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <ArrowUpCircle size={20} />&nbsp;Send
            </NavLink>
          </Nav>
        )}
        {!sideBarIsOpen && (
          <Nav
            defaultActiveKey="/"
            className="flex-column justify-content-between align-items-center mt-4 sideBar sideBarClosed"
            style={{minHeight: "400px"}}
          >
            {/*<NavLink className="lss_nav_text text-center mt-3" to="/">
              <img src={logo} alt="ListShack" height="40px" width="auto"/>
            </NavLink>*/}
            <div id="hw" style={{width: "50px", height: "42px", textAlign: "center"}}></div>
            <NavLink
              to="/smries"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <CardHeading size={20} />
            </NavLink>

            <NavLink
              to="/content"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <CardText size={20} />
            </NavLink>

            <NavLink
              to="/import"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <ArrowBarDown size={20} />
            </NavLink>
            <NavLink
              to="/send"
              className="nav-link"
              activeClassName="activeSidebar"
            >
              <ArrowUpCircle size={20} />
            </NavLink>
          </Nav>
        )}
      </div>
    </div>
  );
};

const Home = props => {
  //console.log("Home props: ", props);
  let { user, handleState } = props;
  useEffect( () => {
    if (handleState) {
      handleState({
        isApp: false,
        noDistract: false
      });
    }
  }, [handleState]);

  if (false) {
    return <Redirect to={process.env.REACT_APP_redirect_path} />;
  } else {
    return (
      <Container>
        <Row
          className="bg-dark text-center text-white d-flex justify-content-center align-items-middle"
          style={{
            height: "45vh",
          }}
        >
            <Jumbotron
              style={{
                backgroundColor: "#ffffff",
                height: "100%",
                width: "100%",
              }}
              className="d-flex justify-content-center align-items-middle flex-column text-dark"
              fluid
            >
              <h1>Send better messages</h1>
              <p className="lead">
                Create simple email and text summaries.
              </p>
              <div className="mb-3">
                <Link className="btn btn-success btn" to="/signup">
                  Get Started Free
                </Link>
              </div>
            </Jumbotron>
        </Row>
        <Row>
          <Col xs={12} className="text-center bg-light p-4">
            <h4>Import integrations</h4>
            <p>Easily import content from the platforms you already use.</p>
            <div className="mt-4">
              {
                [
                  {
                    appName: "forumbee",
                    appLogo: "https://d56vh6ph4jjmq.cloudfront.net/fb-main/logo/color/forumbee.svg"
                  }
                ].map( a => {
                  return(
                    <Image key={a.appName} src={a.appLogo} style={{width: "auto", height: "35px"}} />
                  )
                })
              }
            </div>

          </Col>
          <Col xs={12} className="text-center p-4 mt-5">
            <h4>Delivery integrations</h4>
            <p>Easily send summaries using the platforms you already use.</p>
            <div className="mt-4">
              {
                [
                  {
                    appName: "customerio",
                    appLogo: "https://customer.io/wp-content/uploads/2019/01/customer-io-logo-color.svg"
                  }
                ].map( a => {
                  return(
                    <Image key={a.appName} src={a.appLogo} style={{width: "auto", height: "35px"}} />
                  )
                })
              }
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
};

const Footer = props => {
  return (
    <footer id="footer" className="bg-dark" style={{ padding: "50px 0px" }}>
      <Container>
        <Row>
          <Col>
            <a
              className="btn btn-link text-white"
              href={`mailto: ${process.env.REACT_APP_contact_email}`}
              style={{ paddingLeft: "0px" }}
            >
              <i className="fa fa-envelope"></i>{" "}
              {process.env.REACT_APP_contact_email}
            </a>
            <br />
            <a
              className="btn btn-link text-white"
              style={{ paddingLeft: "0px" }}
              href="sms: 833-222-7919"
            >
              <i className="fa fa-phone"></i> Text "smry" to {formatPhoneNumber(process.env.REACT_APP_contact_sms)}
            </a>
            <p className="text-white">
              <Link className="text-white" to="/privacy">
                Privacy policy
              </Link>{" "}
              |{" "}
              <Link className="text-white" to="/terms">
                Terms of service
              </Link>
            </p>
            <a
              className="text-white"
              href={process.env.REACT_APP_sitemap_path}
            >
              Site map
            </a>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

const FooterSmall = props => {
  return (
    <footer className="text-center" style={{ padding: "20px 0px" }}>
      <Container>
        <Row>
          <Col>
            <p
              style={{
                marginBottom: "0px",
                fontSize: ".8em",
                color: "#00000080"
              }}
            >
              <Link to="/privacy" style={{ color: "#00000080" }}>
                Privacy policy
              </Link>
              &nbsp;&#183;&nbsp;
              <Link to="/terms" style={{ color: "#00000080" }}>
                Terms of service
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

const FooterPoweredBy = props => {
  return (
    <footer className="text-center" style={{ padding: "20px 0px" }}>
      <Container>
        <Row>
          <Col
            style={{
              marginBottom: "0px",
              fontSize: ".8em",
              color: "#00000080"
            }}
          >
              <Link to="/home" style={{ color: "#00000080" }}>
                powered by {process.env.REACT_APP_site_name}
              </Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

const Privacy = props => {
  return(
    <Container>
      <Row>
        <Col className="m-5">
          <h1>Privacy Policy</h1>
          <p>Welcome to {process.env.REACT_APP_site_name.toUpperCase()} (“{process.env.REACT_APP_site_name.toUpperCase()}”, “us”, “we”, or “our”). {process.env.REACT_APP_site_name.toUpperCase()} provides services that aim to help our users streamline or improve their businesses and related tasks (our “Services”). Edinburgh Holdings LLC operates this site {window.location.origin} (the "Site").</p>

          <p>Our Privacy Policy explains how we use, share and protect information in relation to our Site.</p>

          <p>By using our Site and Services, you understand and agree that we are providing a platform for you to post content, including photos, comments and other materials (collectively "Content"), to the Services and to share Content publicly. This means that other Users may search for, see, use, or share any of your Content that you make publicly available through the Services, consistent with the terms and conditions of this Privacy Policy and our Terms of Use​.</p>

          <p>Our Privacy Policy applies to all visitors, users, and others who access the Services or Site (collectively "Users").</p>

          <h3>Information Collection And Use</h3>
          <p>While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your name, your e-mail address, and your phone number. ("Personal Information").</p>

          <p>Log Data, like many site operators, collects information that your browser sends, whenever you visit our Site ("Log Data").</p>

          <p>This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics.</p>

          <p>In addition, we may use third-party services such as Google Analytics that collect, monitor and analyze this Log Data.</p>

          <h3>Communications</h3>
          <p>We may use your Personal Information to contact with our Site updates and subscription changes.</p>

          <h3>Cookies</h3>
          <p>Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive. Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to access some Services on our Site.</p>

          <h3>Security</h3>
          <p>The security of your Personal Information is important to us. But, remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.</p>

          <h3>Disclosure for Legal Reasons</h3>
          <p>We may share Personal Information with companies, organizations or individuals outside of {process.env.REACT_APP_site_name.toUpperCase()}, if we have a good-faith belief that access, use, preservation or disclosure of the information is reasonably necessary to:</p>
          <ul>
            <li>Meet any applicable law, regulation, legal process or enforceable governmental request.</li>
            <li>Enforce applicable Terms of Service, including investigation of potential violations.</li>
            <li>Detect, prevent, or otherwise address fraud, security or technical issues.</li>
            <li>Protect against harm to the rights, property or safety of {process.env.REACT_APP_site_name.toUpperCase()}, our Users or the public, as required or permitted by law.</li>
          </ul>

          <h3>Storage and Processing</h3>
          <p>Your information collected through the Services may be stored and processed in the United States or any other country in which {process.env.REACT_APP_site_name.toUpperCase()}, Directangular, or its service providers maintain facilities.</p>

          <p>By registering for and using the Services you consent to the transfer of information to the U.S. or to any other country in which {process.env.REACT_APP_site_name.toUpperCase()}, Directangular, or its service providers maintain facilities and the use and disclosure of information about you as described in this Privacy Policy.</p>

          <p>We use commercially reasonable safeguards to help keep the information collected through the Site secure and take reasonable steps (such as requesting a unique password) to verify your identity before granting you access to your account. However, {process.env.REACT_APP_site_name.toUpperCase()} cannot ensure the security of any information you transmit to our Site or guarantee that information on the Services may not be accessed, disclosed, altered, or destroyed.</p>

          <p>Please do your part to help us! You are responsible for maintaining the secrecy of your unique password and account information.</p>

          <h3>Other Websites and Services</h3>
          <p>We are not responsible for the practices employed by any websites or services linked to or from our Site, including the information or content contained within them. Please remember that when you use a link to go from our Site to another website or service, our Privacy Policy does not apply to those third-party websites or services. Your browsing and interaction on any third-party website or service, including those that have a link on our Site, are subject to that third party's own rules and policies. In addition, you agree that we are not responsible and do not have control over any third-parties that you authorize to access your Content. If you are using a third-party website or service and you allow them to access your Content you do so at your own risk.</p>

          <h3>Changes To This Privacy Policy</h3>
          <p>This Privacy Policy is effective as of the date above and will remain in effect, except with respect to any future changes, which will go into effect immediately after being posted on this page.</p>

          <p>Our Privacy Policy may change from time to time. We reserve the right to make changes, so you should periodically check this Privacy Policy. Your continued use of the Services, after we post any modifications to the Privacy Policy, will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.</p>

          <p>If we make any material changes to this Privacy Policy, we will make an effort to notify you either through the email address you have provided us or by placing a prominent notice on our Site.</p>

          <h3>Contact Us</h3>
          <p>If you have any questions about this Privacy Policy, please contact us.</p>

          <small>version June 30, 2021</small>
        </Col>
      </Row>
    </Container>
  )
}

const Terms = props => {
  return(
    <Container>
      <Row>
        <Col className="m-5">
          <h1>TERMS OF SERVICE</h1>

          <p>Thank you for selecting the Services offered by {process.env.REACT_APP_site_name.toUpperCase()} and/or its subsidiaries and affiliates (referred to as "{process.env.REACT_APP_site_name.toUpperCase()}", "we", "our", or "us"). Review these Terms of Service ("Agreement") thoroughly. This Agreement is a legal agreement between you and {process.env.REACT_APP_site_name.toUpperCase()}. By accepting electronically (for example, clicking "I Agree"), installing, accessing or using the Services, you agree to these terms. You may not use these services if you do not agree to this Agreement.</p>

          <h5>1. AGREEMENT</h5>

          <p>This Agreement describes the terms governing your use of the {process.env.REACT_APP_site_name.toUpperCase()} online services provided to you on this website, including content, updates and new releases, (collectively, the "Services"). It includes by reference:</p>
          <ul>
            <li>{process.env.REACT_APP_site_name.toUpperCase()}'s Privacy Statement provided to you in the Services available on the website or provided to you otherwise.</li>
            <li>Additional Terms and Conditions, which may include those from third parties.</li>
            <li>Any terms provided separately to you for the Services, including product or program terms, ordering, activation, payment terms, etc.</li>
          </ul>
          <h5>2. YOUR RIGHTS TO USE THE SERVICES</h5>

          <p>2.1 You are only granted the right to use the Services and only for the purposes described by {process.env.REACT_APP_site_name.toUpperCase()}. {process.env.REACT_APP_site_name.toUpperCase()} reserves all other rights in the Services. Until termination of this Agreement and as long as you meet any applicable payment obligations and comply with this Agreement, {process.env.REACT_APP_site_name.toUpperCase()} grants to you a personal, limited, nonexclusive, nontransferable right and license to use the Services.</p>

          <p>2.2 You agree not to use, nor permit any third party to use, the Services or content in a manner that violates any applicable law, regulation or this Agreement. You agree you will not:</p>
          <ul>
            <li>Provide access to or give any part of the Services to any third party.</li>
            <li>Reproduce, modify, copy, deconstruct, sell, trade or resell the Services.</li>
            <li>Make the Services available on any file-sharing or application hosting service.</li>
          </ul>

          <h5>3. SUBSCRIPTION</h5>
          <p>The Software is licensed on a monthly or yearly subscription basis, as selected by User or its agent.</p>

          <p>3.1 Termination by User. User may notify {process.env.REACT_APP_site_name.toUpperCase()} to cancel the subscription prior to the beginning of each Renewal Term. Your rights to use the Software may be terminated by {process.env.REACT_APP_site_name.toUpperCase()} immediately and without notice if {process.env.REACT_APP_site_name.toUpperCase()} is unable to debit your or its agent's Card in accordance with this Agreement.</p>

          <p>3.2 Transfer of Agreement.  The agreement can be transferred to another person or entity only if such license has not been activated. Once validated, and a company is created, the Software will not work by another entity. Even if you stop using the Software, {process.env.REACT_APP_site_name.toUpperCase()} has fulfilled your subscription term.</p>

          <p>3.3 Trial Period. If you registered for a trial use of the Services ("Trial Period"), you must decide to purchase the Services within the Trial Period in order to retain any Content (defined in Section 6) that you have posted or uploaded during the Trial Period. If you do not purchase the Services or products by the end of the Trial Period, your Content will no longer be available to you. To be very clear, after using the Services or product during the trial period, if you decide not to purchase the full version of the Services, you will not be able to access or retrieve any of the data you added/created during the trial.</p>

          <h5>4. PAYMENT.</h5>
          <p>For Services offered on a payment or subscription basis, the following terms apply, unless {process.env.REACT_APP_site_name.toUpperCase()} notifies you otherwise in writing. This Agreement also incorporates by reference and includes program ordering and payment terms provided to you on the website for the Services:</p>
          <p>4.1   Payments will be billed to you in U.S. dollars, and your account will be debited when you subscribe and provide your payment information, unless stated otherwise in the program ordering or payment terms on the website for the Services.</p>
          <p>4.2 You must pay with one of the following:</p>
          <ol type="a">
            <li>A valid credit card acceptable to {process.env.REACT_APP_site_name.toUpperCase()};</li>
            <li>A valid debit card acceptable to {process.env.REACT_APP_site_name.toUpperCase()};</li>
            <li>Sufficient funds in a checking or savings account to cover an electronic debit of the payment due; </li>
            <li>By another payment option {process.env.REACT_APP_site_name.toUpperCase()} provides to you in writing.</li>
          </ol>

          <p>4.3 If your payment and registration information is not accurate, current, and complete and you do not notify us promptly when such information changes, we may suspend or terminate your account and refuse any use of the Services.</p>

          <p>4.4  If you do not notify us of updates to your payment method (e.g., credit card expiration date), to avoid interruption of your service, we may participate in programs supported by your card provider (e.g., updater services, recurring billing programs, etc.) to try to update your payment information, and you authorize us to continue billing your account with the updated information that we obtain.</p>

          <p>4.5 {process.env.REACT_APP_site_name.toUpperCase()} will automatically renew your monthly or annual Services at the current rates, unless the Services are cancelled or terminated under this Agreement.</p>

          <p>4.6 Additional cancellation or renewal terms may be provided to you on the website for the Services.</p>

          <p>4.7 If you prefer to make payment via invoices, you may do so by contacting {process.env.REACT_APP_site_name.toUpperCase()}. All fees are due thirty days from the invoice date. All payments due are in U.S. dollars unless otherwise indicated in an Order Form. Payments made via wire transfer must include the following instructions: Bank Name and Address, Routing Number, and Account number.</p>

          <p>4.8 Delinquent payments may bear interest at the rate of one-and-one-half percent per month (or the highest rate permitted by law, if less) from the payment due date until paid in full. Customer will be responsible for all reasonable expenses (including attorneys' fees) incurred by {process.env.REACT_APP_site_name.toUpperCase()} in collecting such delinquent amounts, except where such delinquent amounts are due to {process.env.REACT_APP_site_name.toUpperCase()}’s billing inaccuracies.</p>

          <p>4.9 Purchase Orders. Required. If Customer wants a Purchase Order number on its invoice, Customer will inform {process.env.REACT_APP_site_name.toUpperCase()} and issue a Purchase Order to {process.env.REACT_APP_site_name.toUpperCase()}. If Customer requires a Purchase Order, and fails to provide the Purchase Order to {process.env.REACT_APP_site_name.toUpperCase()}, then {process.env.REACT_APP_site_name.toUpperCase()} will not be obligated to provide the Services until the Purchase Order has been received by {process.env.REACT_APP_site_name.toUpperCase()}. Any terms and conditions on a Purchase Order do not apply to this Agreement and are null and void. Not Required. If Customer does not require a Purchase Order number to be included on the invoice, Customer will provide {process.env.REACT_APP_site_name.toUpperCase()} a waiver of the Purchase Order requirement, which may be an email to this effect. If Customer waives the Purchase Order requirement, then: (a) {process.env.REACT_APP_site_name.toUpperCase()} will invoice Customer without a Purchase Order; and (b) Customer agrees to pay invoices without a Purchase Order.</p>
          <p>4.10 Taxes. Customer is responsible for any Taxes, and Customer will pay {process.env.REACT_APP_site_name.toUpperCase()} for the Services without any reduction for Taxes. If {process.env.REACT_APP_site_name.toUpperCase()} is obligated to collect or pay Taxes, the Taxes will be invoiced to Customer, unless Customer provides {process.env.REACT_APP_site_name.toUpperCase()} with a valid tax exemption certificate authorized by the appropriate taxing authority. If Customer is required by law to withhold any Taxes from its payments to {process.env.REACT_APP_site_name.toUpperCase()}, Customer must provide {process.env.REACT_APP_site_name.toUpperCase()} with an official tax receipt or other appropriate documentation to support such payments.</p>

          <h5>5. YOUR PERSONAL INFORMATION. </h5>
          <p>You can view {process.env.REACT_APP_site_name.toUpperCase()}'s Privacy Statement provided with the Services and on the website for the Services. You agree to the applicable {process.env.REACT_APP_site_name.toUpperCase()} Privacy Statement, and changes published by {process.env.REACT_APP_site_name.toUpperCase()}. You agree that {process.env.REACT_APP_site_name.toUpperCase()} may use and maintain your data according to the {process.env.REACT_APP_site_name.toUpperCase()} Privacy Statement, as part of the Services. You also give {process.env.REACT_APP_site_name.toUpperCase()} permission to aggregate your non-personally identifiable data which you enter or upload with that of other users of the Services. For example, this means that {process.env.REACT_APP_site_name.toUpperCase()} may use that aggregated data to improve services, design promotions, or provide ways for you to compare business practices with other users.</p>

          <h5>6. CONTENT</h5>

          <p>6.1  You are responsible for your content. You are responsible for all materials ("Content") uploaded, posted or stored through your use of the Services. Archive your Content frequently. You are responsible for any lost or unrecoverable Content. You must provide all required and appropriate warnings, information and disclosures. {process.env.REACT_APP_site_name.toUpperCase()} is not responsible for the Content or data you submit through the Services.</p>

          <p>You agree not to use, nor permit any third party to use, the Services to upload, post, distribute, link to, publish, reproduce, engage in or transmit any of the following, including but not limited to:</p>
          <ol type="a">
            <li>Illegal, fraudulent, defamatory, obscene, pornographic, profane, threatening, abusive, hateful, harassing, offensive, inappropriate or objectionable information or communications of any kind, including without limitation conduct that would encourage "flaming" others, or criminal or civil liability under any local, state, federal or foreign law;</li>
            <li>Content that would impersonate someone else or falsely represent your identity or qualifications, or that constitutes a breach of any individual's privacy;</li>
            <li>Except as permitted by {process.env.REACT_APP_site_name.toUpperCase()} in writing, investment opportunities, solicitations, chain letters, pyramid schemes, other unsolicited commercial communication or engage in spamming or flooding;</li>
            <li>Virus, trojan horse, worm or other disruptive or harmful software or data; and</li>
          </ol>

          <p>Any information, software or Content which is not legally yours and without permission from the copyright owner or intellectual property rights owner.</p>

          <p>6.2  Community forums. The Services may include a community forum or other social features to exchange Content and information with other users of the Services and the public. Please use respect when you interact with other users. Do not reveal information that you do not want to make public.</p>

          <p>6.3  {process.env.REACT_APP_site_name.toUpperCase()} may freely use feedback you provide. You agree that {process.env.REACT_APP_site_name.toUpperCase()} may use your feedback, suggestions, or ideas in any way, including in future modifications of the Services, other products or services, advertising or marketing materials. You grant {process.env.REACT_APP_site_name.toUpperCase()} a perpetual, worldwide, fully transferable, sub-licensable, non-revocable, fully paid-up, royalty free license to use the feedback you provide to {process.env.REACT_APP_site_name.toUpperCase()} in any way.</p>

          <p>6.4  {process.env.REACT_APP_site_name.toUpperCase()} may monitor your Content. {process.env.REACT_APP_site_name.toUpperCase()} may, but has no obligation to, monitor content on the Services. We may disclose any information necessary to satisfy our legal obligations, protect {process.env.REACT_APP_site_name.toUpperCase()} or its customers, or operate the Services properly. {process.env.REACT_APP_site_name.toUpperCase()}, in its sole discretion, may refuse to post, remove, or refuse to remove, any Content, in whole or in part, alleged to be unacceptable, undesirable, inappropriate, or in violation of this Agreement.</p>

          <p>6.5  {process.env.REACT_APP_site_name.toUpperCase()} may publicize that you are our customer. You agree that {process.env.REACT_APP_site_name.toUpperCase()} may, but has no obligation, to publish that you are an {process.env.REACT_APP_site_name.toUpperCase()} customer, include your name or Brand Features in a list of {process.env.REACT_APP_site_name.toUpperCase()} customers, online or in promotional materials. You also agrees that {process.env.REACT_APP_site_name.toUpperCase()} may verbally reference you as a customer of the Services.</p>

          <h5>7. PERMITTED DISCLOSURES AND USE OF DATA. </h5>
          <p>You acknowledge and agree that in order to provide you with access to and use of the Software and Services, {process.env.REACT_APP_site_name.toUpperCase()} may provide your Access Information and Account Data to (i) your employee or agent who is identified in the Registration Data as the current system administrator for the your account (the "Current Administrator"), (ii) such other employee or agent who may be designated by you as a replacement administrator for the your account by following the procedures required by {process.env.REACT_APP_site_name.toUpperCase()} to effectuate such replacement, and (iii) any other person identified as an authorized user of the Software in the set-up interview form or in any subsequent communication to {process.env.REACT_APP_site_name.toUpperCase()} (collectively, "Information Recipients").</p>

          <h5>8. SOFTWARE USE, STORAGE AND ACCESS. </h5>
          <p>{process.env.REACT_APP_site_name.toUpperCase()} shall have the right, in its sole discretion and with reasonable notice posted on its website and/or sent to you at the Current Administrator's email address provided in the Registration Data, to revise, update, or otherwise modify the Services and establish or change limits concerning use of the Software and Services, temporarily or permanently, including but not limited to (i) the amount of storage space you have on the Software at any time, and (ii) the number of times (and the maximum duration for which) you may access the Software in a given period of time. {process.env.REACT_APP_site_name.toUpperCase()} reserves the right to make any such changes effective immediately to maintain the security of the system or User Access Information or to comply with any laws or regulations, and to provide you with electronic or written notice within thirty (30) days after such change. You may reject changes by discontinuing use of the Software and Services to which such changes relate. Your continued use of the Software or Services will constitute your acceptance of and agreement to such changes. {process.env.REACT_APP_site_name.toUpperCase()} may, from time to time, perform maintenance upon the Software or Services resulting in interrupted service, delays or errors in the Software or Services. {process.env.REACT_APP_site_name.toUpperCase()} will attempt to provide prior notice of scheduled maintenance but cannot guarantee that such notice will be provided. {process.env.REACT_APP_site_name.toUpperCase()} reserves the right to use a third party to host the Services from time-to-time. </p>

          <h5>9. USE WITH YOUR MOBILE DEVICE</h5>

          <p>Use of these Services may be available through a compatible mobile device, Internet access and may require software. You agree that you are solely responsible for these requirements, including any applicable changes, updates and fees as well as the terms of your agreement with your mobile device and telecommunications provider.</p>

          <p>{process.env.REACT_APP_site_name.toUpperCase()} MAKES NO WARRANTIES OR REPRESENTATIONS OF ANY KIND, EXPRESS, STATUTORY OR IMPLIED AS TO:</p>
          <ol type="i">
            <li>THE AVAILABILITY OF TELECOMMUNICATION SERVICES FROM YOUR PROVIDER AND ACCESS TO THE SERVICES AT ANY TIME OR FROM ANY LOCATION;</li>
            <li>ANY LOSS, DAMAGE, OR OTHER SECURITY INTRUSION OF THE TELECOMMUNICATION SERVICES; AND</li>
            <li>ANY DISCLOSURE OF INFORMATION TO THIRD PARTIES OR FAILURE TO TRANSMIT ANY DATA, COMMUNICATIONS OR SETTINGS CONNECTED WITH THE SERVICES.</li>
          </ol>

          <h5>10. INTELLECTUAL PROPERTY RIGHTS, COPYRIGHT, AND TRADEMARKS. </h5>
          <p>Except as expressly set forth herein, this Agreement does not grant either party any rights, implied or otherwise, to the other's content or any of the other's intellectual property. As between the parties, Customer owns all Intellectual Property Rights in Customer Data, and {process.env.REACT_APP_site_name.toUpperCase()} owns all Intellectual Property Rights in the Services. The information available on or through this Site is the property of {process.env.REACT_APP_site_name.toUpperCase()}, or its licensors, and is protected by copyright, trademark, and other intellectual property laws. Users may not modify, copy, distribute, transmit, display, publish, sell, license, create derivative works or otherwise use any information available on or through this Site for commercial or public purposes. Users may not use the trademarks, logos and service marks ("Marks") for any purpose including, but not limited to use as "hot links" or meta tags in other pages or sites on the World Wide Web without the written permission of {process.env.REACT_APP_site_name.toUpperCase()} or such third party that may own the Mark. Questions concerning trademarks should be directed to {process.env.REACT_APP_contact_email}.</p>

          <h5>11. TAMPERING. </h5>
          <p>User agrees not to modify, move, add to, delete or otherwise tamper with the information contained in {process.env.REACT_APP_site_name.toUpperCase()}’s Web site to submit questions or comments on user forums or during the normal use of the Services. User also agrees not to decompile, reverse engineer, disassemble or unlawfully use or reproduce any of the software, copyrighted or trademarked material, trade secrets, or other proprietary information contained in the Site.</p>

          12. THIRD PARTY INFORMATION.
          <p>Although {process.env.REACT_APP_site_name.toUpperCase()} monitors the information on the Site, some of the information is supplied by independent third parties. While {process.env.REACT_APP_site_name.toUpperCase()} makes every effort to insure the accuracy of all information on the Site, {process.env.REACT_APP_site_name.toUpperCase()} makes no warranty as to the accuracy of any such information.</p>

          <h5>13. LINKS TO THIRD PARTY SITES. </h5>
          <p>This Site may contain links that will let you access other Web sites that are not under the control of {process.env.REACT_APP_site_name.toUpperCase()}. The links are only provided as a convenience and {process.env.REACT_APP_site_name.toUpperCase()} does not endorse any of these sites. {process.env.REACT_APP_site_name.toUpperCase()} assumes no responsibility or liability for any material that may accessed on other Web sites reached through this Site, nor does {process.env.REACT_APP_site_name.toUpperCase()} make any representation regarding the quality of any product or service contained at any such site.</p>

          <h5>14. LINKS FROM THIRD PARTY SITES. </h5>
          <p>{process.env.REACT_APP_site_name.toUpperCase()} prohibits unauthorized links to the Services and the framing of any information contained on the website or Services. {process.env.REACT_APP_site_name.toUpperCase()} reserves the right to disable any unauthorized links or frames. {process.env.REACT_APP_site_name.toUpperCase()} has no responsibility or liability for any material on other Web sites that may contain links to this Site.</p>

          <h5>15. ADDITIONAL TERMS</h5>

          <p>15.1 {process.env.REACT_APP_site_name.toUpperCase()} does not give professional advice. Unless specifically included with the Services, {process.env.REACT_APP_site_name.toUpperCase()} is not in the business of providing legal, financial, accounting, tax, health care, real estate, marketing or other professional services or advice. Consult the services of a competent professional when you need this type of assistance.</p>

          <p>15.2 We may tell you about other {process.env.REACT_APP_site_name.toUpperCase()} services. You may be offered other services, products, or promotions by {process.env.REACT_APP_site_name.toUpperCase()} ("{process.env.REACT_APP_site_name.toUpperCase()} Services"). Additional terms and conditions and fees may apply. With some {process.env.REACT_APP_site_name.toUpperCase()} Services you may upload or enter data from your account(s) such as names, addresses and phone numbers, purchases, etc., to the Internet. You grant {process.env.REACT_APP_site_name.toUpperCase()} permission to use information about your business and experience to help us to provide the {process.env.REACT_APP_site_name.toUpperCase()} Services to you and to enhance the Services. You grant {process.env.REACT_APP_site_name.toUpperCase()} permission to combine your business data, if any, with that of others in a way that does not identify you or any individual personally. You also grant {process.env.REACT_APP_site_name.toUpperCase()} permission to share or publish summary results relating to research data and to distribute or license such data to third parties.</p>

          <p>15.3 Communications. {process.env.REACT_APP_site_name.toUpperCase()} may be required by law to send you communications about the Services or Third Party Products. You agree that {process.env.REACT_APP_site_name.toUpperCase()} may send these communications to you via email or by posting them on our websites</p>

          <p>15.4 You will manage your passwords and accept updates. You are responsible for securely managing your password(s) for the Services and to contact {process.env.REACT_APP_site_name.toUpperCase()} if you become aware of any unauthorized access to your account. The Services may periodically be updated with tools, utilities, improvements, third party applications, or general updates to improve the Services. You agree to receive these updates.</p>

          <h5>16. SECURITY. </h5>
          <p>Data transmitted to and from the Services is encrypted for the user's protection. However, the security of information transmitted through the Internet can never be guaranteed. {process.env.REACT_APP_site_name.toUpperCase()} is not responsible for any interception or interruption of any communications through the Internet or for changes to or losses of data. In order to protect you and your data, {process.env.REACT_APP_site_name.toUpperCase()} may suspend your use of a client site, without notice, pending an investigation, if any breach of security is suspected. All facilities used to store and process Customer Data will adhere to reasonable security standards no less protective than the security standards at facilities where {process.env.REACT_APP_site_name.toUpperCase()} stores and processes its own information of a similar type. {process.env.REACT_APP_site_name.toUpperCase()} has implemented at least industry standard systems and procedures to ensure the security and confidentiality of Customer Data, protect against anticipated threats or hazards to the security or integrity of Customer Data, and protect against unauthorized access to or use of Customer Data.</p>

          <h5>17. DISCLAIMER OF WARRANTIES</h5>

          17.1  YOUR USE OF THE SERVICES, SOFTWARE, AND CONTENT IS ENTIRELY AT YOUR OWN RISK. EXCEPT AS DESCRIBED IN THIS AGREEMENT, THE SERVICES ARE PROVIDED "AS IS." TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, {process.env.REACT_APP_site_name.toUpperCase()}, ITS AFFILIATES, AND ITS THIRD PARTY PROVIDERS, LICENSORS, DISTRIBUTORS OR SUPPLIERS (COLLECTIVELY,"SUPPLIERS") DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING ANY WARRANTY THAT THE SERVICES ARE FIT FOR A PARTICULAR PURPOSE, TITLE, MERCHANTABILITY, DATA LOSS, NON-<p>INTERFERENCE WITH OR NON-INFRINGEMENT OF ANY INTELLECTUAL PROPERTY RIGHTS, OR THE ACCURACY, RELIABILITY, QUALITY OR CONTENT IN OR LINKED TO THE SERVICES. {process.env.REACT_APP_site_name.toUpperCase()} AND ITS AFFILIATES AND SUPPLIERS DO NOT WARRANT THAT THE SERVICES ARE SECURE, FREE FROM BUGS, VIRUSES, INTERRUPTION, ERRORS, THEFT OR DESTRUCTION. IF THE EXCLUSIONS FOR IMPLIED WARRANTIES DO NOT APPLY TO YOU, ANY IMPLIED WARRANTIES ARE LIMITED TO 60 DAYS FROM THE DATE OF PURCHASE OR DELIVERY OF THE SERVICES, WHICHEVER IS SOONER.</p>

          <p>17.2  {process.env.REACT_APP_site_name.toUpperCase()}, ITS AFFILIATES AND SUPPLIERS DISCLAIM ANY REPRESENTATIONS OR WARRANTIES THAT YOUR USE OF THE SERVICES WILL SATISFY OR ENSURE COMPLIANCE WITH ANY LEGAL OBLIGATIONS OR LAWS OR REGULATIONS.</p>

          <h5>18. LIMITATION OF LIABILITY. </h5>
          <p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE ENTIRE LIABILITY OF {process.env.REACT_APP_site_name.toUpperCase()}, ITS AFFILIATES AND SUPPLIERS FOR ALL CLAIMS RELATING TO THIS AGREEMENT SHALL BE LIMITED TO THE AMOUNT YOU PAID FOR THE SERVICES DURING THE TWELVE (12) MONTHS PRIOR TO SUCH CLAIM. SUBJECT TO APPLICABLE LAW, {process.env.REACT_APP_site_name.toUpperCase()}, ITS AFFILIATES AND SUPPLIERS ARE NOT LIABLE FOR ANY OF THE FOLLOWING: (A) INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR CONSEQUENTIAL DAMAGES; (B) DAMAGES RELATING TO FAILURES OF TELECOMMUNICATIONS, THE INTERNET, ELECTRONIC COMMUNICATIONS, CORRUPTION, SECURITY, LOSS OR THEFT OF DATA, VIRUSES, SPYWARE, LOSS OF BUSINESS, REVENUE, PROFITS OR INVESTMENT, OR USE OF SOFTWARE OR HARDWARE THAT DOES NOT MEET {process.env.REACT_APP_site_name.toUpperCase()} SYSTEMS REQUIREMENTS. THE ABOVE LIMITATIONS APPLY EVEN IF {process.env.REACT_APP_site_name.toUpperCase()} AND ITS AFFILIATES AND SUPPLIERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. THIS AGREEMENT SETS FORTH THE ENTIRE LIABILITY OF {process.env.REACT_APP_site_name.toUpperCase()}, ITS AFFILIATES AND YOUR EXCLUSIVE REMEDY WITH RESPECT TO THE SERVICES AND ITS USE.</p>

          <h5>19. INDEMNITY. </h5>
          <p>You agree to indemnify and hold {process.env.REACT_APP_site_name.toUpperCase()} and its Affiliates and Suppliers harmless from any and all claims, liability and expenses, including reasonable attorneys' fees and costs, arising out of your use of the Services or breach of this Agreement (collectively referred to as "Claims"). {process.env.REACT_APP_site_name.toUpperCase()} reserves the right, in its sole discretion and at its own expense, to assume the exclusive defense and control of any Claims. You agree to reasonably cooperate as requested by {process.env.REACT_APP_site_name.toUpperCase()} in the defense of any Claims.</p>

          <h5>20. CHANGES. </h5>
          <p>We reserve the right to change this Agreement at any time, and the changes will be effective when posted through the Services, on our website for the Services or when we notify you by other means. We may also change or discontinue the Services, in whole or in part. Your continued use of the Services indicates your agreement to the changes.</p>

          <h5>21. TERMINATION. </h5>
          <p>{process.env.REACT_APP_site_name.toUpperCase()} may immediately, in its sole discretion and without notice terminate this Agreement or suspend the Services if you fail to comply with this Agreement or if you no longer agree to receive electronic communications. Upon termination you must immediately stop using the Services and any outstanding payments will become due. Any termination of this Agreement shall not affect {process.env.REACT_APP_site_name.toUpperCase()}'s rights to any payments due to it. {process.env.REACT_APP_site_name.toUpperCase()} may terminate a free account at any time. Sections 2.2, 3 through 14 will survive and remain in effect even if the Agreement is terminated.</p>

          <h5>22. EXPORT RESTRICTIONS. </h5>
          <p>You acknowledge that the Services, including the mobile application, and the underlying software may include U.S. technical data subject to restrictions under export control laws and regulations administered by the United States government. You agree that you will comply with these laws and regulations, and will not export or re-export any part of the Services, in violation of these laws and regulations, directly or indirectly.</p>

          <h5>23. GOVERNING LAW. </h5>
          <p>Laws of Delaware govern this Agreement without regard to its conflicts of laws provisions.</p>

          <h5>24. DISPUTES. </h5>
          <p>ANY DISPUTE OR CLAIM RELATING IN ANY WAY TO THE SERVICES OR THIS AGREEMENT WILL BE RESOLVED BY BINDING ARBITRATION, RATHER THAN IN COURT, except that you may assert claims in small claims court if your claims qualify. The Federal Arbitration Act governs the interpretation and enforcement of this provision; the arbitrator shall apply Delaware law to all other matters. Notwithstanding anything to the contrary, any party to the arbitration may at any time seek injunctions or other forms of equitable relief from any court of competent jurisdiction. WE EACH AGREE THAT ANY AND ALL DISPUTES MUST BE BROUGHT IN THE PARTIES' INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. BY ENTERING INTO THIS AGREEMENT AND AGREEING TO ARBITRATION, YOU AGREE THAT YOU AND {process.env.REACT_APP_site_name.toUpperCase()} ARE EACH WAIVING THE RIGHT TO FILE A LAWSUIT AND THE RIGHT TO A TRIAL BY JURY. IN ADDITION, YOU AGREE TO WAIVE THE RIGHT TO PARTICIPATE IN A CLASS ACTION OR LITIGATE ON A CLASS-WIDE BASIS. YOU AGREE THAT YOU HAVE EXPRESSLY AND KNOWINGLY WAIVED THESE RIGHTS.</p>

          <h5>25. GENERAL. </h5>
          <p>This Agreement, including any Additional Terms below, is the entire agreement between you and {process.env.REACT_APP_site_name.toUpperCase()} and replaces all prior understandings, communications and agreements, oral or written, regarding its subject matter. If any court of law, having the jurisdiction, rules that any part of this Agreement is invalid, that section will be removed without affecting the remainder of the Agreement. The remaining terms will be valid and enforceable. The United Nations Convention on Contracts for the International Sale of Goods does not apply to this Agreement. You cannot assign or transfer ownership of this Agreement to anyone without written approval of {process.env.REACT_APP_site_name.toUpperCase()}. However, {process.env.REACT_APP_site_name.toUpperCase()} may assign or transfer it without your consent to (a) an affiliate, (b) a company through a sale of assets by {process.env.REACT_APP_site_name.toUpperCase()} or (c) a successor by merger. Any assignment in violation of this Section shall be void. If you want to request a transfer of this Agreement, contact {process.env.REACT_APP_site_name.toUpperCase()} via an email to: {process.env.REACT_APP_contact_email}.</p>

          <h5>26. ACCEPTANCE OF THESE TERMS AND CONDITIONS. </h5>
          <p>By using the Software platform, User indicates that he/she has read and understood, and assents to be bound by, the terms of this Agreement. If the person using the platform is an individual working for User ("Agent"), such person is agreeing to the terms and conditions of this Agreement on behalf of the User and certifies that he/she is an Agent of User and has all necessary authority to act on your behalf, including to bind the User to the terms and conditions of this Agreement.</p>

          <p>If the User does not agree to the terms of the Agreement, User is not granted any rights whatsoever in the Software. If User is not willing to be bound by these terms and conditions, User should not use the Software platform and must discontinue access and use of the Software and Services.</p>
        </Col>
      </Row>
    </Container>
  )
}

const settingsPanes = [
  {
    id: "profile",
    name: "Profile",
    admin: false,
    activeKey: "sixth",
    content: props => {
      return (
        <Tab.Pane eventKey="first">
          <UserProfile {...props}
            heading={
              <React.Fragment>
                <h3>Account profile </h3>
                <Form.Text className="mb-3">
                  Manage your account profile
                </Form.Text>
              </React.Fragment>
            }
            size="md"
            nameText="This will be displayed to your organization."
            phoneText="This will be used to contact you via text message about your
            account."
            emailText="This will be used to contact you via email about your account. Use the Update Email settings page if you'd like to update your email."
            addInfo={[]}
          />
        </Tab.Pane>
      );
    }
  },
  {
    id: "update_email",
    name: "Update email",
    activeKey: "fourth",
    content: props => {
      return (
        <Tab.Pane eventKey="first">
          <UpdateEmail {...props} />
        </Tab.Pane>
      );
    }
  },
  {
    id: "change_password",
    name: "Change password",
    activeKey: "first",
    content: props => {
      const { user, handleAlerts } = props;
      return (
        <Tab.Pane eventKey="first">
          <ChangePass user={user} handleAlerts={handleAlerts} />
        </Tab.Pane>
      );
    }
  }
];

// APP CONTENT
const Digests = props => {
  const { userDoc, handleState } = props;

  useEffect(() => {
    handleState({ isApp: true });
  }, [handleState]);
  return (
    <React.Fragment>
      <Collection
        colRef={db}
        collection="summary"
        permissions={["read", "write", "delete"]}
        where={[
          { field: "aid", operand: "==", term: userDoc.id },
          { field: "archived", operand: "!=", term: true }
        ]}
        orderBy={[{ field: "archived" }, { field: "_created" }]}
      >
        <CollectionContext.Consumer>
          {(context) => {
            return(
              <TrelloBoardList {...props} {...context} cardPath="/smry" boardCollectionRef={db.collection("summary")} config={{sections:[]}} />
            )
          }}
        </CollectionContext.Consumer>
      </Collection>
    </React.Fragment>
  );
};

const Integrations = props => {
  console.log("Integrations props: ", props);
  let {integrations, onSelect} = props;
  if (typeof integrations === "undefined") {
    integrations = [];
  }
  let dIntegrations = integrations.map( (int,i) => {
    return(
      <Button
        key={`integ_${i}`}
        variant="link"
        onClick={() => onSelect(int)}
        className="d-flex flex-row justify-content-between"
        block
      >
        <Image src={int.appLogo} alt={int.appName} style={{height: "20px", width: "auto"}} />
        <div className="btn btn-light btn-sm">Select</div>
      </Button>
    )
  });

  return(
    <div
      className={props.className ? props.className : ""}
      style={props.style ? props.style : null}
    >
      {dIntegrations}
    </div>
  )
}

const ImportHeader = props => {
  console.log("ImportHeader props: ", props);
  const {userDoc,handleAlerts} = props;
  const [showModal, setShowModal] = useState(false);
  const [modalHeader, setModalHeader]  = useState("");
  const [modalBody, setModalBody] = useState(null);

  return(
    <div className="d-flex flex-row justify-content-between mt-5 mb-5">
      <div><h3>{props.title ? props.title : "Import Content"}</h3></div>
      <ButtonGroup>
        <Button
          variant="outline-primary"
          onClick={async e => {
            e.preventDefault();
            console.log("Clicked add new integration");
            setModalHeader("Add new integration");
            setModalBody(
              <Integrations
                {...props}
                onSelect={(int) => {
                  let newInt = {...int};
                  let now = Math.round(new Date().getTime() / 1000);
                  newInt._created = now;
                  newInt.lastUpdated = now;
                  newInt.archived = false;
                  db.collection("digests").doc(userDoc.id).collection("integrations")
                  .add(newInt)
                  .catch( err => {
                    console.log("Error adding new integration: ", err);
                    handleAlerts("", `Uh oh, something went wrong adding your integration. ${err.message}`, "warning");
                  })
                }}
              />
              );
            setShowModal(true);
          }}
        >
          New Integration
        </Button>
      </ButtonGroup>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        {modalHeader &&
          <Modal.Header>
            <Modal.Title>{modalHeader}</Modal.Title>
          </Modal.Header>
        }
        <Modal.Body>
          {modalBody}
        </Modal.Body>
        <Modal.Footer className="d-flex flex-row justify-content-between">
          <Button size="sm"
            onClick={() => setShowModal(false)}
            variant="light"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

class EditIntegration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    //console.log("this.props: ", this.props.integration.data, Object.keys(this.props.integration.data));
    let dataKeys = [...Object.keys(this.props.integration.data)];
    dataKeys.forEach( k => {
      this.state[k] = this.props.integration.data[k];
    });
  }

  createFormGroup({key, value}) {
    if (typeof value === "string") {
      return(
        <Form.Group key={`cfg_${key}`}>
          <Form.Label className="text-capitalize">{key}</Form.Label>
          <Form.Control
            type="text"
            value={this.state[key]}
            onChange={ e => this.setState({[key]: e.target.value})}
          />
        </Form.Group>
      )
    }
    if ( Array.isArray(value) ) {
      //console.log("value: ", value);
      return(
        <Form.Group key={`cfg_${key}`} >
          <Form.Label className="text-capitalize">{key}</Form.Label>
          {value.map( (v,i) => {
            return(
              <InputGroup className="mb-1"
                key={`cfg_array_${key}_${i}`}
              >
                <Form.Control
                  type="text"
                  value={this.state[key][i]}
                  onChange={ e => {
                    let newv = [...this.state[key]];
                    newv[i]=e.target.value;
                    this.setState({[key]: newv});
                  }}
                />
                <InputGroup.Append>
                  <Button
                    variant="outline-danger"
                    onClick={() => {
                      let newv = [...value];
                      this.setState({[key]: removeItemOnce(newv,v) });
                    }}
                  >
                    <Trash />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
              
            )
          })}
          <Button size="sm" variant="light"
            className="mt-3"
            onClick={() => {
              let newv = [...this.state[key]];
              newv[newv.length] = "";
              this.setState({[key]: newv});
            }}
          >
            Add
          </Button>
        </Form.Group>
      )
    }
  }

  render() {
    const {integration} = this.props;
    const {_docRef, data} = integration;
    //console.log("EditIntegration this.state: ", this.state, "data: ",this.props.integration.data, "this.props: ", this.props);
    let formGroups = [];
    Object.keys(this.state).sort().forEach( key => {
      //console.log("key: ", key);
      formGroups.push(
        this.createFormGroup({key, value: this.state[key]})
      )
    })
    return(
      <div>
        <Form>
          {formGroups}
          <Button
            onClick={() => {
              _docRef.update({
                data: this.state
              }).catch( err => {
                console.log("Error updating integration: ", err);
              })
            }}
            className="pull-right"
            disabled={object_equals(this.props.integration.data, this.state)}
          >
            Save changes
          </Button>
        </Form>
      </div>
    )
  }
}

const ImportTable = props => {
  const {docs, superAdmin, type, apiKey} = props;
  //console.log("ImportTable props: ", props);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalSize, setModalSize] = useState(null);
  const [modalAction, setModalAction] = useState(null);

  return(
    <React.Fragment>
      <Table responsive>
        <thead>
          <tr>
            <th>App</th>
            <th>Status</th>
            <th>Manage</th>
          </tr>
        </thead>
        <tbody>
          {docs.map( d => {
            let {_docId, _docRef, _created, appLogo, appName, lastImport, lastWebhook, lastUpdated, archived, progress, error } = d;
            let hasImports = d.importedContent ? d.importedContent.length > 0 : false;
            console.log(d);
            return(
              <tr key={`tbtr_${_docId}`}>
                <td>
                  <Image src={appLogo} style={{width: "100px", height: "auto"}} />
                </td>
                <td className="text-muted">
                  {progress < 100 &&
                    <ProgressBar animated variant={error ? "danger" : "success"} label="Syncing" now={progress} />
                  }
                  <small>
                    Created: {new Date( _created * 1000).toLocaleString('en-US', {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </small><br />
                  {type === "import" &&
                    <React.Fragment>
                      <small>
                        Last import: {new Date( (lastImport > lastWebhook ? lastImport : lastWebhook) * 1000).toLocaleString('en-US', {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                        })}
                      </small><br />
                    </React.Fragment>
                  }
                  <small>
                    Last update: {new Date( lastUpdated * 1000 ).toLocaleString('en-US', {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </small>
                </td>
                <td>
                  <ButtonGroup>
                    {!archived &&
                      <React.Fragment>
                        {d.appName === "forumbee" &&
                          <Button
                            variant="outline-primary"
                            className=""
                            onClick={() => {
                              console.log("Clicked fetch new content.")
                              setModalContent(<ImportForumBee {...props} {...d.data} integration={d} />)
                              setModalTitle("Import ForumBee Content");
                              setModalSize("xl");
                              setShowModal(true);
                            }}
                          >
                            <ArrowBarDown size={18} />
                          </Button>
                        }
                        {d.appName === "headway" &&
                          <Button
                            variant="outline-primary"
                            className=""
                            onClick={() => {
                              console.log("Clicked fetch new content.")
                              setModalContent(<ImportHeadway {...props} {...d.data} integration={d} />)
                              setModalTitle("Import Headway Content");
                              setModalSize("xl");
                              setShowModal(true);
                            }}
                          >
                            <ArrowBarDown size={18} />
                          </Button>
                        }
                        {d.appName === "wordpress" &&
                          <Button
                            variant="outline-primary"
                            className=""
                            onClick={() => {
                              console.log("Clicked fetch new content.")
                              setModalContent(<ImportWordPress {...props} {...d.data} integration={d} />)
                              setModalTitle("Import Headway Content");
                              setModalSize("xl");
                              setShowModal(true);
                            }}
                          >
                            <ArrowBarDown size={18} />
                          </Button>
                        }
                        {hasImports &&
                          <Button
                            variant="outline-warning"
                            className=""
                            onClick={() => {
                              console.log("Clicked resync all content.");
                            }}
                          >
                            <ArrowClockwise size={18} />
                          </Button>
                        }
                        {d.appName === "customerio" &&
                          <Button
                            variant="outline-warning"
                            className=""
                            onClick={ async () => {
                              console.log("Clicked resync all segements.");
                              let body = JSON.stringify({
                                siteId: d.data.siteId,
                                apiKey: d.data.apiKey
                              });
                              console.log("body: ", body);
                              const init = {
                                method: "POST",
                                headers: {
                                  authorization: `Bearer ${apiKey}`,
                                  "Content-Type": "application/json"
                                },
                                "Transfer-Encoding": "chunked",
                                cache: "default",
                                accept: "application/json",
                                body
                              };
                              try {
                                let response = await fetch(`${process.env.REACT_APP_api_url}/api/cio/segments`, init);
                                let {segments} = await response.json();
                                console.log("segments: ", segments);
                                d._docRef.update({
                                  segments,
                                  lastUpdated: new Date().getTime()/1000
                                })
                              } catch(err) {
                                console.log("Error adding segments to integration doc: ", err);
                              }
                            }}
                          >
                            <ArrowClockwise size={18} />
                          </Button>
                        }
                        <Button
                          variant="outline-secondary"
                          className=""
                          onClick={() => {
                            console.log("Clicked edit integration.");
                            setModalContent(<EditIntegration {...props} integration={d} />)
                            setModalTitle(<React.Fragment>Edit <span className="text-capitalize">{appName} Settings</span></React.Fragment>);
                            setShowModal(true);
                          }}
                        >
                          <Pencil size={18} />
                        </Button>
                        <Button
                          variant="outline-danger"
                          className=""
                          onClick={() => {
                            if ( window.confirm("Are you sure you want to delete this integration?") ) {
                              archiveDoc({
                                docRef: _docRef,
                                unarchive: archived
                              }, (error, confirm) => {
                                if (error) {
                                  console.log("Error archiving integration: ", error);
                                }
                                if (confirm) {
                                  console.log("Archived integration.");
                                }
                              })
                            }
                          }}
                        >
                          <Trash size={18} />
                        </Button>
                      </React.Fragment>
                    }
                    {(archived && superAdmin) &&
                      <Button
                        variant="warning"
                        onClick={() => {
                          if ( window.confirm("Are you sure you want to restore this integration?") ) {
                            archiveDoc({
                              docRef: _docRef,
                              unarchive: archived
                            }, (error, confirm) => {
                              if (error) {
                                console.log("Error archiving integration: ", error);
                              }
                              if (confirm) {
                                console.log("Restored integration.");
                              }
                            })
                          }
                        }}
                      >
                        Restore
                      </Button>
                    }
                  </ButtonGroup>

                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      {showModal &&
        <Modal
          show={showModal}
          onHide={() => setShowModal(false)}
          size={modalSize ? modalSize : "md"}
        >
          <AppAlerts {...props} />
          {modalTitle &&
            <Modal.Header>
              <Modal.Title>
                {modalTitle}
              </Modal.Title>
            </Modal.Header>
          }
          <Modal.Body>
            {modalContent}
          </Modal.Body>
          <Modal.Footer className="d-flex flex-row justify-content-between">
            <Button
              variant="light"
              onClick={() => setShowModal(false)}
            >
              <small>Close</small>
            </Button>
            <div>&nbsp;</div>
            {modalAction}
          </Modal.Footer>
        </Modal>
      }
    </React.Fragment>
  )
}

const Import = props => {
  let { userDoc, type, title, handleState } = props;
  useEffect( () => {
    handleState({isApp: true});
  }, [handleState]);

  return (
    <React.Fragment>
      <Collection
        colRef={db.collection("digests").doc(userDoc.id)}
        collection="integrations"
        listen={true}
        permissions={["read", "write", "delete"]}
        where={[
          { field: "type", operand: "==", term: type ? type : "import" },
          { field: "archived", operand: "==", term: false }
        ]}
      >
        <CollectionContext.Consumer>
          {(context) => {
            return(
              <React.Fragment>
                <ImportHeader {...props} {...context} title={title ? title : null}/>
                <ImportTable {...props} {...context} />
              </React.Fragment>
            )
          }}
        </CollectionContext.Consumer>
      </Collection>
    </React.Fragment>
  )
}

const htmlToPlain = htmlstring => {
  return htmlstring.replace(/<[^>]*>/g, '');
}

const mdToPlain = mdstring => {
  return mdstring.replace(/[#()*]/g, '');
}

const getFirstSentence = plaintext => {
  //console.log("plaintext: ", plaintext);
  let array = plaintext.split(/[;.?!]/)
  //console.log("array: ", array);
  return array[0];
}

const makeTitle = string => {
  // returns first four words
  let firstFour = string.split(" ").slice(0,4).join(" ");
  return firstFour.charAt(0).toUpperCase() + firstFour.slice(1);
}

const createArticle = async (params, callback) => {
  //contentArticle should be shaped like:
  //{
  //  _created: Number: seconds since epoch,
  //  _lastChanged: Number: seconds sinc epoch,
  //  aid: String: account,
  //  archived: Boolean,
  //  category: Array,
  //  data: Object: {
  //    content: String,
  //    contentType: Enum: html, md, text
  //    imgUrl: String,
  //    link: String,
  //    linkText: String,
  //    preview: String,
  //    title: String
  //  },
  //  description: String
  //}
  let { aid, category, uid, bulkData, db} = params;
  //return console.log("db: ", db);
  if (!aid) {
    return console.log("createArticle requires the aid param.");
  }
  function formatArticleDoc(d, id) {
    let {content, contentType, imgUrl, link, linkText, preview, title, _created, slug, publishedOn, relatedSlugs} = d;

    if (!category) {
      category = [];
    }
    if (!content) {
      content = "";
    }
    if (!contentType) {
      contentType = "md";
    }
    if (!imgUrl) {
      imgUrl = [];
    }
    if (!link) {
      link = "";
    }
    if (!linkText) {
      linkText = "Read More";
    }
    if (!preview) {
      if (!content) {
        preview = "";
      }
      if (content && contentType === "html") {
        preview = getFirstSentence( htmlToPlain(content) );
      }
      if (content && contentType === "md") {
        preview = getFirstSentence( mdToPlain(content) );
      }
      if (content && contentType === "text") {
        preview = getFirstSentence(content);
      }
    }
    if (!title) {
      if (!preview) {
        title = "Please add a title";
      } else {
        title = makeTitle(preview);
      }
    }
    let article = {
      _created: _created ? _created : today,
      _lastChanged: today,
      archived: false,
      aid,
      uid,
      category,
      data: {
        content,
        contentType,
        imgUrl,
        link,
        linkText,
        preview,
        title
      }
    };

    if (slug) {
      article.data.slug = slug;
    }
    if (publishedOn) {
      article.data.publishedOn = publishedOn;
    }
    if (relatedSlugs) {
      article.data.relatedSlugs = relatedSlugs;
    }

    return article;
  }

  let conRef = db.collection("content");
  let today = Math.round(new Date().getTime() / 1000);
  let id;
  if (bulkData) {
    let batch = db.batch();
    let batchCount = 0;
    let docIds = [];
    for (let [i, data] of bulkData.entries()) {
      let articleDoc = formatArticleDoc(data, id);;
      //console.log(i, "data: ", data, "articleDoc: ", articleDoc);
      id = makeid(15);
      batch.set(conRef.doc(id), articleDoc);
      batchCount += 1;
      docIds.push(id);
      if ((i + 1) % 500 === 0) {
        await batch.commit().then((result) => {
          console.log("Wrote data through record: ", i);
          //batch = db.batch();
          //batchCount = 0;
        });
        batchCount = 0;
        batch = db.batch();
      }
    }

    if (batchCount > 0) {
      await batch.commit().then((result) => {
        console.log("Final write count: ", batchCount);
      });
    }
    console.log("Finished writing bulkData to Firestore.");
    console.log("Total articles written to content: ", docIds.length);

    let batchSummary = {
      docIds,
      category,
      uid,
      aid,
    };
    if (callback) {
      callback(null, batchSummary);
    }
    return batchSummary;
  }


  let newArticle = formatArticleDoc(params);
  let savedArticleRef = await conRef.add(newArticle).catch( err => {
    console.log("Error creating article: ", err);
    if (callback) {
      callback(err);
    }
  })
  newArticle._docId = savedArticleRef.id;
  newArticle._docRef = savedArticleRef;

  if (callback) {
    callback(null, newArticle);
  }
  return newArticle
}

const ArticleRouter = props => {
  return (
    <Switch>
      <Route exact path={["/article/:artid", "/a/:artid"]}>
        <Article {...props} />
      </Route>
      <Route exact path={["/article", "/articles", "/article/", "/articles/"]} >
        <Redirect to="/content" />
      </Route>
    </Switch>
  );
}

const Article = props => {

  const {viewOnly, handleState} = props;
  let { artid } = useParams();

  // control when we fetch data
  const [listenerAdded, setListenerAdded ] = useState(false);
  // editing state variables
  const [title, setTitle] = useState("");
  const [preview, setPreview] = useState("");
  const [contentType, setContentType] = useState("md");
  const [content, setContent] = useState("**base md**");
  const [category, setCategory] = useState([]);
  const [imgUrl, setImgUrl] = useState([]);
  const [link, setLink] = useState("");
  const [linkText, setLinkText] = useState("");
  // UI state variables
  const [files, setFiles] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [showPreview, setShowPreview] = useState(true);
  const fileInputRef = useRef(null);

  useEffect( () => {
    let aref = db.collection("content").doc(artid);
    if (!listenerAdded) {
      aref.get().then( doc => {
        if (doc.exists) {
          let d = doc.data();
          //console.log("d: ", d);
          setListenerAdded(true);
          setTitle(d.data.title);
          setPreview(d.data.preview);
          setContentType(d.data.contentType);
          setContent(d.data.content);
          setCategory(d.data.category);
          setImgUrl(d.data.imgUrl);
          setLink(d.data.link);
          setLinkText(d.data.linkText);
        }
      });
    }

    if (listenerAdded && showEdit) {
      let timeOutId = setTimeout(() => {
        console.log("Done typing.");
        aref.update({
          _lastChanged: Math.round( new Date().getTime() / 1000),
          "data.content": content,
          "data.title": title,
          "data.preview": preview,
          "data.imgUrl": imgUrl,
          "data.link": link,
          "data.linkText": linkText
        })
      }, 3000);
      return () => {
        console.log("Clearing timeout");
        clearTimeout(timeOutId);
      };
    }
    if (viewOnly) {
      handleState({
        noDistract: true,
        isApp: false
      });
    }
    return ( () => {
      if (viewOnly) {
        handleState({
          noDistract: false
        });
      }
    })

  }, [title, content, imgUrl, link, linkText, preview]);

  const storeInputFile = async (file) => {
    // upload the file to storage
    await storeMedia(
      `articles/${props.userDoc.id}/${file.name}`,
      file,
      // progressCallBack
      (progress) => {
        console.log("progress: ", progress);
      },
      // downloadCallBack
      (downloadUrl) => {
        console.log("dowloadUrl: ", downloadUrl);
        setImgUrl([downloadUrl, ...imgUrl]);
      },
      // metadataCallBack
      (metadata) => {
        console.log("metadata: ", metadata);
      }
    );
  };

  //console.log("content: ", content);
  let showExternalLink; 
  if (link) {
    let {hostname} = new URL(link);
    console.log("hostname: ", hostname );
    showExternalLink = !(window.location.hostname === hostname);
  }
  
  return(
    <Container>
      <Row>
        {showEdit &&
          <Col>
            <div style={{width: "600px"}}>
              <div className="d-flex flex-row justify-content-between mb-3">
                <h3>Edit article</h3>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={() => {
                    setShowPreview(true);
                    setShowEdit(false);
                  }}
                  className="pull-right"
                >
                  <small>Done editing</small>
                </Button>
              </div>
              <Image src={imgUrl[0]} rounded style={{width: "100%", height: "auto"}}/>

              <Form.Group>
                <Form.File
                  ref={fileInputRef}
                  className=""
                  name="file"
                  label="Upload a new featured image"
                  onChange={e => {
                    e.preventDefault();
                    const {files} = e.target;
                    console.log("files: ", files);
                    storeInputFile(files[0]);
                  }}
                  id="selectArtFile"
                  accept="image/jpeg, image/png, image/gif, image/svg+xml, image/tiff"
                />
                {imgUrl.length > 1 &&
                  <div className="mt-3">
                    <h6>Click an image to use it as the featured image.</h6>
                      <div className="d-flex flex-row flex-wrap">
                          {imgUrl.slice(1).map( (url,i) => {
                            return(
                              <div
                                style={{height: "100px", width: "160px", paddingRight: "10px", position: "relative"}}
                                key={`simg_${i}`}
                              >
                                <Button
                                  variant="link"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    console.log("Set image as featured");
                                    setImgUrl([url, ...imgUrl.filter( u => u !== url)])
                                  }}
                                >
                                  <Image thumbnail src={url} style={{height: "100px", width: "150px", objectFit: "cover"}} />

                                </Button>
                                <Button
                                  variant="link"
                                  style={{
                                    position: "absolute",
                                    right: "15px",
                                    top: "5px",
                                    padding: "2.5px",
                                    backgroundColor: "#fff",
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: "50%",
                                    textAlign: "center"
                                  }}
                                  className="d-flex justify-content-center align-items-center"
                                  onClick={() => {
                                    console.log("Removing image.")
                                    setImgUrl([...imgUrl.filter( u => u !== url)]);
                                  }}
                                >
                                  <Trash size={14} className="text-danger" />
                                </Button>
                              </div>
                            )
                          })}
                      </div>
                  </div>
                }
              </Form.Group>

              <Form.Group>
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={title}
                  onChange={ e => {
                    e.preventDefault();
                    setTitle(e.target.value)
                  }}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Preview</Form.Label>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows="3"
                  value={preview}
                  onChange={(e) => setPreview(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Content</Form.Label>
                  <MDEditor
                    value={content}
                    onChange={setContent}
                    preview="edit"
                  />
              </Form.Group>
              <Form.Group>
                <Form.Label>Button</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Add button text here"
                  value={linkText}
                  onChange={(e) => setLinkText(e.target.value)}
                />
                <Form.Control
                  type="text"
                  placeholder="https://yourlink.com"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
              </Form.Group>
            </div>
          </Col>
        }
        {showPreview &&
          <Col className="mt-5">
            <div style={{width: "600px", minHeight: "93vh"}} className="d-flex flex-column justify-content-between">
              <div>
              {!viewOnly && 
                <div className="d-flex flex-row justify-content-between mb-3">
                  <h3>Preview article</h3>
                  <Button
                    size="sm"
                    variant="light"
                    onClick={() => {
                      setShowPreview(false);
                      setShowEdit(true);
                    }}
                    className="pull-right"
                  >
                    <small>Edit article</small>
                  </Button>
                </div>
              }
              <Image src={imgUrl[0]} rounded style={{width: "100%", height: "auto"}}/>
              <h4 className="mt-3">{title}</h4>
              {preview &&
                <p className="lead">{preview}</p>
              }
              <MDEditor.Markdown source={content} />
              {showExternalLink && 
                <a target="_blank" rel="noopener noreferrer" href={link} className="mt-4 btn btn-secondary btn-block text-white">{linkText}</a>
              }
              </div>
              {viewOnly && 
              <FooterPoweredBy />
            }
            </div>
          </Col>
        }
      </Row>
    </Container>
  )
}

const DigestRouter = props => {
  return (
    <Switch>
      <Route exact path="/smry/:digestid">
        <Digest {...props} />
      </Route>
      <Route exact path="/view/:digestid">
        <Digest {...props} viewOnly />
      </Route>
      <Route exact path={["/digest", "/digest/", "/smries/"]} >
        <Redirect to="/smries" />
      </Route>
      <Route exact path={["/digest/:digestid"]} >
        <Redirect to="/smry/:digestid" />
      </Route>
    </Switch>
  );
}

const CioForm = props => {
  const {config, apiKey, int, handleAlerts, handleDigestModal, handleSendDropdownModal} = props;
  const [isTriggeredBroadcast, setIsTriggeredBroadcast] = useState(false);
  const [broadcastId, setBroadcastId] = useState("");
  const [segment, setSegment] = useState("");
  const [payload, setPayload] = useState(null);
  //console.log("config: ", config);
  if (payload) {
    return(
      <React.Fragment>
        <h4>You send the following to Customer.io</h4>
        <p className="lead">Click anywhere below to copy this to your clipboad.</p>
        <CopyToClipboard
          text={JSON.stringify(payload)}
          onCopy={() => {
            handleAlerts("", "Copied payload to clipboad.", "info");
            handleDigestModal(false);
            handleSendDropdownModal(false);
          }}
        >
          <JSONPretty
            className="text-left"
            id="json-pretty"
            data={payload}
          ></JSONPretty>
        </CopyToClipboard>
      </React.Fragment>

    )
  }

  return(
    <Form
      onSubmit={ async e => {
        e.preventDefault();
        console.log("Submitted cioform!");
        let payload = await createDigestPayload({config});
        //console.log("payload: ", payload);
        let data = {...config, payload};
        delete data._docRef;
        let body = JSON.stringify({
          siteId: int.data.siteId,
          apiKey: int.data.apiKey,
          broadcastId,
          segment: {id: segment},
          data
        });
        //console.log("body: ", body);
        const init = {
          method: "POST",
          headers: {
            authorization: `Bearer ${apiKey}`,
            "Content-Type": "application/json"
          },
          "Transfer-Encoding": "chunked",
          cache: "default",
          accept: "application/json",
          body
        };
        fetch(`${process.env.REACT_APP_api_url}/api/cio/trigger_broadcast`, init);
        setPayload(data);
      }}
    >
      <h4>Send Digest via Customer.io</h4>
      <Form.Group>
        <Form.Check
          id="isTriggeredBroadcast"
          type="switch"
          checked={isTriggeredBroadcast}
          onChange={() => setIsTriggeredBroadcast(!isTriggeredBroadcast)}
          label="Triggered Broadcast"
        />
      </Form.Group>

      <Form.Group>
        <Form.Label>Broadcast Id</Form.Label>
        <Form.Control
          type="text"
          value={broadcastId}
          onChange={ e => setBroadcastId(e.target.value)}
          disabled={!isTriggeredBroadcast}
        />
      </Form.Group>
      <Form.Group>
        <Form.Label>Select segment</Form.Label>
        <Form.Control
          as="select"
          onChange={ e => {
            setSegment(e.target.value);
          }}
          value={segment}
        >
          <option value="">Select cio segment</option>
          {int.segments.map( s => {
            return(
              <option key={s.deduplicate_id} value={s.id}>
                {s.name}
              </option>
            )
          })}
        </Form.Control>
      </Form.Group>

      <Button
        variant="link"
        type="submit"
        className="pull-right"
        disabled={!(broadcastId && segment)}
      >
        <ArrowUpCircleFill size={36} className={`text-${config.themeColor ? config.themeColor === "light" ? "dark" : config.themeColor : "primary"}`} />
      </Button>
    </Form>
  )
}

const SendgridForm = props => {
  const {config, apiKey, int, handleAlerts, handleDigestModal, handleSendDropdownModal, fetchFromApi} = props;
  //console.log("config: ", config, "int: ", int);
  const [payload, setPayload] = useState(null);
  const [sendPayload, setSendPayload] = useState(false);
  const [plainText, setPlainText] = useState("");
  const [html, setHtml] = useState("");

  useEffect( () => {
    createDigestPayload({config}, (payload) => {
      //console.log("payload: ", payload);
      let data = {...config, payload};
      delete data._docRef;
      //console.log("data: ", data);
      let plainText = payload.map( p => p.txt).join("");
      let html = payload.map( p => p.html).join("");
      //console.log("html: ", html);
      setPlainText(plainText);
      setHtml(html);
      setPayload(data);
    });
    
  }, [config, int]);

  //console.log("config: ", config);
  if (payload && sendPayload) {
    return(
      <React.Fragment>
        <h4>You sent the following to SendGrid</h4>
        <p className="lead">Click anywhere below to copy this to your clipboad.</p>
        <CopyToClipboard
          text={JSON.stringify(payload)}
          onCopy={() => {
            handleAlerts("", "Copied payload to clipboad.", "info");
            handleDigestModal(false);
            handleSendDropdownModal(false);
          }}
        >
          <JSONPretty
            className="text-left"
            id="json-pretty"
            data={payload}
          ></JSONPretty>
        </CopyToClipboard>
      </React.Fragment>

    )
  }

  return(
    <ComposeEmail 
      {...props}
      className="pb-5"
      title="Send smry via Sendgrid"
      from=""
      fromOptions={int.data.sendFromEmail.map( e => ({email: e, name: e})) }
      onSend={ async ({msg}) => {
        //console.log("msg: ", msg);
        let sendresult = await fetchFromApi({
          endpoint: "/api/sendgrid/email",
          body: JSON.stringify({
            msg,
            apiKey: int.data.apiKey
          })
        })
        if (sendresult.status === false) {
          throw new Error();
        }
        return true;
      }}
      onSent={ ({sent, failed, msg}) => {
        // set payload
        setSendPayload(true);
        // Let the user know what's going on
        handleAlerts("", "Hot dang, you sent a smry to Sendgrid!", "success");
        // Close the modal
        handleSendDropdownModal(false);
      }}
      consolidated={true}
      plainText={plainText}
      html={html}
      hide={["message"]}
    />
  )
}

const CopyCode = props => {
  const {config, handleAlerts} = props;
  const [view, setView] = useState("json");
  const [json, setJson] = useState("");
  const [html, setHtml] = useState("");
  const [txt, setTxt] = useState("");

  useEffect( () => {
    async function setPayload() {
      let payload = await createDigestPayload({config});
      let html = payload.map( p => {return p.html}).join(" \r \r");
      let txt = payload.map( p => {return p.txt}).join(" \r \r");
      setJson(payload);
      setHtml(html);
      setTxt(txt);
    }
    setPayload();
  }, [config]);

  return(
    <React.Fragment>
      <h4>Digest payload</h4>
      <p>Click anwhere below to copy the code.</p>
      <ButtonGroup className="mb-3">
        {["json", "html", "txt"].map( t => {
          return(
            <Button
              variant={ t === view ? config.themeColor === "light" ? "dark" : config.themeColor : `outline-${config.themeColor === "light" ? "dark" : config.themeColor}`}
              onClick={() => setView(t)}
            >
              {t.toUpperCase()}
            </Button>
          )
        })}
      </ButtonGroup>
      <CopyToClipboard
        text={JSON.stringify(view === "json" ? json : view === "html" ? html : txt)}
        onCopy={() => {
          handleAlerts("", `Copied ${view === "json" ? "json" : view === "html" ? "html" : "txt"} to clipboad.`, "info");
        }}
      >
        <JSONPretty
          className="text-left"
          id="json-pretty"
          data={view === "json" ? json : view === "html" ? html : txt}
        ></JSONPretty>
      </CopyToClipboard>
    </React.Fragment>
  )
}

const SendDropdown = props => {
  const {config, userDoc} = props;
  const [sendMethods, setSendMethods] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [modalBody, setModalBody] = useState(null);
  const [modalFooter, setModalFooter] = useState(null);

  useEffect( () => {
    let ref = db.collection("digests").doc(userDoc.id).collection("integrations").where("type", "==", "send");
    getDocs({ ref }, (err, docs) => setSendMethods(docs));
  }, [config, userDoc]);

  const sendAppAction = int => {
    if (int.appName === "customerio") {
      setModalBody(
        <CioForm {...props} int={int} handleSendDropdownModal={bool => setShowModal(false)}/>
      )
      setShowModal(true);
    }
    if (int.appName === "sendgrid") {
      setModalBody(
        <SendgridForm {...props} int={int} handleSendDropdownModal={bool => setShowModal(false)}/>
      )
      setShowModal(true);
    }
  }

  console.log("sendMethods: ", sendMethods);
  return(
    <React.Fragment>
      <Dropdown>
        <Dropdown.Toggle
          id="list-menu"
          className={`ml-2 p-2 border rounded bg-light border-${
            config.themeColor === "light" ? "dark" : config.themeColor
          } ${props.className ? props.className : ""}`}
        >
          <span className={`text-${config.themeColor === "light" ? "dark" : config.themeColor}`}>Send</span> <ArrowUpCircleFill size={24} className={`text-${config.themeColor === "light" ? "dark" : config.themeColor}`} />
        </Dropdown.Toggle>
        <Dropdown.Menu align="left">
          {Array.isArray(sendMethods) &&
            sendMethods.map( m => {
              return(
                <Dropdown.Item key={m._docId}
                  onClick={() => {
                    sendAppAction(m);
                  }}
                >
                  {m.appName}
                </Dropdown.Item>
              )
          })}
        </Dropdown.Menu>
      </Dropdown>
      {showModal &&
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Body>
            {modalBody}
          </Modal.Body>
          <Modal.Footer className="d-flex flex-row justify-content-between">
            <Button
              variant="light"
              size="sm"
              onClick={() => setShowModal(false)}
            >
              Close
            </Button>
            {modalFooter}
          </Modal.Footer>
        </Modal>
      }
    </React.Fragment>
  )
}

const addDigestSection = async (params, callback) => {
  let {type, sectionConfig, ref, sections, sectionNo} = params;
  let defaultConfig = {
    showTitle: true,
    showPreview: true,
    showContent: false,
    showLink: false,
    showImage: false,
    showDate: false
  }
  if (!sectionConfig) {
    sectionConfig = defaultConfig;
  } else {
    Object.keys(defaultConfig).forEach( k => {
      if (!sectionConfig[k]) {
        sectionConfig[k] = defaultConfig[k];
      }
    })
  }
  if (type === "article") {
    sectionConfig.contentId = "placeholder"
  }
  if (type === "category") {
    sectionConfig.category="Select a category";
    sectionConfig.categoryTitle="";
    sectionConfig.noItems=3;
  }
  try {
    let ns = [...sections.slice(0,sectionNo +1), sectionConfig, ...sections.slice(sectionNo+1)];
    await ref.update({
      lastUpdated: Math.round( new Date().getTime() /1000 ),
      sections: ns
    });
    console.log("sectionConfig: ", sectionConfig, "ns: ", ns);
    if (callback) {
      callback(null, ns);
    }
    return ns;
  } catch (err) {
    console.log("Error adding digest section: ", err);
    if (callback) {
      callback(err);
    }
  }
}

const DigestSection = props => {
  const {sections, sectionNo, docRef, callback} = props;
  return(
    <div className="d-flex flex-column justify-content-between">
      <Button
        onClick={() => {
          addDigestSection({type: "article", sections, sectionNo, ref: docRef}, callback);
        }}
        block
      >
        Add Static Article
      </Button>
      <br />
      <Button
        onClick={() => {
          addDigestSection({type: "category", sections, sectionNo, ref: docRef}, callback);
        }}
        block
      >
        Add Dynamic Category
      </Button>
    </div>
  )
}

const inlineTemplate = (template={}, key, defaultStyle="") => {
  if (!key) { return "" };
  if (!template[key] && defaultStyle == "") { return "" }
  if (!template[key] && defaultStyle !== "") { return ` style="${defaultStyle}"` };
  return ` style="${template[key]}"`;
}

const formatCategoryPayload = async ({config, section, articles, deliveredArticles}) => {
  console.log("formatCategoryPayload config: ", config);
  let {category, noItems, template} = section;
  let {aid} = config;
  if (!deliveredArticles) {
    deliveredArticles = [];
  }
  if (!noItems) {
    noItems = 100;
  }

  let formattedArticles = [];
  if(category && !articles) {
    articles = await getDocs({
      ref: db.collection("content").where("aid", "==", aid).where("archived", "!=", true).where("category", "array-contains", category).orderBy("archived").orderBy("_created", "desc")
    })
  }

  for ( let article of articles ) {
    if (formattedArticles.length < noItems && !deliveredArticles.includes(article._docId)) {
      let payload = await formatArticlePayload({config, article, section})
      console.log("payload: ", payload);
      formattedArticles.push(payload);
    }
  }
  let json = {};
  let htmlArray = [];
  let textArray = [];
  json.articles = formattedArticles;
  if (section.categoryTitle) {
    let categoryTitle = section.categoryTitle;
    json.categoryTitle = categoryTitle;
    htmlArray.push(`<h2${inlineTemplate(template, "categoryTitle")}>${categoryTitle}</h2><hr />`);
    textArray.push(categoryTitle.toUpperCase());
    textArray.push("==========");
  }
  if (noItems) {
    json.noItems = noItems;
  }
  if (category) {
    json.category = category;
  }
  formattedArticles.forEach( a => {
    htmlArray.push(a.html);
    textArray.push(a.txt);
  })
  return({
    json,
    html: `<div class="__digest_section"${inlineTemplate(template, "section")}>${htmlArray.join("")}</div>`,
    txt: textArray.join(" \r")
  })
}

const formatArticlePayload = async ({article, config, section}, callback) => {
  //displayContent push article section.
  //console.log("article: ", article, "config: ", config, "section: ", section);
  let {template} = section;
  // fetch the article
  if (!article && section.contentId) {
    article = await getDoc({
      ref: db.collection("content").doc(section.contentId)
    })
  } else {
    // article already passed in above.
    if (!section.contentId && !article) {
      return console.log("Must pass in config.contentId or article.")
    }
  }
  //console.log("article: ", article);

  // format the json for other applications.
  let json = {};
  // format the html for use in emails
  let htmlArray = [];
  // fomrat the text for use in text messaging
  let textArray = [];
  if (section.showImage && article.data.imgUrl[0]) {
    let img = article.data.imgUrl[0];
    json.img = img;
    htmlArray.push(`<img src="${img}"${inlineTemplate(template, "img", "max-width: 100%; height: auto;")} />`);
    //To Do: add image as attachment to text ...
  }
  if (section.showTitle && article.data.title) {
    let title = article.data.title;
    json.title = title;
    htmlArray.push(`<h3${inlineTemplate(template, "title")}>${title}</h3>`)
    textArray.push(title.toUpperCase());
  }
  if (section.showDate && article._created) {
    let date = article._created
    let dateString = new Date(date * 1000).toLocaleString("en-US", {month: "short", day: "numeric", year: "numeric"});
    json.date = date;
    htmlArray.push(`<p${inlineTemplate(template, "date")}>${dateString}</p>`);
    textArray.push(dateString)
  }
  if (section.showPreview && article.data.preview) {
    let preview = article.data.preview;
    json.preview = preview;
    htmlArray.push(`<p${inlineTemplate(template, "preview")}>${preview}</p>`);
    textArray.push(preview);
  }
  if (section.showContent && article.data.content) {
    let contentType = article.data.contentType;
    let content = article.data.content;
    json.content = content;
    json.contentType = contentType;
    // never push content to textArray.  Too much stuff.
    if (contentType === "html") {
      htmlArray.push(content);
    }
    if (contentType === "text") {
      htmlArray.push(`<p${inlineTemplate(template, "content")}>${content}</p>`);
    }
    if (contentType === "md") {
      htmlArray.push(`<p${inlineTemplate(template, "content")}>${mdToPlain(content)}</p>`)
    }
  }

  if (section.showLink) {
    let link = article.data.link ? article.data.link : `${window.location.origin}/a/${article._docId}`;
    let linkText = article.data.linkText;
    let linkColor = section.linkColor ? section.linkColor : "rgb(242,61,94)";
    let linkTextColor = section.linkTextColor ? section.linkTextColor : "white";
    let linkBorderRadius = section.linkBorderRadius ? section.linkBorderRadius : "3.2px";
    json.link = link;
    json.linkText = linkText;
    htmlArray.push(`<a${inlineTemplate(template, "img", `text-decoration: none; text-align: center; vertical-align: middle; padding: 6px 12px; background-color: ${linkColor}; border: 1px solid transparent; border-color: ${linkColor}; color: ${linkTextColor}; border-radius: ${linkBorderRadius};`)} href="${link}">${linkText}</a>`);
    textArray.push(`[ ${linkText} ] (${link})`);
  }
  // return the object
  return(
    {
      json,
      html: `<div class="__digest_article"${inlineTemplate(template, "article", "padding-bottom: 10px; margin-bottom: 10px;")}>${htmlArray.join("")}</div>`,
      txt: textArray.join(" \r") + "\r"
    }
  )
}

const createDigestPayload = async (params, callback) => {
  const {config} = params;
  let payloadSections = [];
   for (let [i, section] of config.sections.entries()) {
    if (section.contentId) {
      let payload = await formatArticlePayload({config, section});
      payloadSections.push({payload, order: i});
    }
    if (section.category) {
      //displayCategory
      let payload = await formatCategoryPayload({config, section});
      payloadSections.push({payload, order: i});
    }
  }

  let digestPayload = payloadSections.sort( (a,b) => { return a.order - b.order}).map( p => p.payload );
  console.log("digestPayload: ", digestPayload);
  if (callback) {
    callback(digestPayload);
  }
  return digestPayload;
}

const Digest = props => {
  //console.log("Digest props: ", props);
  const {userDoc, viewOnly, handleAlerts, handleState} = props;
  const {digestid} = useParams();
  const [sections, setSections] = useState([]);
  const [config, setConfig] = useState(null);
  const [saving, setSaving] = useState(false);
  const [preview, setPreview] = useState(viewOnly ? viewOnly : false);
  const [desktop, setDesktop] = useState(true);
  // Modal variables
  const [showModal, setShowModal] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [modalSize, setModalSize] = useState("md");
  const [modalBody, setModalBody] = useState("");
  const [modalButton, setModalButton] = useState("");

  const [redirect, setRedirect] = useState(null);

  useEffect( () => {
    //console.log("digestid: ", digestid);
    if (!userDoc && !viewOnly) {
      return setRedirect(`/view/${digestid}`);
    }
    db.collection("summary").doc(digestid).onSnapshot( doc => {
      if (doc.exists) {
        let docData = doc.data();
        docData._docRef= doc.ref;
        docData._docId=doc.id;
        setSections(docData.sections);
        setConfig(docData);
      }
    })
    if (viewOnly) {
      handleState({
        noDistract: true,
        isApp: false
      });
    }
    return ( () => {
      if (viewOnly) {
        handleState({
          isApp: false,
          noDistract: false
        });
      }
    })
  }, [viewOnly, digestid, userDoc]);
  if (!config) {
    return null
  }
  //console.log("config: ", config);
  if (redirect) {
    return(
      <Redirect to={redirect} />
    )
  }

  let canEdit = userDoc ? userDoc.id === config.aid : false;
  let outlineColor = config.themeColor ? `outline-${config.themeColor === "light" ? "dark" : config.themeColor}` : "outline-primary"
  return(
    <Container>
      <Row>
        {!viewOnly &&
          <Col xs={12}>
            <TrelloBoardHeader
              {...props}
              config={config}
              backPath={"/smries"}
              rightButtons={[
                <React.Fragment key="preview_rtn_btns">
                  {preview &&
                    <React.Fragment>
                      <SendDropdown
                        key="send_dig_ddn"
                        {...props}
                        className="mr-1"
                        config={config}
                        handleDigestModal={bool => setShowModal(bool)}
                      />
                      <Button
                        key="cpy_cd_btn"
                        variant={ outlineColor }
                        onClick={async () => {
                          console.log("Clicked to copy code!");
                          setModalSize("xl");
                          setShowModal(true);
                          setModalBody(<CopyCode {...props} config={config} />)
                        }}
                      >
                        <CodeSlash size={25} />
                      </Button>

                      <CopyToClipboard
                        key="cpy_pub_link"
                        text={`${window.location.origin}/view/${digestid}`}
                        onCopy={() => {
                          console.log("Copied public link to your clipboard.");
                          handleAlerts(
                            "",
                            "Copied the public link to your clipboard!",
                            "success"
                          );
                        }}
                      >
                        <Button
                          key="cpy_pub_link_bnt"
                          variant={ outlineColor }
                        >
                          <LinkIcon size={25} />
                        </Button>
                      </CopyToClipboard>

                      <Button
                        key="view_desktop_preview"
                        variant={ outlineColor }
                        onChange={() => setDesktop(!desktop)}
                      >
                        <Form.Check
                          id="preview_check_switch"
                          type="switch"
                          label="Mobile"
                          checked={!desktop}
                          onChange={() => setDesktop(!desktop)}
                        />
                      </Button>
                    </React.Fragment>
                  }
                </React.Fragment>,
                <Button
                  key="prvw_dig"
                  onClick={() => setPreview(!preview)}
                  variant={ outlineColor }
                >
                  {!preview &&
                    <Eye size={25} />
                  }
                  {preview &&
                    <EyeSlash size={25} />
                  }
                </Button>
              ]}
            />
          </Col>
        }
        <Col xs={12} style={{maxWidth: desktop ? "800px" : "400px"}} className={ viewOnly ? "mt-5" : ""}>
          {sections.length > 0 && sections.map( (s,i) => {
            if (s.contentId) {
              return(
                <React.Fragment key={`frag_section_${i}`}>
                  <DisplayContent
                    {...props}
                    key={`section_${i}`}
                    config={config}
                    section={s}
                    sectionNo={i}
                    canEdit={canEdit}
                    displayPreview={preview}
                  />
                  {!preview &&
                    <AddDigestBtn
                      key="add_dig_btn"
                      canEdit={canEdit} size={30}
                      onClick={() => {
                        setModalBody(
                          <DigestSection
                            sections={sections}
                            sectionNo={i}
                            docRef={config._docRef}
                            callback={() => setShowModal(false)}
                          />
                        );
                        setModalHeader("Add Section");
                        setShowModal(true);
                      }}
                    />
                  }
                </React.Fragment>
              )
            }
            if (s.category) {
              return(
                <React.Fragment key={`frag_section_${i}`}>
                  <DisplayCategory
                    {...props}
                    key={`section_${i}`}
                    config={config}
                    section={s}
                    sectionNo={i}
                    canEdit={canEdit}
                    displayPreview={preview}
                  />
                  {!preview &&
                    <AddDigestBtn
                      canEdit={canEdit} size={30}
                      onClick={() => {
                        setModalBody(
                          <DigestSection
                            sections={sections}
                            sectionNo={i}
                            docRef={config._docRef}
                            callback={() => setShowModal(false)}
                          />
                        );
                        setModalHeader("Add Section");
                        setShowModal(true);
                      }}
                    />
                  }
                </React.Fragment>
              )
            }
            return null
          })}
          {(!sections.length && !viewOnly) &&
            <AddDigestBtn
              canEdit={canEdit} size={30}
              onClick={() => {
                setModalBody(
                  <DigestSection
                    sections={sections}
                    sectionNo={0}
                    docRef={config._docRef}
                    callback={() => setShowModal(false)}
                  />
                );
                setModalHeader("Add Section");
                setShowModal(true);
              }}
            />
          }
          {viewOnly &&
            <FooterPoweredBy />
          }
        </Col>
        {!viewOnly &&
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            size={modalSize}
          >
            {modalHeader &&
              <Modal.Header close>
                {modalHeader}
              </Modal.Header>
            }
            <Modal.Body>
              {modalBody}
            </Modal.Body>
            <Modal.Footer className="d-flex flex-row justify-content-between">
              <Button
                onClick={() => setShowModal(false)}
                variant="light"
                size="sm"
              >
                Close
              </Button>
              {modalButton}
            </Modal.Footer>
          </Modal>
        }
      </Row>
    </Container>
  )
}

const AddDigestBtn = props => {
  const {onClick, size, canEdit} = props;

  if (!canEdit) {
    return null
  }
  return(
    <div className="d-block text-center p-3">
      <Button
        variant="link"
        onClick={() => {
          console.log("Clicked add section");
          if (onClick) {
            onClick();
          }
        }}
      >
        <PlusCircle
          className={props.className ? props.className : "text-muted"}
          size={size}
          style={props.style ? props.style : null}
        />
      </Button>
    </div>
  )
}

const DigestCategories = props => {
  const {userDoc, multiselect, onSelectMultipleCategories, onSelectCategory, selectedCategory} = props;
  const [currentTags, setCurrentTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState(selectedCategory ? [selectedCategory] : []);
  const [newTag, setNewTag] = useState("");
  useEffect( () => {
    console.log("userDoc.id: ", userDoc.id);
    let unsubscribe = db.collection("digests").doc(userDoc.id).onSnapshot( doc => {
      console.log("doc: ", doc);
      if (doc.exists) {
        let docData = doc.data();
        console.log("docData: ", docData);
        setCurrentTags(docData.categories);
      }
    })
    //unsubscribe();
    return( () => unsubscribe() )
  }, [userDoc.id]);

  return(
    <React.Fragment>
      {multiselect &&
        <Form.Group>
          <Form.Label>{props.multipleSelectFormLabel ? props.multipleSelectFormLabel : "Select tags"}</Form.Label>
          {currentTags.map( t => {
            return(
              <Form.Check
                key={`ct_${t}`}
                id={`ct_${t}`}
                type="checkbox"
                label={t}
                checked={selectedTags.includes(t)}
                onChange={() => {
                  let newTags;
                  if (selectedTags.includes(t)) {
                    newTags = selectedTags.filter(ct => ct !== t);
                  } else {
                    newTags = [...selectedTags, t];
                  }
                  setSelectedTags(newTags);
                  if (onSelectMultipleCategories) {
                    onSelectMultipleCategories(newTags)
                  }
                }}
              />
            )
          })}
        </Form.Group>
      }
      {!multiselect &&
        <Form.Group>
          <Form.Label>Select tag</Form.Label>
          <Form.Control
            as="select"
            value={selectedTags[0]}
            onChange={e => {
              let st = e.target.value
              setSelectedTags([st]);
              if (onSelectCategory) {
                onSelectCategory(st);
              }
            }}
          >
            <option value="">Select tag</option>
            {currentTags.map( t => {
              return(
                <option
                  key={`st_${t}`}
                >
                  {t}
                </option>
              )
            })}
          </Form.Control>
        </Form.Group>
      }
      <InputGroup>
        <Form.Control
          size="sm"
          type="text"
          value={newTag}
          onChange={e => setNewTag(e.target.value)}
          placeholder="Type new tag name"
        />
        <InputGroup.Append>
          <Button variant="outline-secondary"
            size="sm"
            onClick={() => {
              let newTags = [...currentTags.filter(t => t !== newTag), newTag];
              setCurrentTags(newTags);
              setNewTag("");
              console.log("userDoc: ", userDoc);
              db.collection("digests").doc(userDoc.id).update({
                categories: newTags
              }).catch( err => {
                console.log("Error adding new tag: ", err);
              })
            }}
          >
            Create new
          </Button>
        </InputGroup.Append>
      </InputGroup>
    </React.Fragment>
  )
}

const DigestContentPicker = props => {
  console.log("DigestContentPicker props: ", props);
  const {userDoc, contentId, updateConfig} = props;
  const [content, setContent] = useState([]);
  const [selectedContent, setSelectedContent] = useState("");
  const [changeContent, setChangeContent] = useState(contentId ? false : true);

  useEffect( () => {
    db.collection("content").where("aid", "==", userDoc.id).where("archived", "!=", true).orderBy("archived").orderBy("_created", "desc").get().then(querySnapshot => {
      if (!querySnapshot.empty) {
        let c = [];
        querySnapshot.forEach( doc => {
          let d = doc.data();
          d._docId = doc.id;
          c.push(d);
        })
        if (contentId) {
          let sc = c.find( a => a._docId === contentId);
          console.log("sc: ", sc);
          if( sc ) {
            setSelectedContent(sc.data);
          } else {
            setChangeContent(true);
          }

        }
        setContent(c);
      }
    })
  }, [userDoc.id, contentId])

  const {imgUrl, title} = selectedContent;

  return(
    <React.Fragment>
      {changeContent &&
        <AutoComplete
          suggestions={content.map( a => a.data)}
          placeholder="Search for article"
          helpText="Click a suggestion to select an article for display."
          searchKey="title"
          onSelect={(suggestion) => {
            let sc = content.find( a => a.data.title === suggestion.title);
            console.log("sc: ", sc);
            let contentId = sc._docId;
            console.log("contentId: ", contentId);
            updateConfig(contentId);
            setSelectedContent(sc.data);
            setChangeContent(false);
          }}
          suggestion={suggestion => {
            const {imgUrl, title} = suggestion;
            return(
              <ArticleRow imgUrl={imgUrl} title={title} />
            )
          }}
        />
      }
      {!changeContent &&
        <div className="d-flex flex-row justify-content-between align-items-center">
          <ArticleRow imgUrl={imgUrl} title={title} className="mb-2" />
          <Button
            variant="link"
            onClick={() => setChangeContent(true)}
          >
            <Pencil />
          </Button>
        </div>
      }
    </React.Fragment>
  )
}

const ArticleRow = props => {
  const {imgUrl, title} = props;
  let hasImage = imgUrl ? imgUrl.length > 0 : false;
  return(
    <div className={`d-flex flex-row align-items-center ${props.className ? props.className : ""}`}>
      <div style={{width: "50px", height: "50px"}}>
          <Image
            src={ hasImage ? imgUrl[0] : "https://via.placeholder.com/100/FFFFFF/FFFFFF"}
            style={{height: "50px", width: "50px", objectFit: "cover"}}
            thumbnail
          />
      </div>
      <span className="ml-2">{title}</span>
    </div>
  )
}

const CategoryTitle = props => {
  const {title, updateConfig} = props;
  const [ct, setCt] = useState("");
  useEffect( () => {
    setCt(title);
  }, [title]);

  return(
    <Form.Group>
      <Form.Label>
        Edit category title
      </Form.Label>
      <Form.Control
        type="text"
        value={ct}
        onChange={e => {
          setCt(e.target.value);
        }}
        onBlur={() => {
          updateConfig(ct);
        }}
      />
    </Form.Group>
  )
}

const LinkForm = props => {
  console.log("LinkForm props: ", props);
  const{updateConfig, linkColor, linkTextColor} = props;
  const [buttonColor, setButtonColor] = useState( linkColor ? linkColor : "");
  const [textColor, setTextColor] = useState( linkTextColor ? linkTextColor : "");

  return(
    <Card bg="light">
      <Card.Header>
        Optional: Change Button and Button Text Color
      </Card.Header>
      <Card.Body>
        <Form.Group>
          <Form.Label>
            Button Color
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="#F23D5E"
            value={buttonColor}
            onChange={ e => {
              setButtonColor(e.target.value);
            }}
            onBlur={() => updateConfig(buttonColor, "linkColor")}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Text Color
          </Form.Label>
          <Form.Control
            type="text"
            placeholder="#FFFFFF"
            value={textColor}
            onChange={ e => {
              setTextColor(e.target.value);
            }}
            onBlur={() => updateConfig(textColor, "linkTextColor")}
          />
        </Form.Group>
      </Card.Body>
    </Card>
  )
}

const createFormGroup = ({key, config, section, sectionNo, fieldRef, props}) => {
  const {_docRef} = config;

  const updateConfig = (newvalue, nkey=null) => {
    let newConfig = {...config};
    delete newConfig._docRef;
    newConfig.sections[sectionNo][nkey ? nkey : key] = newvalue;
    _docRef.update(newConfig)
  }

  if (key === "contentId") {
    return(
      <DigestContentPicker
        key={fieldRef}
        {...props}
        contentId={section[key]}
        updateConfig={updateConfig}
      />
    )
  }

  if (key === "categoryTitle") {
    return(
      <CategoryTitle
        title={section[key]}
        updateConfig={updateConfig}
      />
    )
  }

  if (key === "category") {
    return(
      <DigestCategories
        key={fieldRef}
        {...props}
        selectedCategory={section[key]}
        onSelectCategory={updateConfig}
        multiselect={false}
      />
    )
  }
  if( typeof section[key] === "boolean") {
    return(
      <React.Fragment key={fieldRef}>
        <Form.Check
          key={fieldRef}
          type="switch"
          id={fieldRef}
          checked={section[key]}
          label={key}
          onChange={() => {
            updateConfig(!section[key]);
          }}
        />
        { key === "showLink" && section[key] &&
          <LinkForm
            updateConfig={updateConfig}
            linkColor={section.linkColor}
            linkTextColor={section.linkTextColor}
          />
        }
      </React.Fragment>
    )
  }
  if ( typeof section[key] === "number") {
    return(
      <Form.Group className="mt-3">
        <Form.Label>Number articles</Form.Label>
        <Form.Control
          key={fieldRef}
          type="number"
          value={section[key]}
          onChange={ e => {
            updateConfig(parseInt(e.target.value));
          }}
        />
        <Form.Text>Select the number of articles to display in this section.</Form.Text>
      </Form.Group>
    )
  }
  if ( typeof section[key] === "string") {
    return(
      <div key={fieldRef}>
        <h5>Display Category {section[key]}</h5>
        <code>
          {JSON.stringify(section, null, 3)}
        </code>
      </div>
    )
  }
}

const SectionInlineMenu = props => {
  const {edit, setEdit, size, canEdit} = props;
  if (!canEdit) {
    return null
  }

  return(
    <Button
      variant="link"
      onClick={() => setEdit(!edit)}
      className={props.className ? props.className : ""}
      style={props.style ? props.style : null}
    >
      {!edit &&
        <GearWideConnected size={size} />
      }
      {edit &&
        <Eye size={size} />
      }
    </Button>
  )

}

const DisplayContent = props => {
  //console.log("DisplayContent props: ", props);
  const {config, section, sectionNo, canEdit, displayPreview, payloadCallback} = props;
  const {contentId} = section;
  const [edit, setEdit] = useState(false);
  const [article, setArticle] = useState(null);
  const placeholderArticle = {
    data: {
      title: "Select an article"
    }
  }
  useEffect( () => {
    if (contentId) {
      db.collection("content").doc(contentId).get().then( doc => {
        if (doc.exists) {
          let d = doc.data();
          d._docId = doc.id;
          setArticle(d);
          if (payloadCallback) {
            payloadCallback(null, d);
          }
        } else{
          setArticle(placeholderArticle);
        }
      })
    }
  }, [contentId, payloadCallback]);

  const keys = ["contentId", "showTitle", "showImage","showPreview","showContent", "showDate", "showLink"];
  return(
    <div>
      {!displayPreview && <SectionInlineMenu canEdit={canEdit} className="pull-right text-muted" size={25} edit={edit} setEdit={setEdit} />}
      {!edit && article &&
        <ArticlePreview
          isStatic={true}
          displayPreview={displayPreview}
          {...article}
          sectionConfig={section}
        />
      }
      {edit &&
        <Card>
          <Card.Header>
            Edit Article Display
          </Card.Header>
          <Card.Body>
            {keys.map( key => {
              return(createFormGroup({props, key, config, section, sectionNo, fieldRef: `sections[${sectionNo}].${key}`}))
            })}
          </Card.Body>
        </Card>
      }
    </div>
  )
}

const Category = props => {
  const {title, displayPreview, category} = props;
  if (!displayPreview) {
    return(
      <Card>
        <Card.Header>
          Dynamic Category: {category}
        </Card.Header>
        <Card.Body>
          {props.children}
        </Card.Body>
      </Card>
    )
  }
  return(
    <div>
      <h4>{title}</h4>
      <hr />
      {props.children}
    </div>
  )
}

const ArticlePreview = props => {
  //console.log("ArticlePreview props: ", props);
  const {data, _create, sectionConfig, _created, displayPreview, isStatic, _docId} = props;
  const {title, imgUrl, preview, content, contentType, link, linkText} = data;
  const {showImage, showTitle, showPreview, showContent, showDate, showLink, linkColor, linkTextColor} = sectionConfig;

  const hasImg = imgUrl ? imgUrl.length > 0 : false;
  const hasTitle = title ? title !== "" : false;
  const hasDate = _create ? _created > 0 : false;
  const hasPreview = preview ? preview !== "" : false;
  const hasContent = content ? content !== "" : false;
  const hasLink = link ? link !== "" : false;
  const hasLinkText = linkText ? linkText !== "" : false;

  if (!displayPreview && isStatic) {
    return(
      <Card>
        <Card.Header>
          Static Content
        </Card.Header>
        <Card.Body>
          <ArticleRow {...data} />
        </Card.Body>
      </Card>
    )
  };

  if (!displayPreview && !isStatic) {
    return(
      <ArticleRow {...data} className="mb-2" />
    )
  };

  return(
    <div className="mb-4 pb-3">
      {(showImage && hasImg) &&
        <a href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="d-block mb-3"
        >
          <Image src={imgUrl[0]} style={{maxWidth: "100%", height: "auto"}}/>
        </a>
      }
      {(showTitle && hasTitle) &&
        <h5>
          <a
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            className="text-dark"
          >
            {title}
          </a>
        </h5>
      }
      {(showDate && hasDate) &&
        <div>
          {new Date( _created * 1000).toLocaleString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          })}
        </div>
      }
      {(showPreview && hasPreview) &&
        <p><i>{preview}</i></p>
      }
      {(showContent && hasContent && contentType === "text") &&
        <p>{content}</p>
      }
      {(showContent && hasContent && ["md", "html"].includes(contentType)) &&
        <MDEditor.Markdown source={content} />
      }
      {(showLink) &&
        <a
          className="btn btn-sm btn-info"
          href={link === "" ? `/a/${_docId}` : link}
          target="_blank"
          rel="noopener noreferrer"
          style={ linkColor ? {
            backgroundColor: linkColor,
            borderColor: linkColor,
            color: linkTextColor ? linkTextColor : null
          } : null}
        >
          {hasLinkText && linkText}
          {!hasLinkText && "Read more"}
        </a>
      }
    </div>
  )
}

const DisplayCategory = props => {
  //console.log("DisplayCategory props: ", props);
  const {config, section, sectionNo, canEdit, displayPreview, payloadCallback} = props;
  const {category, noItems, categoryTitle} = section;
  const [edit, setEdit] = useState(false);
  const [content, setContent] = useState([]);

  useEffect( () => {
    db.collection("content").where("aid", "==", config.aid).where("archived", "!=", true).where("category", "array-contains", category).orderBy("archived").orderBy("_created", "desc").get().then(querySnapshot => {
      if (!querySnapshot.empty) {
        let c = [];
        querySnapshot.forEach( doc => {
          let d = doc.data();
          d._docId = doc.id;
          c.push(d);
        })
        console.log("c: ", c);
        setContent(c);
        if(payloadCallback) {
          payloadCallback(null, c.slice(0, noItems -1) );
        }
      }
    })
  }, [category, noItems, config.aid]);

  const keys = ["categoryTitle", "category", "noItems", "showTitle", "showImage", "showPreview", "showContent", "showDate", "showLink"];
  return(
    <div>
      {!displayPreview && <SectionInlineMenu canEdit={canEdit} className="pull-right text-muted" size={25} edit={edit} setEdit={setEdit} />}
      {!edit &&
        <Category
          title={categoryTitle}
          displayPreview={displayPreview}
          category={category}
        >
          {content.slice(0, noItems).map( (a,i) => {
            return(
              <ArticlePreview displayPreview={displayPreview} key={`ap_${i}`} {...a} sectionConfig={section} />
            )
          })}
        </Category>
      }
      {edit &&
        <Card>
          <Card.Header>
            Edit Category Display
          </Card.Header>
          <Card.Body>
            {keys.map( key => {
              return(createFormGroup({props, key, config, section, sectionNo, fieldRef: `sections[${sectionNo}].${key}`}))
            })}
          </Card.Body>
        </Card>
      }
    </div>
  )
}

export { Topnav, UserNav, SideBar, Home, Footer, FooterSmall, settingsPanes, Digests, ArticleRouter, Article, Import, getFirstSentence, createArticle, DigestRouter, Privacy, Terms, htmlToPlain };
