import React from "react";
//import PropTypes from 'prop-types';
import { Modal } from "react-bootstrap";
import videoIcon from "./media/video_icon.jpg";

class VideoDemo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
  }

  handleClose() {
    this.setState({
      modalShow: false
    });
  }

  handleShow() {
    this.setState({
      modalShow: true
    });
  }

  render() {
    return (
      <React.Fragment>
        <figure className="figure" onClick={this.handleShow}>
          <img
            src={videoIcon}
            alt={`See ${process.env.REACT_APP_site_name} in action`}
            className="rounded shadow img-fluid"
            style={{
              width: "410px",
              height: "auto",
              margin: "0 auto",
              marginTop: "30px"
            }}
          />
          <figcaption className="text-center text-muted">Watch demo</figcaption>
        </figure>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={this.state.modalShow}
          onHide={this.handleClose}
        >
          <Modal.Body className="d-flex justify-content-center">
            <iframe
              title="bigDemo"
              className="d-none d-md-block"
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/8ztesdn287Y"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>

            <iframe
              title="mobileDemo"
              className="d-md-none"
              width="300"
              height="200"
              src="https://www.youtube-nocookie.com/embed/8ztesdn287Y"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }
}

export default VideoDemo;
