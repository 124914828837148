import React from "react";
import {
  Button
} from "react-bootstrap";
import {
  Circle,
  CheckCircleFill,
  CircleFill
} from "react-bootstrap-icons";

function withBulkSelect( WrappedComponent, props) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedData: [],
        handleBulkSelectState: obj => this.setState(obj),
        toggleSelected: (docId) => {
          let newSelected = [...this.state.selectedData];
          if (newSelected.includes(docId)) {
            newSelected = newSelected.filter((item) => item !== docId);
          } else {
            newSelected.push(docId);
          }
          this.setState({ selectedData: newSelected });
        },
        BulkHeader: props => {
          const {selectedData} = this.state;
          const {data, idField, totalItems} = props;
          let total = totalItems ? totalItems : data.length;
          let size = props.size ? props.size : 30;
          return(
            <React.Fragment>
            {selectedData && (
              <Button
                className="d-flex flex-row"
                variant="link"
                onClick={() => {
                  //console.log(
                  //  "data: ", data,
                  //  "idField: ", idField
                  //);
                  if (selectedData.length === total) {
                    return this.setState({selectedData: []});
                  } else {
                    let allDocIds = [...selectedData];
                    data.forEach( d => {
                      if( !allDocIds.includes(d[idField]) ) {
                        return allDocIds.push(d[idField])
                      }
                    })
                    return this.setState({ selectedData: allDocIds });
                  }
                }}
              >
                {selectedData.length === total &&
                  selectedData.length > 0 && (
                    <>
                      <CheckCircleFill size={size} /> <span className="ml-1">{selectedData.length}</span>
                    </>
                  )}
                {selectedData.length === 0 && <Circle size={size} />}
                {selectedData.length > 0 &&
                  selectedData.length < total && (
                    <>
                      <CircleFill size={size} /> <span className="ml-1">{selectedData.length}</span>
                    </>
                  )}
              </Button>
            )}
            </React.Fragment>
          )
        },
        SelectItem: props => {
          const {toggleSelected, selectedData} = this.state;
          const {id} = props;
          let selected = selectedData.includes(id);
          //console.log("SelectItem props: ", props, "selectedData: ", selectedData);
          return(
            <Button
              variant="link"
              onClick={() => toggleSelected(id)}
              className={props.className ? props.className : ""}
              style={props.style ? props.style : null}
            >
              {!selected && <Circle size={props.size ? props.size : 30} />}
              {selected && <CheckCircleFill size={props.size ? props.size : 30} />}
            </Button>
          )
        }
      };
    }

    render() {
      return <WrappedComponent {...this.props} {...this.state} />;
    }
  };
}
 export default withBulkSelect;
