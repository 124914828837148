export default {
  geography: {
    AREA_CODE: {
      name: 'Area codes',
      displayHeader: true,
      inputType: "geography",
      items: [
        {value: "201", name: "201", meta:" - NJ"},
        {value: "202", name: "202", meta:" - DC"},
        {value: "203", name: "203", meta:" - CT"},
        {value: "204", name: "204", meta:" - MANITOBA"},
        {value: "205", name: "205", meta:" - AL"},
        {value: "206", name: "206", meta:" - WA"},
        {value: "207", name: "207", meta:" - ME"},
        {value: "208", name: "208", meta:" - ID"},
        {value: "209", name: "209", meta:" - CA"},
        {value: "210", name: "210", meta:" - TX"},
        {value: "212", name: "212", meta:" - NY"},
        {value: "213", name: "213", meta:" - CA"},
        {value: "214", name: "214", meta:" - TX"},
        {value: "215", name: "215", meta:" - PA"},
        {value: "216", name: "216", meta:" - OH"},
        {value: "217", name: "217", meta:" - IL"},
        {value: "218", name: "218", meta:" - MN"},
        {value: "219", name: "219", meta:" - IN"},
        {value: "220", name: "220", meta:" - OH"},
        {value: "223", name: "223", meta:" - PA"},
        {value: "224", name: "224", meta:" - IL"},
        {value: "225", name: "225", meta:" - LA"},
        {value: "226", name: "226", meta:" - ONTARIO"},
        {value: "228", name: "228", meta:" - MS"},
        {value: "229", name: "229", meta:" - GA"},
        {value: "231", name: "231", meta:" - MI"},
        {value: "234", name: "234", meta:" - OH"},
        {value: "236", name: "236", meta:" - BRITISH COLUMBIA"},
        {value: "239", name: "239", meta:" - FL"},
        {value: "240", name: "240", meta:" - MD"},
        {value: "242", name: "242", meta:" - BAHAMAS"},
        {value: "246", name: "246", meta:" - BARBADOS"},
        {value: "248", name: "248", meta:" - MI"},
        {value: "249", name: "249", meta:" - ONTARIO"},
        {value: "250", name: "250", meta:" - BRITISH COLUMBIA"},
        {value: "251", name: "251", meta:" - AL"},
        {value: "252", name: "252", meta:" - NC"},
        {value: "253", name: "253", meta:" - WA"},
        {value: "254", name: "254", meta:" - TX"},
        {value: "256", name: "256", meta:" - AL"},
        {value: "260", name: "260", meta:" - IN"},
        {value: "262", name: "262", meta:" - WI"},
        {value: "264", name: "264", meta:" - ANGUILLA"},
        {value: "267", name: "267", meta:" - PA"},
        {value: "268", name: "268", meta:" - ANTIGUA/BARBUDA"},
        {value: "269", name: "269", meta:" - MI"},
        {value: "270", name: "270", meta:" - KY"},
        {value: "272", name: "272", meta:" - PA"},
        {value: "276", name: "276", meta:" - VA"},
        {value: "279", name: "279", meta:" - CA"},
        {value: "281", name: "281", meta:" - TX"},
        {value: "284", name: "284", meta:" - BRITISH VIRGIN ISLANDS"},
        {value: "289", name: "289", meta:" - ONTARIO"},
        {value: "301", name: "301", meta:" - MD"},
        {value: "302", name: "302", meta:" - DE"},
        {value: "303", name: "303", meta:" - CO"},
        {value: "304", name: "304", meta:" - WV"},
        {value: "305", name: "305", meta:" - FL"},
        {value: "306", name: "306", meta:" - SASKATCHEWAN"},
        {value: "307", name: "307", meta:" - WY"},
        {value: "308", name: "308", meta:" - NE"},
        {value: "309", name: "309", meta:" - IL"},
        {value: "310", name: "310", meta:" - CA"},
        {value: "312", name: "312", meta:" - IL"},
        {value: "313", name: "313", meta:" - MI"},
        {value: "314", name: "314", meta:" - MO"},
        {value: "315", name: "315", meta:" - NY"},
        {value: "316", name: "316", meta:" - KS"},
        {value: "317", name: "317", meta:" - IN"},
        {value: "318", name: "318", meta:" - LA"},
        {value: "319", name: "319", meta:" - IA"},
        {value: "320", name: "320", meta:" - MN"},
        {value: "321", name: "321", meta:" - FL"},
        {value: "323", name: "323", meta:" - CA"},
        {value: "325", name: "325", meta:" - TX"},
        {value: "330", name: "330", meta:" - OH"},
        {value: "331", name: "331", meta:" - IL"},
        {value: "332", name: "332", meta:" - NY"},
        {value: "334", name: "334", meta:" - AL"},
        {value: "336", name: "336", meta:" - NC"},
        {value: "337", name: "337", meta:" - LA"},
        {value: "339", name: "339", meta:" - MA"},
        {value: "340", name: "340", meta:" - USVI"},
        {value: "341", name: "341", meta:" - CA"},
        {value: "343", name: "343", meta:" - ONTARIO"},
        {value: "345", name: "345", meta:" - CAYMAN ISLANDS"},
        {value: "346", name: "346", meta:" - TX"},
        {value: "347", name: "347", meta:" - NY"},
        {value: "351", name: "351", meta:" - MA"},
        {value: "352", name: "352", meta:" - FL"},
        {value: "360", name: "360", meta:" - WA"},
        {value: "361", name: "361", meta:" - TX"},
        {value: "364", name: "364", meta:" - KY"},
        {value: "365", name: "365", meta:" - ONTARIO"},
        {value: "367", name: "367", meta:" - QUEBEC"},
        {value: "380", name: "380", meta:" - OH"},
        {value: "385", name: "385", meta:" - UT"},
        {value: "386", name: "386", meta:" - FL"},
        {value: "401", name: "401", meta:" - RI"},
        {value: "402", name: "402", meta:" - NE"},
        {value: "403", name: "403", meta:" - ALBERTA"},
        {value: "404", name: "404", meta:" - GA"},
        {value: "405", name: "405", meta:" - OK"},
        {value: "406", name: "406", meta:" - MT"},
        {value: "407", name: "407", meta:" - FL"},
        {value: "408", name: "408", meta:" - CA"},
        {value: "409", name: "409", meta:" - TX"},
        {value: "410", name: "410", meta:" - MD"},
        {value: "412", name: "412", meta:" - PA"},
        {value: "413", name: "413", meta:" - MA"},
        {value: "414", name: "414", meta:" - WI"},
        {value: "415", name: "415", meta:" - CA"},
        {value: "416", name: "416", meta:" - ONTARIO"},
        {value: "417", name: "417", meta:" - MO"},
        {value: "418", name: "418", meta:" - QUEBEC"},
        {value: "419", name: "419", meta:" - OH"},
        {value: "423", name: "423", meta:" - TN"},
        {value: "424", name: "424", meta:" - CA"},
        {value: "425", name: "425", meta:" - WA"},
        {value: "430", name: "430", meta:" - TX"},
        {value: "431", name: "431", meta:" - MANITOBA"},
        {value: "432", name: "432", meta:" - TX"},
        {value: "434", name: "434", meta:" - VA"},
        {value: "435", name: "435", meta:" - UT"},
        {value: "437", name: "437", meta:" - ONTARIO"},
        {value: "438", name: "438", meta:" - QUEBEC"},
        {value: "440", name: "440", meta:" - OH"},
        {value: "441", name: "441", meta:" - BERMUDA"},
        {value: "442", name: "442", meta:" - CA"},
        {value: "443", name: "443", meta:" - MD"},
        {value: "445", name: "445", meta:" - PA"},
        {value: "450", name: "450", meta:" - QUEBEC"},
        {value: "458", name: "458", meta:" - OR"},
        {value: "463", name: "463", meta:" - IN"},
        {value: "469", name: "469", meta:" - TX"},
        {value: "470", name: "470", meta:" - GA"},
        {value: "473", name: "473", meta:" - GRENADA"},
        {value: "475", name: "475", meta:" - CT"},
        {value: "478", name: "478", meta:" - GA"},
        {value: "479", name: "479", meta:" - AR"},
        {value: "480", name: "480", meta:" - AZ"},
        {value: "484", name: "484", meta:" - PA"},
        {value: "501", name: "501", meta:" - AR"},
        {value: "502", name: "502", meta:" - KY"},
        {value: "503", name: "503", meta:" - OR"},
        {value: "504", name: "504", meta:" - LA"},
        {value: "505", name: "505", meta:" - NM"},
        {value: "506", name: "506", meta:" - NEW BRUNSWICK"},
        {value: "507", name: "507", meta:" - MN"},
        {value: "508", name: "508", meta:" - MA"},
        {value: "509", name: "509", meta:" - WA"},
        {value: "510", name: "510", meta:" - CA"},
        {value: "512", name: "512", meta:" - TX"},
        {value: "513", name: "513", meta:" - OH"},
        {value: "514", name: "514", meta:" - QUEBEC"},
        {value: "515", name: "515", meta:" - IA"},
        {value: "516", name: "516", meta:" - NY"},
        {value: "517", name: "517", meta:" - MI"},
        {value: "518", name: "518", meta:" - NY"},
        {value: "519", name: "519", meta:" - ONTARIO"},
        {value: "520", name: "520", meta:" - AZ"},
        {value: "530", name: "530", meta:" - CA"},
        {value: "531", name: "531", meta:" - NE"},
        {value: "534", name: "534", meta:" - WI"},
        {value: "539", name: "539", meta:" - OK"},
        {value: "540", name: "540", meta:" - VA"},
        {value: "541", name: "541", meta:" - OR"},
        {value: "548", name: "548", meta:" - ONTARIO"},
        {value: "551", name: "551", meta:" - NJ"},
        {value: "559", name: "559", meta:" - CA"},
        {value: "561", name: "561", meta:" - FL"},
        {value: "562", name: "562", meta:" - CA"},
        {value: "563", name: "563", meta:" - IA"},
        {value: "564", name: "564", meta:" - WA"},
        {value: "567", name: "567", meta:" - OH"},
        {value: "570", name: "570", meta:" - PA"},
        {value: "571", name: "571", meta:" - VA"},
        {value: "573", name: "573", meta:" - MO"},
        {value: "574", name: "574", meta:" - IN"},
        {value: "575", name: "575", meta:" - NM"},
        {value: "579", name: "579", meta:" - QUEBEC"},
        {value: "580", name: "580", meta:" - OK"},
        {value: "581", name: "581", meta:" - QUEBEC"},
        {value: "585", name: "585", meta:" - NY"},
        {value: "586", name: "586", meta:" - MI"},
        {value: "587", name: "587", meta:" - ALBERTA"},
        {value: "601", name: "601", meta:" - MS"},
        {value: "602", name: "602", meta:" - AZ"},
        {value: "603", name: "603", meta:" - NH"},
        {value: "604", name: "604", meta:" - BRITISH COLUMBIA"},
        {value: "605", name: "605", meta:" - SD"},
        {value: "606", name: "606", meta:" - KY"},
        {value: "607", name: "607", meta:" - NY"},
        {value: "608", name: "608", meta:" - WI"},
        {value: "609", name: "609", meta:" - NJ"},
        {value: "610", name: "610", meta:" - PA"},
        {value: "612", name: "612", meta:" - MN"},
        {value: "613", name: "613", meta:" - ONTARIO"},
        {value: "614", name: "614", meta:" - OH"},
        {value: "615", name: "615", meta:" - TN"},
        {value: "616", name: "616", meta:" - MI"},
        {value: "617", name: "617", meta:" - MA"},
        {value: "618", name: "618", meta:" - IL"},
        {value: "619", name: "619", meta:" - CA"},
        {value: "620", name: "620", meta:" - KS"},
        {value: "623", name: "623", meta:" - AZ"},
        {value: "626", name: "626", meta:" - CA"},
        {value: "628", name: "628", meta:" - CA"},
        {value: "629", name: "629", meta:" - TN"},
        {value: "630", name: "630", meta:" - IL"},
        {value: "631", name: "631", meta:" - NY"},
        {value: "636", name: "636", meta:" - MO"},
        {value: "639", name: "639", meta:" - SASKATCHEWAN"},
        {value: "640", name: "640", meta:" - NJ"},
        {value: "641", name: "641", meta:" - IA"},
        {value: "646", name: "646", meta:" - NY"},
        {value: "647", name: "647", meta:" - ONTARIO"},
        {value: "649", name: "649", meta:" - TURKS & CAICOS ISLANDS"},
        {value: "650", name: "650", meta:" - CA"},
        {value: "651", name: "651", meta:" - MN"},
        {value: "657", name: "657", meta:" - CA"},
        {value: "658", name: "658", meta:" - JAMAICA"},
        {value: "660", name: "660", meta:" - MO"},
        {value: "661", name: "661", meta:" - CA"},
        {value: "662", name: "662", meta:" - MS"},
        {value: "664", name: "664", meta:" - MONTSERRAT"},
        {value: "667", name: "667", meta:" - MD"},
        {value: "669", name: "669", meta:" - CA"},
        {value: "670", name: "670", meta:" - CNMI"},
        {value: "671", name: "671", meta:" - GU"},
        {value: "672", name: "672", meta:" - BRITISH COLUMBIA"},
        {value: "678", name: "678", meta:" - GA"},
        {value: "680", name: "680", meta:" - NY"},
        {value: "681", name: "681", meta:" - WV"},
        {value: "682", name: "682", meta:" - TX"},
        {value: "684", name: "684", meta:" - AS"},
        {value: "689", name: "689", meta:" - FL"},
        {value: "701", name: "701", meta:" - ND"},
        {value: "702", name: "702", meta:" - NV"},
        {value: "703", name: "703", meta:" - VA"},
        {value: "704", name: "704", meta:" - NC"},
        {value: "705", name: "705", meta:" - ONTARIO"},
        {value: "706", name: "706", meta:" - GA"},
        {value: "707", name: "707", meta:" - CA"},
        {value: "708", name: "708", meta:" - IL"},
        {value: "709", name: "709", meta:" - NEWFOUNDLAND AND LABRADOR"},
        {value: "712", name: "712", meta:" - IA"},
        {value: "713", name: "713", meta:" - TX"},
        {value: "714", name: "714", meta:" - CA"},
        {value: "715", name: "715", meta:" - WI"},
        {value: "716", name: "716", meta:" - NY"},
        {value: "717", name: "717", meta:" - PA"},
        {value: "718", name: "718", meta:" - NY"},
        {value: "719", name: "719", meta:" - CO"},
        {value: "720", name: "720", meta:" - CO"},
        {value: "721", name: "721", meta:" - SINT MAARTEN"},
        {value: "724", name: "724", meta:" - PA"},
        {value: "725", name: "725", meta:" - NV"},
        {value: "726", name: "726", meta:" - TX"},
        {value: "727", name: "727", meta:" - FL"},
        {value: "731", name: "731", meta:" - TN"},
        {value: "732", name: "732", meta:" - NJ"},
        {value: "734", name: "734", meta:" - MI"},
        {value: "737", name: "737", meta:" - TX"},
        {value: "740", name: "740", meta:" - OH"},
        {value: "743", name: "743", meta:" - NC"},
        {value: "747", name: "747", meta:" - CA"},
        {value: "754", name: "754", meta:" - FL"},
        {value: "757", name: "757", meta:" - VA"},
        {value: "758", name: "758", meta:" - ST. LUCIA"},
        {value: "760", name: "760", meta:" - CA"},
        {value: "762", name: "762", meta:" - GA"},
        {value: "763", name: "763", meta:" - MN"},
        {value: "765", name: "765", meta:" - IN"},
        {value: "767", name: "767", meta:" - DOMINICA"},
        {value: "769", name: "769", meta:" - MS"},
        {value: "770", name: "770", meta:" - GA"},
        {value: "772", name: "772", meta:" - FL"},
        {value: "773", name: "773", meta:" - IL"},
        {value: "774", name: "774", meta:" - MA"},
        {value: "775", name: "775", meta:" - NV"},
        {value: "778", name: "778", meta:" - BRITISH COLUMBIA"},
        {value: "779", name: "779", meta:" - IL"},
        {value: "780", name: "780", meta:" - ALBERTA"},
        {value: "781", name: "781", meta:" - MA"},
        {value: "782", name: "782", meta:" - NOVA SCOTIA - PRINCE EDWARD ISLAND"},
        {value: "784", name: "784", meta:" - ST. VINCENT & GRENADINES"},
        {value: "785", name: "785", meta:" - KS"},
        {value: "786", name: "786", meta:" - FL"},
        {value: "787", name: "787", meta:" - PUERTO RICO"},
        {value: "801", name: "801", meta:" - UT"},
        {value: "802", name: "802", meta:" - VT"},
        {value: "803", name: "803", meta:" - SC"},
        {value: "804", name: "804", meta:" - VA"},
        {value: "805", name: "805", meta:" - CA"},
        {value: "806", name: "806", meta:" - TX"},
        {value: "807", name: "807", meta:" - ONTARIO"},
        {value: "808", name: "808", meta:" - HI"},
        {value: "809", name: "809", meta:" - DOMINICAN REPUBLIC"},
        {value: "810", name: "810", meta:" - MI"},
        {value: "812", name: "812", meta:" - IN"},
        {value: "813", name: "813", meta:" - FL"},
        {value: "814", name: "814", meta:" - PA"},
        {value: "815", name: "815", meta:" - IL"},
        {value: "816", name: "816", meta:" - MO"},
        {value: "817", name: "817", meta:" - TX"},
        {value: "818", name: "818", meta:" - CA"},
        {value: "819", name: "819", meta:" - QUEBEC"},
        {value: "820", name: "820", meta:" - CA"},
        {value: "825", name: "825", meta:" - ALBERTA"},
        {value: "828", name: "828", meta:" - NC"},
        {value: "829", name: "829", meta:" - DOMINICAN REPUBLIC"},
        {value: "830", name: "830", meta:" - TX"},
        {value: "831", name: "831", meta:" - CA"},
        {value: "832", name: "832", meta:" - TX"},
        {value: "838", name: "838", meta:" - NY"},
        {value: "843", name: "843", meta:" - SC"},
        {value: "845", name: "845", meta:" - NY"},
        {value: "847", name: "847", meta:" - IL"},
        {value: "848", name: "848", meta:" - NJ"},
        {value: "849", name: "849", meta:" - DOMINICAN REPUBLIC"},
        {value: "850", name: "850", meta:" - FL"},
        {value: "854", name: "854", meta:" - SC"},
        {value: "856", name: "856", meta:" - NJ"},
        {value: "857", name: "857", meta:" - MA"},
        {value: "858", name: "858", meta:" - CA"},
        {value: "859", name: "859", meta:" - KY"},
        {value: "860", name: "860", meta:" - CT"},
        {value: "862", name: "862", meta:" - NJ"},
        {value: "863", name: "863", meta:" - FL"},
        {value: "864", name: "864", meta:" - SC"},
        {value: "865", name: "865", meta:" - TN"},
        {value: "867", name: "867", meta:" - YUKON-NW TERR. - NUNAVUT"},
        {value: "868", name: "868", meta:" - TRINIDAD & TOBAGO"},
        {value: "869", name: "869", meta:" - ST. KITTS & NEVIS"},
        {value: "870", name: "870", meta:" - AR"},
        {value: "872", name: "872", meta:" - IL"},
        {value: "873", name: "873", meta:" - QUEBEC"},
        {value: "876", name: "876", meta:" - JAMAICA"},
        {value: "878", name: "878", meta:" - PA"},
        {value: "901", name: "901", meta:" - TN"},
        {value: "902", name: "902", meta:" - NOVA SCOTIA - PRINCE EDWARD ISLAND"},
        {value: "903", name: "903", meta:" - TX"},
        {value: "904", name: "904", meta:" - FL"},
        {value: "905", name: "905", meta:" - ONTARIO"},
        {value: "906", name: "906", meta:" - MI"},
        {value: "907", name: "907", meta:" - AK"},
        {value: "908", name: "908", meta:" - NJ"},
        {value: "909", name: "909", meta:" - CA"},
        {value: "910", name: "910", meta:" - NC"},
        {value: "912", name: "912", meta:" - GA"},
        {value: "913", name: "913", meta:" - KS"},
        {value: "914", name: "914", meta:" - NY"},
        {value: "915", name: "915", meta:" - TX"},
        {value: "916", name: "916", meta:" - CA"},
        {value: "917", name: "917", meta:" - NY"},
        {value: "918", name: "918", meta:" - OK"},
        {value: "919", name: "919", meta:" - NC"},
        {value: "920", name: "920", meta:" - WI"},
        {value: "925", name: "925", meta:" - CA"},
        {value: "928", name: "928", meta:" - AZ"},
        {value: "929", name: "929", meta:" - NY"},
        {value: "930", name: "930", meta:" - IN"},
        {value: "931", name: "931", meta:" - TN"},
        {value: "934", name: "934", meta:" - NY"},
        {value: "936", name: "936", meta:" - TX"},
        {value: "937", name: "937", meta:" - OH"},
        {value: "938", name: "938", meta:" - AL"},
        {value: "939", name: "939", meta:" - PUERTO RICO"},
        {value: "940", name: "940", meta:" - TX"},
        {value: "941", name: "941", meta:" - FL"},
        {value: "947", name: "947", meta:" - MI"},
        {value: "949", name: "949", meta:" - CA"},
        {value: "951", name: "951", meta:" - CA"},
        {value: "952", name: "952", meta:" - MN"},
        {value: "954", name: "954", meta:" - FL"},
        {value: "956", name: "956", meta:" - TX"},
        {value: "959", name: "959", meta:" - CT"},
        {value: "970", name: "970", meta:" - CO"},
        {value: "971", name: "971", meta:" - OR"},
        {value: "972", name: "972", meta:" - TX"},
        {value: "973", name: "973", meta:" - NJ"},
        {value: "978", name: "978", meta:" - MA"},
        {value: "979", name: "979", meta:" - TX"},
        {value: "980", name: "980", meta:" - NC"},
        {value: "984", name: "984", meta:" - NC"},
        {value: "985", name: "985", meta:" - LA"},
        {value: "986", name: "986", meta:" - ID"},
        {value: "989", name: "989", meta:" - MI"},
      ],
      helpText: "Select up to 5 area codes per search"
    },
    AREA_CODE_Integer: {
      name: 'Area codes',
      displayHeader: true,
      inputType: "geography",
      items: [
        {value: 201, name: "201", meta:" - NJ"},
        {value: 202, name: "202", meta:" - DC"},
        {value: 203, name: "203", meta:" - CT"},
        {value: 204, name: "204", meta:" - MANITOBA"},
        {value: 205, name: "205", meta:" - AL"},
        {value: 206, name: "206", meta:" - WA"},
        {value: 207, name: "207", meta:" - ME"},
        {value: 208, name: "208", meta:" - ID"},
        {value: 209, name: "209", meta:" - CA"},
        {value: 210, name: "210", meta:" - TX"},
        {value: 212, name: "212", meta:" - NY"},
        {value: 213, name: "213", meta:" - CA"},
        {value: 214, name: "214", meta:" - TX"},
        {value: 215, name: "215", meta:" - PA"},
        {value: 216, name: "216", meta:" - OH"},
        {value: 217, name: "217", meta:" - IL"},
        {value: 218, name: "218", meta:" - MN"},
        {value: 219, name: "219", meta:" - IN"},
        {value: 220, name: "220", meta:" - OH"},
        {value: 223, name: "223", meta:" - PA"},
        {value: 224, name: "224", meta:" - IL"},
        {value: 225, name: "225", meta:" - LA"},
        {value: 226, name: "226", meta:" - ONTARIO"},
        {value: 228, name: "228", meta:" - MS"},
        {value: 229, name: "229", meta:" - GA"},
        {value: 231, name: "231", meta:" - MI"},
        {value: 234, name: "234", meta:" - OH"},
        {value: 236, name: "236", meta:" - BRITISH COLUMBIA"},
        {value: 239, name: "239", meta:" - FL"},
        {value: 240, name: "240", meta:" - MD"},
        {value: 242, name: "242", meta:" - BAHAMAS"},
        {value: 246, name: "246", meta:" - BARBADOS"},
        {value: 248, name: "248", meta:" - MI"},
        {value: 249, name: "249", meta:" - ONTARIO"},
        {value: 250, name: "250", meta:" - BRITISH COLUMBIA"},
        {value: 251, name: "251", meta:" - AL"},
        {value: 252, name: "252", meta:" - NC"},
        {value: 253, name: "253", meta:" - WA"},
        {value: 254, name: "254", meta:" - TX"},
        {value: 256, name: "256", meta:" - AL"},
        {value: 260, name: "260", meta:" - IN"},
        {value: 262, name: "262", meta:" - WI"},
        {value: 264, name: "264", meta:" - ANGUILLA"},
        {value: 267, name: "267", meta:" - PA"},
        {value: 268, name: "268", meta:" - ANTIGUA/BARBUDA"},
        {value: 269, name: "269", meta:" - MI"},
        {value: 270, name: "270", meta:" - KY"},
        {value: 272, name: "272", meta:" - PA"},
        {value: 276, name: "276", meta:" - VA"},
        {value: 279, name: "279", meta:" - CA"},
        {value: 281, name: "281", meta:" - TX"},
        {value: 284, name: "284", meta:" - BRITISH VIRGIN ISLANDS"},
        {value: 289, name: "289", meta:" - ONTARIO"},
        {value: 301, name: "301", meta:" - MD"},
        {value: 302, name: "302", meta:" - DE"},
        {value: 303, name: "303", meta:" - CO"},
        {value: 304, name: "304", meta:" - WV"},
        {value: 305, name: "305", meta:" - FL"},
        {value: 306, name: "306", meta:" - SASKATCHEWAN"},
        {value: 307, name: "307", meta:" - WY"},
        {value: 308, name: "308", meta:" - NE"},
        {value: 309, name: "309", meta:" - IL"},
        {value: 310, name: "310", meta:" - CA"},
        {value: 312, name: "312", meta:" - IL"},
        {value: 313, name: "313", meta:" - MI"},
        {value: 314, name: "314", meta:" - MO"},
        {value: 315, name: "315", meta:" - NY"},
        {value: 316, name: "316", meta:" - KS"},
        {value: 317, name: "317", meta:" - IN"},
        {value: 318, name: "318", meta:" - LA"},
        {value: 319, name: "319", meta:" - IA"},
        {value: 320, name: "320", meta:" - MN"},
        {value: 321, name: "321", meta:" - FL"},
        {value: 323, name: "323", meta:" - CA"},
        {value: 325, name: "325", meta:" - TX"},
        {value: 330, name: "330", meta:" - OH"},
        {value: 331, name: "331", meta:" - IL"},
        {value: 332, name: "332", meta:" - NY"},
        {value: 334, name: "334", meta:" - AL"},
        {value: 336, name: "336", meta:" - NC"},
        {value: 337, name: "337", meta:" - LA"},
        {value: 339, name: "339", meta:" - MA"},
        {value: 340, name: "340", meta:" - USVI"},
        {value: 341, name: "341", meta:" - CA"},
        {value: 343, name: "343", meta:" - ONTARIO"},
        {value: 345, name: "345", meta:" - CAYMAN ISLANDS"},
        {value: 346, name: "346", meta:" - TX"},
        {value: 347, name: "347", meta:" - NY"},
        {value: 351, name: "351", meta:" - MA"},
        {value: 352, name: "352", meta:" - FL"},
        {value: 360, name: "360", meta:" - WA"},
        {value: 361, name: "361", meta:" - TX"},
        {value: 364, name: "364", meta:" - KY"},
        {value: 365, name: "365", meta:" - ONTARIO"},
        {value: 367, name: "367", meta:" - QUEBEC"},
        {value: 380, name: "380", meta:" - OH"},
        {value: 385, name: "385", meta:" - UT"},
        {value: 386, name: "386", meta:" - FL"},
        {value: 401, name: "401", meta:" - RI"},
        {value: 402, name: "402", meta:" - NE"},
        {value: 403, name: "403", meta:" - ALBERTA"},
        {value: 404, name: "404", meta:" - GA"},
        {value: 405, name: "405", meta:" - OK"},
        {value: 406, name: "406", meta:" - MT"},
        {value: 407, name: "407", meta:" - FL"},
        {value: 408, name: "408", meta:" - CA"},
        {value: 409, name: "409", meta:" - TX"},
        {value: 410, name: "410", meta:" - MD"},
        {value: 412, name: "412", meta:" - PA"},
        {value: 413, name: "413", meta:" - MA"},
        {value: 414, name: "414", meta:" - WI"},
        {value: 415, name: "415", meta:" - CA"},
        {value: 416, name: "416", meta:" - ONTARIO"},
        {value: 417, name: "417", meta:" - MO"},
        {value: 418, name: "418", meta:" - QUEBEC"},
        {value: 419, name: "419", meta:" - OH"},
        {value: 423, name: "423", meta:" - TN"},
        {value: 424, name: "424", meta:" - CA"},
        {value: 425, name: "425", meta:" - WA"},
        {value: 430, name: "430", meta:" - TX"},
        {value: 431, name: "431", meta:" - MANITOBA"},
        {value: 432, name: "432", meta:" - TX"},
        {value: 434, name: "434", meta:" - VA"},
        {value: 435, name: "435", meta:" - UT"},
        {value: 437, name: "437", meta:" - ONTARIO"},
        {value: 438, name: "438", meta:" - QUEBEC"},
        {value: 440, name: "440", meta:" - OH"},
        {value: 441, name: "441", meta:" - BERMUDA"},
        {value: 442, name: "442", meta:" - CA"},
        {value: 443, name: "443", meta:" - MD"},
        {value: 445, name: "445", meta:" - PA"},
        {value: 450, name: "450", meta:" - QUEBEC"},
        {value: 458, name: "458", meta:" - OR"},
        {value: 463, name: "463", meta:" - IN"},
        {value: 469, name: "469", meta:" - TX"},
        {value: 470, name: "470", meta:" - GA"},
        {value: 473, name: "473", meta:" - GRENADA"},
        {value: 475, name: "475", meta:" - CT"},
        {value: 478, name: "478", meta:" - GA"},
        {value: 479, name: "479", meta:" - AR"},
        {value: 480, name: "480", meta:" - AZ"},
        {value: 484, name: "484", meta:" - PA"},
        {value: 501, name: "501", meta:" - AR"},
        {value: 502, name: "502", meta:" - KY"},
        {value: 503, name: "503", meta:" - OR"},
        {value: 504, name: "504", meta:" - LA"},
        {value: 505, name: "505", meta:" - NM"},
        {value: 506, name: "506", meta:" - NEW BRUNSWICK"},
        {value: 507, name: "507", meta:" - MN"},
        {value: 508, name: "508", meta:" - MA"},
        {value: 509, name: "509", meta:" - WA"},
        {value: 510, name: "510", meta:" - CA"},
        {value: 512, name: "512", meta:" - TX"},
        {value: 513, name: "513", meta:" - OH"},
        {value: 514, name: "514", meta:" - QUEBEC"},
        {value: 515, name: "515", meta:" - IA"},
        {value: 516, name: "516", meta:" - NY"},
        {value: 517, name: "517", meta:" - MI"},
        {value: 518, name: "518", meta:" - NY"},
        {value: 519, name: "519", meta:" - ONTARIO"},
        {value: 520, name: "520", meta:" - AZ"},
        {value: 530, name: "530", meta:" - CA"},
        {value: 531, name: "531", meta:" - NE"},
        {value: 534, name: "534", meta:" - WI"},
        {value: 539, name: "539", meta:" - OK"},
        {value: 540, name: "540", meta:" - VA"},
        {value: 541, name: "541", meta:" - OR"},
        {value: 548, name: "548", meta:" - ONTARIO"},
        {value: 551, name: "551", meta:" - NJ"},
        {value: 559, name: "559", meta:" - CA"},
        {value: 561, name: "561", meta:" - FL"},
        {value: 562, name: "562", meta:" - CA"},
        {value: 563, name: "563", meta:" - IA"},
        {value: 564, name: "564", meta:" - WA"},
        {value: 567, name: "567", meta:" - OH"},
        {value: 570, name: "570", meta:" - PA"},
        {value: 571, name: "571", meta:" - VA"},
        {value: 573, name: "573", meta:" - MO"},
        {value: 574, name: "574", meta:" - IN"},
        {value: 575, name: "575", meta:" - NM"},
        {value: 579, name: "579", meta:" - QUEBEC"},
        {value: 580, name: "580", meta:" - OK"},
        {value: 581, name: "581", meta:" - QUEBEC"},
        {value: 585, name: "585", meta:" - NY"},
        {value: 586, name: "586", meta:" - MI"},
        {value: 587, name: "587", meta:" - ALBERTA"},
        {value: 601, name: "601", meta:" - MS"},
        {value: 602, name: "602", meta:" - AZ"},
        {value: 603, name: "603", meta:" - NH"},
        {value: 604, name: "604", meta:" - BRITISH COLUMBIA"},
        {value: 605, name: "605", meta:" - SD"},
        {value: 606, name: "606", meta:" - KY"},
        {value: 607, name: "607", meta:" - NY"},
        {value: 608, name: "608", meta:" - WI"},
        {value: 609, name: "609", meta:" - NJ"},
        {value: 610, name: "610", meta:" - PA"},
        {value: 612, name: "612", meta:" - MN"},
        {value: 613, name: "613", meta:" - ONTARIO"},
        {value: 614, name: "614", meta:" - OH"},
        {value: 615, name: "615", meta:" - TN"},
        {value: 616, name: "616", meta:" - MI"},
        {value: 617, name: "617", meta:" - MA"},
        {value: 618, name: "618", meta:" - IL"},
        {value: 619, name: "619", meta:" - CA"},
        {value: 620, name: "620", meta:" - KS"},
        {value: 623, name: "623", meta:" - AZ"},
        {value: 626, name: "626", meta:" - CA"},
        {value: 628, name: "628", meta:" - CA"},
        {value: 629, name: "629", meta:" - TN"},
        {value: 630, name: "630", meta:" - IL"},
        {value: 631, name: "631", meta:" - NY"},
        {value: 636, name: "636", meta:" - MO"},
        {value: 639, name: "639", meta:" - SASKATCHEWAN"},
        {value: 640, name: "640", meta:" - NJ"},
        {value: 641, name: "641", meta:" - IA"},
        {value: 646, name: "646", meta:" - NY"},
        {value: 647, name: "647", meta:" - ONTARIO"},
        {value: 649, name: "649", meta:" - TURKS & CAICOS ISLANDS"},
        {value: 650, name: "650", meta:" - CA"},
        {value: 651, name: "651", meta:" - MN"},
        {value: 657, name: "657", meta:" - CA"},
        {value: 658, name: "658", meta:" - JAMAICA"},
        {value: 660, name: "660", meta:" - MO"},
        {value: 661, name: "661", meta:" - CA"},
        {value: 662, name: "662", meta:" - MS"},
        {value: 664, name: "664", meta:" - MONTSERRAT"},
        {value: 667, name: "667", meta:" - MD"},
        {value: 669, name: "669", meta:" - CA"},
        {value: 670, name: "670", meta:" - CNMI"},
        {value: 671, name: "671", meta:" - GU"},
        {value: 672, name: "672", meta:" - BRITISH COLUMBIA"},
        {value: 678, name: "678", meta:" - GA"},
        {value: 680, name: "680", meta:" - NY"},
        {value: 681, name: "681", meta:" - WV"},
        {value: 682, name: "682", meta:" - TX"},
        {value: 684, name: "684", meta:" - AS"},
        {value: 689, name: "689", meta:" - FL"},
        {value: 701, name: "701", meta:" - ND"},
        {value: 702, name: "702", meta:" - NV"},
        {value: 703, name: "703", meta:" - VA"},
        {value: 704, name: "704", meta:" - NC"},
        {value: 705, name: "705", meta:" - ONTARIO"},
        {value: 706, name: "706", meta:" - GA"},
        {value: 707, name: "707", meta:" - CA"},
        {value: 708, name: "708", meta:" - IL"},
        {value: 709, name: "709", meta:" - NEWFOUNDLAND AND LABRADOR"},
        {value: 712, name: "712", meta:" - IA"},
        {value: 713, name: "713", meta:" - TX"},
        {value: 714, name: "714", meta:" - CA"},
        {value: 715, name: "715", meta:" - WI"},
        {value: 716, name: "716", meta:" - NY"},
        {value: 717, name: "717", meta:" - PA"},
        {value: 718, name: "718", meta:" - NY"},
        {value: 719, name: "719", meta:" - CO"},
        {value: 720, name: "720", meta:" - CO"},
        {value: 721, name: "721", meta:" - SINT MAARTEN"},
        {value: 724, name: "724", meta:" - PA"},
        {value: 725, name: "725", meta:" - NV"},
        {value: 726, name: "726", meta:" - TX"},
        {value: 727, name: "727", meta:" - FL"},
        {value: 731, name: "731", meta:" - TN"},
        {value: 732, name: "732", meta:" - NJ"},
        {value: 734, name: "734", meta:" - MI"},
        {value: 737, name: "737", meta:" - TX"},
        {value: 740, name: "740", meta:" - OH"},
        {value: 743, name: "743", meta:" - NC"},
        {value: 747, name: "747", meta:" - CA"},
        {value: 754, name: "754", meta:" - FL"},
        {value: 757, name: "757", meta:" - VA"},
        {value: 758, name: "758", meta:" - ST. LUCIA"},
        {value: 760, name: "760", meta:" - CA"},
        {value: 762, name: "762", meta:" - GA"},
        {value: 763, name: "763", meta:" - MN"},
        {value: 765, name: "765", meta:" - IN"},
        {value: 767, name: "767", meta:" - DOMINICA"},
        {value: 769, name: "769", meta:" - MS"},
        {value: 770, name: "770", meta:" - GA"},
        {value: 772, name: "772", meta:" - FL"},
        {value: 773, name: "773", meta:" - IL"},
        {value: 774, name: "774", meta:" - MA"},
        {value: 775, name: "775", meta:" - NV"},
        {value: 778, name: "778", meta:" - BRITISH COLUMBIA"},
        {value: 779, name: "779", meta:" - IL"},
        {value: 780, name: "780", meta:" - ALBERTA"},
        {value: 781, name: "781", meta:" - MA"},
        {value: 782, name: "782", meta:" - NOVA SCOTIA - PRINCE EDWARD ISLAND"},
        {value: 784, name: "784", meta:" - ST. VINCENT & GRENADINES"},
        {value: 785, name: "785", meta:" - KS"},
        {value: 786, name: "786", meta:" - FL"},
        {value: 787, name: "787", meta:" - PUERTO RICO"},
        {value: 801, name: "801", meta:" - UT"},
        {value: 802, name: "802", meta:" - VT"},
        {value: 803, name: "803", meta:" - SC"},
        {value: 804, name: "804", meta:" - VA"},
        {value: 805, name: "805", meta:" - CA"},
        {value: 806, name: "806", meta:" - TX"},
        {value: 807, name: "807", meta:" - ONTARIO"},
        {value: 808, name: "808", meta:" - HI"},
        {value: 809, name: "809", meta:" - DOMINICAN REPUBLIC"},
        {value: 810, name: "810", meta:" - MI"},
        {value: 812, name: "812", meta:" - IN"},
        {value: 813, name: "813", meta:" - FL"},
        {value: 814, name: "814", meta:" - PA"},
        {value: 815, name: "815", meta:" - IL"},
        {value: 816, name: "816", meta:" - MO"},
        {value: 817, name: "817", meta:" - TX"},
        {value: 818, name: "818", meta:" - CA"},
        {value: 819, name: "819", meta:" - QUEBEC"},
        {value: 820, name: "820", meta:" - CA"},
        {value: 825, name: "825", meta:" - ALBERTA"},
        {value: 828, name: "828", meta:" - NC"},
        {value: 829, name: "829", meta:" - DOMINICAN REPUBLIC"},
        {value: 830, name: "830", meta:" - TX"},
        {value: 831, name: "831", meta:" - CA"},
        {value: 832, name: "832", meta:" - TX"},
        {value: 838, name: "838", meta:" - NY"},
        {value: 843, name: "843", meta:" - SC"},
        {value: 845, name: "845", meta:" - NY"},
        {value: 847, name: "847", meta:" - IL"},
        {value: 848, name: "848", meta:" - NJ"},
        {value: 849, name: "849", meta:" - DOMINICAN REPUBLIC"},
        {value: 850, name: "850", meta:" - FL"},
        {value: 854, name: "854", meta:" - SC"},
        {value: 856, name: "856", meta:" - NJ"},
        {value: 857, name: "857", meta:" - MA"},
        {value: 858, name: "858", meta:" - CA"},
        {value: 859, name: "859", meta:" - KY"},
        {value: 860, name: "860", meta:" - CT"},
        {value: 862, name: "862", meta:" - NJ"},
        {value: 863, name: "863", meta:" - FL"},
        {value: 864, name: "864", meta:" - SC"},
        {value: 865, name: "865", meta:" - TN"},
        {value: 867, name: "867", meta:" - YUKON-NW TERR. - NUNAVUT"},
        {value: 868, name: "868", meta:" - TRINIDAD & TOBAGO"},
        {value: 869, name: "869", meta:" - ST. KITTS & NEVIS"},
        {value: 870, name: "870", meta:" - AR"},
        {value: 872, name: "872", meta:" - IL"},
        {value: 873, name: "873", meta:" - QUEBEC"},
        {value: 876, name: "876", meta:" - JAMAICA"},
        {value: 878, name: "878", meta:" - PA"},
        {value: 901, name: "901", meta:" - TN"},
        {value: 902, name: "902", meta:" - NOVA SCOTIA - PRINCE EDWARD ISLAND"},
        {value: 903, name: "903", meta:" - TX"},
        {value: 904, name: "904", meta:" - FL"},
        {value: 905, name: "905", meta:" - ONTARIO"},
        {value: 906, name: "906", meta:" - MI"},
        {value: 907, name: "907", meta:" - AK"},
        {value: 908, name: "908", meta:" - NJ"},
        {value: 909, name: "909", meta:" - CA"},
        {value: 910, name: "910", meta:" - NC"},
        {value: 912, name: "912", meta:" - GA"},
        {value: 913, name: "913", meta:" - KS"},
        {value: 914, name: "914", meta:" - NY"},
        {value: 915, name: "915", meta:" - TX"},
        {value: 916, name: "916", meta:" - CA"},
        {value: 917, name: "917", meta:" - NY"},
        {value: 918, name: "918", meta:" - OK"},
        {value: 919, name: "919", meta:" - NC"},
        {value: 920, name: "920", meta:" - WI"},
        {value: 925, name: "925", meta:" - CA"},
        {value: 928, name: "928", meta:" - AZ"},
        {value: 929, name: "929", meta:" - NY"},
        {value: 930, name: "930", meta:" - IN"},
        {value: 931, name: "931", meta:" - TN"},
        {value: 934, name: "934", meta:" - NY"},
        {value: 936, name: "936", meta:" - TX"},
        {value: 937, name: "937", meta:" - OH"},
        {value: 938, name: "938", meta:" - AL"},
        {value: 939, name: "939", meta:" - PUERTO RICO"},
        {value: 940, name: "940", meta:" - TX"},
        {value: 941, name: "941", meta:" - FL"},
        {value: 947, name: "947", meta:" - MI"},
        {value: 949, name: "949", meta:" - CA"},
        {value: 951, name: "951", meta:" - CA"},
        {value: 952, name: "952", meta:" - MN"},
        {value: 954, name: "954", meta:" - FL"},
        {value: 956, name: "956", meta:" - TX"},
        {value: 959, name: "959", meta:" - CT"},
        {value: 970, name: "970", meta:" - CO"},
        {value: 971, name: "971", meta:" - OR"},
        {value: 972, name: "972", meta:" - TX"},
        {value: 973, name: "973", meta:" - NJ"},
        {value: 978, name: "978", meta:" - MA"},
        {value: 979, name: "979", meta:" - TX"},
        {value: 980, name: "980", meta:" - NC"},
        {value: 984, name: "984", meta:" - NC"},
        {value: 985, name: "985", meta:" - LA"},
        {value: 986, name: "986", meta:" - ID"},
        {value: 989, name: "989", meta:" - MI"},
      ],
      helpText: "Select up to 5 area codes per search"
    },
    COUNTY: {
      name: 'Counties',
      displayHeader: true,
      inputType: "geography",
      items: [],
      helpText: "Select up to 5 counties per search",
    }, 
    CITY: {
      name: 'Cities',
      displayHeader: true,
      inputType: "geography",
      items: []
    }, 
    ZIP: {
      name: 'Zip codes',
      displayHeader: true,
      inputType: "geography",
      items: [],
    },
    ST: {
      name: "States",
      displayHeader: true,
      inputType: "geography",
      items: [
        { name: "Alabama",	fips: "01",	value: "AL" },
        { name: "Alaska",	fips: "02",	value: "AK" },
        { name: "Arizona",	fips: "04",	value: "AZ" },
        { name: "Arkansas",	fips: "05",	value: "AR" },
        { name: "California",	fips: "06",	value: "CA" },
        { name: "Colorado",	fips: "08",	value: "CO" },
        { name: "Connecticut",	fips: "09",	value: "CT" },
        { name: "Delaware",	fips: "10",	value: "DE" },
        { name: "District of Columbia",	fips: "11",	value: "DC" },
        { name: "Florida",	fips: "12",	value: "FL" },
        { name: "Georgia",	fips: "13",	value: "GA" },
        { name: "Hawaii",	fips: "15",	value: "HI" },
        { name: "Idaho",	fips: "16",	value: "ID" },
        { name: "Illinois",	fips: "17",	value: "IL" },
        { name: "Indiana",	fips: "18",	value: "IN" },
        { name: "Iowa",	fips: "19",	value: "IA" },
        { name: "Kansas",	fips: "20",	value: "KS" },
        { name: "Kentucky",	fips: "21",	value: "KY" },
        { name: "Louisiana",	fips: "22",	value: "LA" },
        { name: "Maine",	fips: "23",	value: "ME" },
        { name: "Maryland",	fips: "24",	value: "MD" },
        { name: "Massachusetts",	fips: "25",	value: "MA" },
        { name: "Michigan",	fips: "26",	value: "MI" },
        { name: "Minnesota",	fips: "27",	value: "MN" },
        { name: "Mississippi",	fips: "28",	value: "MS" },
        { name: "Missouri",	fips: "29",	value: "MO" },
        { name: "Montana",	fips: "30",	value: "MT" },
        { name: "Nebraska",	fips: "31",	value: "NE" },
        { name: "Nevada",	fips: "32",	value: "NV" },
        { name: "New Hampshire",	fips: "33",	value: "NH" },
        { name: "New Jersey",	fips: "34",	value: "NJ" },
        { name: "New Mexico",	fips: "35",	value: "NM" },
        { name: "New York",	fips: "36",	value: "NY" },
        { name: "North Carolina",	fips: "37",	value: "NC" },
        { name: "North Dakota",	fips: "38",	value: "ND" },
        { name: "Ohio",	fips: "39",	value: "OH" },
        { name: "Oklahoma",	fips: "40",	value: "OK" },
        { name: "Oregon",	fips: "41",	value: "OR" },
        { name: "Pennsylvania",	fips: "42",	value: "PA" },
        { name: "Rhode Island",	fips: "44",	value: "RI" },
        { name: "South Carolina",	fips: "45",	value: "SC" },
        { name: "South Dakota",	fips: "46",	value: "SD" },
        { name: "Tennessee",	fips: "47",	value: "TN" },
        { name: "Texas",	fips: "48",	value: "TX" },
        { name: "Utah",	fips: "49",	value: "UT" },
        { name: "Vermont",	fips: "50",	value: "VT" },
        { name: "Virginia",	fips: "51",	value: "VA" },
        { name: "Washington",	fips: "53",	value: "WA" },
        { name: "West Virginia",	fips: "54",	value: "WV" },
        { name: "Wisconsin",	fips: "55",	value: "WI" },
        { name: "Wyoming",	fips: "56",	value: "WY" }
      ]
    },
    USTER: {
      name: "US Territories",
      displayHeader: true,
      inputType: "geography",
      meta: {
        status: {
          1: "Under U.S sovereignty",
          2: "An aggregation of nine U.S. territories: Baker Island, Howland Island, Jarvis Island, Johnston Atoll, Kingman Reef, Midway Islands, Navassa Island, Palmyra Atoll, and Wake Island. Each territory is assigned an ANSI County Code in INCITS 31:2009, and may be individually identified through a combination of the ANSI State Code (74 or UM) and the appropriate ANSI County Code.",
          3: "Independent nation that operates under a Compact of Free Association with the United States of America."
        }
      },
      items: [
        { name: "American Samoa",	fips: "60",	value: "AS",	status: "1" },
        { name: "Federated States of Micronesia",	fips: "64",	value: "FM",	status: "3"},
        { name: "Guam",	fips: "66",	value: "GU",	status: "1"},
        { name: "Marshall Islands",	fips: "68",	value: "MH",	status: "3"},
        { name: "Commonwealth of the Northern Mariana Islands",	fips: "69",	value: "MP",	status: "1"},
        { name: "Palau",	fips: "70",	value: "PW",	status: "3"},
        { name: "Puerto Rico",	fips: "72",	value: "PR",	status: "1"},
        { name: "U.S. Minor Outlying Islands",	fips: "74",	value: "UM",	status: "2"},
        { name: "U.S. Virgin Islands",	fips: "78",	value: "VI",	status: "1"}
      ]
    },
    FIPS_ST: {
      name: "Federal Information Processing System (FIPS) Codes for States",
      displayHeader: true,
      inputType: "geography",
      meta: {
        description: "FIPS codes are numbers which uniquely identify geographic areas.  The number of  digits in FIPS codes vary depending on the level of geography.  State-level FIPS codes have two digits, county-level FIPS codes have five digits of which the  first two are the FIPS code of the state to which the county belongs.  When  using the list below to look up county FIPS codes, it is advisable to first look up the FIPS code for the state to which the county belongs.  This will help you identify the right section of the list while scrolling down, which can be important since there are over 3000 counties and county-equivalents (e.g. independent cities, parishes, boroughs) in the United States.",
        source: "https://transition.fcc.gov/oet/info/maps/census/fips/fips.txt"
      },
      items: [
        { value: "01", name: "ALABAMA" },
        { value: "02", name: "ALASKA" },
        { value: "04", name: "ARIZONA" },
        { value: "05", name: "ARKANSAS" },
        { value: "06", name: "CALIFORNIA" },
        { value: "08", name: "COLORADO" },
        { value: "09", name: "CONNECTICUT" },
        { value: "10", name: "DELAWARE" },
        { value: "11", name: "DISTRICT OF COLUMBIA" },
        { value: "12", name: "FLORIDA" },
        { value: "13", name: "GEORGIA" },
        { value: "15", name: "HAWAII" },
        { value: "16", name: "IDAHO" },
        { value: "17", name: "ILLINOIS" },
        { value: "18", name: "INDIANA" },
        { value: "19", name: "IOWA" },
        { value: "20", name: "KANSAS" },
        { value: "21", name: "KENTUCKY" },
        { value: "22", name: "LOUISIANA" },
        { value: "23", name: "MAINE" },
        { value: "24", name: "MARYLAND" },
        { value: "25", name: "MASSACHUSETTS" },
        { value: "26", name: "MICHIGAN" },
        { value: "27", name: "MINNESOTA" },
        { value: "28", name: "MISSISSIPPI" },
        { value: "29", name: "MISSOURI" },
        { value: "30", name: "MONTANA" },
        { value: "31", name: "NEBRASKA" },
        { value: "32", name: "NEVADA" },
        { value: "33", name: "NEW HAMPSHIRE" },
        { value: "34", name: "NEW JERSEY" },
        { value: "35", name: "NEW MEXICO" },
        { value: "36", name: "NEW YORK" },
        { value: "37", name: "NORTH CAROLINA" },
        { value: "38", name: "NORTH DAKOTA" },
        { value: "39", name: "OHIO" },
        { value: "40", name: "OKLAHOMA" },
        { value: "41", name: "OREGON" },
        { value: "42", name: "PENNSYLVANIA" },
        { value: "44", name: "RHODE ISLAND" },
        { value: "45", name: "SOUTH CAROLINA" },
        { value: "46", name: "SOUTH DAKOTA" },
        { value: "47", name: "TENNESSEE" },
        { value: "48", name: "TEXAS" },
        { value: "49", name: "UTAH" },
        { value: "50", name: "VERMONT" },
        { value: "51", name: "VIRGINIA" },
        { value: "53", name: "WASHINGTON" },
        { value: "54", name: "WEST VIRGINIA" },
        { value: "55", name: "WISCONSIN" },
        { value: "56", name: "WYOMING" }
      ]
    },
    FIPS: {
      name: "Federal Information Processing System (FIPS) Codes for Counties",
      displayHeader: true,
      inputType: "geography",
      meta: {
        description: "FIPS codes are numbers which uniquely identify geographic areas.  The number of  digits in FIPS codes vary depending on the level of geography.  State-level FIPS codes have two digits, county-level FIPS codes have five digits of which the  first two are the FIPS code of the state to which the county belongs.  When  using the list below to look up county FIPS codes, it is advisable to first look up the FIPS code for the state to which the county belongs.  This will help you identify the right section of the list while scrolling down, which can be important since there are over 3000 counties and county-equivalents (e.g. independent cities, parishes, boroughs) in the United States.",
        source: "https://transition.fcc.gov/oet/info/maps/census/fips/fips.txt"
      },
      items: [
        { value: "01000", name: "Alabama" },
        { value: "01001", name: "Autauga County" },
        { value: "01003", name: "Baldwin County" },
        { value: "01005", name: "Barbour County" },
        { value: "01007", name: "Bibb County" },
        { value: "01009", name: "Blount County" },
        { value: "01011", name: "Bullock County" },
        { value: "01013", name: "Butler County" },
        { value: "01015", name: "Calhoun County" },
        { value: "01017", name: "Chambers County" },
        { value: "01019", name: "Cherokee County" },
        { value: "01021", name: "Chilton County" },
        { value: "01023", name: "Choctaw County" },
        { value: "01025", name: "Clarke County" },
        { value: "01027", name: "Clay County" },
        { value: "01029", name: "Cleburne County" },
        { value: "01031", name: "Coffee County" },
        { value: "01033", name: "Colbert County" },
        { value: "01035", name: "Conecuh County" },
        { value: "01037", name: "Coosa County" },
        { value: "01039", name: "Covington County" },
        { value: "01041", name: "Crenshaw County" },
        { value: "01043", name: "Cullman County" },
        { value: "01045", name: "Dale County" },
        { value: "01047", name: "Dallas County" },
        { value: "01049", name: "DeKalb County" },
        { value: "01051", name: "Elmore County" },
        { value: "01053", name: "Escambia County" },
        { value: "01055", name: "Etowah County" },
        { value: "01057", name: "Fayette County" },
        { value: "01059", name: "Franklin County" },
        { value: "01061", name: "Geneva County" },
        { value: "01063", name: "Greene County" },
        { value: "01065", name: "Hale County" },
        { value: "01067", name: "Henry County" },
        { value: "01069", name: "Houston County" },
        { value: "01071", name: "Jackson County" },
        { value: "01073", name: "Jefferson County" },
        { value: "01075", name: "Lamar County" },
        { value: "01077", name: "Lauderdale County" },
        { value: "01079", name: "Lawrence County" },
        { value: "01081", name: "Lee County" },
        { value: "01083", name: "Limestone County" },
        { value: "01085", name: "Lowndes County" },
        { value: "01087", name: "Macon County" },
        { value: "01089", name: "Madison County" },
        { value: "01091", name: "Marengo County" },
        { value: "01093", name: "Marion County" },
        { value: "01095", name: "Marshall County" },
        { value: "01097", name: "Mobile County" },
        { value: "01099", name: "Monroe County" },
        { value: "01101", name: "Montgomery County" },
        { value: "01103", name: "Morgan County" },
        { value: "01105", name: "Perry County" },
        { value: "01107", name: "Pickens County" },
        { value: "01109", name: "Pike County" },
        { value: "01111", name: "Randolph County" },
        { value: "01113", name: "Russell County" },
        { value: "01115", name: "St. Clair County" },
        { value: "01117", name: "Shelby County" },
        { value: "01119", name: "Sumter County" },
        { value: "01121", name: "Talladega County" },
        { value: "01123", name: "Tallapoosa County" },
        { value: "01125", name: "Tuscaloosa County" },
        { value: "01127", name: "Walker County" },
        { value: "01129", name: "Washington County" },
        { value: "01131", name: "Wilcox County" },
        { value: "01133", name: "Winston County" },
        { value: "02000", name: "Alaska" },
        { value: "02013", name: "Aleutians East Borough" },
        { value: "02016", name: "Aleutians West Census Area" },
        { value: "02020", name: "Anchorage Borough" },
        { value: "02050", name: "Bethel Census Area" },
        { value: "02060", name: "Bristol Bay Borough" },
        { value: "02068", name: "Denali Borough (created after 1990)" },
        { value: "02070", name: "Dillingham Census Area" },
        { value: "02090", name: "Fairbanks North Star Borough" },
        { value: "02100", name: "Haines Borough" },
        { value: "02110", name: "Juneau Borough" },
        { value: "02122", name: "Kenai Peninsula Borough" },
        { value: "02130", name: "Ketchikan Gateway Borough" },
        { value: "02150", name: "Kodiak Island Borough" },
        { value: "02164", name: "Lake and Peninsula Borough" },
        { value: "02170", name: "Matanuska-Susitna Borough" },
        { value: "02180", name: "Nome Census Area" },
        { value: "02185", name: "North Slope Borough" },
        { value: "02188", name: "Northwest Arctic Borough" },
        { value: "02201", name: "Prince of Wales-Outer Ketchikan Census Area" },
        { value: "02220", name: "Sitka Borough" },
        { value: "02231", name: "Skagway-Yakutat-Angoon Census Area (1990 Census Area)" },
        { value: "02232", name: "Skagway-Hoonah-Angoon Census Area (created after 1990)" },
        { value: "02240", name: "Southeast Fairbanks Census Area" },
        { value: "02261", name: "Valdez-Cordova Census Area" },
        { value: "02270", name: "Wade Hampton Census Area" },
        { value: "02280", name: "Wrangell-Petersburg Census Area" },
        { value: "02282", name: "Yakutat Borough (created after 1990)" },
        { value: "02290", name: "Yukon-Koyukuk Census Area" },
        { value: "04000", name: "Arizona" },
        { value: "04001", name: "Apache County" },
        { value: "04003", name: "Cochise County" },
        { value: "04005", name: "Coconino County" },
        { value: "04007", name: "Gila County" },
        { value: "04009", name: "Graham County" },
        { value: "04011", name: "Greenlee County" },
        { value: "04012", name: "La Paz County" },
        { value: "04013", name: "Maricopa County" },
        { value: "04015", name: "Mohave County" },
        { value: "04017", name: "Navajo County" },
        { value: "04019", name: "Pima County" },
        { value: "04021", name: "Pinal County" },
        { value: "04023", name: "Santa Cruz County" },
        { value: "04025", name: "Yavapai County" },
        { value: "04027", name: "Yuma County" },
        { value: "05000", name: "Arkansas" },
        { value: "05001", name: "Arkansas County" },
        { value: "05003", name: "Ashley County" },
        { value: "05005", name: "Baxter County" },
        { value: "05007", name: "Benton County" },
        { value: "05009", name: "Boone County" },
        { value: "05011", name: "Bradley County" },
        { value: "05013", name: "Calhoun County" },
        { value: "05015", name: "Carroll County" },
        { value: "05017", name: "Chicot County" },
        { value: "05019", name: "Clark County" },
        { value: "05021", name: "Clay County" },
        { value: "05023", name: "Cleburne County" },
        { value: "05025", name: "Cleveland County" },
        { value: "05027", name: "Columbia County" },
        { value: "05029", name: "Conway County" },
        { value: "05031", name: "Craighead County" },
        { value: "05033", name: "Crawford County" },
        { value: "05035", name: "Crittenden County" },
        { value: "05037", name: "Cross County" },
        { value: "05039", name: "Dallas County" },
        { value: "05041", name: "Desha County" },
        { value: "05043", name: "Drew County" },
        { value: "05045", name: "Faulkner County" },
        { value: "05047", name: "Franklin County" },
        { value: "05049", name: "Fulton County" },
        { value: "05051", name: "Garland County" },
        { value: "05053", name: "Grant County" },
        { value: "05055", name: "Greene County" },
        { value: "05057", name: "Hempstead County" },
        { value: "05059", name: "Hot Spring County" },
        { value: "05061", name: "Howard County" },
        { value: "05063", name: "Independence County" },
        { value: "05065", name: "Izard County" },
        { value: "05067", name: "Jackson County" },
        { value: "05069", name: "Jefferson County" },
        { value: "05071", name: "Johnson County" },
        { value: "05073", name: "Lafayette County" },
        { value: "05075", name: "Lawrence County" },
        { value: "05077", name: "Lee County" },
        { value: "05079", name: "Lincoln County" },
        { value: "05081", name: "Little River County" },
        { value: "05083", name: "Logan County" },
        { value: "05085", name: "Lonoke County" },
        { value: "05087", name: "Madison County" },
        { value: "05089", name: "Marion County" },
        { value: "05091", name: "Miller County" },
        { value: "05093", name: "Mississippi County" },
        { value: "05095", name: "Monroe County" },
        { value: "05097", name: "Montgomery County" },
        { value: "05099", name: "Nevada County" },
        { value: "05101", name: "Newton County" },
        { value: "05103", name: "Ouachita County" },
        { value: "05105", name: "Perry County" },
        { value: "05107", name: "Phillips County" },
        { value: "05109", name: "Pike County" },
        { value: "05111", name: "Poinsett County" },
        { value: "05113", name: "Polk County" },
        { value: "05115", name: "Pope County" },
        { value: "05117", name: "Prairie County" },
        { value: "05119", name: "Pulaski County" },
        { value: "05121", name: "Randolph County" },
        { value: "05123", name: "St. Francis County" },
        { value: "05125", name: "Saline County" },
        { value: "05127", name: "Scott County" },
        { value: "05129", name: "Searcy County" },
        { value: "05131", name: "Sebastian County" },
        { value: "05133", name: "Sevier County" },
        { value: "05135", name: "Sharp County" },
        { value: "05137", name: "Stone County" },
        { value: "05139", name: "Union County" },
        { value: "05141", name: "Van Buren County" },
        { value: "05143", name: "Washington County" },
        { value: "05145", name: "White County" },
        { value: "05147", name: "Woodruff County" },
        { value: "05149", name: "Yell County" },
        { value: "06000", name: "California" },
        { value: "06001", name: "Alameda County" },
        { value: "06003", name: "Alpine County" },
        { value: "06005", name: "Amador County" },
        { value: "06007", name: "Butte County" },
        { value: "06009", name: "Calaveras County" },
        { value: "06011", name: "Colusa County" },
        { value: "06013", name: "Contra Costa County" },
        { value: "06015", name: "Del Norte County" },
        { value: "06017", name: "El Dorado County" },
        { value: "06019", name: "Fresno County" },
        { value: "06021", name: "Glenn County" },
        { value: "06023", name: "Humboldt County" },
        { value: "06025", name: "Imperial County" },
        { value: "06027", name: "Inyo County" },
        { value: "06029", name: "Kern County" },
        { value: "06031", name: "Kings County" },
        { value: "06033", name: "Lake County" },
        { value: "06035", name: "Lassen County" },
        { value: "06037", name: "Los Angeles County" },
        { value: "06039", name: "Madera County" },
        { value: "06041", name: "Marin County" },
        { value: "06043", name: "Mariposa County" },
        { value: "06045", name: "Mendocino County" },
        { value: "06047", name: "Merced County" },
        { value: "06049", name: "Modoc County" },
        { value: "06051", name: "Mono County" },
        { value: "06053", name: "Monterey County" },
        { value: "06055", name: "Napa County" },
        { value: "06057", name: "Nevada County" },
        { value: "06059", name: "Orange County" },
        { value: "06061", name: "Placer County" },
        { value: "06063", name: "Plumas County" },
        { value: "06065", name: "Riverside County" },
        { value: "06067", name: "Sacramento County" },
        { value: "06069", name: "San Benito County" },
        { value: "06071", name: "San Bernardino County" },
        { value: "06073", name: "San Diego County" },
        { value: "06075", name: "San Francisco County" },
        { value: "06077", name: "San Joaquin County" },
        { value: "06079", name: "San Luis Obispo County" },
        { value: "06081", name: "San Mateo County" },
        { value: "06083", name: "Santa Barbara County" },
        { value: "06085", name: "Santa Clara County" },
        { value: "06087", name: "Santa Cruz County" },
        { value: "06089", name: "Shasta County" },
        { value: "06091", name: "Sierra County" },
        { value: "06093", name: "Siskiyou County" },
        { value: "06095", name: "Solano County" },
        { value: "06097", name: "Sonoma County" },
        { value: "06099", name: "Stanislaus County" },
        { value: "06101", name: "Sutter County" },
        { value: "06103", name: "Tehama County" },
        { value: "06105", name: "Trinity County" },
        { value: "06107", name: "Tulare County" },
        { value: "06109", name: "Tuolumne County" },
        { value: "06111", name: "Ventura County" },
        { value: "06113", name: "Yolo County" },
        { value: "06115", name: "Yuba County" },
        { value: "08000", name: "Colorado" },
        { value: "08001", name: "Adams County" },
        { value: "08003", name: "Alamosa County" },
        { value: "08005", name: "Arapahoe County" },
        { value: "08007", name: "Archuleta County" },
        { value: "08009", name: "Baca County" },
        { value: "08011", name: "Bent County" },
        { value: "08013", name: "Boulder County" },
        { value: "08015", name: "Chaffee County" },
        { value: "08017", name: "Cheyenne County" },
        { value: "08019", name: "Clear Creek County" },
        { value: "08021", name: "Conejos County" },
        { value: "08023", name: "Costilla County" },
        { value: "08025", name: "Crowley County" },
        { value: "08027", name: "Custer County" },
        { value: "08029", name: "Delta County" },
        { value: "08031", name: "Denver County" },
        { value: "08033", name: "Dolores County" },
        { value: "08035", name: "Douglas County" },
        { value: "08037", name: "Eagle County" },
        { value: "08039", name: "Elbert County" },
        { value: "08041", name: "El Paso County" },
        { value: "08043", name: "Fremont County" },
        { value: "08045", name: "Garfield County" },
        { value: "08047", name: "Gilpin County" },
        { value: "08049", name: "Grand County" },
        { value: "08051", name: "Gunnison County" },
        { value: "08053", name: "Hinsdale County" },
        { value: "08055", name: "Huerfano County" },
        { value: "08057", name: "Jackson County" },
        { value: "08059", name: "Jefferson County" },
        { value: "08061", name: "Kiowa County" },
        { value: "08063", name: "Kit Carson County" },
        { value: "08065", name: "Lake County" },
        { value: "08067", name: "La Plata County" },
        { value: "08069", name: "Larimer County" },
        { value: "08071", name: "Las Animas County" },
        { value: "08073", name: "Lincoln County" },
        { value: "08075", name: "Logan County" },
        { value: "08077", name: "Mesa County" },
        { value: "08079", name: "Mineral County" },
        { value: "08081", name: "Moffat County" },
        { value: "08083", name: "Montezuma County" },
        { value: "08085", name: "Montrose County" },
        { value: "08087", name: "Morgan County" },
        { value: "08089", name: "Otero County" },
        { value: "08091", name: "Ouray County" },
        { value: "08093", name: "Park County" },
        { value: "08095", name: "Phillips County" },
        { value: "08097", name: "Pitkin County" },
        { value: "08099", name: "Prowers County" },
        { value: "08101", name: "Pueblo County" },
        { value: "08103", name: "Rio Blanco County" },
        { value: "08105", name: "Rio Grande County" },
        { value: "08107", name: "Routt County" },
        { value: "08109", name: "Saguache County" },
        { value: "08111", name: "San Juan County" },
        { value: "08113", name: "San Miguel County" },
        { value: "08115", name: "Sedgwick County" },
        { value: "08117", name: "Summit County" },
        { value: "08119", name: "Teller County" },
        { value: "08121", name: "Washington County" },
        { value: "08123", name: "Weld County" },
        { value: "08125", name: "Yuma County" },
        { value: "09000", name: "Connecticut" },
        { value: "09001", name: "Fairfield County" },
        { value: "09003", name: "Hartford County" },
        { value: "09005", name: "Litchfield County" },
        { value: "09007", name: "Middlesex County" },
        { value: "09009", name: "New Haven County" },
        { value: "09011", name: "New London County" },
        { value: "09013", name: "Tolland County" },
        { value: "09015", name: "Windham County" },
        { value: "10000", name: "Delaware" },
        { value: "10001", name: "Kent County" },
        { value: "10003", name: "New Castle County" },
        { value: "10005", name: "Sussex County" },
        { value: "11000", name: "District of Columbia" },
        { value: "11001", name: "District of Columbia" },
        { value: "12000", name: "Florida" },
        { value: "12001", name: "Alachua County" },
        { value: "12003", name: "Baker County" },
        { value: "12005", name: "Bay County" },
        { value: "12007", name: "Bradford County" },
        { value: "12009", name: "Brevard County" },
        { value: "12011", name: "Broward County" },
        { value: "12013", name: "Calhoun County" },
        { value: "12015", name: "Charlotte County" },
        { value: "12017", name: "Citrus County" },
        { value: "12019", name: "Clay County" },
        { value: "12021", name: "Collier County" },
        { value: "12023", name: "Columbia County" },
        { value: "12025", name: "Dade County" },
        { value: "12027", name: "DeSoto County" },
        { value: "12029", name: "Dixie County" },
        { value: "12031", name: "Duval County" },
        { value: "12033", name: "Escambia County" },
        { value: "12035", name: "Flagler County" },
        { value: "12037", name: "Franklin County" },
        { value: "12039", name: "Gadsden County" },
        { value: "12041", name: "Gilchrist County" },
        { value: "12043", name: "Glades County" },
        { value: "12045", name: "Gulf County" },
        { value: "12047", name: "Hamilton County" },
        { value: "12049", name: "Hardee County" },
        { value: "12051", name: "Hendry County" },
        { value: "12053", name: "Hernando County" },
        { value: "12055", name: "Highlands County" },
        { value: "12057", name: "Hillsborough County" },
        { value: "12059", name: "Holmes County" },
        { value: "12061", name: "Indian River County" },
        { value: "12063", name: "Jackson County" },
        { value: "12065", name: "Jefferson County" },
        { value: "12067", name: "Lafayette County" },
        { value: "12069", name: "Lake County" },
        { value: "12071", name: "Lee County" },
        { value: "12073", name: "Leon County" },
        { value: "12075", name: "Levy County" },
        { value: "12077", name: "Liberty County" },
        { value: "12079", name: "Madison County" },
        { value: "12081", name: "Manatee County" },
        { value: "12083", name: "Marion County" },
        { value: "12085", name: "Martin County" },
        { value: "12087", name: "Monroe County" },
        { value: "12089", name: "Nassau County" },
        { value: "12091", name: "Okaloosa County" },
        { value: "12093", name: "Okeechobee County" },
        { value: "12095", name: "Orange County" },
        { value: "12097", name: "Osceola County" },
        { value: "12099", name: "Palm Beach County" },
        { value: "12101", name: "Pasco County" },
        { value: "12103", name: "Pinellas County" },
        { value: "12105", name: "Polk County" },
        { value: "12107", name: "Putnam County" },
        { value: "12109", name: "St. Johns County" },
        { value: "12111", name: "St. Lucie County" },
        { value: "12113", name: "Santa Rosa County" },
        { value: "12115", name: "Sarasota County" },
        { value: "12117", name: "Seminole County" },
        { value: "12119", name: "Sumter County" },
        { value: "12121", name: "Suwannee County" },
        { value: "12123", name: "Taylor County" },
        { value: "12125", name: "Union County" },
        { value: "12127", name: "Volusia County" },
        { value: "12129", name: "Wakulla County" },
        { value: "12131", name: "Walton County" },
        { value: "12133", name: "Washington County" },
        { value: "13000", name: "Georgia" },
        { value: "13001", name: "Appling County" },
        { value: "13003", name: "Atkinson County" },
        { value: "13005", name: "Bacon County" },
        { value: "13007", name: "Baker County" },
        { value: "13009", name: "Baldwin County" },
        { value: "13011", name: "Banks County" },
        { value: "13013", name: "Barrow County" },
        { value: "13015", name: "Bartow County" },
        { value: "13017", name: "Ben Hill County" },
        { value: "13019", name: "Berrien County" },
        { value: "13021", name: "Bibb County" },
        { value: "13023", name: "Bleckley County" },
        { value: "13025", name: "Brantley County" },
        { value: "13027", name: "Brooks County" },
        { value: "13029", name: "Bryan County" },
        { value: "13031", name: "Bulloch County" },
        { value: "13033", name: "Burke County" },
        { value: "13035", name: "Butts County" },
        { value: "13037", name: "Calhoun County" },
        { value: "13039", name: "Camden County" },
        { value: "13043", name: "Candler County" },
        { value: "13045", name: "Carroll County" },
        { value: "13047", name: "Catoosa County" },
        { value: "13049", name: "Charlton County" },
        { value: "13051", name: "Chatham County" },
        { value: "13053", name: "Chattahoochee County" },
        { value: "13055", name: "Chattooga County" },
        { value: "13057", name: "Cherokee County" },
        { value: "13059", name: "Clarke County" },
        { value: "13061", name: "Clay County" },
        { value: "13063", name: "Clayton County" },
        { value: "13065", name: "Clinch County" },
        { value: "13067", name: "Cobb County" },
        { value: "13069", name: "Coffee County" },
        { value: "13071", name: "Colquitt County" },
        { value: "13073", name: "Columbia County" },
        { value: "13075", name: "Cook County" },
        { value: "13077", name: "Coweta County" },
        { value: "13079", name: "Crawford County" },
        { value: "13081", name: "Crisp County" },
        { value: "13083", name: "Dade County" },
        { value: "13085", name: "Dawson County" },
        { value: "13087", name: "Decatur County" },
        { value: "13089", name: "DeKalb County" },
        { value: "13091", name: "Dodge County" },
        { value: "13093", name: "Dooly County" },
        { value: "13095", name: "Dougherty County" },
        { value: "13097", name: "Douglas County" },
        { value: "13099", name: "Early County" },
        { value: "13101", name: "Echols County" },
        { value: "13103", name: "Effingham County" },
        { value: "13105", name: "Elbert County" },
        { value: "13107", name: "Emanuel County" },
        { value: "13109", name: "Evans County" },
        { value: "13111", name: "Fannin County" },
        { value: "13113", name: "Fayette County" },
        { value: "13115", name: "Floyd County" },
        { value: "13117", name: "Forsyth County" },
        { value: "13119", name: "Franklin County" },
        { value: "13121", name: "Fulton County" },
        { value: "13123", name: "Gilmer County" },
        { value: "13125", name: "Glascock County" },
        { value: "13127", name: "Glynn County" },
        { value: "13129", name: "Gordon County" },
        { value: "13131", name: "Grady County" },
        { value: "13133", name: "Greene County" },
        { value: "13135", name: "Gwinnett County" },
        { value: "13137", name: "Habersham County" },
        { value: "13139", name: "Hall County" },
        { value: "13141", name: "Hancock County" },
        { value: "13143", name: "Haralson County" },
        { value: "13145", name: "Harris County" },
        { value: "13147", name: "Hart County" },
        { value: "13149", name: "Heard County" },
        { value: "13151", name: "Henry County" },
        { value: "13153", name: "Houston County" },
        { value: "13155", name: "Irwin County" },
        { value: "13157", name: "Jackson County" },
        { value: "13159", name: "Jasper County" },
        { value: "13161", name: "Jeff Davis County" },
        { value: "13163", name: "Jefferson County" },
        { value: "13165", name: "Jenkins County" },
        { value: "13167", name: "Johnson County" },
        { value: "13169", name: "Jones County" },
        { value: "13171", name: "Lamar County" },
        { value: "13173", name: "Lanier County" },
        { value: "13175", name: "Laurens County" },
        { value: "13177", name: "Lee County" },
        { value: "13179", name: "Liberty County" },
        { value: "13181", name: "Lincoln County" },
        { value: "13183", name: "Long County" },
        { value: "13185", name: "Lowndes County" },
        { value: "13187", name: "Lumpkin County" },
        { value: "13189", name: "McDuffie County" },
        { value: "13191", name: "McIntosh County" },
        { value: "13193", name: "Macon County" },
        { value: "13195", name: "Madison County" },
        { value: "13197", name: "Marion County" },
        { value: "13199", name: "Meriwether County" },
        { value: "13201", name: "Miller County" },
        { value: "13205", name: "Mitchell County" },
        { value: "13207", name: "Monroe County" },
        { value: "13209", name: "Montgomery County" },
        { value: "13211", name: "Morgan County" },
        { value: "13213", name: "Murray County" },
        { value: "13215", name: "Muscogee County" },
        { value: "13217", name: "Newton County" },
        { value: "13219", name: "Oconee County" },
        { value: "13221", name: "Oglethorpe County" },
        { value: "13223", name: "Paulding County" },
        { value: "13225", name: "Peach County" },
        { value: "13227", name: "Pickens County" },
        { value: "13229", name: "Pierce County" },
        { value: "13231", name: "Pike County" },
        { value: "13233", name: "Polk County" },
        { value: "13235", name: "Pulaski County" },
        { value: "13237", name: "Putnam County" },
        { value: "13239", name: "Quitman County" },
        { value: "13241", name: "Rabun County" },
        { value: "13243", name: "Randolph County" },
        { value: "13245", name: "Richmond County" },
        { value: "13247", name: "Rockdale County" },
        { value: "13249", name: "Schley County" },
        { value: "13251", name: "Screven County" },
        { value: "13253", name: "Seminole County" },
        { value: "13255", name: "Spalding County" },
        { value: "13257", name: "Stephens County" },
        { value: "13259", name: "Stewart County" },
        { value: "13261", name: "Sumter County" },
        { value: "13263", name: "Talbot County" },
        { value: "13265", name: "Taliaferro County" },
        { value: "13267", name: "Tattnall County" },
        { value: "13269", name: "Taylor County" },
        { value: "13271", name: "Telfair County" },
        { value: "13273", name: "Terrell County" },
        { value: "13275", name: "Thomas County" },
        { value: "13277", name: "Tift County" },
        { value: "13279", name: "Toombs County" },
        { value: "13281", name: "Towns County" },
        { value: "13283", name: "Treutlen County" },
        { value: "13285", name: "Troup County" },
        { value: "13287", name: "Turner County" },
        { value: "13289", name: "Twiggs County" },
        { value: "13291", name: "Union County" },
        { value: "13293", name: "Upson County" },
        { value: "13295", name: "Walker County" },
        { value: "13297", name: "Walton County" },
        { value: "13299", name: "Ware County" },
        { value: "13301", name: "Warren County" },
        { value: "13303", name: "Washington County" },
        { value: "13305", name: "Wayne County" },
        { value: "13307", name: "Webster County" },
        { value: "13309", name: "Wheeler County" },
        { value: "13311", name: "White County" },
        { value: "13313", name: "Whitfield County" },
        { value: "13315", name: "Wilcox County" },
        { value: "13317", name: "Wilkes County" },
        { value: "13319", name: "Wilkinson County" },
        { value: "13321", name: "Worth County" },
        { value: "15000", name: "Hawaii" },
        { value: "15001", name: "Hawaii County" },
        { value: "15003", name: "Honolulu County" },
        { value: "15005", name: "Kalawao County" },
        { value: "15007", name: "Kauai County" },
        { value: "15009", name: "Maui County" },
        { value: "16000", name: "Idaho" },
        { value: "16001", name: "Ada County" },
        { value: "16003", name: "Adams County" },
        { value: "16005", name: "Bannock County" },
        { value: "16007", name: "Bear Lake County" },
        { value: "16009", name: "Benewah County" },
        { value: "16011", name: "Bingham County" },
        { value: "16013", name: "Blaine County" },
        { value: "16015", name: "Boise County" },
        { value: "16017", name: "Bonner County" },
        { value: "16019", name: "Bonneville County" },
        { value: "16021", name: "Boundary County" },
        { value: "16023", name: "Butte County" },
        { value: "16025", name: "Camas County" },
        { value: "16027", name: "Canyon County" },
        { value: "16029", name: "Caribou County" },
        { value: "16031", name: "Cassia County" },
        { value: "16033", name: "Clark County" },
        { value: "16035", name: "Clearwater County" },
        { value: "16037", name: "Custer County" },
        { value: "16039", name: "Elmore County" },
        { value: "16041", name: "Franklin County" },
        { value: "16043", name: "Fremont County" },
        { value: "16045", name: "Gem County" },
        { value: "16047", name: "Gooding County" },
        { value: "16049", name: "Idaho County" },
        { value: "16051", name: "Jefferson County" },
        { value: "16053", name: "Jerome County" },
        { value: "16055", name: "Kootenai County" },
        { value: "16057", name: "Latah County" },
        { value: "16059", name: "Lemhi County" },
        { value: "16061", name: "Lewis County" },
        { value: "16063", name: "Lincoln County" },
        { value: "16065", name: "Madison County" },
        { value: "16067", name: "Minidoka County" },
        { value: "16069", name: "Nez Perce County" },
        { value: "16071", name: "Oneida County" },
        { value: "16073", name: "Owyhee County" },
        { value: "16075", name: "Payette County" },
        { value: "16077", name: "Power County" },
        { value: "16079", name: "Shoshone County" },
        { value: "16081", name: "Teton County" },
        { value: "16083", name: "Twin Falls County" },
        { value: "16085", name: "Valley County" },
        { value: "16087", name: "Washington County" },
        { value: "17000", name: "Illinois" },
        { value: "17001", name: "Adams County" },
        { value: "17003", name: "Alexander County" },
        { value: "17005", name: "Bond County" },
        { value: "17007", name: "Boone County" },
        { value: "17009", name: "Brown County" },
        { value: "17011", name: "Bureau County" },
        { value: "17013", name: "Calhoun County" },
        { value: "17015", name: "Carroll County" },
        { value: "17017", name: "Cass County" },
        { value: "17019", name: "Champaign County" },
        { value: "17021", name: "Christian County" },
        { value: "17023", name: "Clark County" },
        { value: "17025", name: "Clay County" },
        { value: "17027", name: "Clinton County" },
        { value: "17029", name: "Coles County" },
        { value: "17031", name: "Cook County" },
        { value: "17033", name: "Crawford County" },
        { value: "17035", name: "Cumberland County" },
        { value: "17037", name: "DeKalb County" },
        { value: "17039", name: "De Witt County" },
        { value: "17041", name: "Douglas County" },
        { value: "17043", name: "DuPage County" },
        { value: "17045", name: "Edgar County" },
        { value: "17047", name: "Edwards County" },
        { value: "17049", name: "Effingham County" },
        { value: "17051", name: "Fayette County" },
        { value: "17053", name: "Ford County" },
        { value: "17055", name: "Franklin County" },
        { value: "17057", name: "Fulton County" },
        { value: "17059", name: "Gallatin County" },
        { value: "17061", name: "Greene County" },
        { value: "17063", name: "Grundy County" },
        { value: "17065", name: "Hamilton County" },
        { value: "17067", name: "Hancock County" },
        { value: "17069", name: "Hardin County" },
        { value: "17071", name: "Henderson County" },
        { value: "17073", name: "Henry County" },
        { value: "17075", name: "Iroquois County" },
        { value: "17077", name: "Jackson County" },
        { value: "17079", name: "Jasper County" },
        { value: "17081", name: "Jefferson County" },
        { value: "17083", name: "Jersey County" },
        { value: "17085", name: "Jo Daviess County" },
        { value: "17087", name: "Johnson County" },
        { value: "17089", name: "Kane County" },
        { value: "17091", name: "Kankakee County" },
        { value: "17093", name: "Kendall County" },
        { value: "17095", name: "Knox County" },
        { value: "17097", name: "Lake County" },
        { value: "17099", name: "La Salle County" },
        { value: "17101", name: "Lawrence County" },
        { value: "17103", name: "Lee County" },
        { value: "17105", name: "Livingston County" },
        { value: "17107", name: "Logan County" },
        { value: "17109", name: "McDonough County" },
        { value: "17111", name: "McHenry County" },
        { value: "17113", name: "McLean County" },
        { value: "17115", name: "Macon County" },
        { value: "17117", name: "Macoupin County" },
        { value: "17119", name: "Madison County" },
        { value: "17121", name: "Marion County" },
        { value: "17123", name: "Marshall County" },
        { value: "17125", name: "Mason County" },
        { value: "17127", name: "Massac County" },
        { value: "17129", name: "Menard County" },
        { value: "17131", name: "Mercer County" },
        { value: "17133", name: "Monroe County" },
        { value: "17135", name: "Montgomery County" },
        { value: "17137", name: "Morgan County" },
        { value: "17139", name: "Moultrie County" },
        { value: "17141", name: "Ogle County" },
        { value: "17143", name: "Peoria County" },
        { value: "17145", name: "Perry County" },
        { value: "17147", name: "Piatt County" },
        { value: "17149", name: "Pike County" },
        { value: "17151", name: "Pope County" },
        { value: "17153", name: "Pulaski County" },
        { value: "17155", name: "Putnam County" },
        { value: "17157", name: "Randolph County" },
        { value: "17159", name: "Richland County" },
        { value: "17161", name: "Rock Island County" },
        { value: "17163", name: "St. Clair County" },
        { value: "17165", name: "Saline County" },
        { value: "17167", name: "Sangamon County" },
        { value: "17169", name: "Schuyler County" },
        { value: "17171", name: "Scott County" },
        { value: "17173", name: "Shelby County" },
        { value: "17175", name: "Stark County" },
        { value: "17177", name: "Stephenson County" },
        { value: "17179", name: "Tazewell County" },
        { value: "17181", name: "Union County" },
        { value: "17183", name: "Vermilion County" },
        { value: "17185", name: "Wabash County" },
        { value: "17187", name: "Warren County" },
        { value: "17189", name: "Washington County" },
        { value: "17191", name: "Wayne County" },
        { value: "17193", name: "White County" },
        { value: "17195", name: "Whiteside County" },
        { value: "17197", name: "Will County" },
        { value: "17199", name: "Williamson County" },
        { value: "17201", name: "Winnebago County" },
        { value: "17203", name: "Woodford County" },
        { value: "18000", name: "Indiana" },
        { value: "18001", name: "Adams County" },
        { value: "18003", name: "Allen County" },
        { value: "18005", name: "Bartholomew County" },
        { value: "18007", name: "Benton County" },
        { value: "18009", name: "Blackford County" },
        { value: "18011", name: "Boone County" },
        { value: "18013", name: "Brown County" },
        { value: "18015", name: "Carroll County" },
        { value: "18017", name: "Cass County" },
        { value: "18019", name: "Clark County" },
        { value: "18021", name: "Clay County" },
        { value: "18023", name: "Clinton County" },
        { value: "18025", name: "Crawford County" },
        { value: "18027", name: "Daviess County" },
        { value: "18029", name: "Dearborn County" },
        { value: "18031", name: "Decatur County" },
        { value: "18033", name: "De Kalb County" },
        { value: "18035", name: "Delaware County" },
        { value: "18037", name: "Dubois County" },
        { value: "18039", name: "Elkhart County" },
        { value: "18041", name: "Fayette County" },
        { value: "18043", name: "Floyd County" },
        { value: "18045", name: "Fountain County" },
        { value: "18047", name: "Franklin County" },
        { value: "18049", name: "Fulton County" },
        { value: "18051", name: "Gibson County" },
        { value: "18053", name: "Grant County" },
        { value: "18055", name: "Greene County" },
        { value: "18057", name: "Hamilton County" },
        { value: "18059", name: "Hancock County" },
        { value: "18061", name: "Harrison County" },
        { value: "18063", name: "Hendricks County" },
        { value: "18065", name: "Henry County" },
        { value: "18067", name: "Howard County" },
        { value: "18069", name: "Huntington County" },
        { value: "18071", name: "Jackson County" },
        { value: "18073", name: "Jasper County" },
        { value: "18075", name: "Jay County" },
        { value: "18077", name: "Jefferson County" },
        { value: "18079", name: "Jennings County" },
        { value: "18081", name: "Johnson County" },
        { value: "18083", name: "Knox County" },
        { value: "18085", name: "Kosciusko County" },
        { value: "18087", name: "Lagrange County" },
        { value: "18089", name: "Lake County" },
        { value: "18091", name: "La Porte County" },
        { value: "18093", name: "Lawrence County" },
        { value: "18095", name: "Madison County" },
        { value: "18097", name: "Marion County" },
        { value: "18099", name: "Marshall County" },
        { value: "18101", name: "Martin County" },
        { value: "18103", name: "Miami County" },
        { value: "18105", name: "Monroe County" },
        { value: "18107", name: "Montgomery County" },
        { value: "18109", name: "Morgan County" },
        { value: "18111", name: "Newton County" },
        { value: "18113", name: "Noble County" },
        { value: "18115", name: "Ohio County" },
        { value: "18117", name: "Orange County" },
        { value: "18119", name: "Owen County" },
        { value: "18121", name: "Parke County" },
        { value: "18123", name: "Perry County" },
        { value: "18125", name: "Pike County" },
        { value: "18127", name: "Porter County" },
        { value: "18129", name: "Posey County" },
        { value: "18131", name: "Pulaski County" },
        { value: "18133", name: "Putnam County" },
        { value: "18135", name: "Randolph County" },
        { value: "18137", name: "Ripley County" },
        { value: "18139", name: "Rush County" },
        { value: "18141", name: "St. Joseph County" },
        { value: "18143", name: "Scott County" },
        { value: "18145", name: "Shelby County" },
        { value: "18147", name: "Spencer County" },
        { value: "18149", name: "Starke County" },
        { value: "18151", name: "Steuben County" },
        { value: "18153", name: "Sullivan County" },
        { value: "18155", name: "Switzerland County" },
        { value: "18157", name: "Tippecanoe County" },
        { value: "18159", name: "Tipton County" },
        { value: "18161", name: "Union County" },
        { value: "18163", name: "Vanderburgh County" },
        { value: "18165", name: "Vermillion County" },
        { value: "18167", name: "Vigo County" },
        { value: "18169", name: "Wabash County" },
        { value: "18171", name: "Warren County" },
        { value: "18173", name: "Warrick County" },
        { value: "18175", name: "Washington County" },
        { value: "18177", name: "Wayne County" },
        { value: "18179", name: "Wells County" },
        { value: "18181", name: "White County" },
        { value: "18183", name: "Whitley County" },
        { value: "19000", name: "Iowa" },
        { value: "19001", name: "Adair County" },
        { value: "19003", name: "Adams County" },
        { value: "19005", name: "Allamakee County" },
        { value: "19007", name: "Appanoose County" },
        { value: "19009", name: "Audubon County" },
        { value: "19011", name: "Benton County" },
        { value: "19013", name: "Black Hawk County" },
        { value: "19015", name: "Boone County" },
        { value: "19017", name: "Bremer County" },
        { value: "19019", name: "Buchanan County" },
        { value: "19021", name: "Buena Vista County" },
        { value: "19023", name: "Butler County" },
        { value: "19025", name: "Calhoun County" },
        { value: "19027", name: "Carroll County" },
        { value: "19029", name: "Cass County" },
        { value: "19031", name: "Cedar County" },
        { value: "19033", name: "Cerro Gordo County" },
        { value: "19035", name: "Cherokee County" },
        { value: "19037", name: "Chickasaw County" },
        { value: "19039", name: "Clarke County" },
        { value: "19041", name: "Clay County" },
        { value: "19043", name: "Clayton County" },
        { value: "19045", name: "Clinton County" },
        { value: "19047", name: "Crawford County" },
        { value: "19049", name: "Dallas County" },
        { value: "19051", name: "Davis County" },
        { value: "19053", name: "Decatur County" },
        { value: "19055", name: "Delaware County" },
        { value: "19057", name: "Des Moines County" },
        { value: "19059", name: "Dickinson County" },
        { value: "19061", name: "Dubuque County" },
        { value: "19063", name: "Emmet County" },
        { value: "19065", name: "Fayette County" },
        { value: "19067", name: "Floyd County" },
        { value: "19069", name: "Franklin County" },
        { value: "19071", name: "Fremont County" },
        { value: "19073", name: "Greene County" },
        { value: "19075", name: "Grundy County" },
        { value: "19077", name: "Guthrie County" },
        { value: "19079", name: "Hamilton County" },
        { value: "19081", name: "Hancock County" },
        { value: "19083", name: "Hardin County" },
        { value: "19085", name: "Harrison County" },
        { value: "19087", name: "Henry County" },
        { value: "19089", name: "Howard County" },
        { value: "19091", name: "Humboldt County" },
        { value: "19093", name: "Ida County" },
        { value: "19095", name: "Iowa County" },
        { value: "19097", name: "Jackson County" },
        { value: "19099", name: "Jasper County" },
        { value: "19101", name: "Jefferson County" },
        { value: "19103", name: "Johnson County" },
        { value: "19105", name: "Jones County" },
        { value: "19107", name: "Keokuk County" },
        { value: "19109", name: "Kossuth County" },
        { value: "19111", name: "Lee County" },
        { value: "19113", name: "Linn County" },
        { value: "19115", name: "Louisa County" },
        { value: "19117", name: "Lucas County" },
        { value: "19119", name: "Lyon County" },
        { value: "19121", name: "Madison County" },
        { value: "19123", name: "Mahaska County" },
        { value: "19125", name: "Marion County" },
        { value: "19127", name: "Marshall County" },
        { value: "19129", name: "Mills County" },
        { value: "19131", name: "Mitchell County" },
        { value: "19133", name: "Monona County" },
        { value: "19135", name: "Monroe County" },
        { value: "19137", name: "Montgomery County" },
        { value: "19139", name: "Muscatine County" },
        { value: "19141", name: "O" },
        { value: "19143", name: "Osceola County" },
        { value: "19145", name: "Page County" },
        { value: "19147", name: "Palo Alto County" },
        { value: "19149", name: "Plymouth County" },
        { value: "19151", name: "Pocahontas County" },
        { value: "19153", name: "Polk County" },
        { value: "19155", name: "Pottawattamie County" },
        { value: "19157", name: "Poweshiek County" },
        { value: "19159", name: "Ringgold County" },
        { value: "19161", name: "Sac County" },
        { value: "19163", name: "Scott County" },
        { value: "19165", name: "Shelby County" },
        { value: "19167", name: "Sioux County" },
        { value: "19169", name: "Story County" },
        { value: "19171", name: "Tama County" },
        { value: "19173", name: "Taylor County" },
        { value: "19175", name: "Union County" },
        { value: "19177", name: "Van Buren County" },
        { value: "19179", name: "Wapello County" },
        { value: "19181", name: "Warren County" },
        { value: "19183", name: "Washington County" },
        { value: "19185", name: "Wayne County" },
        { value: "19187", name: "Webster County" },
        { value: "19189", name: "Winnebago County" },
        { value: "19191", name: "Winneshiek County" },
        { value: "19193", name: "Woodbury County" },
        { value: "19195", name: "Worth County" },
        { value: "19197", name: "Wright County" },
        { value: "20000", name: "Kansas" },
        { value: "20001", name: "Allen County" },
        { value: "20003", name: "Anderson County" },
        { value: "20005", name: "Atchison County" },
        { value: "20007", name: "Barber County" },
        { value: "20009", name: "Barton County" },
        { value: "20011", name: "Bourbon County" },
        { value: "20013", name: "Brown County" },
        { value: "20015", name: "Butler County" },
        { value: "20017", name: "Chase County" },
        { value: "20019", name: "Chautauqua County" },
        { value: "20021", name: "Cherokee County" },
        { value: "20023", name: "Cheyenne County" },
        { value: "20025", name: "Clark County" },
        { value: "20027", name: "Clay County" },
        { value: "20029", name: "Cloud County" },
        { value: "20031", name: "Coffey County" },
        { value: "20033", name: "Comanche County" },
        { value: "20035", name: "Cowley County" },
        { value: "20037", name: "Crawford County" },
        { value: "20039", name: "Decatur County" },
        { value: "20041", name: "Dickinson County" },
        { value: "20043", name: "Doniphan County" },
        { value: "20045", name: "Douglas County" },
        { value: "20047", name: "Edwards County" },
        { value: "20049", name: "Elk County" },
        { value: "20051", name: "Ellis County" },
        { value: "20053", name: "Ellsworth County" },
        { value: "20055", name: "Finney County" },
        { value: "20057", name: "Ford County" },
        { value: "20059", name: "Franklin County" },
        { value: "20061", name: "Geary County" },
        { value: "20063", name: "Gove County" },
        { value: "20065", name: "Graham County" },
        { value: "20067", name: "Grant County" },
        { value: "20069", name: "Gray County" },
        { value: "20071", name: "Greeley County" },
        { value: "20073", name: "Greenwood County" },
        { value: "20075", name: "Hamilton County" },
        { value: "20077", name: "Harper County" },
        { value: "20079", name: "Harvey County" },
        { value: "20081", name: "Haskell County" },
        { value: "20083", name: "Hodgeman County" },
        { value: "20085", name: "Jackson County" },
        { value: "20087", name: "Jefferson County" },
        { value: "20089", name: "Jewell County" },
        { value: "20091", name: "Johnson County" },
        { value: "20093", name: "Kearny County" },
        { value: "20095", name: "Kingman County" },
        { value: "20097", name: "Kiowa County" },
        { value: "20099", name: "Labette County" },
        { value: "20101", name: "Lane County" },
        { value: "20103", name: "Leavenworth County" },
        { value: "20105", name: "Lincoln County" },
        { value: "20107", name: "Linn County" },
        { value: "20109", name: "Logan County" },
        { value: "20111", name: "Lyon County" },
        { value: "20113", name: "McPherson County" },
        { value: "20115", name: "Marion County" },
        { value: "20117", name: "Marshall County" },
        { value: "20119", name: "Meade County" },
        { value: "20121", name: "Miami County" },
        { value: "20123", name: "Mitchell County" },
        { value: "20125", name: "Montgomery County" },
        { value: "20127", name: "Morris County" },
        { value: "20129", name: "Morton County" },
        { value: "20131", name: "Nemaha County" },
        { value: "20133", name: "Neosho County" },
        { value: "20135", name: "Ness County" },
        { value: "20137", name: "Norton County" },
        { value: "20139", name: "Osage County" },
        { value: "20141", name: "Osborne County" },
        { value: "20143", name: "Ottawa County" },
        { value: "20145", name: "Pawnee County" },
        { value: "20147", name: "Phillips County" },
        { value: "20149", name: "Pottawatomie County" },
        { value: "20151", name: "Pratt County" },
        { value: "20153", name: "Rawlins County" },
        { value: "20155", name: "Reno County" },
        { value: "20157", name: "Republic County" },
        { value: "20159", name: "Rice County" },
        { value: "20161", name: "Riley County" },
        { value: "20163", name: "Rooks County" },
        { value: "20165", name: "Rush County" },
        { value: "20167", name: "Russell County" },
        { value: "20169", name: "Saline County" },
        { value: "20171", name: "Scott County" },
        { value: "20173", name: "Sedgwick County" },
        { value: "20175", name: "Seward County" },
        { value: "20177", name: "Shawnee County" },
        { value: "20179", name: "Sheridan County" },
        { value: "20181", name: "Sherman County" },
        { value: "20183", name: "Smith County" },
        { value: "20185", name: "Stafford County" },
        { value: "20187", name: "Stanton County" },
        { value: "20189", name: "Stevens County" },
        { value: "20191", name: "Sumner County" },
        { value: "20193", name: "Thomas County" },
        { value: "20195", name: "Trego County" },
        { value: "20197", name: "Wabaunsee County" },
        { value: "20199", name: "Wallace County" },
        { value: "20201", name: "Washington County" },
        { value: "20203", name: "Wichita County" },
        { value: "20205", name: "Wilson County" },
        { value: "20207", name: "Woodson County" },
        { value: "20209", name: "Wyandotte County" },
        { value: "21000", name: "Kentucky" },
        { value: "21001", name: "Adair County" },
        { value: "21003", name: "Allen County" },
        { value: "21005", name: "Anderson County" },
        { value: "21007", name: "Ballard County" },
        { value: "21009", name: "Barren County" },
        { value: "21011", name: "Bath County" },
        { value: "21013", name: "Bell County" },
        { value: "21015", name: "Boone County" },
        { value: "21017", name: "Bourbon County" },
        { value: "21019", name: "Boyd County" },
        { value: "21021", name: "Boyle County" },
        { value: "21023", name: "Bracken County" },
        { value: "21025", name: "Breathitt County" },
        { value: "21027", name: "Breckinridge County" },
        { value: "21029", name: "Bullitt County" },
        { value: "21031", name: "Butler County" },
        { value: "21033", name: "Caldwell County" },
        { value: "21035", name: "Calloway County" },
        { value: "21037", name: "Campbell County" },
        { value: "21039", name: "Carlisle County" },
        { value: "21041", name: "Carroll County" },
        { value: "21043", name: "Carter County" },
        { value: "21045", name: "Casey County" },
        { value: "21047", name: "Christian County" },
        { value: "21049", name: "Clark County" },
        { value: "21051", name: "Clay County" },
        { value: "21053", name: "Clinton County" },
        { value: "21055", name: "Crittenden County" },
        { value: "21057", name: "Cumberland County" },
        { value: "21059", name: "Daviess County" },
        { value: "21061", name: "Edmonson County" },
        { value: "21063", name: "Elliott County" },
        { value: "21065", name: "Estill County" },
        { value: "21067", name: "Fayette County" },
        { value: "21069", name: "Fleming County" },
        { value: "21071", name: "Floyd County" },
        { value: "21073", name: "Franklin County" },
        { value: "21075", name: "Fulton County" },
        { value: "21077", name: "Gallatin County" },
        { value: "21079", name: "Garrard County" },
        { value: "21081", name: "Grant County" },
        { value: "21083", name: "Graves County" },
        { value: "21085", name: "Grayson County" },
        { value: "21087", name: "Green County" },
        { value: "21089", name: "Greenup County" },
        { value: "21091", name: "Hancock County" },
        { value: "21093", name: "Hardin County" },
        { value: "21095", name: "Harlan County" },
        { value: "21097", name: "Harrison County" },
        { value: "21099", name: "Hart County" },
        { value: "21101", name: "Henderson County" },
        { value: "21103", name: "Henry County" },
        { value: "21105", name: "Hickman County" },
        { value: "21107", name: "Hopkins County" },
        { value: "21109", name: "Jackson County" },
        { value: "21111", name: "Jefferson County" },
        { value: "21113", name: "Jessamine County" },
        { value: "21115", name: "Johnson County" },
        { value: "21117", name: "Kenton County" },
        { value: "21119", name: "Knott County" },
        { value: "21121", name: "Knox County" },
        { value: "21123", name: "Larue County" },
        { value: "21125", name: "Laurel County" },
        { value: "21127", name: "Lawrence County" },
        { value: "21129", name: "Lee County" },
        { value: "21131", name: "Leslie County" },
        { value: "21133", name: "Letcher County" },
        { value: "21135", name: "Lewis County" },
        { value: "21137", name: "Lincoln County" },
        { value: "21139", name: "Livingston County" },
        { value: "21141", name: "Logan County" },
        { value: "21143", name: "Lyon County" },
        { value: "21145", name: "McCracken County" },
        { value: "21147", name: "McCreary County" },
        { value: "21149", name: "McLean County" },
        { value: "21151", name: "Madison County" },
        { value: "21153", name: "Magoffin County" },
        { value: "21155", name: "Marion County" },
        { value: "21157", name: "Marshall County" },
        { value: "21159", name: "Martin County" },
        { value: "21161", name: "Mason County" },
        { value: "21163", name: "Meade County" },
        { value: "21165", name: "Menifee County" },
        { value: "21167", name: "Mercer County" },
        { value: "21169", name: "Metcalfe County" },
        { value: "21171", name: "Monroe County" },
        { value: "21173", name: "Montgomery County" },
        { value: "21175", name: "Morgan County" },
        { value: "21177", name: "Muhlenberg County" },
        { value: "21179", name: "Nelson County" },
        { value: "21181", name: "Nicholas County" },
        { value: "21183", name: "Ohio County" },
        { value: "21185", name: "Oldham County" },
        { value: "21187", name: "Owen County" },
        { value: "21189", name: "Owsley County" },
        { value: "21191", name: "Pendleton County" },
        { value: "21193", name: "Perry County" },
        { value: "21195", name: "Pike County" },
        { value: "21197", name: "Powell County" },
        { value: "21199", name: "Pulaski County" },
        { value: "21201", name: "Robertson County" },
        { value: "21203", name: "Rockcastle County" },
        { value: "21205", name: "Rowan County" },
        { value: "21207", name: "Russell County" },
        { value: "21209", name: "Scott County" },
        { value: "21211", name: "Shelby County" },
        { value: "21213", name: "Simpson County" },
        { value: "21215", name: "Spencer County" },
        { value: "21217", name: "Taylor County" },
        { value: "21219", name: "Todd County" },
        { value: "21221", name: "Trigg County" },
        { value: "21223", name: "Trimble County" },
        { value: "21225", name: "Union County" },
        { value: "21227", name: "Warren County" },
        { value: "21229", name: "Washington County" },
        { value: "21231", name: "Wayne County" },
        { value: "21233", name: "Webster County" },
        { value: "21235", name: "Whitley County" },
        { value: "21237", name: "Wolfe County" },
        { value: "21239", name: "Woodford County" },
        { value: "22000", name: "Louisiana" },
        { value: "22001", name: "Acadia Parish" },
        { value: "22003", name: "Allen Parish" },
        { value: "22005", name: "Ascension Parish" },
        { value: "22007", name: "Assumption Parish" },
        { value: "22009", name: "Avoyelles Parish" },
        { value: "22011", name: "Beauregard Parish" },
        { value: "22013", name: "Bienville Parish" },
        { value: "22015", name: "Bossier Parish" },
        { value: "22017", name: "Caddo Parish" },
        { value: "22019", name: "Calcasieu Parish" },
        { value: "22021", name: "Caldwell Parish" },
        { value: "22023", name: "Cameron Parish" },
        { value: "22025", name: "Catahoula Parish" },
        { value: "22027", name: "Claiborne Parish" },
        { value: "22029", name: "Concordia Parish" },
        { value: "22031", name: "De Soto Parish" },
        { value: "22033", name: "East Baton Rouge Parish" },
        { value: "22035", name: "East Carroll Parish" },
        { value: "22037", name: "East Feliciana Parish" },
        { value: "22039", name: "Evangeline Parish" },
        { value: "22041", name: "Franklin Parish" },
        { value: "22043", name: "Grant Parish" },
        { value: "22045", name: "Iberia Parish" },
        { value: "22047", name: "Iberville Parish" },
        { value: "22049", name: "Jackson Parish" },
        { value: "22051", name: "Jefferson Parish" },
        { value: "22053", name: "Jefferson Davis Parish" },
        { value: "22055", name: "Lafayette Parish" },
        { value: "22057", name: "Lafourche Parish" },
        { value: "22059", name: "La Salle Parish" },
        { value: "22061", name: "Lincoln Parish" },
        { value: "22063", name: "Livingston Parish" },
        { value: "22065", name: "Madison Parish" },
        { value: "22067", name: "Morehouse Parish" },
        { value: "22069", name: "Natchitoches Parish" },
        { value: "22071", name: "Orleans Parish" },
        { value: "22073", name: "Ouachita Parish" },
        { value: "22075", name: "Plaquemines Parish" },
        { value: "22077", name: "Pointe Coupee Parish" },
        { value: "22079", name: "Rapides Parish" },
        { value: "22081", name: "Red River Parish" },
        { value: "22083", name: "Richland Parish" },
        { value: "22085", name: "Sabine Parish" },
        { value: "22087", name: "St. Bernard Parish" },
        { value: "22089", name: "St. Charles Parish" },
        { value: "22091", name: "St. Helena Parish" },
        { value: "22093", name: "St. James Parish" },
        { value: "22095", name: "St. John the Baptist Parish" },
        { value: "22097", name: "St. Landry Parish" },
        { value: "22099", name: "St. Martin Parish" },
        { value: "22101", name: "St. Mary Parish" },
        { value: "22103", name: "St. Tammany Parish" },
        { value: "22105", name: "Tangipahoa Parish" },
        { value: "22107", name: "Tensas Parish" },
        { value: "22109", name: "Terrebonne Parish" },
        { value: "22111", name: "Union Parish" },
        { value: "22113", name: "Vermilion Parish" },
        { value: "22115", name: "Vernon Parish" },
        { value: "22117", name: "Washington Parish" },
        { value: "22119", name: "Webster Parish" },
        { value: "22121", name: "West Baton Rouge Parish" },
        { value: "22123", name: "West Carroll Parish" },
        { value: "22125", name: "West Feliciana Parish" },
        { value: "22127", name: "Winn Parish" },
        { value: "23000", name: "Maine" },
        { value: "23001", name: "Androscoggin County" },
        { value: "23003", name: "Aroostook County" },
        { value: "23005", name: "Cumberland County" },
        { value: "23007", name: "Franklin County" },
        { value: "23009", name: "Hancock County" },
        { value: "23011", name: "Kennebec County" },
        { value: "23013", name: "Knox County" },
        { value: "23015", name: "Lincoln County" },
        { value: "23017", name: "Oxford County" },
        { value: "23019", name: "Penobscot County" },
        { value: "23021", name: "Piscataquis County" },
        { value: "23023", name: "Sagadahoc County" },
        { value: "23025", name: "Somerset County" },
        { value: "23027", name: "Waldo County" },
        { value: "23029", name: "Washington County" },
        { value: "23031", name: "York County" },
        { value: "24000", name: "Maryland" },
        { value: "24001", name: "Allegany County" },
        { value: "24003", name: "Anne Arundel County" },
        { value: "24005", name: "Baltimore County" },
        { value: "24009", name: "Calvert County" },
        { value: "24011", name: "Caroline County" },
        { value: "24013", name: "Carroll County" },
        { value: "24015", name: "Cecil County" },
        { value: "24017", name: "Charles County" },
        { value: "24019", name: "Dorchester County" },
        { value: "24021", name: "Frederick County" },
        { value: "24023", name: "Garrett County" },
        { value: "24025", name: "Harford County" },
        { value: "24027", name: "Howard County" },
        { value: "24029", name: "Kent County" },
        { value: "24031", name: "Montgomery County" },
        { value: "24033", name: "Prince George" },
        { value: "24035", name: "Queen Anne" },
        { value: "24037", name: "St. Mary" },
        { value: "24039", name: "Somerset County" },
        { value: "24041", name: "Talbot County" },
        { value: "24043", name: "Washington County" },
        { value: "24045", name: "Wicomico County" },
        { value: "24047", name: "Worcester County" },
        { value: "24510", name: "Baltimore city" },
        { value: "25000", name: "Massachusetts" },
        { value: "25001", name: "Barnstable County" },
        { value: "25003", name: "Berkshire County" },
        { value: "25005", name: "Bristol County" },
        { value: "25007", name: "Dukes County" },
        { value: "25009", name: "Essex County" },
        { value: "25011", name: "Franklin County" },
        { value: "25013", name: "Hampden County" },
        { value: "25015", name: "Hampshire County" },
        { value: "25017", name: "Middlesex County" },
        { value: "25019", name: "Nantucket County" },
        { value: "25021", name: "Norfolk County" },
        { value: "25023", name: "Plymouth County" },
        { value: "25025", name: "Suffolk County" },
        { value: "25027", name: "Worcester County" },
        { value: "26000", name: "Michigan" },
        { value: "26001", name: "Alcona County" },
        { value: "26003", name: "Alger County" },
        { value: "26005", name: "Allegan County" },
        { value: "26007", name: "Alpena County" },
        { value: "26009", name: "Antrim County" },
        { value: "26011", name: "Arenac County" },
        { value: "26013", name: "Baraga County" },
        { value: "26015", name: "Barry County" },
        { value: "26017", name: "Bay County" },
        { value: "26019", name: "Benzie County" },
        { value: "26021", name: "Berrien County" },
        { value: "26023", name: "Branch County" },
        { value: "26025", name: "Calhoun County" },
        { value: "26027", name: "Cass County" },
        { value: "26029", name: "Charlevoix County" },
        { value: "26031", name: "Cheboygan County" },
        { value: "26033", name: "Chippewa County" },
        { value: "26035", name: "Clare County" },
        { value: "26037", name: "Clinton County" },
        { value: "26039", name: "Crawford County" },
        { value: "26041", name: "Delta County" },
        { value: "26043", name: "Dickinson County" },
        { value: "26045", name: "Eaton County" },
        { value: "26047", name: "Emmet County" },
        { value: "26049", name: "Genesee County" },
        { value: "26051", name: "Gladwin County" },
        { value: "26053", name: "Gogebic County" },
        { value: "26055", name: "Grand Traverse County" },
        { value: "26057", name: "Gratiot County" },
        { value: "26059", name: "Hillsdale County" },
        { value: "26061", name: "Houghton County" },
        { value: "26063", name: "Huron County" },
        { value: "26065", name: "Ingham County" },
        { value: "26067", name: "Ionia County" },
        { value: "26069", name: "Iosco County" },
        { value: "26071", name: "Iron County" },
        { value: "26073", name: "Isabella County" },
        { value: "26075", name: "Jackson County" },
        { value: "26077", name: "Kalamazoo County" },
        { value: "26079", name: "Kalkaska County" },
        { value: "26081", name: "Kent County" },
        { value: "26083", name: "Keweenaw County" },
        { value: "26085", name: "Lake County" },
        { value: "26087", name: "Lapeer County" },
        { value: "26089", name: "Leelanau County" },
        { value: "26091", name: "Lenawee County" },
        { value: "26093", name: "Livingston County" },
        { value: "26095", name: "Luce County" },
        { value: "26097", name: "Mackinac County" },
        { value: "26099", name: "Macomb County" },
        { value: "26101", name: "Manistee County" },
        { value: "26103", name: "Marquette County" },
        { value: "26105", name: "Mason County" },
        { value: "26107", name: "Mecosta County" },
        { value: "26109", name: "Menominee County" },
        { value: "26111", name: "Midland County" },
        { value: "26113", name: "Missaukee County" },
        { value: "26115", name: "Monroe County" },
        { value: "26117", name: "Montcalm County" },
        { value: "26119", name: "Montmorency County" },
        { value: "26121", name: "Muskegon County" },
        { value: "26123", name: "Newaygo County" },
        { value: "26125", name: "Oakland County" },
        { value: "26127", name: "Oceana County" },
        { value: "26129", name: "Ogemaw County" },
        { value: "26131", name: "Ontonagon County" },
        { value: "26133", name: "Osceola County" },
        { value: "26135", name: "Oscoda County" },
        { value: "26137", name: "Otsego County" },
        { value: "26139", name: "Ottawa County" },
        { value: "26141", name: "Presque Isle County" },
        { value: "26143", name: "Roscommon County" },
        { value: "26145", name: "Saginaw County" },
        { value: "26147", name: "St. Clair County" },
        { value: "26149", name: "St. Joseph County" },
        { value: "26151", name: "Sanilac County" },
        { value: "26153", name: "Schoolcraft County" },
        { value: "26155", name: "Shiawassee County" },
        { value: "26157", name: "Tuscola County" },
        { value: "26159", name: "Van Buren County" },
        { value: "26161", name: "Washtenaw County" },
        { value: "26163", name: "Wayne County" },
        { value: "26165", name: "Wexford County" },
        { value: "27000", name: "Minnesota" },
        { value: "27001", name: "Aitkin County" },
        { value: "27003", name: "Anoka County" },
        { value: "27005", name: "Becker County" },
        { value: "27007", name: "Beltrami County" },
        { value: "27009", name: "Benton County" },
        { value: "27011", name: "Big Stone County" },
        { value: "27013", name: "Blue Earth County" },
        { value: "27015", name: "Brown County" },
        { value: "27017", name: "Carlton County" },
        { value: "27019", name: "Carver County" },
        { value: "27021", name: "Cass County" },
        { value: "27023", name: "Chippewa County" },
        { value: "27025", name: "Chisago County" },
        { value: "27027", name: "Clay County" },
        { value: "27029", name: "Clearwater County" },
        { value: "27031", name: "Cook County" },
        { value: "27033", name: "Cottonwood County" },
        { value: "27035", name: "Crow Wing County" },
        { value: "27037", name: "Dakota County" },
        { value: "27039", name: "Dodge County" },
        { value: "27041", name: "Douglas County" },
        { value: "27043", name: "Faribault County" },
        { value: "27045", name: "Fillmore County" },
        { value: "27047", name: "Freeborn County" },
        { value: "27049", name: "Goodhue County" },
        { value: "27051", name: "Grant County" },
        { value: "27053", name: "Hennepin County" },
        { value: "27055", name: "Houston County" },
        { value: "27057", name: "Hubbard County" },
        { value: "27059", name: "Isanti County" },
        { value: "27061", name: "Itasca County" },
        { value: "27063", name: "Jackson County" },
        { value: "27065", name: "Kanabec County" },
        { value: "27067", name: "Kandiyohi County" },
        { value: "27069", name: "Kittson County" },
        { value: "27071", name: "Koochiching County" },
        { value: "27073", name: "Lac qui Parle County" },
        { value: "27075", name: "Lake County" },
        { value: "27077", name: "Lake of the Woods County" },
        { value: "27079", name: "Le Sueur County" },
        { value: "27081", name: "Lincoln County" },
        { value: "27083", name: "Lyon County" },
        { value: "27085", name: "McLeod County" },
        { value: "27087", name: "Mahnomen County" },
        { value: "27089", name: "Marshall County" },
        { value: "27091", name: "Martin County" },
        { value: "27093", name: "Meeker County" },
        { value: "27095", name: "Mille Lacs County" },
        { value: "27097", name: "Morrison County" },
        { value: "27099", name: "Mower County" },
        { value: "27101", name: "Murray County" },
        { value: "27103", name: "Nicollet County" },
        { value: "27105", name: "Nobles County" },
        { value: "27107", name: "Norman County" },
        { value: "27109", name: "Olmsted County" },
        { value: "27111", name: "Otter Tail County" },
        { value: "27113", name: "Pennington County" },
        { value: "27115", name: "Pine County" },
        { value: "27117", name: "Pipestone County" },
        { value: "27119", name: "Polk County" },
        { value: "27121", name: "Pope County" },
        { value: "27123", name: "Ramsey County" },
        { value: "27125", name: "Red Lake County" },
        { value: "27127", name: "Redwood County" },
        { value: "27129", name: "Renville County" },
        { value: "27131", name: "Rice County" },
        { value: "27133", name: "Rock County" },
        { value: "27135", name: "Roseau County" },
        { value: "27137", name: "St. Louis County" },
        { value: "27139", name: "Scott County" },
        { value: "27141", name: "Sherburne County" },
        { value: "27143", name: "Sibley County" },
        { value: "27145", name: "Stearns County" },
        { value: "27147", name: "Steele County" },
        { value: "27149", name: "Stevens County" },
        { value: "27151", name: "Swift County" },
        { value: "27153", name: "Todd County" },
        { value: "27155", name: "Traverse County" },
        { value: "27157", name: "Wabasha County" },
        { value: "27159", name: "Wadena County" },
        { value: "27161", name: "Waseca County" },
        { value: "27163", name: "Washington County" },
        { value: "27165", name: "Watonwan County" },
        { value: "27167", name: "Wilkin County" },
        { value: "27169", name: "Winona County" },
        { value: "27171", name: "Wright County" },
        { value: "27173", name: "Yellow Medicine County" },
        { value: "28000", name: "Mississippi" },
        { value: "28001", name: "Adams County" },
        { value: "28003", name: "Alcorn County" },
        { value: "28005", name: "Amite County" },
        { value: "28007", name: "Attala County" },
        { value: "28009", name: "Benton County" },
        { value: "28011", name: "Bolivar County" },
        { value: "28013", name: "Calhoun County" },
        { value: "28015", name: "Carroll County" },
        { value: "28017", name: "Chickasaw County" },
        { value: "28019", name: "Choctaw County" },
        { value: "28021", name: "Claiborne County" },
        { value: "28023", name: "Clarke County" },
        { value: "28025", name: "Clay County" },
        { value: "28027", name: "Coahoma County" },
        { value: "28029", name: "Copiah County" },
        { value: "28031", name: "Covington County" },
        { value: "28033", name: "DeSoto County" },
        { value: "28035", name: "Forrest County" },
        { value: "28037", name: "Franklin County" },
        { value: "28039", name: "George County" },
        { value: "28041", name: "Greene County" },
        { value: "28043", name: "Grenada County" },
        { value: "28045", name: "Hancock County" },
        { value: "28047", name: "Harrison County" },
        { value: "28049", name: "Hinds County" },
        { value: "28051", name: "Holmes County" },
        { value: "28053", name: "Humphreys County" },
        { value: "28055", name: "Issaquena County" },
        { value: "28057", name: "Itawamba County" },
        { value: "28059", name: "Jackson County" },
        { value: "28061", name: "Jasper County" },
        { value: "28063", name: "Jefferson County" },
        { value: "28065", name: "Jefferson Davis County" },
        { value: "28067", name: "Jones County" },
        { value: "28069", name: "Kemper County" },
        { value: "28071", name: "Lafayette County" },
        { value: "28073", name: "Lamar County" },
        { value: "28075", name: "Lauderdale County" },
        { value: "28077", name: "Lawrence County" },
        { value: "28079", name: "Leake County" },
        { value: "28081", name: "Lee County" },
        { value: "28083", name: "Leflore County" },
        { value: "28085", name: "Lincoln County" },
        { value: "28087", name: "Lowndes County" },
        { value: "28089", name: "Madison County" },
        { value: "28091", name: "Marion County" },
        { value: "28093", name: "Marshall County" },
        { value: "28095", name: "Monroe County" },
        { value: "28097", name: "Montgomery County" },
        { value: "28099", name: "Neshoba County" },
        { value: "28101", name: "Newton County" },
        { value: "28103", name: "Noxubee County" },
        { value: "28105", name: "Oktibbeha County" },
        { value: "28107", name: "Panola County" },
        { value: "28109", name: "Pearl River County" },
        { value: "28111", name: "Perry County" },
        { value: "28113", name: "Pike County" },
        { value: "28115", name: "Pontotoc County" },
        { value: "28117", name: "Prentiss County" },
        { value: "28119", name: "Quitman County" },
        { value: "28121", name: "Rankin County" },
        { value: "28123", name: "Scott County" },
        { value: "28125", name: "Sharkey County" },
        { value: "28127", name: "Simpson County" },
        { value: "28129", name: "Smith County" },
        { value: "28131", name: "Stone County" },
        { value: "28133", name: "Sunflower County" },
        { value: "28135", name: "Tallahatchie County" },
        { value: "28137", name: "Tate County" },
        { value: "28139", name: "Tippah County" },
        { value: "28141", name: "Tishomingo County" },
        { value: "28143", name: "Tunica County" },
        { value: "28145", name: "Union County" },
        { value: "28147", name: "Walthall County" },
        { value: "28149", name: "Warren County" },
        { value: "28151", name: "Washington County" },
        { value: "28153", name: "Wayne County" },
        { value: "28155", name: "Webster County" },
        { value: "28157", name: "Wilkinson County" },
        { value: "28159", name: "Winston County" },
        { value: "28161", name: "Yalobusha County" },
        { value: "28163", name: "Yazoo County" },
        { value: "29000", name: "Missouri" },
        { value: "29001", name: "Adair County" },
        { value: "29003", name: "Andrew County" },
        { value: "29005", name: "Atchison County" },
        { value: "29007", name: "Audrain County" },
        { value: "29009", name: "Barry County" },
        { value: "29011", name: "Barton County" },
        { value: "29013", name: "Bates County" },
        { value: "29015", name: "Benton County" },
        { value: "29017", name: "Bollinger County" },
        { value: "29019", name: "Boone County" },
        { value: "29021", name: "Buchanan County" },
        { value: "29023", name: "Butler County" },
        { value: "29025", name: "Caldwell County" },
        { value: "29027", name: "Callaway County" },
        { value: "29029", name: "Camden County" },
        { value: "29031", name: "Cape Girardeau County" },
        { value: "29033", name: "Carroll County" },
        { value: "29035", name: "Carter County" },
        { value: "29037", name: "Cass County" },
        { value: "29039", name: "Cedar County" },
        { value: "29041", name: "Chariton County" },
        { value: "29043", name: "Christian County" },
        { value: "29045", name: "Clark County" },
        { value: "29047", name: "Clay County" },
        { value: "29049", name: "Clinton County" },
        { value: "29051", name: "Cole County" },
        { value: "29053", name: "Cooper County" },
        { value: "29055", name: "Crawford County" },
        { value: "29057", name: "Dade County" },
        { value: "29059", name: "Dallas County" },
        { value: "29061", name: "Daviess County" },
        { value: "29063", name: "DeKalb County" },
        { value: "29065", name: "Dent County" },
        { value: "29067", name: "Douglas County" },
        { value: "29069", name: "Dunklin County" },
        { value: "29071", name: "Franklin County" },
        { value: "29073", name: "Gasconade County" },
        { value: "29075", name: "Gentry County" },
        { value: "29077", name: "Greene County" },
        { value: "29079", name: "Grundy County" },
        { value: "29081", name: "Harrison County" },
        { value: "29083", name: "Henry County" },
        { value: "29085", name: "Hickory County" },
        { value: "29087", name: "Holt County" },
        { value: "29089", name: "Howard County" },
        { value: "29091", name: "Howell County" },
        { value: "29093", name: "Iron County" },
        { value: "29095", name: "Jackson County" },
        { value: "29097", name: "Jasper County" },
        { value: "29099", name: "Jefferson County" },
        { value: "29101", name: "Johnson County" },
        { value: "29103", name: "Knox County" },
        { value: "29105", name: "Laclede County" },
        { value: "29107", name: "Lafayette County" },
        { value: "29109", name: "Lawrence County" },
        { value: "29111", name: "Lewis County" },
        { value: "29113", name: "Lincoln County" },
        { value: "29115", name: "Linn County" },
        { value: "29117", name: "Livingston County" },
        { value: "29119", name: "McDonald County" },
        { value: "29121", name: "Macon County" },
        { value: "29123", name: "Madison County" },
        { value: "29125", name: "Maries County" },
        { value: "29127", name: "Marion County" },
        { value: "29129", name: "Mercer County" },
        { value: "29131", name: "Miller County" },
        { value: "29133", name: "Mississippi County" },
        { value: "29135", name: "Moniteau County" },
        { value: "29137", name: "Monroe County" },
        { value: "29139", name: "Montgomery County" },
        { value: "29141", name: "Morgan County" },
        { value: "29143", name: "New Madrid County" },
        { value: "29145", name: "Newton County" },
        { value: "29147", name: "Nodaway County" },
        { value: "29149", name: "Oregon County" },
        { value: "29151", name: "Osage County" },
        { value: "29153", name: "Ozark County" },
        { value: "29155", name: "Pemiscot County" },
        { value: "29157", name: "Perry County" },
        { value: "29159", name: "Pettis County" },
        { value: "29161", name: "Phelps County" },
        { value: "29163", name: "Pike County" },
        { value: "29165", name: "Platte County" },
        { value: "29167", name: "Polk County" },
        { value: "29169", name: "Pulaski County" },
        { value: "29171", name: "Putnam County" },
        { value: "29173", name: "Ralls County" },
        { value: "29175", name: "Randolph County" },
        { value: "29177", name: "Ray County" },
        { value: "29179", name: "Reynolds County" },
        { value: "29181", name: "Ripley County" },
        { value: "29183", name: "St. Charles County" },
        { value: "29185", name: "St. Clair County" },
        { value: "29186", name: "Ste. Genevieve County" },
        { value: "29187", name: "St. Francois County" },
        { value: "29189", name: "St. Louis County" },
        { value: "29195", name: "Saline County" },
        { value: "29197", name: "Schuyler County" },
        { value: "29199", name: "Scotland County" },
        { value: "29201", name: "Scott County" },
        { value: "29203", name: "Shannon County" },
        { value: "29205", name: "Shelby County" },
        { value: "29207", name: "Stoddard County" },
        { value: "29209", name: "Stone County" },
        { value: "29211", name: "Sullivan County" },
        { value: "29213", name: "Taney County" },
        { value: "29215", name: "Texas County" },
        { value: "29217", name: "Vernon County" },
        { value: "29219", name: "Warren County" },
        { value: "29221", name: "Washington County" },
        { value: "29223", name: "Wayne County" },
        { value: "29225", name: "Webster County" },
        { value: "29227", name: "Worth County" },
        { value: "29229", name: "Wright County" },
        { value: "29510", name: "St. Louis city" },
        { value: "30000", name: "Montana" },
        { value: "30001", name: "Beaverhead County" },
        { value: "30003", name: "Big Horn County" },
        { value: "30005", name: "Blaine County" },
        { value: "30007", name: "Broadwater County" },
        { value: "30009", name: "Carbon County" },
        { value: "30011", name: "Carter County" },
        { value: "30013", name: "Cascade County" },
        { value: "30015", name: "Chouteau County" },
        { value: "30017", name: "Custer County" },
        { value: "30019", name: "Daniels County" },
        { value: "30021", name: "Dawson County" },
        { value: "30023", name: "Deer Lodge County" },
        { value: "30025", name: "Fallon County" },
        { value: "30027", name: "Fergus County" },
        { value: "30029", name: "Flathead County" },
        { value: "30031", name: "Gallatin County" },
        { value: "30033", name: "Garfield County" },
        { value: "30035", name: "Glacier County" },
        { value: "30037", name: "Golden Valley County" },
        { value: "30039", name: "Granite County" },
        { value: "30041", name: "Hill County" },
        { value: "30043", name: "Jefferson County" },
        { value: "30045", name: "Judith Basin County" },
        { value: "30047", name: "Lake County" },
        { value: "30049", name: "Lewis and Clark County" },
        { value: "30051", name: "Liberty County" },
        { value: "30053", name: "Lincoln County" },
        { value: "30055", name: "McCone County" },
        { value: "30057", name: "Madison County" },
        { value: "30059", name: "Meagher County" },
        { value: "30061", name: "Mineral County" },
        { value: "30063", name: "Missoula County" },
        { value: "30065", name: "Musselshell County" },
        { value: "30067", name: "Park County" },
        { value: "30069", name: "Petroleum County" },
        { value: "30071", name: "Phillips County" },
        { value: "30073", name: "Pondera County" },
        { value: "30075", name: "Powder River County" },
        { value: "30077", name: "Powell County" },
        { value: "30079", name: "Prairie County" },
        { value: "30081", name: "Ravalli County" },
        { value: "30083", name: "Richland County" },
        { value: "30085", name: "Roosevelt County" },
        { value: "30087", name: "Rosebud County" },
        { value: "30089", name: "Sanders County" },
        { value: "30091", name: "Sheridan County" },
        { value: "30093", name: "Silver Bow County" },
        { value: "30095", name: "Stillwater County" },
        { value: "30097", name: "Sweet Grass County" },
        { value: "30099", name: "Teton County" },
        { value: "30101", name: "Toole County" },
        { value: "30103", name: "Treasure County" },
        { value: "30105", name: "Valley County" },
        { value: "30107", name: "Wheatland County" },
        { value: "30109", name: "Wibaux County" },
        { value: "30111", name: "Yellowstone County" },
        { value: "30113", name: "Yellowstone National Park" },
        { value: "31000", name: "Nebraska" },
        { value: "31001", name: "Adams County" },
        { value: "31003", name: "Antelope County" },
        { value: "31005", name: "Arthur County" },
        { value: "31007", name: "Banner County" },
        { value: "31009", name: "Blaine County" },
        { value: "31011", name: "Boone County" },
        { value: "31013", name: "Box Butte County" },
        { value: "31015", name: "Boyd County" },
        { value: "31017", name: "Brown County" },
        { value: "31019", name: "Buffalo County" },
        { value: "31021", name: "Burt County" },
        { value: "31023", name: "Butler County" },
        { value: "31025", name: "Cass County" },
        { value: "31027", name: "Cedar County" },
        { value: "31029", name: "Chase County" },
        { value: "31031", name: "Cherry County" },
        { value: "31033", name: "Cheyenne County" },
        { value: "31035", name: "Clay County" },
        { value: "31037", name: "Colfax County" },
        { value: "31039", name: "Cuming County" },
        { value: "31041", name: "Custer County" },
        { value: "31043", name: "Dakota County" },
        { value: "31045", name: "Dawes County" },
        { value: "31047", name: "Dawson County" },
        { value: "31049", name: "Deuel County" },
        { value: "31051", name: "Dixon County" },
        { value: "31053", name: "Dodge County" },
        { value: "31055", name: "Douglas County" },
        { value: "31057", name: "Dundy County" },
        { value: "31059", name: "Fillmore County" },
        { value: "31061", name: "Franklin County" },
        { value: "31063", name: "Frontier County" },
        { value: "31065", name: "Furnas County" },
        { value: "31067", name: "Gage County" },
        { value: "31069", name: "Garden County" },
        { value: "31071", name: "Garfield County" },
        { value: "31073", name: "Gosper County" },
        { value: "31075", name: "Grant County" },
        { value: "31077", name: "Greeley County" },
        { value: "31079", name: "Hall County" },
        { value: "31081", name: "Hamilton County" },
        { value: "31083", name: "Harlan County" },
        { value: "31085", name: "Hayes County" },
        { value: "31087", name: "Hitchcock County" },
        { value: "31089", name: "Holt County" },
        { value: "31091", name: "Hooker County" },
        { value: "31093", name: "Howard County" },
        { value: "31095", name: "Jefferson County" },
        { value: "31097", name: "Johnson County" },
        { value: "31099", name: "Kearney County" },
        { value: "31101", name: "Keith County" },
        { value: "31103", name: "Keya Paha County" },
        { value: "31105", name: "Kimball County" },
        { value: "31107", name: "Knox County" },
        { value: "31109", name: "Lancaster County" },
        { value: "31111", name: "Lincoln County" },
        { value: "31113", name: "Logan County" },
        { value: "31115", name: "Loup County" },
        { value: "31117", name: "McPherson County" },
        { value: "31119", name: "Madison County" },
        { value: "31121", name: "Merrick County" },
        { value: "31123", name: "Morrill County" },
        { value: "31125", name: "Nance County" },
        { value: "31127", name: "Nemaha County" },
        { value: "31129", name: "Nuckolls County" },
        { value: "31131", name: "Otoe County" },
        { value: "31133", name: "Pawnee County" },
        { value: "31135", name: "Perkins County" },
        { value: "31137", name: "Phelps County" },
        { value: "31139", name: "Pierce County" },
        { value: "31141", name: "Platte County" },
        { value: "31143", name: "Polk County" },
        { value: "31145", name: "Red Willow County" },
        { value: "31147", name: "Richardson County" },
        { value: "31149", name: "Rock County" },
        { value: "31151", name: "Saline County" },
        { value: "31153", name: "Sarpy County" },
        { value: "31155", name: "Saunders County" },
        { value: "31157", name: "Scotts Bluff County" },
        { value: "31159", name: "Seward County" },
        { value: "31161", name: "Sheridan County" },
        { value: "31163", name: "Sherman County" },
        { value: "31165", name: "Sioux County" },
        { value: "31167", name: "Stanton County" },
        { value: "31169", name: "Thayer County" },
        { value: "31171", name: "Thomas County" },
        { value: "31173", name: "Thurston County" },
        { value: "31175", name: "Valley County" },
        { value: "31177", name: "Washington County" },
        { value: "31179", name: "Wayne County" },
        { value: "31181", name: "Webster County" },
        { value: "31183", name: "Wheeler County" },
        { value: "31185", name: "York County" },
        { value: "32000", name: "Nevada" },
        { value: "32001", name: "Churchill County" },
        { value: "32003", name: "Clark County" },
        { value: "32005", name: "Douglas County" },
        { value: "32007", name: "Elko County" },
        { value: "32009", name: "Esmeralda County" },
        { value: "32011", name: "Eureka County" },
        { value: "32013", name: "Humboldt County" },
        { value: "32015", name: "Lander County" },
        { value: "32017", name: "Lincoln County" },
        { value: "32019", name: "Lyon County" },
        { value: "32021", name: "Mineral County" },
        { value: "32023", name: "Nye County" },
        { value: "32027", name: "Pershing County" },
        { value: "32029", name: "Storey County" },
        { value: "32031", name: "Washoe County" },
        { value: "32033", name: "White Pine County" },
        { value: "32510", name: "Carson City" },
        { value: "33000", name: "New Hampshire" },
        { value: "33001", name: "Belknap County" },
        { value: "33003", name: "Carroll County" },
        { value: "33005", name: "Cheshire County" },
        { value: "33007", name: "Coos County" },
        { value: "33009", name: "Grafton County" },
        { value: "33011", name: "Hillsborough County" },
        { value: "33013", name: "Merrimack County" },
        { value: "33015", name: "Rockingham County" },
        { value: "33017", name: "Strafford County" },
        { value: "33019", name: "Sullivan County" },
        { value: "34000", name: "New Jersey" },
        { value: "34001", name: "Atlantic County" },
        { value: "34003", name: "Bergen County" },
        { value: "34005", name: "Burlington County" },
        { value: "34007", name: "Camden County" },
        { value: "34009", name: "Cape May County" },
        { value: "34011", name: "Cumberland County" },
        { value: "34013", name: "Essex County" },
        { value: "34015", name: "Gloucester County" },
        { value: "34017", name: "Hudson County" },
        { value: "34019", name: "Hunterdon County" },
        { value: "34021", name: "Mercer County" },
        { value: "34023", name: "Middlesex County" },
        { value: "34025", name: "Monmouth County" },
        { value: "34027", name: "Morris County" },
        { value: "34029", name: "Ocean County" },
        { value: "34031", name: "Passaic County" },
        { value: "34033", name: "Salem County" },
        { value: "34035", name: "Somerset County" },
        { value: "34037", name: "Sussex County" },
        { value: "34039", name: "Union County" },
        { value: "34041", name: "Warren County" },
        { value: "35000", name: "New Mexico" },
        { value: "35001", name: "Bernalillo County" },
        { value: "35003", name: "Catron County" },
        { value: "35005", name: "Chaves County" },
        { value: "35006", name: "Cibola County" },
        { value: "35007", name: "Colfax County" },
        { value: "35009", name: "Curry County" },
        { value: "35011", name: "DeBaca County" },
        { value: "35013", name: "Dona Ana County" },
        { value: "35015", name: "Eddy County" },
        { value: "35017", name: "Grant County" },
        { value: "35019", name: "Guadalupe County" },
        { value: "35021", name: "Harding County" },
        { value: "35023", name: "Hidalgo County" },
        { value: "35025", name: "Lea County" },
        { value: "35027", name: "Lincoln County" },
        { value: "35028", name: "Los Alamos County" },
        { value: "35029", name: "Luna County" },
        { value: "35031", name: "McKinley County" },
        { value: "35033", name: "Mora County" },
        { value: "35035", name: "Otero County" },
        { value: "35037", name: "Quay County" },
        { value: "35039", name: "Rio Arriba County" },
        { value: "35041", name: "Roosevelt County" },
        { value: "35043", name: "Sandoval County" },
        { value: "35045", name: "San Juan County" },
        { value: "35047", name: "San Miguel County" },
        { value: "35049", name: "Santa Fe County" },
        { value: "35051", name: "Sierra County" },
        { value: "35053", name: "Socorro County" },
        { value: "35055", name: "Taos County" },
        { value: "35057", name: "Torrance County" },
        { value: "35059", name: "Union County" },
        { value: "35061", name: "Valencia County" },
        { value: "36000", name: "New York" },
        { value: "36001", name: "Albany County" },
        { value: "36003", name: "Allegany County" },
        { value: "36005", name: "Bronx County" },
        { value: "36007", name: "Broome County" },
        { value: "36009", name: "Cattaraugus County" },
        { value: "36011", name: "Cayuga County" },
        { value: "36013", name: "Chautauqua County" },
        { value: "36015", name: "Chemung County" },
        { value: "36017", name: "Chenango County" },
        { value: "36019", name: "Clinton County" },
        { value: "36021", name: "Columbia County" },
        { value: "36023", name: "Cortland County" },
        { value: "36025", name: "Delaware County" },
        { value: "36027", name: "Dutchess County" },
        { value: "36029", name: "Erie County" },
        { value: "36031", name: "Essex County" },
        { value: "36033", name: "Franklin County" },
        { value: "36035", name: "Fulton County" },
        { value: "36037", name: "Genesee County" },
        { value: "36039", name: "Greene County" },
        { value: "36041", name: "Hamilton County" },
        { value: "36043", name: "Herkimer County" },
        { value: "36045", name: "Jefferson County" },
        { value: "36047", name: "Kings County" },
        { value: "36049", name: "Lewis County" },
        { value: "36051", name: "Livingston County" },
        { value: "36053", name: "Madison County" },
        { value: "36055", name: "Monroe County" },
        { value: "36057", name: "Montgomery County" },
        { value: "36059", name: "Nassau County" },
        { value: "36061", name: "New York County" },
        { value: "36063", name: "Niagara County" },
        { value: "36065", name: "Oneida County" },
        { value: "36067", name: "Onondaga County" },
        { value: "36069", name: "Ontario County" },
        { value: "36071", name: "Orange County" },
        { value: "36073", name: "Orleans County" },
        { value: "36075", name: "Oswego County" },
        { value: "36077", name: "Otsego County" },
        { value: "36079", name: "Putnam County" },
        { value: "36081", name: "Queens County" },
        { value: "36083", name: "Rensselaer County" },
        { value: "36085", name: "Richmond County" },
        { value: "36087", name: "Rockland County" },
        { value: "36089", name: "St. Lawrence County" },
        { value: "36091", name: "Saratoga County" },
        { value: "36093", name: "Schenectady County" },
        { value: "36095", name: "Schoharie County" },
        { value: "36097", name: "Schuyler County" },
        { value: "36099", name: "Seneca County" },
        { value: "36101", name: "Steuben County" },
        { value: "36103", name: "Suffolk County" },
        { value: "36105", name: "Sullivan County" },
        { value: "36107", name: "Tioga County" },
        { value: "36109", name: "Tompkins County" },
        { value: "36111", name: "Ulster County" },
        { value: "36113", name: "Warren County" },
        { value: "36115", name: "Washington County" },
        { value: "36117", name: "Wayne County" },
        { value: "36119", name: "Westchester County" },
        { value: "36121", name: "Wyoming County" },
        { value: "36123", name: "Yates County" },
        { value: "37000", name: "North Carolina" },
        { value: "37001", name: "Alamance County" },
        { value: "37003", name: "Alexander County" },
        { value: "37005", name: "Alleghany County" },
        { value: "37007", name: "Anson County" },
        { value: "37009", name: "Ashe County" },
        { value: "37011", name: "Avery County" },
        { value: "37013", name: "Beaufort County" },
        { value: "37015", name: "Bertie County" },
        { value: "37017", name: "Bladen County" },
        { value: "37019", name: "Brunswick County" },
        { value: "37021", name: "Buncombe County" },
        { value: "37023", name: "Burke County" },
        { value: "37025", name: "Cabarrus County" },
        { value: "37027", name: "Caldwell County" },
        { value: "37029", name: "Camden County" },
        { value: "37031", name: "Carteret County" },
        { value: "37033", name: "Caswell County" },
        { value: "37035", name: "Catawba County" },
        { value: "37037", name: "Chatham County" },
        { value: "37039", name: "Cherokee County" },
        { value: "37041", name: "Chowan County" },
        { value: "37043", name: "Clay County" },
        { value: "37045", name: "Cleveland County" },
        { value: "37047", name: "Columbus County" },
        { value: "37049", name: "Craven County" },
        { value: "37051", name: "Cumberland County" },
        { value: "37053", name: "Currituck County" },
        { value: "37055", name: "Dare County" },
        { value: "37057", name: "Davidson County" },
        { value: "37059", name: "Davie County" },
        { value: "37061", name: "Duplin County" },
        { value: "37063", name: "Durham County" },
        { value: "37065", name: "Edgecombe County" },
        { value: "37067", name: "Forsyth County" },
        { value: "37069", name: "Franklin County" },
        { value: "37071", name: "Gaston County" },
        { value: "37073", name: "Gates County" },
        { value: "37075", name: "Graham County" },
        { value: "37077", name: "Granville County" },
        { value: "37079", name: "Greene County" },
        { value: "37081", name: "Guilford County" },
        { value: "37083", name: "Halifax County" },
        { value: "37085", name: "Harnett County" },
        { value: "37087", name: "Haywood County" },
        { value: "37089", name: "Henderson County" },
        { value: "37091", name: "Hertford County" },
        { value: "37093", name: "Hoke County" },
        { value: "37095", name: "Hyde County" },
        { value: "37097", name: "Iredell County" },
        { value: "37099", name: "Jackson County" },
        { value: "37101", name: "Johnston County" },
        { value: "37103", name: "Jones County" },
        { value: "37105", name: "Lee County" },
        { value: "37107", name: "Lenoir County" },
        { value: "37109", name: "Lincoln County" },
        { value: "37111", name: "McDowell County" },
        { value: "37113", name: "Macon County" },
        { value: "37115", name: "Madison County" },
        { value: "37117", name: "Martin County" },
        { value: "37119", name: "Mecklenburg County" },
        { value: "37121", name: "Mitchell County" },
        { value: "37123", name: "Montgomery County" },
        { value: "37125", name: "Moore County" },
        { value: "37127", name: "Nash County" },
        { value: "37129", name: "New Hanover County" },
        { value: "37131", name: "Northampton County" },
        { value: "37133", name: "Onslow County" },
        { value: "37135", name: "Orange County" },
        { value: "37137", name: "Pamlico County" },
        { value: "37139", name: "Pasquotank County" },
        { value: "37141", name: "Pender County" },
        { value: "37143", name: "Perquimans County" },
        { value: "37145", name: "Person County" },
        { value: "37147", name: "Pitt County" },
        { value: "37149", name: "Polk County" },
        { value: "37151", name: "Randolph County" },
        { value: "37153", name: "Richmond County" },
        { value: "37155", name: "Robeson County" },
        { value: "37157", name: "Rockingham County" },
        { value: "37159", name: "Rowan County" },
        { value: "37161", name: "Rutherford County" },
        { value: "37163", name: "Sampson County" },
        { value: "37165", name: "Scotland County" },
        { value: "37167", name: "Stanly County" },
        { value: "37169", name: "Stokes County" },
        { value: "37171", name: "Surry County" },
        { value: "37173", name: "Swain County" },
        { value: "37175", name: "Transylvania County" },
        { value: "37177", name: "Tyrrell County" },
        { value: "37179", name: "Union County" },
        { value: "37181", name: "Vance County" },
        { value: "37183", name: "Wake County" },
        { value: "37185", name: "Warren County" },
        { value: "37187", name: "Washington County" },
        { value: "37189", name: "Watauga County" },
        { value: "37191", name: "Wayne County" },
        { value: "37193", name: "Wilkes County" },
        { value: "37195", name: "Wilson County" },
        { value: "37197", name: "Yadkin County" },
        { value: "37199", name: "Yancey County" },
        { value: "38000", name: "North Dakota" },
        { value: "38001", name: "Adams County" },
        { value: "38003", name: "Barnes County" },
        { value: "38005", name: "Benson County" },
        { value: "38007", name: "Billings County" },
        { value: "38009", name: "Bottineau County" },
        { value: "38011", name: "Bowman County" },
        { value: "38013", name: "Burke County" },
        { value: "38015", name: "Burleigh County" },
        { value: "38017", name: "Cass County" },
        { value: "38019", name: "Cavalier County" },
        { value: "38021", name: "Dickey County" },
        { value: "38023", name: "Divide County" },
        { value: "38025", name: "Dunn County" },
        { value: "38027", name: "Eddy County" },
        { value: "38029", name: "Emmons County" },
        { value: "38031", name: "Foster County" },
        { value: "38033", name: "Golden Valley County" },
        { value: "38035", name: "Grand Forks County" },
        { value: "38037", name: "Grant County" },
        { value: "38039", name: "Griggs County" },
        { value: "38041", name: "Hettinger County" },
        { value: "38043", name: "Kidder County" },
        { value: "38045", name: "LaMoure County" },
        { value: "38047", name: "Logan County" },
        { value: "38049", name: "McHenry County" },
        { value: "38051", name: "McIntosh County" },
        { value: "38053", name: "McKenzie County" },
        { value: "38055", name: "McLean County" },
        { value: "38057", name: "Mercer County" },
        { value: "38059", name: "Morton County" },
        { value: "38061", name: "Mountrail County" },
        { value: "38063", name: "Nelson County" },
        { value: "38065", name: "Oliver County" },
        { value: "38067", name: "Pembina County" },
        { value: "38069", name: "Pierce County" },
        { value: "38071", name: "Ramsey County" },
        { value: "38073", name: "Ransom County" },
        { value: "38075", name: "Renville County" },
        { value: "38077", name: "Richland County" },
        { value: "38079", name: "Rolette County" },
        { value: "38081", name: "Sargent County" },
        { value: "38083", name: "Sheridan County" },
        { value: "38085", name: "Sioux County" },
        { value: "38087", name: "Slope County" },
        { value: "38089", name: "Stark County" },
        { value: "38091", name: "Steele County" },
        { value: "38093", name: "Stutsman County" },
        { value: "38095", name: "Towner County" },
        { value: "38097", name: "Traill County" },
        { value: "38099", name: "Walsh County" },
        { value: "38101", name: "Ward County" },
        { value: "38103", name: "Wells County" },
        { value: "38105", name: "Williams County" },
        { value: "39000", name: "Ohio" },
        { value: "39001", name: "Adams County" },
        { value: "39003", name: "Allen County" },
        { value: "39005", name: "Ashland County" },
        { value: "39007", name: "Ashtabula County" },
        { value: "39009", name: "Athens County" },
        { value: "39011", name: "Auglaize County" },
        { value: "39013", name: "Belmont County" },
        { value: "39015", name: "Brown County" },
        { value: "39017", name: "Butler County" },
        { value: "39019", name: "Carroll County" },
        { value: "39021", name: "Champaign County" },
        { value: "39023", name: "Clark County" },
        { value: "39025", name: "Clermont County" },
        { value: "39027", name: "Clinton County" },
        { value: "39029", name: "Columbiana County" },
        { value: "39031", name: "Coshocton County" },
        { value: "39033", name: "Crawford County" },
        { value: "39035", name: "Cuyahoga County" },
        { value: "39037", name: "Darke County" },
        { value: "39039", name: "Defiance County" },
        { value: "39041", name: "Delaware County" },
        { value: "39043", name: "Erie County" },
        { value: "39045", name: "Fairfield County" },
        { value: "39047", name: "Fayette County" },
        { value: "39049", name: "Franklin County" },
        { value: "39051", name: "Fulton County" },
        { value: "39053", name: "Gallia County" },
        { value: "39055", name: "Geauga County" },
        { value: "39057", name: "Greene County" },
        { value: "39059", name: "Guernsey County" },
        { value: "39061", name: "Hamilton County" },
        { value: "39063", name: "Hancock County" },
        { value: "39065", name: "Hardin County" },
        { value: "39067", name: "Harrison County" },
        { value: "39069", name: "Henry County" },
        { value: "39071", name: "Highland County" },
        { value: "39073", name: "Hocking County" },
        { value: "39075", name: "Holmes County" },
        { value: "39077", name: "Huron County" },
        { value: "39079", name: "Jackson County" },
        { value: "39081", name: "Jefferson County" },
        { value: "39083", name: "Knox County" },
        { value: "39085", name: "Lake County" },
        { value: "39087", name: "Lawrence County" },
        { value: "39089", name: "Licking County" },
        { value: "39091", name: "Logan County" },
        { value: "39093", name: "Lorain County" },
        { value: "39095", name: "Lucas County" },
        { value: "39097", name: "Madison County" },
        { value: "39099", name: "Mahoning County" },
        { value: "39101", name: "Marion County" },
        { value: "39103", name: "Medina County" },
        { value: "39105", name: "Meigs County" },
        { value: "39107", name: "Mercer County" },
        { value: "39109", name: "Miami County" },
        { value: "39111", name: "Monroe County" },
        { value: "39113", name: "Montgomery County" },
        { value: "39115", name: "Morgan County" },
        { value: "39117", name: "Morrow County" },
        { value: "39119", name: "Muskingum County" },
        { value: "39121", name: "Noble County" },
        { value: "39123", name: "Ottawa County" },
        { value: "39125", name: "Paulding County" },
        { value: "39127", name: "Perry County" },
        { value: "39129", name: "Pickaway County" },
        { value: "39131", name: "Pike County" },
        { value: "39133", name: "Portage County" },
        { value: "39135", name: "Preble County" },
        { value: "39137", name: "Putnam County" },
        { value: "39139", name: "Richland County" },
        { value: "39141", name: "Ross County" },
        { value: "39143", name: "Sandusky County" },
        { value: "39145", name: "Scioto County" },
        { value: "39147", name: "Seneca County" },
        { value: "39149", name: "Shelby County" },
        { value: "39151", name: "Stark County" },
        { value: "39153", name: "Summit County" },
        { value: "39155", name: "Trumbull County" },
        { value: "39157", name: "Tuscarawas County" },
        { value: "39159", name: "Union County" },
        { value: "39161", name: "Van Wert County" },
        { value: "39163", name: "Vinton County" },
        { value: "39165", name: "Warren County" },
        { value: "39167", name: "Washington County" },
        { value: "39169", name: "Wayne County" },
        { value: "39171", name: "Williams County" },
        { value: "39173", name: "Wood County" },
        { value: "39175", name: "Wyandot County" },
        { value: "40000", name: "Oklahoma" },
        { value: "40001", name: "Adair County" },
        { value: "40003", name: "Alfalfa County" },
        { value: "40005", name: "Atoka County" },
        { value: "40007", name: "Beaver County" },
        { value: "40009", name: "Beckham County" },
        { value: "40011", name: "Blaine County" },
        { value: "40013", name: "Bryan County" },
        { value: "40015", name: "Caddo County" },
        { value: "40017", name: "Canadian County" },
        { value: "40019", name: "Carter County" },
        { value: "40021", name: "Cherokee County" },
        { value: "40023", name: "Choctaw County" },
        { value: "40025", name: "Cimarron County" },
        { value: "40027", name: "Cleveland County" },
        { value: "40029", name: "Coal County" },
        { value: "40031", name: "Comanche County" },
        { value: "40033", name: "Cotton County" },
        { value: "40035", name: "Craig County" },
        { value: "40037", name: "Creek County" },
        { value: "40039", name: "Custer County" },
        { value: "40041", name: "Delaware County" },
        { value: "40043", name: "Dewey County" },
        { value: "40045", name: "Ellis County" },
        { value: "40047", name: "Garfield County" },
        { value: "40049", name: "Garvin County" },
        { value: "40051", name: "Grady County" },
        { value: "40053", name: "Grant County" },
        { value: "40055", name: "Greer County" },
        { value: "40057", name: "Harmon County" },
        { value: "40059", name: "Harper County" },
        { value: "40061", name: "Haskell County" },
        { value: "40063", name: "Hughes County" },
        { value: "40065", name: "Jackson County" },
        { value: "40067", name: "Jefferson County" },
        { value: "40069", name: "Johnston County" },
        { value: "40071", name: "Kay County" },
        { value: "40073", name: "Kingfisher County" },
        { value: "40075", name: "Kiowa County" },
        { value: "40077", name: "Latimer County" },
        { value: "40079", name: "Le Flore County" },
        { value: "40081", name: "Lincoln County" },
        { value: "40083", name: "Logan County" },
        { value: "40085", name: "Love County" },
        { value: "40087", name: "McClain County" },
        { value: "40089", name: "McCurtain County" },
        { value: "40091", name: "McIntosh County" },
        { value: "40093", name: "Major County" },
        { value: "40095", name: "Marshall County" },
        { value: "40097", name: "Mayes County" },
        { value: "40099", name: "Murray County" },
        { value: "40101", name: "Muskogee County" },
        { value: "40103", name: "Noble County" },
        { value: "40105", name: "Nowata County" },
        { value: "40107", name: "Okfuskee County" },
        { value: "40109", name: "Oklahoma County" },
        { value: "40111", name: "Okmulgee County" },
        { value: "40113", name: "Osage County" },
        { value: "40115", name: "Ottawa County" },
        { value: "40117", name: "Pawnee County" },
        { value: "40119", name: "Payne County" },
        { value: "40121", name: "Pittsburg County" },
        { value: "40123", name: "Pontotoc County" },
        { value: "40125", name: "Pottawatomie County" },
        { value: "40127", name: "Pushmataha County" },
        { value: "40129", name: "Roger Mills County" },
        { value: "40131", name: "Rogers County" },
        { value: "40133", name: "Seminole County" },
        { value: "40135", name: "Sequoyah County" },
        { value: "40137", name: "Stephens County" },
        { value: "40139", name: "Texas County" },
        { value: "40141", name: "Tillman County" },
        { value: "40143", name: "Tulsa County" },
        { value: "40145", name: "Wagoner County" },
        { value: "40147", name: "Washington County" },
        { value: "40149", name: "Washita County" },
        { value: "40151", name: "Woods County" },
        { value: "40153", name: "Woodward County" },
        { value: "41000", name: "Oregon" },
        { value: "41001", name: "Baker County" },
        { value: "41003", name: "Benton County" },
        { value: "41005", name: "Clackamas County" },
        { value: "41007", name: "Clatsop County" },
        { value: "41009", name: "Columbia County" },
        { value: "41011", name: "Coos County" },
        { value: "41013", name: "Crook County" },
        { value: "41015", name: "Curry County" },
        { value: "41017", name: "Deschutes County" },
        { value: "41019", name: "Douglas County" },
        { value: "41021", name: "Gilliam County" },
        { value: "41023", name: "Grant County" },
        { value: "41025", name: "Harney County" },
        { value: "41027", name: "Hood River County" },
        { value: "41029", name: "Jackson County" },
        { value: "41031", name: "Jefferson County" },
        { value: "41033", name: "Josephine County" },
        { value: "41035", name: "Klamath County" },
        { value: "41037", name: "Lake County" },
        { value: "41039", name: "Lane County" },
        { value: "41041", name: "Lincoln County" },
        { value: "41043", name: "Linn County" },
        { value: "41045", name: "Malheur County" },
        { value: "41047", name: "Marion County" },
        { value: "41049", name: "Morrow County" },
        { value: "41051", name: "Multnomah County" },
        { value: "41053", name: "Polk County" },
        { value: "41055", name: "Sherman County" },
        { value: "41057", name: "Tillamook County" },
        { value: "41059", name: "Umatilla County" },
        { value: "41061", name: "Union County" },
        { value: "41063", name: "Wallowa County" },
        { value: "41065", name: "Wasco County" },
        { value: "41067", name: "Washington County" },
        { value: "41069", name: "Wheeler County" },
        { value: "41071", name: "Yamhill County" },
        { value: "42000", name: "Pennsylvania" },
        { value: "42001", name: "Adams County" },
        { value: "42003", name: "Allegheny County" },
        { value: "42005", name: "Armstrong County" },
        { value: "42007", name: "Beaver County" },
        { value: "42009", name: "Bedford County" },
        { value: "42011", name: "Berks County" },
        { value: "42013", name: "Blair County" },
        { value: "42015", name: "Bradford County" },
        { value: "42017", name: "Bucks County" },
        { value: "42019", name: "Butler County" },
        { value: "42021", name: "Cambria County" },
        { value: "42023", name: "Cameron County" },
        { value: "42025", name: "Carbon County" },
        { value: "42027", name: "Centre County" },
        { value: "42029", name: "Chester County" },
        { value: "42031", name: "Clarion County" },
        { value: "42033", name: "Clearfield County" },
        { value: "42035", name: "Clinton County" },
        { value: "42037", name: "Columbia County" },
        { value: "42039", name: "Crawford County" },
        { value: "42041", name: "Cumberland County" },
        { value: "42043", name: "Dauphin County" },
        { value: "42045", name: "Delaware County" },
        { value: "42047", name: "Elk County" },
        { value: "42049", name: "Erie County" },
        { value: "42051", name: "Fayette County" },
        { value: "42053", name: "Forest County" },
        { value: "42055", name: "Franklin County" },
        { value: "42057", name: "Fulton County" },
        { value: "42059", name: "Greene County" },
        { value: "42061", name: "Huntingdon County" },
        { value: "42063", name: "Indiana County" },
        { value: "42065", name: "Jefferson County" },
        { value: "42067", name: "Juniata County" },
        { value: "42069", name: "Lackawanna County" },
        { value: "42071", name: "Lancaster County" },
        { value: "42073", name: "Lawrence County" },
        { value: "42075", name: "Lebanon County" },
        { value: "42077", name: "Lehigh County" },
        { value: "42079", name: "Luzerne County" },
        { value: "42081", name: "Lycoming County" },
        { value: "42083", name: "Mc Kean County" },
        { value: "42085", name: "Mercer County" },
        { value: "42087", name: "Mifflin County" },
        { value: "42089", name: "Monroe County" },
        { value: "42091", name: "Montgomery County" },
        { value: "42093", name: "Montour County" },
        { value: "42095", name: "Northampton County" },
        { value: "42097", name: "Northumberland County" },
        { value: "42099", name: "Perry County" },
        { value: "42101", name: "Philadelphia County" },
        { value: "42103", name: "Pike County" },
        { value: "42105", name: "Potter County" },
        { value: "42107", name: "Schuylkill County" },
        { value: "42109", name: "Snyder County" },
        { value: "42111", name: "Somerset County" },
        { value: "42113", name: "Sullivan County" },
        { value: "42115", name: "Susquehanna County" },
        { value: "42117", name: "Tioga County" },
        { value: "42119", name: "Union County" },
        { value: "42121", name: "Venango County" },
        { value: "42123", name: "Warren County" },
        { value: "42125", name: "Washington County" },
        { value: "42127", name: "Wayne County" },
        { value: "42129", name: "Westmoreland County" },
        { value: "42131", name: "Wyoming County" },
        { value: "42133", name: "York County" },
        { value: "44000", name: "Rhode Island" },
        { value: "44001", name: "Bristol County" },
        { value: "44003", name: "Kent County" },
        { value: "44005", name: "Newport County" },
        { value: "44007", name: "Providence County" },
        { value: "44009", name: "Washington County" },
        { value: "45000", name: "South Carolina" },
        { value: "45001", name: "Abbeville County" },
        { value: "45003", name: "Aiken County" },
        { value: "45005", name: "Allendale County" },
        { value: "45007", name: "Anderson County" },
        { value: "45009", name: "Bamberg County" },
        { value: "45011", name: "Barnwell County" },
        { value: "45013", name: "Beaufort County" },
        { value: "45015", name: "Berkeley County" },
        { value: "45017", name: "Calhoun County" },
        { value: "45019", name: "Charleston County" },
        { value: "45021", name: "Cherokee County" },
        { value: "45023", name: "Chester County" },
        { value: "45025", name: "Chesterfield County" },
        { value: "45027", name: "Clarendon County" },
        { value: "45029", name: "Colleton County" },
        { value: "45031", name: "Darlington County" },
        { value: "45033", name: "Dillon County" },
        { value: "45035", name: "Dorchester County" },
        { value: "45037", name: "Edgefield County" },
        { value: "45039", name: "Fairfield County" },
        { value: "45041", name: "Florence County" },
        { value: "45043", name: "Georgetown County" },
        { value: "45045", name: "Greenville County" },
        { value: "45047", name: "Greenwood County" },
        { value: "45049", name: "Hampton County" },
        { value: "45051", name: "Horry County" },
        { value: "45053", name: "Jasper County" },
        { value: "45055", name: "Kershaw County" },
        { value: "45057", name: "Lancaster County" },
        { value: "45059", name: "Laurens County" },
        { value: "45061", name: "Lee County" },
        { value: "45063", name: "Lexington County" },
        { value: "45065", name: "McCormick County" },
        { value: "45067", name: "Marion County" },
        { value: "45069", name: "Marlboro County" },
        { value: "45071", name: "Newberry County" },
        { value: "45073", name: "Oconee County" },
        { value: "45075", name: "Orangeburg County" },
        { value: "45077", name: "Pickens County" },
        { value: "45079", name: "Richland County" },
        { value: "45081", name: "Saluda County" },
        { value: "45083", name: "Spartanburg County" },
        { value: "45085", name: "Sumter County" },
        { value: "45087", name: "Union County" },
        { value: "45089", name: "Williamsburg County" },
        { value: "45091", name: "York County" },
        { value: "46000", name: "South Dakota" },
        { value: "46003", name: "Aurora County" },
        { value: "46005", name: "Beadle County" },
        { value: "46007", name: "Bennett County" },
        { value: "46009", name: "Bon Homme County" },
        { value: "46011", name: "Brookings County" },
        { value: "46013", name: "Brown County" },
        { value: "46015", name: "Brule County" },
        { value: "46017", name: "Buffalo County" },
        { value: "46019", name: "Butte County" },
        { value: "46021", name: "Campbell County" },
        { value: "46023", name: "Charles Mix County" },
        { value: "46025", name: "Clark County" },
        { value: "46027", name: "Clay County" },
        { value: "46029", name: "Codington County" },
        { value: "46031", name: "Corson County" },
        { value: "46033", name: "Custer County" },
        { value: "46035", name: "Davison County" },
        { value: "46037", name: "Day County" },
        { value: "46039", name: "Deuel County" },
        { value: "46041", name: "Dewey County" },
        { value: "46043", name: "Douglas County" },
        { value: "46045", name: "Edmunds County" },
        { value: "46047", name: "Fall River County" },
        { value: "46049", name: "Faulk County" },
        { value: "46051", name: "Grant County" },
        { value: "46053", name: "Gregory County" },
        { value: "46055", name: "Haakon County" },
        { value: "46057", name: "Hamlin County" },
        { value: "46059", name: "Hand County" },
        { value: "46061", name: "Hanson County" },
        { value: "46063", name: "Harding County" },
        { value: "46065", name: "Hughes County" },
        { value: "46067", name: "Hutchinson County" },
        { value: "46069", name: "Hyde County" },
        { value: "46071", name: "Jackson County" },
        { value: "46073", name: "Jerauld County" },
        { value: "46075", name: "Jones County" },
        { value: "46077", name: "Kingsbury County" },
        { value: "46079", name: "Lake County" },
        { value: "46081", name: "Lawrence County" },
        { value: "46083", name: "Lincoln County" },
        { value: "46085", name: "Lyman County" },
        { value: "46087", name: "McCook County" },
        { value: "46089", name: "McPherson County" },
        { value: "46091", name: "Marshall County" },
        { value: "46093", name: "Meade County" },
        { value: "46095", name: "Mellette County" },
        { value: "46097", name: "Miner County" },
        { value: "46099", name: "Minnehaha County" },
        { value: "46101", name: "Moody County" },
        { value: "46103", name: "Pennington County" },
        { value: "46105", name: "Perkins County" },
        { value: "46107", name: "Potter County" },
        { value: "46109", name: "Roberts County" },
        { value: "46111", name: "Sanborn County" },
        { value: "46113", name: "Shannon County" },
        { value: "46115", name: "Spink County" },
        { value: "46117", name: "Stanley County" },
        { value: "46119", name: "Sully County" },
        { value: "46121", name: "Todd County" },
        { value: "46123", name: "Tripp County" },
        { value: "46125", name: "Turner County" },
        { value: "46127", name: "Union County" },
        { value: "46129", name: "Walworth County" },
        { value: "46135", name: "Yankton County" },
        { value: "46137", name: "Ziebach County" },
        { value: "47000", name: "Tennessee" },
        { value: "47001", name: "Anderson County" },
        { value: "47003", name: "Bedford County" },
        { value: "47005", name: "Benton County" },
        { value: "47007", name: "Bledsoe County" },
        { value: "47009", name: "Blount County" },
        { value: "47011", name: "Bradley County" },
        { value: "47013", name: "Campbell County" },
        { value: "47015", name: "Cannon County" },
        { value: "47017", name: "Carroll County" },
        { value: "47019", name: "Carter County" },
        { value: "47021", name: "Cheatham County" },
        { value: "47023", name: "Chester County" },
        { value: "47025", name: "Claiborne County" },
        { value: "47027", name: "Clay County" },
        { value: "47029", name: "Cocke County" },
        { value: "47031", name: "Coffee County" },
        { value: "47033", name: "Crockett County" },
        { value: "47035", name: "Cumberland County" },
        { value: "47037", name: "Davidson County" },
        { value: "47039", name: "Decatur County" },
        { value: "47041", name: "DeKalb County" },
        { value: "47043", name: "Dickson County" },
        { value: "47045", name: "Dyer County" },
        { value: "47047", name: "Fayette County" },
        { value: "47049", name: "Fentress County" },
        { value: "47051", name: "Franklin County" },
        { value: "47053", name: "Gibson County" },
        { value: "47055", name: "Giles County" },
        { value: "47057", name: "Grainger County" },
        { value: "47059", name: "Greene County" },
        { value: "47061", name: "Grundy County" },
        { value: "47063", name: "Hamblen County" },
        { value: "47065", name: "Hamilton County" },
        { value: "47067", name: "Hancock County" },
        { value: "47069", name: "Hardeman County" },
        { value: "47071", name: "Hardin County" },
        { value: "47073", name: "Hawkins County" },
        { value: "47075", name: "Haywood County" },
        { value: "47077", name: "Henderson County" },
        { value: "47079", name: "Henry County" },
        { value: "47081", name: "Hickman County" },
        { value: "47083", name: "Houston County" },
        { value: "47085", name: "Humphreys County" },
        { value: "47087", name: "Jackson County" },
        { value: "47089", name: "Jefferson County" },
        { value: "47091", name: "Johnson County" },
        { value: "47093", name: "Knox County" },
        { value: "47095", name: "Lake County" },
        { value: "47097", name: "Lauderdale County" },
        { value: "47099", name: "Lawrence County" },
        { value: "47101", name: "Lewis County" },
        { value: "47103", name: "Lincoln County" },
        { value: "47105", name: "Loudon County" },
        { value: "47107", name: "McMinn County" },
        { value: "47109", name: "McNairy County" },
        { value: "47111", name: "Macon County" },
        { value: "47113", name: "Madison County" },
        { value: "47115", name: "Marion County" },
        { value: "47117", name: "Marshall County" },
        { value: "47119", name: "Maury County" },
        { value: "47121", name: "Meigs County" },
        { value: "47123", name: "Monroe County" },
        { value: "47125", name: "Montgomery County" },
        { value: "47127", name: "Moore County" },
        { value: "47129", name: "Morgan County" },
        { value: "47131", name: "Obion County" },
        { value: "47133", name: "Overton County" },
        { value: "47135", name: "Perry County" },
        { value: "47137", name: "Pickett County" },
        { value: "47139", name: "Polk County" },
        { value: "47141", name: "Putnam County" },
        { value: "47143", name: "Rhea County" },
        { value: "47145", name: "Roane County" },
        { value: "47147", name: "Robertson County" },
        { value: "47149", name: "Rutherford County" },
        { value: "47151", name: "Scott County" },
        { value: "47153", name: "Sequatchie County" },
        { value: "47155", name: "Sevier County" },
        { value: "47157", name: "Shelby County" },
        { value: "47159", name: "Smith County" },
        { value: "47161", name: "Stewart County" },
        { value: "47163", name: "Sullivan County" },
        { value: "47165", name: "Sumner County" },
        { value: "47167", name: "Tipton County" },
        { value: "47169", name: "Trousdale County" },
        { value: "47171", name: "Unicoi County" },
        { value: "47173", name: "Union County" },
        { value: "47175", name: "Van Buren County" },
        { value: "47177", name: "Warren County" },
        { value: "47179", name: "Washington County" },
        { value: "47181", name: "Wayne County" },
        { value: "47183", name: "Weakley County" },
        { value: "47185", name: "White County" },
        { value: "47187", name: "Williamson County" },
        { value: "47189", name: "Wilson County" },
        { value: "48000", name: "Texas" },
        { value: "48001", name: "Anderson County" },
        { value: "48003", name: "Andrews County" },
        { value: "48005", name: "Angelina County" },
        { value: "48007", name: "Aransas County" },
        { value: "48009", name: "Archer County" },
        { value: "48011", name: "Armstrong County" },
        { value: "48013", name: "Atascosa County" },
        { value: "48015", name: "Austin County" },
        { value: "48017", name: "Bailey County" },
        { value: "48019", name: "Bandera County" },
        { value: "48021", name: "Bastrop County" },
        { value: "48023", name: "Baylor County" },
        { value: "48025", name: "Bee County" },
        { value: "48027", name: "Bell County" },
        { value: "48029", name: "Bexar County" },
        { value: "48031", name: "Blanco County" },
        { value: "48033", name: "Borden County" },
        { value: "48035", name: "Bosque County" },
        { value: "48037", name: "Bowie County" },
        { value: "48039", name: "Brazoria County" },
        { value: "48041", name: "Brazos County" },
        { value: "48043", name: "Brewster County" },
        { value: "48045", name: "Briscoe County" },
        { value: "48047", name: "Brooks County" },
        { value: "48049", name: "Brown County" },
        { value: "48051", name: "Burleson County" },
        { value: "48053", name: "Burnet County" },
        { value: "48055", name: "Caldwell County" },
        { value: "48057", name: "Calhoun County" },
        { value: "48059", name: "Callahan County" },
        { value: "48061", name: "Cameron County" },
        { value: "48063", name: "Camp County" },
        { value: "48065", name: "Carson County" },
        { value: "48067", name: "Cass County" },
        { value: "48069", name: "Castro County" },
        { value: "48071", name: "Chambers County" },
        { value: "48073", name: "Cherokee County" },
        { value: "48075", name: "Childress County" },
        { value: "48077", name: "Clay County" },
        { value: "48079", name: "Cochran County" },
        { value: "48081", name: "Coke County" },
        { value: "48083", name: "Coleman County" },
        { value: "48085", name: "Collin County" },
        { value: "48087", name: "Collingsworth County" },
        { value: "48089", name: "Colorado County" },
        { value: "48091", name: "Comal County" },
        { value: "48093", name: "Comanche County" },
        { value: "48095", name: "Concho County" },
        { value: "48097", name: "Cooke County" },
        { value: "48099", name: "Coryell County" },
        { value: "48101", name: "Cottle County" },
        { value: "48103", name: "Crane County" },
        { value: "48105", name: "Crockett County" },
        { value: "48107", name: "Crosby County" },
        { value: "48109", name: "Culberson County" },
        { value: "48111", name: "Dallam County" },
        { value: "48113", name: "Dallas County" },
        { value: "48115", name: "Dawson County" },
        { value: "48117", name: "Deaf Smith County" },
        { value: "48119", name: "Delta County" },
        { value: "48121", name: "Denton County" },
        { value: "48123", name: "DeWitt County" },
        { value: "48125", name: "Dickens County" },
        { value: "48127", name: "Dimmit County" },
        { value: "48129", name: "Donley County" },
        { value: "48131", name: "Duval County" },
        { value: "48133", name: "Eastland County" },
        { value: "48135", name: "Ector County" },
        { value: "48137", name: "Edwards County" },
        { value: "48139", name: "Ellis County" },
        { value: "48141", name: "El Paso County" },
        { value: "48143", name: "Erath County" },
        { value: "48145", name: "Falls County" },
        { value: "48147", name: "Fannin County" },
        { value: "48149", name: "Fayette County" },
        { value: "48151", name: "Fisher County" },
        { value: "48153", name: "Floyd County" },
        { value: "48155", name: "Foard County" },
        { value: "48157", name: "Fort Bend County" },
        { value: "48159", name: "Franklin County" },
        { value: "48161", name: "Freestone County" },
        { value: "48163", name: "Frio County" },
        { value: "48165", name: "Gaines County" },
        { value: "48167", name: "Galveston County" },
        { value: "48169", name: "Garza County" },
        { value: "48171", name: "Gillespie County" },
        { value: "48173", name: "Glasscock County" },
        { value: "48175", name: "Goliad County" },
        { value: "48177", name: "Gonzales County" },
        { value: "48179", name: "Gray County" },
        { value: "48181", name: "Grayson County" },
        { value: "48183", name: "Gregg County" },
        { value: "48185", name: "Grimes County" },
        { value: "48187", name: "Guadalupe County" },
        { value: "48189", name: "Hale County" },
        { value: "48191", name: "Hall County" },
        { value: "48193", name: "Hamilton County" },
        { value: "48195", name: "Hansford County" },
        { value: "48197", name: "Hardeman County" },
        { value: "48199", name: "Hardin County" },
        { value: "48201", name: "Harris County" },
        { value: "48203", name: "Harrison County" },
        { value: "48205", name: "Hartley County" },
        { value: "48207", name: "Haskell County" },
        { value: "48209", name: "Hays County" },
        { value: "48211", name: "Hemphill County" },
        { value: "48213", name: "Henderson County" },
        { value: "48215", name: "Hidalgo County" },
        { value: "48217", name: "Hill County" },
        { value: "48219", name: "Hockley County" },
        { value: "48221", name: "Hood County" },
        { value: "48223", name: "Hopkins County" },
        { value: "48225", name: "Houston County" },
        { value: "48227", name: "Howard County" },
        { value: "48229", name: "Hudspeth County" },
        { value: "48231", name: "Hunt County" },
        { value: "48233", name: "Hutchinson County" },
        { value: "48235", name: "Irion County" },
        { value: "48237", name: "Jack County" },
        { value: "48239", name: "Jackson County" },
        { value: "48241", name: "Jasper County" },
        { value: "48243", name: "Jeff Davis County" },
        { value: "48245", name: "Jefferson County" },
        { value: "48247", name: "Jim Hogg County" },
        { value: "48249", name: "Jim Wells County" },
        { value: "48251", name: "Johnson County" },
        { value: "48253", name: "Jones County" },
        { value: "48255", name: "Karnes County" },
        { value: "48257", name: "Kaufman County" },
        { value: "48259", name: "Kendall County" },
        { value: "48261", name: "Kenedy County" },
        { value: "48263", name: "Kent County" },
        { value: "48265", name: "Kerr County" },
        { value: "48267", name: "Kimble County" },
        { value: "48269", name: "King County" },
        { value: "48271", name: "Kinney County" },
        { value: "48273", name: "Kleberg County" },
        { value: "48275", name: "Knox County" },
        { value: "48277", name: "Lamar County" },
        { value: "48279", name: "Lamb County" },
        { value: "48281", name: "Lampasas County" },
        { value: "48283", name: "La Salle County" },
        { value: "48285", name: "Lavaca County" },
        { value: "48287", name: "Lee County" },
        { value: "48289", name: "Leon County" },
        { value: "48291", name: "Liberty County" },
        { value: "48293", name: "Limestone County" },
        { value: "48295", name: "Lipscomb County" },
        { value: "48297", name: "Live Oak County" },
        { value: "48299", name: "Llano County" },
        { value: "48301", name: "Loving County" },
        { value: "48303", name: "Lubbock County" },
        { value: "48305", name: "Lynn County" },
        { value: "48307", name: "McCulloch County" },
        { value: "48309", name: "McLennan County" },
        { value: "48311", name: "McMullen County" },
        { value: "48313", name: "Madison County" },
        { value: "48315", name: "Marion County" },
        { value: "48317", name: "Martin County" },
        { value: "48319", name: "Mason County" },
        { value: "48321", name: "Matagorda County" },
        { value: "48323", name: "Maverick County" },
        { value: "48325", name: "Medina County" },
        { value: "48327", name: "Menard County" },
        { value: "48329", name: "Midland County" },
        { value: "48331", name: "Milam County" },
        { value: "48333", name: "Mills County" },
        { value: "48335", name: "Mitchell County" },
        { value: "48337", name: "Montague County" },
        { value: "48339", name: "Montgomery County" },
        { value: "48341", name: "Moore County" },
        { value: "48343", name: "Morris County" },
        { value: "48345", name: "Motley County" },
        { value: "48347", name: "Nacogdoches County" },
        { value: "48349", name: "Navarro County" },
        { value: "48351", name: "Newton County" },
        { value: "48353", name: "Nolan County" },
        { value: "48355", name: "Nueces County" },
        { value: "48357", name: "Ochiltree County" },
        { value: "48359", name: "Oldham County" },
        { value: "48361", name: "Orange County" },
        { value: "48363", name: "Palo Pinto County" },
        { value: "48365", name: "Panola County" },
        { value: "48367", name: "Parker County" },
        { value: "48369", name: "Parmer County" },
        { value: "48371", name: "Pecos County" },
        { value: "48373", name: "Polk County" },
        { value: "48375", name: "Potter County" },
        { value: "48377", name: "Presidio County" },
        { value: "48379", name: "Rains County" },
        { value: "48381", name: "Randall County" },
        { value: "48383", name: "Reagan County" },
        { value: "48385", name: "Real County" },
        { value: "48387", name: "Red River County" },
        { value: "48389", name: "Reeves County" },
        { value: "48391", name: "Refugio County" },
        { value: "48393", name: "Roberts County" },
        { value: "48395", name: "Robertson County" },
        { value: "48397", name: "Rockwall County" },
        { value: "48399", name: "Runnels County" },
        { value: "48401", name: "Rusk County" },
        { value: "48403", name: "Sabine County" },
        { value: "48405", name: "San Augustine County" },
        { value: "48407", name: "San Jacinto County" },
        { value: "48409", name: "San Patricio County" },
        { value: "48411", name: "San Saba County" },
        { value: "48413", name: "Schleicher County" },
        { value: "48415", name: "Scurry County" },
        { value: "48417", name: "Shackelford County" },
        { value: "48419", name: "Shelby County" },
        { value: "48421", name: "Sherman County" },
        { value: "48423", name: "Smith County" },
        { value: "48425", name: "Somervell County" },
        { value: "48427", name: "Starr County" },
        { value: "48429", name: "Stephens County" },
        { value: "48431", name: "Sterling County" },
        { value: "48433", name: "Stonewall County" },
        { value: "48435", name: "Sutton County" },
        { value: "48437", name: "Swisher County" },
        { value: "48439", name: "Tarrant County" },
        { value: "48441", name: "Taylor County" },
        { value: "48443", name: "Terrell County" },
        { value: "48445", name: "Terry County" },
        { value: "48447", name: "Throckmorton County" },
        { value: "48449", name: "Titus County" },
        { value: "48451", name: "Tom Green County" },
        { value: "48453", name: "Travis County" },
        { value: "48455", name: "Trinity County" },
        { value: "48457", name: "Tyler County" },
        { value: "48459", name: "Upshur County" },
        { value: "48461", name: "Upton County" },
        { value: "48463", name: "Uvalde County" },
        { value: "48465", name: "Val Verde County" },
        { value: "48467", name: "Van Zandt County" },
        { value: "48469", name: "Victoria County" },
        { value: "48471", name: "Walker County" },
        { value: "48473", name: "Waller County" },
        { value: "48475", name: "Ward County" },
        { value: "48477", name: "Washington County" },
        { value: "48479", name: "Webb County" },
        { value: "48481", name: "Wharton County" },
        { value: "48483", name: "Wheeler County" },
        { value: "48485", name: "Wichita County" },
        { value: "48487", name: "Wilbarger County" },
        { value: "48489", name: "Willacy County" },
        { value: "48491", name: "Williamson County" },
        { value: "48493", name: "Wilson County" },
        { value: "48495", name: "Winkler County" },
        { value: "48497", name: "Wise County" },
        { value: "48499", name: "Wood County" },
        { value: "48501", name: "Yoakum County" },
        { value: "48503", name: "Young County" },
        { value: "48505", name: "Zapata County" },
        { value: "48507", name: "Zavala County" },
        { value: "49000", name: "Utah" },
        { value: "49001", name: "Beaver County" },
        { value: "49003", name: "Box Elder County" },
        { value: "49005", name: "Cache County" },
        { value: "49007", name: "Carbon County" },
        { value: "49009", name: "Daggett County" },
        { value: "49011", name: "Davis County" },
        { value: "49013", name: "Duchesne County" },
        { value: "49015", name: "Emery County" },
        { value: "49017", name: "Garfield County" },
        { value: "49019", name: "Grand County" },
        { value: "49021", name: "Iron County" },
        { value: "49023", name: "Juab County" },
        { value: "49025", name: "Kane County" },
        { value: "49027", name: "Millard County" },
        { value: "49029", name: "Morgan County" },
        { value: "49031", name: "Piute County" },
        { value: "49033", name: "Rich County" },
        { value: "49035", name: "Salt Lake County" },
        { value: "49037", name: "San Juan County" },
        { value: "49039", name: "Sanpete County" },
        { value: "49041", name: "Sevier County" },
        { value: "49043", name: "Summit County" },
        { value: "49045", name: "Tooele County" },
        { value: "49047", name: "Uintah County" },
        { value: "49049", name: "Utah County" },
        { value: "49051", name: "Wasatch County" },
        { value: "49053", name: "Washington County" },
        { value: "49055", name: "Wayne County" },
        { value: "49057", name: "Weber County" },
        { value: "50000", name: "Vermont" },
        { value: "50001", name: "Addison County" },
        { value: "50003", name: "Bennington County" },
        { value: "50005", name: "Caledonia County" },
        { value: "50007", name: "Chittenden County" },
        { value: "50009", name: "Essex County" },
        { value: "50011", name: "Franklin County" },
        { value: "50013", name: "Grand Isle County" },
        { value: "50015", name: "Lamoille County" },
        { value: "50017", name: "Orange County" },
        { value: "50019", name: "Orleans County" },
        { value: "50021", name: "Rutland County" },
        { value: "50023", name: "Washington County" },
        { value: "50025", name: "Windham County" },
        { value: "50027", name: "Windsor County" },
        { value: "51000", name: "Virginia" },
        { value: "51001", name: "Accomack County" },
        { value: "51003", name: "Albemarle County" },
        { value: "51005", name: "Alleghany County" },
        { value: "51007", name: "Amelia County" },
        { value: "51009", name: "Amherst County" },
        { value: "51011", name: "Appomattox County" },
        { value: "51013", name: "Arlington County" },
        { value: "51015", name: "Augusta County" },
        { value: "51017", name: "Bath County" },
        { value: "51019", name: "Bedford County" },
        { value: "51021", name: "Bland County" },
        { value: "51023", name: "Botetourt County" },
        { value: "51025", name: "Brunswick County" },
        { value: "51027", name: "Buchanan County" },
        { value: "51029", name: "Buckingham County" },
        { value: "51031", name: "Campbell County" },
        { value: "51033", name: "Caroline County" },
        { value: "51035", name: "Carroll County" },
        { value: "51036", name: "Charles City County" },
        { value: "51037", name: "Charlotte County" },
        { value: "51041", name: "Chesterfield County" },
        { value: "51043", name: "Clarke County" },
        { value: "51045", name: "Craig County" },
        { value: "51047", name: "Culpeper County" },
        { value: "51049", name: "Cumberland County" },
        { value: "51051", name: "Dickenson County" },
        { value: "51053", name: "Dinwiddie County" },
        { value: "51057", name: "Essex County" },
        { value: "51059", name: "Fairfax County" },
        { value: "51061", name: "Fauquier County" },
        { value: "51063", name: "Floyd County" },
        { value: "51065", name: "Fluvanna County" },
        { value: "51067", name: "Franklin County" },
        { value: "51069", name: "Frederick County" },
        { value: "51071", name: "Giles County" },
        { value: "51073", name: "Gloucester County" },
        { value: "51075", name: "Goochland County" },
        { value: "51077", name: "Grayson County" },
        { value: "51079", name: "Greene County" },
        { value: "51081", name: "Greensville County" },
        { value: "51083", name: "Halifax County" },
        { value: "51085", name: "Hanover County" },
        { value: "51087", name: "Henrico County" },
        { value: "51089", name: "Henry County" },
        { value: "51091", name: "Highland County" },
        { value: "51093", name: "Isle of Wight County" },
        { value: "51095", name: "James City County" },
        { value: "51097", name: "King and Queen County" },
        { value: "51099", name: "King George County" },
        { value: "51101", name: "King William County" },
        { value: "51103", name: "Lancaster County" },
        { value: "51105", name: "Lee County" },
        { value: "51107", name: "Loudoun County" },
        { value: "51109", name: "Louisa County" },
        { value: "51111", name: "Lunenburg County" },
        { value: "51113", name: "Madison County" },
        { value: "51115", name: "Mathews County" },
        { value: "51117", name: "Mecklenburg County" },
        { value: "51119", name: "Middlesex County" },
        { value: "51121", name: "Montgomery County" },
        { value: "51125", name: "Nelson County" },
        { value: "51127", name: "New Kent County" },
        { value: "51131", name: "Northampton County" },
        { value: "51133", name: "Northumberland County" },
        { value: "51135", name: "Nottoway County" },
        { value: "51137", name: "Orange County" },
        { value: "51139", name: "Page County" },
        { value: "51141", name: "Patrick County" },
        { value: "51143", name: "Pittsylvania County" },
        { value: "51145", name: "Powhatan County" },
        { value: "51147", name: "Prince Edward County" },
        { value: "51149", name: "Prince George County" },
        { value: "51153", name: "Prince William County" },
        { value: "51155", name: "Pulaski County" },
        { value: "51157", name: "Rappahannock County" },
        { value: "51159", name: "Richmond County" },
        { value: "51161", name: "Roanoke County" },
        { value: "51163", name: "Rockbridge County" },
        { value: "51165", name: "Rockingham County" },
        { value: "51167", name: "Russell County" },
        { value: "51169", name: "Scott County" },
        { value: "51171", name: "Shenandoah County" },
        { value: "51173", name: "Smyth County" },
        { value: "51175", name: "Southampton County" },
        { value: "51177", name: "Spotsylvania County" },
        { value: "51179", name: "Stafford County" },
        { value: "51181", name: "Surry County" },
        { value: "51183", name: "Sussex County" },
        { value: "51185", name: "Tazewell County" },
        { value: "51187", name: "Warren County" },
        { value: "51191", name: "Washington County" },
        { value: "51193", name: "Westmoreland County" },
        { value: "51195", name: "Wise County" },
        { value: "51197", name: "Wythe County" },
        { value: "51199", name: "York County" },
        { value: "51510", name: "Alexandria city" },
        { value: "51515", name: "Bedford city" },
        { value: "51520", name: "Bristol city" },
        { value: "51530", name: "Buena Vista city" },
        { value: "51540", name: "Charlottesville city" },
        { value: "51550", name: "Chesapeake city" },
        { value: "51560", name: "Clifton Forge city" },
        { value: "51570", name: "Colonial Heights city" },
        { value: "51580", name: "Covington city" },
        { value: "51590", name: "Danville city" },
        { value: "51595", name: "Emporia city" },
        { value: "51600", name: "Fairfax city" },
        { value: "51610", name: "Falls Church city" },
        { value: "51620", name: "Franklin city" },
        { value: "51630", name: "Fredericksburg city" },
        { value: "51640", name: "Galax city" },
        { value: "51650", name: "Hampton city" },
        { value: "51660", name: "Harrisonburg city" },
        { value: "51670", name: "Hopewell city" },
        { value: "51678", name: "Lexington city" },
        { value: "51680", name: "Lynchburg city" },
        { value: "51683", name: "Manassas city" },
        { value: "51685", name: "Manassas Park city" },
        { value: "51690", name: "Martinsville city" },
        { value: "51700", name: "Newport News city" },
        { value: "51710", name: "Norfolk city" },
        { value: "51720", name: "Norton city" },
        { value: "51730", name: "Petersburg city" },
        { value: "51735", name: "Poquoson city" },
        { value: "51740", name: "Portsmouth city" },
        { value: "51750", name: "Radford city" },
        { value: "51760", name: "Richmond city" },
        { value: "51770", name: "Roanoke city" },
        { value: "51775", name: "Salem city" },
        { value: "51780", name: "South Boston city (After 1990, part of Halifax County)" },
        { value: "51790", name: "Staunton city" },
        { value: "51800", name: "Suffolk city" },
        { value: "51810", name: "Virginia Beach city" },
        { value: "51820", name: "Waynesboro city" },
        { value: "51830", name: "Williamsburg city" },
        { value: "51840", name: "Winchester city" },
        { value: "53000", name: "Washington" },
        { value: "53001", name: "Adams County" },
        { value: "53003", name: "Asotin County" },
        { value: "53005", name: "Benton County" },
        { value: "53007", name: "Chelan County" },
        { value: "53009", name: "Clallam County" },
        { value: "53011", name: "Clark County" },
        { value: "53013", name: "Columbia County" },
        { value: "53015", name: "Cowlitz County" },
        { value: "53017", name: "Douglas County" },
        { value: "53019", name: "Ferry County" },
        { value: "53021", name: "Franklin County" },
        { value: "53023", name: "Garfield County" },
        { value: "53025", name: "Grant County" },
        { value: "53027", name: "Grays Harbor County" },
        { value: "53029", name: "Island County" },
        { value: "53031", name: "Jefferson County" },
        { value: "53033", name: "King County" },
        { value: "53035", name: "Kitsap County" },
        { value: "53037", name: "Kittitas County" },
        { value: "53039", name: "Klickitat County" },
        { value: "53041", name: "Lewis County" },
        { value: "53043", name: "Lincoln County" },
        { value: "53045", name: "Mason County" },
        { value: "53047", name: "Okanogan County" },
        { value: "53049", name: "Pacific County" },
        { value: "53051", name: "Pend Oreille County" },
        { value: "53053", name: "Pierce County" },
        { value: "53055", name: "San Juan County" },
        { value: "53057", name: "Skagit County" },
        { value: "53059", name: "Skamania County" },
        { value: "53061", name: "Snohomish County" },
        { value: "53063", name: "Spokane County" },
        { value: "53065", name: "Stevens County" },
        { value: "53067", name: "Thurston County" },
        { value: "53069", name: "Wahkiakum County" },
        { value: "53071", name: "Walla Walla County" },
        { value: "53073", name: "Whatcom County" },
        { value: "53075", name: "Whitman County" },
        { value: "53077", name: "Yakima County" },
        { value: "54000", name: "West Virginia" },
        { value: "54001", name: "Barbour County" },
        { value: "54003", name: "Berkeley County" },
        { value: "54005", name: "Boone County" },
        { value: "54007", name: "Braxton County" },
        { value: "54009", name: "Brooke County" },
        { value: "54011", name: "Cabell County" },
        { value: "54013", name: "Calhoun County" },
        { value: "54015", name: "Clay County" },
        { value: "54017", name: "Doddridge County" },
        { value: "54019", name: "Fayette County" },
        { value: "54021", name: "Gilmer County" },
        { value: "54023", name: "Grant County" },
        { value: "54025", name: "Greenbrier County" },
        { value: "54027", name: "Hampshire County" },
        { value: "54029", name: "Hancock County" },
        { value: "54031", name: "Hardy County" },
        { value: "54033", name: "Harrison County" },
        { value: "54035", name: "Jackson County" },
        { value: "54037", name: "Jefferson County" },
        { value: "54039", name: "Kanawha County" },
        { value: "54041", name: "Lewis County" },
        { value: "54043", name: "Lincoln County" },
        { value: "54045", name: "Logan County" },
        { value: "54047", name: "McDowell County" },
        { value: "54049", name: "Marion County" },
        { value: "54051", name: "Marshall County" },
        { value: "54053", name: "Mason County" },
        { value: "54055", name: "Mercer County" },
        { value: "54057", name: "Mineral County" },
        { value: "54059", name: "Mingo County" },
        { value: "54061", name: "Monongalia County" },
        { value: "54063", name: "Monroe County" },
        { value: "54065", name: "Morgan County" },
        { value: "54067", name: "Nicholas County" },
        { value: "54069", name: "Ohio County" },
        { value: "54071", name: "Pendleton County" },
        { value: "54073", name: "Pleasants County" },
        { value: "54075", name: "Pocahontas County" },
        { value: "54077", name: "Preston County" },
        { value: "54079", name: "Putnam County" },
        { value: "54081", name: "Raleigh County" },
        { value: "54083", name: "Randolph County" },
        { value: "54085", name: "Ritchie County" },
        { value: "54087", name: "Roane County" },
        { value: "54089", name: "Summers County" },
        { value: "54091", name: "Taylor County" },
        { value: "54093", name: "Tucker County" },
        { value: "54095", name: "Tyler County" },
        { value: "54097", name: "Upshur County" },
        { value: "54099", name: "Wayne County" },
        { value: "54101", name: "Webster County" },
        { value: "54103", name: "Wetzel County" },
        { value: "54105", name: "Wirt County" },
        { value: "54107", name: "Wood County" },
        { value: "54109", name: "Wyoming County" },
        { value: "55000", name: "Wisconsin" },
        { value: "55001", name: "Adams County" },
        { value: "55003", name: "Ashland County" },
        { value: "55005", name: "Barron County" },
        { value: "55007", name: "Bayfield County" },
        { value: "55009", name: "Brown County" },
        { value: "55011", name: "Buffalo County" },
        { value: "55013", name: "Burnett County" },
        { value: "55015", name: "Calumet County" },
        { value: "55017", name: "Chippewa County" },
        { value: "55019", name: "Clark County" },
        { value: "55021", name: "Columbia County" },
        { value: "55023", name: "Crawford County" },
        { value: "55025", name: "Dane County" },
        { value: "55027", name: "Dodge County" },
        { value: "55029", name: "Door County" },
        { value: "55031", name: "Douglas County" },
        { value: "55033", name: "Dunn County" },
        { value: "55035", name: "Eau Claire County" },
        { value: "55037", name: "Florence County" },
        { value: "55039", name: "Fond du Lac County" },
        { value: "55041", name: "Forest County" },
        { value: "55043", name: "Grant County" },
        { value: "55045", name: "Green County" },
        { value: "55047", name: "Green Lake County" },
        { value: "55049", name: "Iowa County" },
        { value: "55051", name: "Iron County" },
        { value: "55053", name: "Jackson County" },
        { value: "55055", name: "Jefferson County" },
        { value: "55057", name: "Juneau County" },
        { value: "55059", name: "Kenosha County" },
        { value: "55061", name: "Kewaunee County" },
        { value: "55063", name: "La Crosse County" },
        { value: "55065", name: "Lafayette County" },
        { value: "55067", name: "Langlade County" },
        { value: "55069", name: "Lincoln County" },
        { value: "55071", name: "Manitowoc County" },
        { value: "55073", name: "Marathon County" },
        { value: "55075", name: "Marinette County" },
        { value: "55077", name: "Marquette County" },
        { value: "55078", name: "Menominee County" },
        { value: "55079", name: "Milwaukee County" },
        { value: "55081", name: "Monroe County" },
        { value: "55083", name: "Oconto County" },
        { value: "55085", name: "Oneida County" },
        { value: "55087", name: "Outagamie County" },
        { value: "55089", name: "Ozaukee County" },
        { value: "55091", name: "Pepin County" },
        { value: "55093", name: "Pierce County" },
        { value: "55095", name: "Polk County" },
        { value: "55097", name: "Portage County" },
        { value: "55099", name: "Price County" },
        { value: "55101", name: "Racine County" },
        { value: "55103", name: "Richland County" },
        { value: "55105", name: "Rock County" },
        { value: "55107", name: "Rusk County" },
        { value: "55109", name: "St. Croix County" },
        { value: "55111", name: "Sauk County" },
        { value: "55113", name: "Sawyer County" },
        { value: "55115", name: "Shawano County" },
        { value: "55117", name: "Sheboygan County" },
        { value: "55119", name: "Taylor County" },
        { value: "55121", name: "Trempealeau County" },
        { value: "55123", name: "Vernon County" },
        { value: "55125", name: "Vilas County" },
        { value: "55127", name: "Walworth County" },
        { value: "55129", name: "Washburn County" },
        { value: "55131", name: "Washington County" },
        { value: "55133", name: "Waukesha County" },
        { value: "55135", name: "Waupaca County" },
        { value: "55137", name: "Waushara County" },
        { value: "55139", name: "Winnebago County" },
        { value: "55141", name: "Wood County" },
        { value: "56000", name: "Wyoming" },
        { value: "56001", name: "Albany County" },
        { value: "56003", name: "Big Horn County" },
        { value: "56005", name: "Campbell County" },
        { value: "56007", name: "Carbon County" },
        { value: "56009", name: "Converse County" },
        { value: "56011", name: "Crook County" },
        { value: "56013", name: "Fremont County" },
        { value: "56015", name: "Goshen County" },
        { value: "56017", name: "Hot Springs County" },
        { value: "56019", name: "Johnson County" },
        { value: "56021", name: "Laramie County" },
        { value: "56023", name: "Lincoln County" },
        { value: "56025", name: "Natrona County" },
        { value: "56027", name: "Niobrara County" },
        { value: "56029", name: "Park County" },
        { value: "56031", name: "Platte County" },
        { value: "56033", name: "Sheridan County" },
        { value: "56035", name: "Sublette County" },
        { value: "56037", name: "Sweetwater County" },
        { value: "56039", name: "Teton County" },
        { value: "56041", name: "Uinta County" },
        { value: "56043", name: "Washakie County" },
        { value: "56045", name: "Weston County" },
      ]
    },
    nationwide: {
      name: 'Nationwide',
      displayHeader: false,
      inputType: "geography",
      items: [],
    }
  },
  SIC: {
    name: 'SIC code',
    displayHeader: true,
    inputType: "search",
    items: [
      {value: "0111", name: "0111 - WHEAT (1455 records)"},
      {value: "0112", name: "0112 - RICE PRODUCTION (97 records)"},
      {value: "0115", name: "0115 - CORN (3543 records)"},
      {value: "0116", name: "0116 - SOYBEANS (527 records)"},
      {value: "0119", name: "0119 - CASH GRAINS NEC (817 records)"},
      {value: "0131", name: "0131 - COTTON (254 records)"},
      {value: "0132", name: "0132 - TOBACCO GROWERS (210 records)"},
      {value: "0133", name: "0133 - SUGARCANE & SUGAR BEETS (56 records)"},
      {value: "0134", name: "0134 - POTATO GROWERS (99 records)"},
      {value: "0139", name: "0139 - AGRICULTURAL PRODUCTS (3554 records)"},
      {value: "0161", name: "0161 - VEGETABLES & MELONS (394 records)"},
      {value: "0171", name: "0171 - CRANBERRY GROWERS (279 records)"},
      {value: "0172", name: "0172 - VINEYARDS (2090 records)"},
      {value: "0173", name: "0173 - FARMS-PECAN (167 records)"},
      {value: "0174", name: "0174 - CITRUS FRUIT PRODUCTS (228 records)"},
      {value: "0175", name: "0175 - ORCHARDS (2426 records)"},
      {value: "0179", name: "0179 - DATES-EDIBLE (75 records)"},
      {value: "0181", name: "0181 - GREENHOUSES (5486 records)"},
      {value: "0182", name: "0182 - MUSHROOMS (653 records)"},
      {value: "0191", name: "0191 - FARMS (61845 records)"},
      {value: "0211", name: "0211 - LIVESTOCK FEEDING (1668 records)"},
      {value: "0212", name: "0212 - BEEF CATTLE-EXCEPT FEEDLOTS (2427 records)"},
      {value: "0213", name: "0213 - HOGS (565 records)"},
      {value: "0214", name: "0214 - SHEEP & GOATS (183 records)"},
      {value: "0219", name: "0219 - GENERAL LIVESTOCK-EXCEPT DAIRY/POULTRY (96 records)"},
      {value: "0241", name: "0241 - DAIRIES (MILK) (7051 records)"},
      {value: "0251", name: "0251 - BROILER FRYER & ROASTER CHICKENS (95 records)"},
      {value: "0252", name: "0252 - CHICKEN EGGS (87 records)"},
      {value: "0253", name: "0253 - TURKEY FARMS (117 records)"},
      {value: "0254", name: "0254 - POULTRY FARMS (1085 records)"},
      {value: "0259", name: "0259 - OSTRICHES & EMUS (98 records)"},
      {value: "0271", name: "0271 - GAME FARMS (147 records)"},
      {value: "0272", name: "0272 - HORSES & OTHER EQUINES (669 records)"},
      {value: "0273", name: "0273 - FISH FARMS (921 records)"},
      {value: "0279", name: "0279 - BEEKEEPERS (1258 records)"},
      {value: "0291", name: "0291 - RANCHES (17231 records)"},
      {value: "0711", name: "0711 - DISCING-ROTARY CULTIVATING & PLOWING SVC (317 records)"},
      {value: "0721", name: "0721 - AGRICULTURAL CONTRACTORS (3105 records)"},
      {value: "0722", name: "0722 - CROP HARVESTING-PRIMARILY BY MACHINE (321 records)"},
      {value: "0723", name: "0723 - FARM PRODUCE (893 records)"},
      {value: "0724", name: "0724 - COTTON GINS (671 records)"},
      {value: "0741", name: "0741 - VETERINARY SERVICES FOR LIVESTOCK (6 records)"},
      {value: "0742", name: "0742 - VETERINARIANS (56393 records)"},
      {value: "0751", name: "0751 - LIVESTOCK BREEDERS (3801 records)"},
      {value: "0752", name: "0752 - PET WASHING & GROOMING (60509 records)"},
      {value: "0761", name: "0761 - PLANTERS (359 records)"},
      {value: "0762", name: "0762 - FARMING SERVICE (3262 records)"},
      {value: "0781", name: "0781 - LANDSCAPE DESIGNERS (10432 records)"},
      {value: "0782", name: "0782 - LANDSCAPE CONTRACTORS (131648 records)"},
      {value: "0783", name: "0783 - TREE SERVICE (37368 records)"},
      {value: "0811", name: "0811 - TIMBER TRACTS (198 records)"},
      {value: "0831", name: "0831 - GINSENG (204 records)"},
      {value: "0851", name: "0851 - GOVERNMENT-FORESTRY SERVICES (5239 records)"},
      {value: "0912", name: "0912 - FISHERMEN-COMMERCIAL (275 records)"},
      {value: "0913", name: "0913 - OYSTERS (114 records)"},
      {value: "0919", name: "0919 - MISCELLANEOUS MARINE PRODUCTS (23 records)"},
      {value: "0921", name: "0921 - FISHERY CONSULTANTS (262 records)"},
      {value: "0971", name: "0971 - HUNTING & FISHING PRESERVES (2444 records)"},
      {value: "1011", name: "1011 - IRON-ORE (8 records)"},
      {value: "1021", name: "1021 - COPPER ORES (10 records)"},
      {value: "1041", name: "1041 - GOLD PANNING (57 records)"},
      {value: "1044", name: "1044 - SILVER ORES (11 records)"},
      {value: "1061", name: "1061 - FERROALLOY ORES EXCEPT VANADIUM (4 records)"},
      {value: "1081", name: "1081 - METAL MINING SERVICES (54 records)"},
      {value: "1094", name: "1094 - URANIUM (5 records)"},
      {value: "1099", name: "1099 - ORE (35 records)"},
      {value: "1119", name: "1119 - Wheat Farm (6 records)"},
      {value: "1159", name: "1159 - Corn Farm (17 records)"},
      {value: "1169", name: "1169 - Soybean Farm (4 records)"},
      {value: "1199", name: "1199 - Cash Grains Farm (10 records)"},
      {value: "1221", name: "1221 - COAL MINING & SHIPPING (1480 records)"},
      {value: "1222", name: "1222 - BITUMINOUS COAL-UNDERGROUND MINING (3 records)"},
      {value: "1241", name: "1241 - COAL MINING SERVICES (89 records)"},
      {value: "1311", name: "1311 - OIL & GAS PRODUCERS (10929 records)"},
      {value: "1319", name: "1319 - Cotton Farm Tobacco Farm (3 records)"},
      {value: "1320", name: "1320 - Tobacco Farm (1 records)"},
      {value: "1321", name: "1321 - NATURAL GAS LIQUIDS (18 records)"},
      {value: "1340", name: "1340 - Irish Potato Farm (1 records)"},
      {value: "1381", name: "1381 - CONTRACTORS-OIL FIELD (1283 records)"},
      {value: "1382", name: "1382 - OIL & GAS EXPLORATION & DEVELOPMENT (8106 records)"},
      {value: "1389", name: "1389 - OIL FIELD SERVICE (15007 records)"},
      {value: "1390", name: "1390 - Field Crop Farm (161 records)"},
      {value: "1399", name: "1399 - Field Crop Farm (7 records)"},
      {value: "1411", name: "1411 - STONE-NATURAL (1832 records)"},
      {value: "1422", name: "1422 - LIMESTONE (302 records)"},
      {value: "1423", name: "1423 - CRUSHED & BROKEN GRANITE (10 records)"},
      {value: "1429", name: "1429 - STONE-CRUSHED (1166 records)"},
      {value: "1442", name: "1442 - QUARRIES (1458 records)"},
      {value: "1446", name: "1446 - SILICA (58 records)"},
      {value: "1455", name: "1455 - KAOLIN & BALL CLAY (7 records)"},
      {value: "1459", name: "1459 - CLAY (71 records)"},
      {value: "1474", name: "1474 - POTASH SODA & BORATE MINERALS (2 records)"},
      {value: "1479", name: "1479 - CHEMICAL & FERTILIZER MINING NEC (40 records)"},
      {value: "1481", name: "1481 - NONMETALLIC MINERALS SVC-EXCEPT FUELS (10 records)"},
      {value: "1499", name: "1499 - MISCELLANEOUS NONMETALLIC MINERALS (2655 records)"},
      {value: "1521", name: "1521 - GENERAL CONTRACTORS (400656 records)"},
      {value: "1522", name: "1522 - GENERAL CONTRACTORS-RESIDENTIAL BLDGS (2700 records)"},
      {value: "1531", name: "1531 - OPERATIVE BUILDERS (167 records)"},
      {value: "1541", name: "1541 - BUILDING RESTORATION & PRESERVATION (4879 records)"},
      {value: "1542", name: "1542 - BUILDING CONTRACTORS (77256 records)"},
      {value: "1610", name: "1610 - Vegetable/melon Farm (10 records)"},
      {value: "1611", name: "1611 - ROAD BUILDING CONTRACTORS (38076 records)"},
      {value: "1619", name: "1619 - Vegetable/melon Farm (3 records)"},
      {value: "1622", name: "1622 - CULVERTS (653 records)"},
      {value: "1623", name: "1623 - PIPE LINE CONTRACTORS (11872 records)"},
      {value: "1629", name: "1629 - CONSTRUCTION-HEAVY PROJECTS (9275 records)"},
      {value: "1710", name: "1710 - Berry Crop Farm (11 records)"},
      {value: "1711", name: "1711 - PLUMBING CONTRACTORS (252814 records)"},
      {value: "1720", name: "1720 - Grape Vineyard (71 records)"},
      {value: "1721", name: "1721 - PAINTERS (73608 records)"},
      {value: "1731", name: "1731 - ELECTRIC CONTRACTORS (113116 records)"},
      {value: "1740", name: "1740 - Citrus Fruit Grove Refrigeration Warehouse/storage (1 records)"},
      {value: "1741", name: "1741 - MASONRY CONTRACTORS (21188 records)"},
      {value: "1742", name: "1742 - DRY WALL CONTRACTORS (27734 records)"},
      {value: "1743", name: "1743 - TILE-CERAMIC-CONTRACTORS & DEALERS (15438 records)"},
      {value: "1750", name: "1750 - Orchard (71 records)"},
      {value: "1751", name: "1751 - CABINET MAKERS (38983 records)"},
      {value: "1752", name: "1752 - FLOOR LAYING REFINISHING & RESURFACING (29028 records)"},
      {value: "1761", name: "1761 - GUTTERS & DOWNSPOUTS (86492 records)"},
      {value: "1771", name: "1771 - CONCRETE CONTRACTORS (39974 records)"},
      {value: "1781", name: "1781 - WATER WELL DRILLING & SERVICE (9985 records)"},
      {value: "1790", name: "1790 - Dates-Edible (1 records)"},
      {value: "1791", name: "1791 - WELDING & STEEL FABRICATION (8870 records)"},
      {value: "1793", name: "1793 - GLAZIERS (1939 records)"},
      {value: "1794", name: "1794 - EXCAVATING CONTRACTORS (39203 records)"},
      {value: "1795", name: "1795 - DEMOLITION CONTRACTORS (5685 records)"},
      {value: "1796", name: "1796 - ELEVATORS-SALES & SERVICE (4373 records)"},
      {value: "1799", name: "1799 - ASBESTOS REMOVAL SERVICE (77969 records)"},
      {value: "1810", name: "1810 - Ornamental Nursery (210 records)"},
      {value: "1819", name: "1819 - Ornamental Nursery (2 records)"},
      {value: "1820", name: "1820 - Covered Food Crops Farm (18 records)"},
      {value: "1829", name: "1829 - Covered Food Crops Farm (1 records)"},
      {value: "1910", name: "1910 - General Crop Farms (3139 records)"},
      {value: "1919", name: "1919 - General Crop Farms (232 records)"},
      {value: "2011", name: "2011 - MEAT PRODUCTS (MFRS) (3638 records)"},
      {value: "2013", name: "2013 - GAME PROCESSING (MFRS) (464 records)"},
      {value: "2015", name: "2015 - POULTRY PROCESSING PLANTS (MFRS) (682 records)"},
      {value: "2021", name: "2021 - BUTTER MANUFACTURING (60 records)"},
      {value: "2022", name: "2022 - CHEESE PROCESSORS (MFRS) (352 records)"},
      {value: "2023", name: "2023 - DRY CONDENSED/EVPRTD DAIRY PROD (MFRS) (103 records)"},
      {value: "2024", name: "2024 - ICE CREAM & FROZEN DESSERTS (MFRS) (549 records)"},
      {value: "2026", name: "2026 - FLUID MILK (MFRS) (290 records)"},
      {value: "2032", name: "2032 - RAVIOLI-MANUFACTURERS (221 records)"},
      {value: "2033", name: "2033 - CANNING (MFRS) (670 records)"},
      {value: "2034", name: "2034 - DEHYDRATING SERVICE (MFRS) (156 records)"},
      {value: "2035", name: "2035 - CONDIMENTS & SAUCES-MANUFACTURERS (297 records)"},
      {value: "2037", name: "2037 - CITRUS FRUIT PRODUCTS-MANUFACTURERS (126 records)"},
      {value: "2038", name: "2038 - FROZEN FOOD PROCESSORS (MFRS) (418 records)"},
      {value: "2041", name: "2041 - MILLING (MFRS) (445 records)"},
      {value: "2043", name: "2043 - CEREALS (MFRS) (73 records)"},
      {value: "2044", name: "2044 - RICE MILLS (MFRS) (46 records)"},
      {value: "2045", name: "2045 - PREPARED FLOUR MIXES & DOUGHS (MFRS) (96 records)"},
      {value: "2046", name: "2046 - WET CORN MILLING (MFRS) (17 records)"},
      {value: "2047", name: "2047 - DOG & CAT FOOD (MFRS) (232 records)"},
      {value: "2048", name: "2048 - FEED-MANUFACTURERS (1558 records)"},
      {value: "2051", name: "2051 - BREAD/OTHER BAKERY PROD-EX COOKIES (2159 records)"},
      {value: "2052", name: "2052 - ICE CREAM CONE-MANUFACTURERS (270 records)"},
      {value: "2053", name: "2053 - FROZEN BAKERY PRODS-EXCEPT BREAD (MFRS) (24 records)"},
      {value: "2061", name: "2061 - SUGAR-RAW-MANUFACTURERS (71 records)"},
      {value: "2062", name: "2062 - SUGAR REFINERS (MFRS) (208 records)"},
      {value: "2063", name: "2063 - BEET SUGAR (MFRS) (5 records)"},
      {value: "2064", name: "2064 - CANDY & CONFECTIONERY-MANUFACTURERS (823 records)"},
      {value: "2066", name: "2066 - CHOCOLATE & COCOA-MANUFACTURERS (572 records)"},
      {value: "2067", name: "2067 - CHEWING GUM (MFRS) (10 records)"},
      {value: "2068", name: "2068 - SEEDS-COATING (MFRS) (87 records)"},
      {value: "2074", name: "2074 - COTTON OIL MILLS (MFRS) (86 records)"},
      {value: "2075", name: "2075 - SOYBEAN OIL MILLS (MFRS) (33 records)"},
      {value: "2076", name: "2076 - TALLOW (MFRS) (20 records)"},
      {value: "2077", name: "2077 - FISH BY-PRODUCTS (MFRS) (648 records)"},
      {value: "2079", name: "2079 - OLIVE OIL (MFRS) (389 records)"},
      {value: "2082", name: "2082 - BREWERS (MFRS) (5000 records)"},
      {value: "2083", name: "2083 - MALT (MFRS) (10 records)"},
      {value: "2084", name: "2084 - WINERIES (MFRS) (8300 records)"},
      {value: "2085", name: "2085 - DISTILLERS (MFRS) (666 records)"},
      {value: "2086", name: "2086 - BOTTLERS (MFRS) (2037 records)"},
      {value: "2087", name: "2087 - FLAVORING EXTRACTS & SYRUPS NEC (MFRS) (272 records)"},
      {value: "2091", name: "2091 - FISH PACKERS (MFRS) (286 records)"},
      {value: "2092", name: "2092 - PREPARED FISH & SEAFOOD PRODUCTS (MFRS) (596 records)"},
      {value: "2095", name: "2095 - COFFEE MILLS (MFRS) (607 records)"},
      {value: "2096", name: "2096 - POTATO CHIPS CORN CHIPS/SNACKS (MFRS) (744 records)"},
      {value: "2097", name: "2097 - ICE-MANUFACTURERS (524 records)"},
      {value: "2098", name: "2098 - MACARONI SPAGHETTI & NOODLES (MFRS) (275 records)"},
      {value: "2099", name: "2099 - FOOD PRODUCTS & MANUFACTURERS (4558 records)"},
      {value: "2110", name: "2110 - Beef Cattle Feedlot (41 records)"},
      {value: "2111", name: "2111 - CIGARETTES (MFRS) (172 records)"},
      {value: "2120", name: "2120 - Beef Cattle-except Feedlot (20 records)"},
      {value: "2121", name: "2121 - CIGAR CIGARETTE & TOBACCO-MANUFACTURERS (337 records)"},
      {value: "2131", name: "2131 - TOBACCO PRODUCTS-MANUFACTURERS (102 records)"},
      {value: "2139", name: "2139 - Hog Farm Or Feedlot (14 records)"},
      {value: "2140", name: "2140 - Sheep/goat Farm Horses/other Equines Farm (4 records)"},
      {value: "2141", name: "2141 - TOBACCO STEMMING & REDRYING (MFRS) (4 records)"},
      {value: "2149", name: "2149 - Sheep/goat Farm Horses/other Equines Farm (3 records)"},
      {value: "2211", name: "2211 - COTTON GOODS-MANUFACTURERS (651 records)"},
      {value: "2221", name: "2221 - SILKS-MANUFACTURERS (244 records)"},
      {value: "2231", name: "2231 - WOOLEN MILLS (MFRS) (50 records)"},
      {value: "2241", name: "2241 - BRAIDS (MFRS) (190 records)"},
      {value: "2251", name: "2251 - WOMENS FULL-LENGTH/KNEE-LNTH HSRY (MFRS) (13 records)"},
      {value: "2252", name: "2252 - HOSIERY-MANUFACTURERS (198 records)"},
      {value: "2253", name: "2253 - T-SHIRTS-MANUFACTURERS (135 records)"},
      {value: "2254", name: "2254 - KNIT UNDERWEAR & NIGHTWEAR MILLS (MFRS) (44 records)"},
      {value: "2257", name: "2257 - WEFT KNIT FABRIC MILLS (MFRS) (10 records)"},
      {value: "2258", name: "2258 - KNIT GOODS-PROCESSING & DYEING (MFRS) (62 records)"},
      {value: "2259", name: "2259 - CURTAINS-MANUFACTURERS (709 records)"},
      {value: "2261", name: "2261 - TEXTILE FINISHING (MFRS) (296 records)"},
      {value: "2262", name: "2262 - FLOCKING (MFRS) (36 records)"},
      {value: "2269", name: "2269 - LINENS-MANUFACTURERS (219 records)"},
      {value: "2273", name: "2273 - CARPET & RUG-MANUFACTURERS (576 records)"},
      {value: "2281", name: "2281 - COTTON PROCESSING (MFRS) (200 records)"},
      {value: "2282", name: "2282 - YARN-TEXTURIZING THROWING/WINDING MILLS (32 records)"},
      {value: "2284", name: "2284 - THREAD-MANUFACTURERS (98 records)"},
      {value: "2295", name: "2295 - WATERPROOFING MATERIALS-MANUFACTURERS (148 records)"},
      {value: "2296", name: "2296 - TIRE CORD & FABRICS (MFRS) (4 records)"},
      {value: "2297", name: "2297 - BLANKETS-MANUFACTURERS (138 records)"},
      {value: "2298", name: "2298 - ROPE-MANUFACTURERS (112 records)"},
      {value: "2299", name: "2299 - CAR TRIMMING & UPHOLSTERY MFRS (1496 records)"},
      {value: "2311", name: "2311 - COATS-MANUFACTURERS (66 records)"},
      {value: "2321", name: "2321 - SHIRTS-MANUFACTURERS (71 records)"},
      {value: "2322", name: "2322 - UNDERWEAR-MENS-MANUFACTURERS (3 records)"},
      {value: "2323", name: "2323 - NECKWEAR-MENS-MANUFACTURERS (52 records)"},
      {value: "2325", name: "2325 - PANTS-MANUFACTURERS (32 records)"},
      {value: "2326", name: "2326 - MENS & BOYS WORK CLOTHING (MFRS) (187 records)"},
      {value: "2329", name: "2329 - MEN'S CLOTHING-MANUFACTURERS (470 records)"},
      {value: "2331", name: "2331 - WOMEN'S APPAREL-MANUFACTURERS (708 records)"},
      {value: "2335", name: "2335 - DRESSES-MANUFACTURERS (135 records)"},
      {value: "2337", name: "2337 - WOMENS MISSES JR SUITS SKRTS/COATS (12 records)"},
      {value: "2339", name: "2339 - CLOTHES & ACCESSORIES-WOMENS-MFRS (1196 records)"},
      {value: "2341", name: "2341 - WOMENS MISSES/CHILDRENS UNDERWEAR (MFRS) (69 records)"},
      {value: "2342", name: "2342 - MATERNITY APPAREL-MANUFACTURERS (71 records)"},
      {value: "2353", name: "2353 - HATS-MANUFACTURERS (247 records)"},
      {value: "2361", name: "2361 - GIRLS CHLDRNS INFNTS DRESSES/SHRTS (20 records)"},
      {value: "2369", name: "2369 - CLOTHES & ACCESSORIES-CHILDRENS-MFRS (255 records)"},
      {value: "2371", name: "2371 - FUR GOODS (MFRS) (68 records)"},
      {value: "2381", name: "2381 - DRESS/WORK GLOVES-EX KNIT/LEATHER (MFRS) (72 records)"},
      {value: "2385", name: "2385 - WATERPROOF OUTERWEAR (MFRS) (38 records)"},
      {value: "2386", name: "2386 - LEATHER APPAREL-MANUFACTURERS (84 records)"},
      {value: "2387", name: "2387 - APPAREL-BELTS (MFRS) (37 records)"},
      {value: "2389", name: "2389 - APPAREL & ACCESSORIES NEC (MFRS) (345 records)"},
      {value: "2391", name: "2391 - CURTAIN & DRAPERY-MANUFACTURERS (282 records)"},
      {value: "2392", name: "2392 - QUILTS-MANUFACTURERS (637 records)"},
      {value: "2393", name: "2393 - TEXTILE BAGS (MFRS) (96 records)"},
      {value: "2394", name: "2394 - CANVAS & RELATED PRODUCTS (MFRS) (1325 records)"},
      {value: "2395", name: "2395 - PATCHES (MFRS) (541 records)"},
      {value: "2396", name: "2396 - AUTOMOTIVE TRIMMING/APPRL FINDINGS (524 records)"},
      {value: "2397", name: "2397 - SCHIFFLI MACHINE EMBROIDERIES (MFRS) (16 records)"},
      {value: "2399", name: "2399 - FLAGS & BANNERS-MANUFACTURERS (6726 records)"},
      {value: "2410", name: "2410 - Dairy Farm (345 records)"},
      {value: "2411", name: "2411 - LOGGING COMPANIES (MFRS) (4918 records)"},
      {value: "2421", name: "2421 - SAWMILLS (MFRS) (3512 records)"},
      {value: "2426", name: "2426 - HARDWOOD FLOORING (MFRS) (1326 records)"},
      {value: "2429", name: "2429 - STAVES & STAVE MILLS (MFRS) (27 records)"},
      {value: "2431", name: "2431 - MILLWORK (MFRS) (5641 records)"},
      {value: "2434", name: "2434 - CABINETS-MANUFACTURERS (2025 records)"},
      {value: "2435", name: "2435 - LAMINATED WOOD (MFRS) (228 records)"},
      {value: "2436", name: "2436 - SOFTWOOD VENEER & PLYWOOD (MFRS) (23 records)"},
      {value: "2439", name: "2439 - TRUSSES (MFRS) (396 records)"},
      {value: "2441", name: "2441 - PACKING CASE MFRS (142 records)"},
      {value: "2448", name: "2448 - PALLETS & SKIDS-MANUFACTURERS (1397 records)"},
      {value: "2449", name: "2449 - BARRELS & DRUMS-WOODEN (MFRS) (127 records)"},
      {value: "2451", name: "2451 - MOBILE HOMES-MANUFACTURERS (1145 records)"},
      {value: "2452", name: "2452 - LOG CABINS HOMES & BUILDINGS (MFRS) (4656 records)"},
      {value: "2491", name: "2491 - LUMBER-TREATING (MFRS) (341 records)"},
      {value: "2493", name: "2493 - RECONSTITUTED WOOD PRODUCTS (MFRS) (39 records)"},
      {value: "2499", name: "2499 - WOOD PRODUCTS-MANUFACTURERS (2363 records)"},
      {value: "2511", name: "2511 - WOOD-HOUSEHOLD FURN-EX UPHOLSTERED (406 records)"},
      {value: "2512", name: "2512 - WOOD-HOUSEHOLD FURN-UPHOLSTERED (MFRS) (147 records)"},
      {value: "2514", name: "2514 - METAL-HOUSEHOLD FURNITURE (MFRS) (107 records)"},
      {value: "2515", name: "2515 - MATTRESSES/FOUNDATIONS/CONV BEDS (MFRS) (567 records)"},
      {value: "2517", name: "2517 - WOOD-TV RADIO PHONO/SEWING CABNTS (MFRS) (2 records)"},
      {value: "2519", name: "2519 - HOUSEHOLD FURNITURE NEC (MFRS) (98 records)"},
      {value: "2521", name: "2521 - WOOD-OFFICE FURNITURE (MFRS) (111 records)"},
      {value: "2522", name: "2522 - OFFICE FURNITURE & EQUIP-MFRS (777 records)"},
      {value: "2530", name: "2530 - Turkey Farms (1 records)"},
      {value: "2531", name: "2531 - PUBLIC BUILDING/RELATED FURNITURE (MFRS) (159 records)"},
      {value: "2540", name: "2540 - Poultry Hatchery (32 records)"},
      {value: "2541", name: "2541 - COUNTER TOPS-MANUFACTURERS (428 records)"},
      {value: "2542", name: "2542 - OFFICE & STORE FIXTURES-EX WOOD (MFRS) (748 records)"},
      {value: "2590", name: "2590 - Poultry/poultry Egg Farm (2 records)"},
      {value: "2591", name: "2591 - DRAPERY HDWR-WINDOW BLINDS/SHADES (MFRS) (474 records)"},
      {value: "2599", name: "2599 - RESTAURANT EQUIPMENT & SUPPLIES-MFRS (2963 records)"},
      {value: "2611", name: "2611 - PULP & PULP PRODUCTS (MFRS) (560 records)"},
      {value: "2621", name: "2621 - PAPER-MANUFACTURERS (1747 records)"},
      {value: "2631", name: "2631 - PAPERBOARD MILLS (MFRS) (147 records)"},
      {value: "2652", name: "2652 - BOXES-PAPER (MFRS) (604 records)"},
      {value: "2653", name: "2653 - CORRUGATED & SOLID FIBER BOXES (MFRS) (1161 records)"},
      {value: "2655", name: "2655 - PAPER TUBES & CORES-MANUFACTURERS (563 records)"},
      {value: "2656", name: "2656 - PAPER & PLASTIC CUPS CONTAINERS ETC-MFRS (86 records)"},
      {value: "2657", name: "2657 - BOXES-FOLDING-MANUFACTURERS (228 records)"},
      {value: "2671", name: "2671 - PACKAGING MATERIALS-MANUFACTURERS (1165 records)"},
      {value: "2672", name: "2672 - LABELS-PAPER (MFRS) (831 records)"},
      {value: "2673", name: "2673 - BAGS-PLASTIC (MFRS) (851 records)"},
      {value: "2674", name: "2674 - BAGS-MANUFACTURERS (456 records)"},
      {value: "2675", name: "2675 - BOX PARTITIONS (MFRS) (89 records)"},
      {value: "2676", name: "2676 - SANITARY PAPER PRODUCTS (MFRS) (126 records)"},
      {value: "2677", name: "2677 - ENVELOPES-MANUFACTURERS (361 records)"},
      {value: "2678", name: "2678 - STATIONERY-MANUFACTURERS (181 records)"},
      {value: "2679", name: "2679 - TAGS-MANUFACTURERS (690 records)"},
      {value: "2710", name: "2710 - Fur Bearing Animal Farm General Crop Farm (6 records)"},
      {value: "2711", name: "2711 - NEWSPAPERS (PUBLISHERS/MFRS) (16388 records)"},
      {value: "2721", name: "2721 - PUBLISHERS-PERIODICAL (MFRS) (9935 records)"},
      {value: "2729", name: "2729 - Horses/other Equines Farm (7 records)"},
      {value: "2730", name: "2730 - Fish/shellfish Farm (27 records)"},
      {value: "2731", name: "2731 - PUBLISHERS-BOOK (MFRS) (4975 records)"},
      {value: "2732", name: "2732 - PRINTERS-BOOKS (MFRS) (105 records)"},
      {value: "2741", name: "2741 - MULTIMEDIA (MFRS) (21431 records)"},
      {value: "2752", name: "2752 - PRINTERS (MFRS) (36484 records)"},
      {value: "2754", name: "2754 - ENGRAVERS-STATIONERY (MFRS) (133 records)"},
      {value: "2759", name: "2759 - COMMERCIAL PRINTING NEC (MFRS) (19292 records)"},
      {value: "2761", name: "2761 - PRINTERS-BUSINESS FORMS (MFRS) (887 records)"},
      {value: "2771", name: "2771 - GREETING CARDS (MFRS) (176 records)"},
      {value: "2782", name: "2782 - CHECKS-PRINTING (MFRS) (281 records)"},
      {value: "2789", name: "2789 - BOOKBINDERS (MFRS) (716 records)"},
      {value: "2790", name: "2790 - Dog Grooming (31 records)"},
      {value: "2791", name: "2791 - TYPESETTING (MFRS) (434 records)"},
      {value: "2796", name: "2796 - ENGRAVERS-PLASTIC WOOD & ETC (MFRS) (983 records)"},
      {value: "2799", name: "2799 - Dog Grooming (9 records)"},
      {value: "2812", name: "2812 - ALKALIES (MFRS) (45 records)"},
      {value: "2813", name: "2813 - GAS-IND & MEDICAL-CYLINDER & BULK-MFRS (968 records)"},
      {value: "2816", name: "2816 - COLORS & PIGMENTS-MANUFACTURERS (201 records)"},
      {value: "2819", name: "2819 - INDUSTRIAL INORGANIC CHMCLS NEC (MFRS) (623 records)"},
      {value: "2821", name: "2821 - FIBER GLASS PRODUCTS-MANUFACTURERS (1605 records)"},
      {value: "2822", name: "2822 - RUBBER-SYNTHETIC (MFRS) (92 records)"},
      {value: "2823", name: "2823 - CELLULOSIC MANMADE FIBERS (MFRS) (19 records)"},
      {value: "2824", name: "2824 - SHRINK WRAPPING (MFRS) (82 records)"},
      {value: "2833", name: "2833 - MEDICINAL CHEM/BOTANICAL PRODS (MFRS) (162 records)"},
      {value: "2834", name: "2834 - DRUG MILLERS (MFRS) (1956 records)"},
      {value: "2835", name: "2835 - IN-VITRO/IN-VIVO DIAGNOSTIC SBSTNC (51 records)"},
      {value: "2836", name: "2836 - BIOLOGICAL PRODUCTS (MFRS) (751 records)"},
      {value: "2841", name: "2841 - SOAPS & DETERGENTS-MANUFACTURERS (1064 records)"},
      {value: "2842", name: "2842 - SPECIALTY CLNG PLSHNG/SANITATION (MFRS) (689 records)"},
      {value: "2843", name: "2843 - LEATHER FINISHERS (MFRS) (35 records)"},
      {value: "2844", name: "2844 - HEALTH & BEAUTY AIDS-MANUFACTURERS (1073 records)"},
      {value: "2851", name: "2851 - PAINT-MANUFACTURERS (917 records)"},
      {value: "2861", name: "2861 - GUM & WOOD CHEMICALS (MFRS) (51 records)"},
      {value: "2865", name: "2865 - CYCLIC ORGANIC CRUDES/DYES/PGMNTS (MFRS) (132 records)"},
      {value: "2869", name: "2869 - SOLVENTS-MANUFACTURERS (308 records)"},
      {value: "2873", name: "2873 - FERTILIZERS-MANUFACTURERS (1048 records)"},
      {value: "2874", name: "2874 - PHOSPHATIC FERTILIZERS (MFRS) (17 records)"},
      {value: "2875", name: "2875 - FERTILIZER MIXING ONLY (MFRS) (638 records)"},
      {value: "2879", name: "2879 - PESTICIDES & AG CHEMICALS NEC (MFRS) (312 records)"},
      {value: "2891", name: "2891 - ADHESIVES & SEALANTS (MFRS) (661 records)"},
      {value: "2892", name: "2892 - EXPLOSIVES-MANUFACTURERS (140 records)"},
      {value: "2893", name: "2893 - INKS-PRINTING & LITHOGRAPHING (MFRS) (687 records)"},
      {value: "2895", name: "2895 - CARBON BLACK-MANUFACTURERS (57 records)"},
      {value: "2899", name: "2899 - FIREPROOFING MATERIALS (MFRS) (3435 records)"},
      {value: "2910", name: "2910 - General Animal Farm (380 records)"},
      {value: "2911", name: "2911 - OILS-PETROLEUM-MANUFACTURERS (4236 records)"},
      {value: "2951", name: "2951 - ASPHALT & ASPHALT PRODUCTS-MANUFACTURERS (1232 records)"},
      {value: "2952", name: "2952 - ROOFING MATERIALS-MANUFACTURERS (227 records)"},
      {value: "2992", name: "2992 - LUBRICATING OILS & GREASES (MFRS) (523 records)"},
      {value: "2999", name: "2999 - PRODUCTS OF PETROLEUM & COAL NEC (MFRS) (96 records)"},
      {value: "3011", name: "3011 - TIRE-MANUFACTURERS (219 records)"},
      {value: "3021", name: "3021 - RUBBER & PLASTICS-FOOTWEAR (MFRS) (13 records)"},
      {value: "3052", name: "3052 - BELTING & BELTING SUPPLIES-MANUFACTURERS (340 records)"},
      {value: "3053", name: "3053 - SEALS-OIL, GREASE, ETC (MFRS) (810 records)"},
      {value: "3061", name: "3061 - MOLDED EXTRUDED/LATHE CUT RBBR GDS (65 records)"},
      {value: "3069", name: "3069 - RUBBER PRODUCTS-MANUFACTURERS (1723 records)"},
      {value: "3081", name: "3081 - PLASTICS-FABRICS,FILM-ETC PRODUCER (732 records)"},
      {value: "3082", name: "3082 - PLASTICS-ROD,TUBE,SHEETS,ETC-SUPL (MFRS) (44 records)"},
      {value: "3083", name: "3083 - LAMINATED PLSTCS-PLATE SHT/PROFILE (190 records)"},
      {value: "3084", name: "3084 - PIPE-PLASTIC (MFRS) (320 records)"},
      {value: "3085", name: "3085 - PLASTIC BOTTLES (MFRS) (142 records)"},
      {value: "3086", name: "3086 - INSULATION MATERIALS-COLD & HEAT-MFRS (625 records)"},
      {value: "3087", name: "3087 - CUSTOM COMPOUNDING-PURCHASED RESIN (21 records)"},
      {value: "3088", name: "3088 - PLASTICS-PLUMBING FIXTURES (MFRS) (142 records)"},
      {value: "3089", name: "3089 - PLASTICS-PRODUCTS-FINISHED-MANUFACTURERS (9587 records)"},
      {value: "3111", name: "3111 - TANNERS (MFRS) (332 records)"},
      {value: "3131", name: "3131 - SHOE FABRIC & LEATHER COMBINERS (MFRS) (86 records)"},
      {value: "3142", name: "3142 - HOUSE SLIPPERS (MFRS) (2 records)"},
      {value: "3143", name: "3143 - BOOTS-MANUFACTURERS (31 records)"},
      {value: "3144", name: "3144 - WOMENS FOOTWEAR-EXCEPT ATHLETIC (MFRS) (241 records)"},
      {value: "3149", name: "3149 - FOOTWEAR EXCEPT RUBBER NEC (MFRS) (38 records)"},
      {value: "3151", name: "3151 - LEATHER GLOVES & MITTENS (MFRS) (45 records)"},
      {value: "3161", name: "3161 - WARDROBES (MFRS) (193 records)"},
      {value: "3171", name: "3171 - WOMEN'S HANDBAGS & PURSES (MFRS) (260 records)"},
      {value: "3172", name: "3172 - LEATHER GOODS-MANUFACTURERS (306 records)"},
      {value: "3199", name: "3199 - LEATHER GOODS NEC (MFRS) (312 records)"},
      {value: "3211", name: "3211 - GLASS-MANUFACTURERS (373 records)"},
      {value: "3221", name: "3221 - GLASS CONTAINERS (MFRS) (223 records)"},
      {value: "3229", name: "3229 - GLASS-BLOWERS (MFRS) (870 records)"},
      {value: "3231", name: "3231 - GLASS SUBSTITUTES (MFRS) (1468 records)"},
      {value: "3241", name: "3241 - CEMENT-MANUFACTURERS (673 records)"},
      {value: "3251", name: "3251 - BRICK-CLAY COMMON & FACE-MANUFACTURERS (499 records)"},
      {value: "3253", name: "3253 - TILE-CERAMIC-MANUFACTURERS (240 records)"},
      {value: "3255", name: "3255 - CLAY REFRACTORIES (MFRS) (36 records)"},
      {value: "3259", name: "3259 - DRAIN PIPES-CLAY-MANUFACTURERS (198 records)"},
      {value: "3261", name: "3261 - BATHROOM ACCESSORIES-MANUFACTURERS (95 records)"},
      {value: "3262", name: "3262 - VITREOUS CHINA-TBL/KTCHN ARTICLES (MFRS) (17 records)"},
      {value: "3263", name: "3263 - FINE EARTHENWARE TBL/KTCHN ARTICLE (3 records)"},
      {value: "3264", name: "3264 - FERRITES (MFRS) (14 records)"},
      {value: "3269", name: "3269 - GIFTWARES-MANUFACTURERS (669 records)"},
      {value: "3271", name: "3271 - CONCRETE-BLOCK & BRICK (MFRS) (559 records)"},
      {value: "3272", name: "3272 - CONCRETE PRODS-EX BLOCK & BRICK (MFRS) (3292 records)"},
      {value: "3273", name: "3273 - READY-MIXED CONCRETE-MANUFACTURERS (1764 records)"},
      {value: "3274", name: "3274 - LIME-MANUFACTURERS (160 records)"},
      {value: "3275", name: "3275 - GYPSUM & GYPSUM PRODUCTS (MFRS) (285 records)"},
      {value: "3281", name: "3281 - CUT STONE & STONE PRODUCTS (MFRS) (2565 records)"},
      {value: "3291", name: "3291 - GRINDING WHEELS (MFRS) (428 records)"},
      {value: "3292", name: "3292 - ASBESTOS & ASBESTOS FREE PRODS (MFRS) (51 records)"},
      {value: "3295", name: "3295 - GRAPHITE (MFRS) (420 records)"},
      {value: "3296", name: "3296 - FIBER GLASS FABRICATORS (MFRS) (1172 records)"},
      {value: "3297", name: "3297 - REFRACTORIES (MFRS) (541 records)"},
      {value: "3299", name: "3299 - NONMETALLIC MINERAL PRODUCTS NEC (MFRS) (849 records)"},
      {value: "3312", name: "3312 - STEEL PROCESSING (MFRS) (2487 records)"},
      {value: "3313", name: "3313 - ELECTROMETALLURGICAL PROD-EX STEEL (MFR) (5 records)"},
      {value: "3315", name: "3315 - WIRE-MANUFACTURERS (292 records)"},
      {value: "3316", name: "3316 - STEEL-BAR, SHEET, STRIP, TUBE ETC (MFRS) (211 records)"},
      {value: "3317", name: "3317 - STEEL PIPE & TUBES (MFRS) (245 records)"},
      {value: "3321", name: "3321 - MANHOLE COVERS (MFRS) (211 records)"},
      {value: "3322", name: "3322 - FOUNDRIES-MALLEABLE IRON (MFRS) (12 records)"},
      {value: "3324", name: "3324 - INVESTMENT CASTINGS (MFRS) (145 records)"},
      {value: "3325", name: "3325 - FOUNDRIES-STEEL (MFRS) (1190 records)"},
      {value: "3331", name: "3331 - PRIMARY SMELTING-REFINING-COPPER (MFRS) (9 records)"},
      {value: "3334", name: "3334 - PRIMARY PRODUCTION OF ALUMINUM (MFRS) (25 records)"},
      {value: "3339", name: "3339 - SMELTERS-REFINERS-PRECIOUS METALS (MFRS) (275 records)"},
      {value: "3341", name: "3341 - REFINING (MFRS) (242 records)"},
      {value: "3351", name: "3351 - COPPER PRODUCTS-MANUFACTURERS (72 records)"},
      {value: "3353", name: "3353 - ALUMINUM SHEET PLATE & FOIL (MFRS) (24 records)"},
      {value: "3354", name: "3354 - ALUMINUM EXTRUDED PRODUCTS (MFRS) (183 records)"},
      {value: "3355", name: "3355 - ALUMINUM FABRICATORS (MFRS) (604 records)"},
      {value: "3356", name: "3356 - METAL-ROLLING & FORMING (MFRS) (299 records)"},
      {value: "3357", name: "3357 - CABLE (MFRS) (1206 records)"},
      {value: "3363", name: "3363 - ALUMINUM DIE CASTINGS (MFRS) (148 records)"},
      {value: "3364", name: "3364 - NONFERROUS DIE CASTINGS-EX ALUM (MFRS) (54 records)"},
      {value: "3365", name: "3365 - FOUNDRIES-ALUMINUM BRASS BRONZE (MFRS) (391 records)"},
      {value: "3366", name: "3366 - BRONZE (MFRS) (294 records)"},
      {value: "3369", name: "3369 - CASTINGS (MFRS) (709 records)"},
      {value: "3398", name: "3398 - LEAD BURNING (MFRS) (902 records)"},
      {value: "3399", name: "3399 - PRIMARY METAL PRODUCTS NEC (MFRS) (340 records)"},
      {value: "3411", name: "3411 - CAN-MANUFACTURERS (262 records)"},
      {value: "3412", name: "3412 - BARRELS & DRUMS (MFRS) (490 records)"},
      {value: "3421", name: "3421 - CUTLERY-MANUFACTURERS (65 records)"},
      {value: "3423", name: "3423 - KNIVES-MACHINE (MFRS) (1111 records)"},
      {value: "3425", name: "3425 - SHARPENING EQUIPMENT & STONES (MFRS) (215 records)"},
      {value: "3429", name: "3429 - HOSE COUPLINGS & FITTINGS-MANUFACTURERS (1161 records)"},
      {value: "3431", name: "3431 - HYGIENE & SANITARY APPLIANCE MFRS (23 records)"},
      {value: "3432", name: "3432 - PLUMBING FIXTURES & SUPPLIES-MFRS (687 records)"},
      {value: "3433", name: "3433 - SOLAR ENERGY EQUIPMENT-MANUFACTURERS (1068 records)"},
      {value: "3441", name: "3441 - STEEL-STRUCTURAL (MFRS) (8763 records)"},
      {value: "3442", name: "3442 - METAL DOORS SASH FRAMES & TRIM (MFRS) (947 records)"},
      {value: "3443", name: "3443 - TANKS-MANUFACTURERS (1285 records)"},
      {value: "3444", name: "3444 - SHEET METAL FABRICATORS (MFRS) (4010 records)"},
      {value: "3446", name: "3446 - ORNAMENTAL METAL WORK (MFRS) (3151 records)"},
      {value: "3448", name: "3448 - BUILDINGS-METAL-MANUFACTURERS (457 records)"},
      {value: "3449", name: "3449 - CONCRETE REINFORCEMENTS-MANUFACTURERS (199 records)"},
      {value: "3451", name: "3451 - SCREW MACHINE PRODUCTS (MFRS) (1030 records)"},
      {value: "3452", name: "3452 - BOLTS NUTS SCREWS RIVETS/WASHERS (MFRS) (779 records)"},
      {value: "3462", name: "3462 - FORGINGS (MFRS) (553 records)"},
      {value: "3463", name: "3463 - NONFERROUS FORGINGS (MFRS) (9 records)"},
      {value: "3465", name: "3465 - AUTOMOTIVE STAMPINGS (MFRS) (103 records)"},
      {value: "3466", name: "3466 - BOTTLE CAPS & SEALS (MFRS) (64 records)"},
      {value: "3469", name: "3469 - COINS & TOKENS-MANUFACTURERS (2718 records)"},
      {value: "3471", name: "3471 - METAL FINISHERS (MFRS) (3834 records)"},
      {value: "3479", name: "3479 - ENGRAVERS-METAL (MFRS) (7993 records)"},
      {value: "3482", name: "3482 - SMALL ARMS AMMUNITION (MFRS) (177 records)"},
      {value: "3483", name: "3483 - AMMUNITION-EXCEPT FOR SMALL ARMS (MFRS) (15 records)"},
      {value: "3484", name: "3484 - SMALL ARMS-MANUFACTURERS (318 records)"},
      {value: "3489", name: "3489 - ORDNANCE & ACCESSORIES NEC (MFRS) (26 records)"},
      {value: "3491", name: "3491 - INDUSTRIAL VALVES (MFRS) (260 records)"},
      {value: "3492", name: "3492 - HOSE ASSEMBLIES (MFRS) (124 records)"},
      {value: "3493", name: "3493 - SPRINGS-AUTO-SALES & SVC-MANUFACTURERS (45 records)"},
      {value: "3494", name: "3494 - VALVES & PIPE FITTINGS NEC (MFRS) (941 records)"},
      {value: "3495", name: "3495 - SPRINGS-MANUFACTURERS (328 records)"},
      {value: "3496", name: "3496 - FENCE-MANUFACTURERS (2407 records)"},
      {value: "3497", name: "3497 - FOIL & FOIL PRODUCTS (MFRS) (93 records)"},
      {value: "3498", name: "3498 - FABRICATED PIPE & PIPE FITTINGS (MFRS) (1148 records)"},
      {value: "3499", name: "3499 - METAL GOODS-MANUFACTURERS (5602 records)"},
      {value: "3511", name: "3511 - TURBINES-MANUFACTURERS (1216 records)"},
      {value: "3519", name: "3519 - INTERNAL COMBUSTION ENGINES NEC (MFRS) (475 records)"},
      {value: "3523", name: "3523 - FARM EQUIPMENT-MANUFACTURERS (2377 records)"},
      {value: "3524", name: "3524 - GARDEN & LAWN EQUIPMENT & SUPLS-MFRS (716 records)"},
      {value: "3531", name: "3531 - EXCAVATION EQUIPMENT MANUFACTURERS (2448 records)"},
      {value: "3532", name: "3532 - MANUFACTURING-AUGERS & TRENCHERS (236 records)"},
      {value: "3533", name: "3533 - OIL & GAS-FIELD MACHINERY/EQUIP (MFRS) (858 records)"},
      {value: "3534", name: "3534 - ELEVATORS & MOVING STAIRWAYS (MFRS) (906 records)"},
      {value: "3535", name: "3535 - CONVEYORS & CONVEYING EQUIPMENT-MFRS (725 records)"},
      {value: "3536", name: "3536 - BOAT LIFTS (MFRS) (603 records)"},
      {value: "3537", name: "3537 - MECHANICAL HANDLING EQUIPMENT MFRS (778 records)"},
      {value: "3541", name: "3541 - MILLING MACHINES (MFRS) (1642 records)"},
      {value: "3542", name: "3542 - PUNCH PRESSES (MFRS) (284 records)"},
      {value: "3543", name: "3543 - MODEL MAKERS (MFRS) (541 records)"},
      {value: "3544", name: "3544 - MOLDS (MFRS) (5356 records)"},
      {value: "3545", name: "3545 - BITS (MFRS) (816 records)"},
      {value: "3546", name: "3546 - POWER-DRIVEN HAND TOOLS (MFRS) (215 records)"},
      {value: "3547", name: "3547 - STEEL PROCESSING/FABRICATING EQUIP (MFR) (180 records)"},
      {value: "3548", name: "3548 - WELDING EQUIPMENT & SUPPLIES-MFRS (529 records)"},
      {value: "3549", name: "3549 - METALWORKING MACHINERY NEC (MFRS) (209 records)"},
      {value: "3552", name: "3552 - TEXTILE MACHINERY (MFRS) (811 records)"},
      {value: "3553", name: "3553 - PATTERN MAKERS (MFRS) (736 records)"},
      {value: "3554", name: "3554 - PAPER MILL MACHINERY-MANUFACTURERS (190 records)"},
      {value: "3555", name: "3555 - PRINTING EQUIPMENT-MANUFACTURERS (497 records)"},
      {value: "3556", name: "3556 - FISH PACKERS EQUIPMENT (MFRS) (788 records)"},
      {value: "3559", name: "3559 - AUTOMOBILE BODY SHOP EQUIPMENT-MFRS (2172 records)"},
      {value: "3561", name: "3561 - PUMPS-MANUFACTURERS (1366 records)"},
      {value: "3562", name: "3562 - BALL & ROLLER BEARING (MFRS) (330 records)"},
      {value: "3563", name: "3563 - COMPRESSORS-MANUFACTURERS (527 records)"},
      {value: "3564", name: "3564 - AIR CLEANING & PURIFYING EQUIPMENT-MFRS (767 records)"},
      {value: "3565", name: "3565 - PACKAGING MACHINERY-MANUFACTURERS (450 records)"},
      {value: "3566", name: "3566 - SPEED CHANGERS-IND-DRIVES & GEARS (MFRS) (500 records)"},
      {value: "3567", name: "3567 - FURNACES-INDUSTRIAL (MFRS) (515 records)"},
      {value: "3568", name: "3568 - ROTARY JOINTS (MFRS) (117 records)"},
      {value: "3569", name: "3569 - FILTERS-LIQUID (MFRS) (4097 records)"},
      {value: "3571", name: "3571 - COMPUTERS-ELECTRONIC-MANUFACTURERS (1432 records)"},
      {value: "3572", name: "3572 - COMPUTER STORAGE DEVICES (MFRS) (154 records)"},
      {value: "3575", name: "3575 - COMPUTER TERMINALS (MFRS) (40 records)"},
      {value: "3577", name: "3577 - COMPUTER PERIPHERALS (MFRS) (564 records)"},
      {value: "3578", name: "3578 - BANK EQUIPMENT & SUPPLIES-MANUFACTURERS (558 records)"},
      {value: "3579", name: "3579 - COPYING MACHINES & SUPPLIES-MFRS (1147 records)"},
      {value: "3581", name: "3581 - VENDING MACHINES-MANUFACTURERS (71 records)"},
      {value: "3582", name: "3582 - CARPET & RUG CLEANING EQUIP & SUPLS-MFRS (226 records)"},
      {value: "3585", name: "3585 - MARINE REFRIGERATION & AIR COND-MFRS (1551 records)"},
      {value: "3586", name: "3586 - SERVICE STATION EQUIPMENT-MANUFACTURERS (107 records)"},
      {value: "3589", name: "3589 - WATER PURIFICATION/FILTRATION EQPT-MFRS (2175 records)"},
      {value: "3592", name: "3592 - CARBURETORS PISTONS RINGS/VALVES (MFRS) (45 records)"},
      {value: "3593", name: "3593 - CYLINDERS-AIR & HYDRAULIC-MANUFACTURERS (171 records)"},
      {value: "3594", name: "3594 - FLUID POWER PUMPS & MOTORS (MFRS) (52 records)"},
      {value: "3596", name: "3596 - SCALES & BALANCES-EX LABORATORY (MFRS) (173 records)"},
      {value: "3599", name: "3599 - MACHINE SHOPS (MFRS) (37806 records)"},
      {value: "3612", name: "3612 - POWER PLANT EQUIPMENT-MANUFACTURERS (657 records)"},
      {value: "3613", name: "3613 - SWITCHGEAR/SWITCHBOARD APPARATUS (MFRS) (355 records)"},
      {value: "3621", name: "3621 - GENERATORS-ELECTRIC-MANUFACTURERS (1260 records)"},
      {value: "3624", name: "3624 - CARBON & GRAPHITE PRODUCTS (MFRS) (84 records)"},
      {value: "3625", name: "3625 - ELECTRONIC CONTROLS-MANUFACTURERS (711 records)"},
      {value: "3629", name: "3629 - ELECTRICAL INDSTRL APPARATUS NEC (MFRS) (133 records)"},
      {value: "3631", name: "3631 - BARBECUE EQUIPMENT & SUPPLIES-MFRS (133 records)"},
      {value: "3632", name: "3632 - REFRIGERATORS-MANUFACTURERS (36 records)"},
      {value: "3633", name: "3633 - HOUSEHOLD LAUNDRY EQUIPMENT (MFRS) (28 records)"},
      {value: "3634", name: "3634 - FOOD PROCESSORS-HOUSEHOLD (MFRS) (415 records)"},
      {value: "3635", name: "3635 - HOUSEHOLD VACUUM CLEANERS (MFRS) (569 records)"},
      {value: "3639", name: "3639 - SEWING MACHINES-MANUFACTURERS (380 records)"},
      {value: "3641", name: "3641 - LAMP BULBS-MANUFACTURERS (458 records)"},
      {value: "3643", name: "3643 - CONNECTORS-ELECTRIC-MANUFACTURERS (209 records)"},
      {value: "3644", name: "3644 - NONCURRENT-CARRYING WIRING DEVICES (MFR) (44 records)"},
      {value: "3645", name: "3645 - LIGHTING EQUIPMENT-MANUFACTURERS (766 records)"},
      {value: "3646", name: "3646 - COML IND INSTNL ELEC LIGHTING (MFRS) (77 records)"},
      {value: "3647", name: "3647 - VEHICULAR LIGHTING EQUIPMENT (MFRS) (46 records)"},
      {value: "3648", name: "3648 - LIGHTING EQUIPMENT NEC (MFRS) (390 records)"},
      {value: "3651", name: "3651 - SOUND SYSTEMS & EQUIPMENT-MANUFACTURERS (824 records)"},
      {value: "3652", name: "3652 - VIDEO TAPES & DISCS-MANUFACTURERS (297 records)"},
      {value: "3661", name: "3661 - TELEPHONE EQUIPMENT & SYSTEMS-MFRS (326 records)"},
      {value: "3663", name: "3663 - RADIO/TV BROADCASTING/COMM EQUIP (MFRS) (1146 records)"},
      {value: "3669", name: "3669 - TRAFFIC SIGNS SIGNALS & EQUIP-MFRS (2277 records)"},
      {value: "3671", name: "3671 - TELEVISION PICTURE TUBES-MANUFACTURERS (15 records)"},
      {value: "3672", name: "3672 - PRINTED & ETCHED CIRCUITS-MFRS (1147 records)"},
      {value: "3674", name: "3674 - SEMICONDUCTOR DEVICES (MFRS) (2648 records)"},
      {value: "3675", name: "3675 - ELECTRONIC CAPACITOR (MFRS) (61 records)"},
      {value: "3676", name: "3676 - RESISTORS (MFRS) (44 records)"},
      {value: "3677", name: "3677 - ELECTRONIC COIL & TRANSFORMERS (MFRS) (178 records)"},
      {value: "3678", name: "3678 - ELECTRONIC CONNECTORS (MFRS) (178 records)"},
      {value: "3679", name: "3679 - ELECTRONIC EQUIPMENT & SUPPLIES-MFRS (3200 records)"},
      {value: "3691", name: "3691 - STORAGE-BATTERIES (MFRS) (205 records)"},
      {value: "3692", name: "3692 - BATTERIES-DRY CELL-MANUFACTURERS (205 records)"},
      {value: "3694", name: "3694 - IGNITION INTERLOCK DEVICES (MFRS) (675 records)"},
      {value: "3695", name: "3695 - MAGNETIC/OPTICAL RECORDING MEDIA (MFRS) (109 records)"},
      {value: "3699", name: "3699 - ELECTRICAL PRODUCTS-MANUFACTURERS (1732 records)"},
      {value: "3711", name: "3711 - MOTOR VHCLS/PASSENGER CAR BODIES (MFRS) (1107 records)"},
      {value: "3713", name: "3713 - TRUCK BODIES-MANUFACTURERS (712 records)"},
      {value: "3714", name: "3714 - TRUCK EQUIPMENT & PARTS-MANUFACTURERS (5130 records)"},
      {value: "3715", name: "3715 - TRAILERS-TRUCK-MANUFACTURERS (330 records)"},
      {value: "3716", name: "3716 - MOTOR HOMES-MANUFACTURERS (31 records)"},
      {value: "3721", name: "3721 - AIRCRAFT-MANUFACTURERS (719 records)"},
      {value: "3724", name: "3724 - AIRCRAFT ENGINES & ENGINE PARTS-MFRS (303 records)"},
      {value: "3728", name: "3728 - AIRCRAFT COMPONENTS-MANUFACTURERS (1177 records)"},
      {value: "3731", name: "3731 - SHIP BUILDERS & REPAIRERS (MFRS) (751 records)"},
      {value: "3732", name: "3732 - BOATS-MANUFACTURERS (1633 records)"},
      {value: "3743", name: "3743 - RAILROAD CARS-MANUFACTURERS (441 records)"},
      {value: "3751", name: "3751 - MOTORCYCLES-SUPLS & PARTS-MANUFACTURERS (474 records)"},
      {value: "3761", name: "3761 - SPACE RESEARCH & DEVELOPMENT (MFRS) (36 records)"},
      {value: "3764", name: "3764 - GUIDED MSSLE/SPACE VHCL-PROP UNITS (MFR) (12 records)"},
      {value: "3769", name: "3769 - SPACE COMPONENTS & SYSTEMS (MFRS) (49 records)"},
      {value: "3792", name: "3792 - TRAILER-MANUFACTURERS & DESIGNERS (605 records)"},
      {value: "3795", name: "3795 - TANKS & TANK COMPONENTS (MFRS) (97 records)"},
      {value: "3799", name: "3799 - TRAILERS CARGO (609 records)"},
      {value: "3812", name: "3812 - AEROSPACE INDUSTRIES (MFRS) (3279 records)"},
      {value: "3821", name: "3821 - LABORATORY FURNITURE (MFRS) (447 records)"},
      {value: "3822", name: "3822 - ENERGY EQUIPMENT SYSTEMS-SUPPLIES (MFRS) (1696 records)"},
      {value: "3823", name: "3823 - INDUSTRIAL MEASURING/CONTROLLING INSTR (MFRS) (824 records)"},
      {value: "3824", name: "3824 - TOTALIZING FLUID MTRS/CNTNG DEVICE (MFR) (162 records)"},
      {value: "3825", name: "3825 - INSTRS-MEASURING/TESTING ELEC (MFRS) (432 records)"},
      {value: "3826", name: "3826 - LABORATORY ANALYTICAL INSTRUMENTS (MFRS) (214 records)"},
      {value: "3827", name: "3827 - MAGNIFYING GLASSES (MFRS) (259 records)"},
      {value: "3829", name: "3829 - THERMOMETERS-MANUFACTURERS (978 records)"},
      {value: "3841", name: "3841 - HOSPITAL EQUIPMENT & SUPPLIES-MFRS (7294 records)"},
      {value: "3842", name: "3842 - SAFETY EQUIPMENT & CLOTHING-MFRS (1139 records)"},
      {value: "3843", name: "3843 - DENTAL EQUIPMENT & SUPPLIES-MFRS (576 records)"},
      {value: "3844", name: "3844 - X-RAY APPARATUS & SUPPLIES-MANUFACTURERS (189 records)"},
      {value: "3845", name: "3845 - LASER MEDICAL (MFRS) (288 records)"},
      {value: "3851", name: "3851 - OPTICAL GOODS-MANUFACTURERS (899 records)"},
      {value: "3861", name: "3861 - MICROFILMING SERVICE EQUIP & SUPLS-MFRS (360 records)"},
      {value: "3873", name: "3873 - WATCHMAKERS (MFRS) (185 records)"},
      {value: "3911", name: "3911 - JEWELRY-MANUFACTURERS (3835 records)"},
      {value: "3914", name: "3914 - CUTLERY-MANUFACTURERS (TABLEWARE) (600 records)"},
      {value: "3915", name: "3915 - JEWELRY POLISHING (MFRS) (529 records)"},
      {value: "3931", name: "3931 - MUSICAL INSTRUMENTS-MANUFACTURERS (565 records)"},
      {value: "3942", name: "3942 - DOLLS & STUFFED TOYS (MFRS) (192 records)"},
      {value: "3944", name: "3944 - FUND RAISING GAMES MDSE & SUPLS-MFRS (986 records)"},
      {value: "3949", name: "3949 - HEALTH EQUIPMENT & SUPLS-MANUFACTURERS (2436 records)"},
      {value: "3951", name: "3951 - PENCILS (MFRS) (60 records)"},
      {value: "3952", name: "3952 - LEAD PENCILS CRAYONS/ARTISTS MTRL (MFRS) (150 records)"},
      {value: "3953", name: "3953 - RUBBER STAMPS-MANUFACTURERS (505 records)"},
      {value: "3955", name: "3955 - CARBON PAPER-MANUFACTURERS (19 records)"},
      {value: "3961", name: "3961 - COSTUME JWLRY/NOVELTIES-EX PRCS (MFRS) (226 records)"},
      {value: "3965", name: "3965 - FASTENERS-BUTTONS NEEDLES & PINS (MFRS) (191 records)"},
      {value: "3991", name: "3991 - BROOMS & BRUSHES (MFRS) (223 records)"},
      {value: "3993", name: "3993 - SIGNS & ADVERTISING SPECIALTIES (MFRS) (29246 records)"},
      {value: "3995", name: "3995 - BURIAL CASKETS (MFRS) (477 records)"},
      {value: "3996", name: "3996 - LINOLEUM-ASPHALT FELT BASE FLR CVR (MFR) (23 records)"},
      {value: "3999", name: "3999 - MANUFACTURERS (28463 records)"},
      {value: "4011", name: "4011 - RAILROADS (5352 records)"},
      {value: "4013", name: "4013 - RAILROAD SWITCHING & TERMINAL ESTBLSHMNT (28 records)"},
      {value: "4111", name: "4111 - SHUTTLE SERVICE (6730 records)"},
      {value: "4119", name: "4119 - LIMOUSINE SERVICE (44433 records)"},
      {value: "4121", name: "4121 - TAXICABS & TRANSPORTATION SERVICE (19281 records)"},
      {value: "4131", name: "4131 - BUS LINES (3595 records)"},
      {value: "4141", name: "4141 - LOCAL BUS CHARTER SERVICE (50 records)"},
      {value: "4142", name: "4142 - BUSES-CHARTER & RENTAL (4557 records)"},
      {value: "4151", name: "4151 - BUSES-SCHOOL TRANSPORTATION SERVICE (1897 records)"},
      {value: "4173", name: "4173 - TERMINAL & SERVICE FACILITIES (60 records)"},
      {value: "4212", name: "4212 - DELIVERY SERVICE (29078 records)"},
      {value: "4213", name: "4213 - TRUCKING (107637 records)"},
      {value: "4214", name: "4214 - MOVERS (30247 records)"},
      {value: "4215", name: "4215 - COURIER SERVICES (4280 records)"},
      {value: "4221", name: "4221 - COTTON COMPRESSES (6105 records)"},
      {value: "4222", name: "4222 - WAREHOUSES-COLD STORAGE (1509 records)"},
      {value: "4225", name: "4225 - STORAGE (78778 records)"},
      {value: "4226", name: "4226 - RECREATIONAL VEHICLES-STORAGE (2474 records)"},
      {value: "4231", name: "4231 - DISPATCH SERVICE-TRUCKING (196 records)"},
      {value: "4311", name: "4311 - POST OFFICES (39421 records)"},
      {value: "4412", name: "4412 - DEEP SEA-FOREIGN TRANSPORT OF FREIGHT (35 records)"},
      {value: "4424", name: "4424 - OCEAN-FREIGHT (62 records)"},
      {value: "4432", name: "4432 - FREIGHT-TRANSPORT ON THE GREAT LAKES (9 records)"},
      {value: "4449", name: "4449 - SHIPPING AGENTS (4447 records)"},
      {value: "4481", name: "4481 - DEEP SEA-PASSENGER TRANS-EXCEPT FERRY (64 records)"},
      {value: "4482", name: "4482 - FERRIES (250 records)"},
      {value: "4489", name: "4489 - BOATS-EXCURSIONS (1232 records)"},
      {value: "4491", name: "4491 - MARINE CARGO HANDLING (628 records)"},
      {value: "4492", name: "4492 - TOWING-MARINE (1417 records)"},
      {value: "4493", name: "4493 - BOAT REPAIRING (13208 records)"},
      {value: "4499", name: "4499 - SURVEYORS-MARINE (1779 records)"},
      {value: "4512", name: "4512 - AIRLINE COMPANIES (5465 records)"},
      {value: "4513", name: "4513 - EXPEDITERS (761 records)"},
      {value: "4522", name: "4522 - AIR AMBULANCE SERVICE (1268 records)"},
      {value: "4581", name: "4581 - AIRCRAFT SERVICING & MAINTENANCE (21714 records)"},
      {value: "4612", name: "4612 - PIPE LINE COMPANIES (5403 records)"},
      {value: "4613", name: "4613 - REFINED PETROLEUM PIPELINES (15 records)"},
      {value: "4619", name: "4619 - PIPELINES NEC (141 records)"},
      {value: "4724", name: "4724 - TRAVEL AGENCIES & BUREAUS (45044 records)"},
      {value: "4725", name: "4725 - TOURS-OPERATORS & PROMOTERS (8524 records)"},
      {value: "4729", name: "4729 - STEAMSHIP AGENCIES (1173 records)"},
      {value: "4731", name: "4731 - FREIGHT-FORWARDING (24160 records)"},
      {value: "4741", name: "4741 - RAILROAD CAR LEASING (112 records)"},
      {value: "4783", name: "4783 - PACKING & CRATING SERVICE (1507 records)"},
      {value: "4785", name: "4785 - WEIGHERS (287 records)"},
      {value: "4789", name: "4789 - TRANSPORTATION (17960 records)"},
      {value: "4812", name: "4812 - CELLULAR TELEPHONES (SERVICES) (71124 records)"},
      {value: "4813", name: "4813 - TELEPHONE COMPANIES (27762 records)"},
      {value: "4822", name: "4822 - TELEGRAM SERVICE (1061 records)"},
      {value: "4832", name: "4832 - RADIO STATIONS & BROADCASTING COMPANIES (22660 records)"},
      {value: "4833", name: "4833 - TELEVISION STATIONS & BROADCASTING CO (6282 records)"},
      {value: "4841", name: "4841 - TELEVISION-CABLE & CATV (11349 records)"},
      {value: "4899", name: "4899 - COMMUNICATIONS SERVICES-COMMON CARRIERS (23488 records)"},
      {value: "4911", name: "4911 - ELECTRIC COMPANIES (16412 records)"},
      {value: "4922", name: "4922 - GAS TRANSMISSION COMPANIES (422 records)"},
      {value: "4923", name: "4923 - NATURAL GAS TRANSMISSION & DISTRIBUTION (180 records)"},
      {value: "4924", name: "4924 - HEAT SUPPLYING COMPANIES (1081 records)"},
      {value: "4925", name: "4925 - GAS COMPANIES (5462 records)"},
      {value: "4931", name: "4931 - ELECTRIC & OTHER SERVICES-COMBINED (110 records)"},
      {value: "4932", name: "4932 - GAS SYSTEMS (46 records)"},
      {value: "4939", name: "4939 - UTILITIES-UNDERGROUND-CABLE LOCATING SVC (2135 records)"},
      {value: "4941", name: "4941 - WATER & SEWAGE COMPANIES-UTILITY (14992 records)"},
      {value: "4952", name: "4952 - SEWAGE TREATMENT EQUIPMENT (823 records)"},
      {value: "4953", name: "4953 - GARBAGE COLLECTION (24546 records)"},
      {value: "4959", name: "4959 - OIL SPILL CLEAN UP SERVICE (3745 records)"},
      {value: "4961", name: "4961 - GEOTHERMAL HEAT PUMPS (50 records)"},
      {value: "4971", name: "4971 - HYDROLOGISTS (707 records)"},
      {value: "5012", name: "5012 - AUTOMOBILE AUCTIONS (WHLS) (7410 records)"},
      {value: "5013", name: "5013 - TRUCK ACCESSORIES (WHLS) (15689 records)"},
      {value: "5014", name: "5014 - TIRE-DEALERS-USED (WHLS) (3193 records)"},
      {value: "5015", name: "5015 - AUTOMOBILE WRECKING (WHLS) (14157 records)"},
      {value: "5021", name: "5021 - OFFICE FURNITURE & EQUIP-DEALERS (WHLS) (8451 records)"},
      {value: "5023", name: "5023 - FLOOR MATERIALS-WHOLESALE (5607 records)"},
      {value: "5031", name: "5031 - TIMBER & TIMBERLAND COMPANIES (WHLS) (11411 records)"},
      {value: "5032", name: "5032 - SAND & GRAVEL (WHLS) (18815 records)"},
      {value: "5033", name: "5033 - ROOFING MATERIALS-WHOLESALE (1842 records)"},
      {value: "5039", name: "5039 - FENCE (WHLS) (11283 records)"},
      {value: "5043", name: "5043 - AUDIO-VISUAL EQUIPMENT & SUPLS (WHLS) (1747 records)"},
      {value: "5044", name: "5044 - COPYING & DUPLICATING MACHINES & SUPLS (13090 records)"},
      {value: "5045", name: "5045 - COMPUTERS-WHOLESALE (2630 records)"},
      {value: "5046", name: "5046 - RESTAURANT EQUIPMENT & SUPPLIES (WHLS) (9194 records)"},
      {value: "5047", name: "5047 - PHYSICIANS & SURGEONS EQUIP & SUPLS-WHLS (29368 records)"},
      {value: "5048", name: "5048 - OPTICAL EQUIPMENT MACHINERY/SUPLS (WHLS) (1189 records)"},
      {value: "5049", name: "5049 - LABORATORY EQUIPMENT & SUPPLIES (WHLS) (3440 records)"},
      {value: "5051", name: "5051 - ALUMINUM (WHLS) (11913 records)"},
      {value: "5052", name: "5052 - COPPER (WHLS) (441 records)"},
      {value: "5063", name: "5063 - TRAFFIC SIGNS SIGNALS & EQUIP-WHLS (60976 records)"},
      {value: "5064", name: "5064 - APPLIANCES-HOUSEHOLD-MAJOR-WHOLESALE (2220 records)"},
      {value: "5065", name: "5065 - SOUND SYSTEMS & EQUIPMENT (WHLS) (12460 records)"},
      {value: "5072", name: "5072 - FASTENERS-INDUSTRIAL (WHLS) (10156 records)"},
      {value: "5074", name: "5074 - BOILERS-SUPPLIES & PARTS (WHLS) (11948 records)"},
      {value: "5075", name: "5075 - HEATING EQUIPMENT & SYSTEMS (WHLS) (7759 records)"},
      {value: "5078", name: "5078 - REFRIGERATING EQUIP-COMMERCIAL (WHLS) (5842 records)"},
      {value: "5082", name: "5082 - CONTRACTORS-EQUIP/SUPLS-DLRS/SVC (WHLS) (12186 records)"},
      {value: "5083", name: "5083 - TRACTOR-DEALERS (WHLS) (16444 records)"},
      {value: "5084", name: "5084 - GAUGES & GAGES (WHLS) (42114 records)"},
      {value: "5085", name: "5085 - VALVES-WHOLESALE (25140 records)"},
      {value: "5087", name: "5087 - BEAUTY SALONS-EQUIPMENT & SUPLS (WHLS) (23476 records)"},
      {value: "5088", name: "5088 - AIRCRAFT AVIONICS-SALES & SERVICE (WHLS) (3210 records)"},
      {value: "5091", name: "5091 - SPORTING GOODS-WHOLESALE (6682 records)"},
      {value: "5092", name: "5092 - WHOLESALERS (12192 records)"},
      {value: "5093", name: "5093 - SCRAP METALS & IRON (WHLS) (21332 records)"},
      {value: "5094", name: "5094 - JEWELERS-WHOLESALE (9980 records)"},
      {value: "5099", name: "5099 - IMPORTERS (WHLS) (45381 records)"},
      {value: "5111", name: "5111 - PAPER PRODUCTS (WHLS) (2420 records)"},
      {value: "5112", name: "5112 - LEGAL FORMS (WHLS) (6232 records)"},
      {value: "5113", name: "5113 - PACKAGING MATERIALS-WHOLESALE (5993 records)"},
      {value: "5122", name: "5122 - PHARMACEUTICAL PRODUCTS-WHOLESALE (7782 records)"},
      {value: "5131", name: "5131 - WOOLEN GOODS-WHOLESALE (3114 records)"},
      {value: "5136", name: "5136 - T-SHIRTS-WHOLESALE (4429 records)"},
      {value: "5137", name: "5137 - FUR BUSINESS-WHOLESALE (3683 records)"},
      {value: "5139", name: "5139 - SHOES-WHOLESALE (706 records)"},
      {value: "5141", name: "5141 - FOOD BROKERS (WHLS) (13659 records)"},
      {value: "5142", name: "5142 - FROZEN FOODS-WHOLESALE (1094 records)"},
      {value: "5143", name: "5143 - ICE CREAM & FROZEN DESSERTS-DISTR (WHLS) (2001 records)"},
      {value: "5144", name: "5144 - POULTRY SERVICES (WHLS) (976 records)"},
      {value: "5145", name: "5145 - POTATO CHIPS (WHLS) (2681 records)"},
      {value: "5146", name: "5146 - SEAFOOD-WHOLESALE (2722 records)"},
      {value: "5147", name: "5147 - MEAT-WHOLESALE (3158 records)"},
      {value: "5148", name: "5148 - FRUITS & VEGETABLES-WHOLESALE (6362 records)"},
      {value: "5149", name: "5149 - CHOCOLATE & COCOA (WHLS) (15943 records)"},
      {value: "5153", name: "5153 - GRAIN-DEALERS (WHLS) (1824 records)"},
      {value: "5154", name: "5154 - LIVESTOCK-DEALERS (WHLS) (3588 records)"},
      {value: "5159", name: "5159 - PEANUT PRODUCTS (WHLS) (1109 records)"},
      {value: "5162", name: "5162 - PLASTICS-PRODUCTS-FINISHED-WHOLESALE (912 records)"},
      {value: "5169", name: "5169 - EXPLOSIVES (WHLS) (10838 records)"},
      {value: "5171", name: "5171 - GASOLINE & OIL BULK STATIONS (WHLS) (539 records)"},
      {value: "5172", name: "5172 - OILS-FUEL (WHLS) (35583 records)"},
      {value: "5181", name: "5181 - BEER & ALE-WHOLESALE (3003 records)"},
      {value: "5182", name: "5182 - WINES-WHOLESALE (2325 records)"},
      {value: "5191", name: "5191 - FEED-DEALERS (WHLS) (23534 records)"},
      {value: "5192", name: "5192 - BOOK DEALERS-WHOLESALE (1093 records)"},
      {value: "5193", name: "5193 - FLORISTS-WHOLESALE (5014 records)"},
      {value: "5194", name: "5194 - CIGAR CIGARETTE & TOBACCO DEALERS-WHLS (1387 records)"},
      {value: "5198", name: "5198 - WALLPAPERS & WALLCOVERINGS-WHOLESALE (1271 records)"},
      {value: "5199", name: "5199 - ADVERTISING-SPECIALTIES (WHLS) (15684 records)"},
      {value: "5211", name: "5211 - INSULATION MATERIALS-COLD & HEAT (107328 records)"},
      {value: "5231", name: "5231 - GLASS-AUTO PLATE & WINDOW & ETC (48424 records)"},
      {value: "5251", name: "5251 - TOOLS-HAND (26821 records)"},
      {value: "5261", name: "5261 - GARDEN CENTERS (42566 records)"},
      {value: "5271", name: "5271 - MOBILE OFFICES & COMMERCIAL UNITS (3265 records)"},
      {value: "5311", name: "5311 - RETAIL SHOPS (44079 records)"},
      {value: "5331", name: "5331 - VARIETY STORES (45974 records)"},
      {value: "5399", name: "5399 - GENERAL MERCHANDISE-RETAIL (37336 records)"},
      {value: "5411", name: "5411 - GROCERS-RETAIL (205997 records)"},
      {value: "5421", name: "5421 - SEAFOOD-RETAIL (18069 records)"},
      {value: "5431", name: "5431 - FRUITS & VEGETABLES & PRODUCE-RETAIL (14042 records)"},
      {value: "5441", name: "5441 - POPCORN & POPCORN SUPPLIES (11324 records)"},
      {value: "5451", name: "5451 - YOGURT (9386 records)"},
      {value: "5461", name: "5461 - BAKERS-RETAIL (67860 records)"},
      {value: "5499", name: "5499 - HEALTH & DIET FOODS-RETAIL (44410 records)"},
      {value: "5511", name: "5511 - AUTOMOBILE DEALERS-USED CARS (147455 records)"},
      {value: "5521", name: "5521 - TRUCK-DEALERS-USED (4902 records)"},
      {value: "5531", name: "5531 - TIRE-DEALERS-RETAIL (109761 records)"},
      {value: "5541", name: "5541 - SERVICE STATIONS-GASOLINE & OIL (108766 records)"},
      {value: "5551", name: "5551 - BOAT DEALERS SALES & SERVICE (17650 records)"},
      {value: "5561", name: "5561 - RECREATIONAL VEHICLES (7609 records)"},
      {value: "5571", name: "5571 - ALL TERRAIN VEHICLES (15127 records)"},
      {value: "5599", name: "5599 - AIRCRAFT EQUIPMENT PARTS & SUPPLIES (10317 records)"},
      {value: "5611", name: "5611 - LEATHER CLOTHING-RETAIL (14505 records)"},
      {value: "5621", name: "5621 - BRIDAL SHOPS (56285 records)"},
      {value: "5632", name: "5632 - HANDBAGS (11976 records)"},
      {value: "5641", name: "5641 - INFANTS WEAR (13534 records)"},
      {value: "5651", name: "5651 - CLOTHING-RETAIL (63207 records)"},
      {value: "5661", name: "5661 - SHOES-RETAIL (39322 records)"},
      {value: "5699", name: "5699 - DANCING SUPPLIES (52356 records)"},
      {value: "5712", name: "5712 - CABINETS (91140 records)"},
      {value: "5713", name: "5713 - FLOOR COVERINGS-RETAIL (29574 records)"},
      {value: "5714", name: "5714 - DRAPERY INSTALLATION SERVICE (7323 records)"},
      {value: "5719", name: "5719 - GLASS-CARVED ORNAMENTAL BEVELED ETC (33479 records)"},
      {value: "5722", name: "5722 - VACUUM CLEANERS-HOUSEHOLD-DEALERS (23540 records)"},
      {value: "5731", name: "5731 - VIDEO RECORDERS & PLAYERS (53865 records)"},
      {value: "5734", name: "5734 - COMPUTER & EQUIPMENT DEALERS (73032 records)"},
      {value: "5735", name: "5735 - VIDEO TAPES DISCS & CASSETTES (5169 records)"},
      {value: "5736", name: "5736 - PIANOS (14741 records)"},
      {value: "5812", name: "5812 - DELICATESSENS (885501 records)"},
      {value: "5813", name: "5813 - BARS (60578 records)"},
      {value: "5912", name: "5912 - PHARMACIES (85231 records)"},
      {value: "5921", name: "5921 - LIQUORS-RETAIL (48437 records)"},
      {value: "5932", name: "5932 - CARPET & RUG DEALERS-USED (96826 records)"},
      {value: "5941", name: "5941 - FISHING TACKLE-DEALERS (68995 records)"},
      {value: "5942", name: "5942 - BOOK DEALERS-RETAIL (20964 records)"},
      {value: "5943", name: "5943 - SCRAPBOOK ALBUMS & SUPPLIES (15986 records)"},
      {value: "5944", name: "5944 - JEWELERS-RETAIL (60933 records)"},
      {value: "5945", name: "5945 - CERAMIC EQUIPMENT & SUPPLIES (36152 records)"},
      {value: "5946", name: "5946 - PHOTOGRAPHIC EQUIP & SUPPLIES-RETAIL (1854 records)"},
      {value: "5947", name: "5947 - GIFT SHOPS (75986 records)"},
      {value: "5948", name: "5948 - LEATHER GOODS-DEALERS (3549 records)"},
      {value: "5949", name: "5949 - YARN-RETAIL (10875 records)"},
      {value: "5961", name: "5961 - E-COMMERCE (19417 records)"},
      {value: "5962", name: "5962 - VENDING MACHINES (9088 records)"},
      {value: "5963", name: "5963 - DIRECT SELLING ESTABLISHMENTS (13082 records)"},
      {value: "5983", name: "5983 - FUEL-RETAIL (582 records)"},
      {value: "5984", name: "5984 - PROPANE (LP) GAS (8490 records)"},
      {value: "5989", name: "5989 - FIREWOOD (1946 records)"},
      {value: "5992", name: "5992 - FLORISTS-RETAIL (48197 records)"},
      {value: "5993", name: "5993 - CIGAR CIGARETTE & TOBACCO DEALERS-RETAIL (26519 records)"},
      {value: "5994", name: "5994 - NEWS DEALERS (3548 records)"},
      {value: "5995", name: "5995 - OPTICAL GOODS-RETAIL (28463 records)"},
      {value: "5999", name: "5999 - ART GALLERIES & DEALERS (213402 records)"},
      {value: "6011", name: "6011 - FEDERAL RESERVE BANKS (31 records)"},
      {value: "6019", name: "6019 - CENTRAL RESERVE DEPOSITORY INSTN NEC (1 records)"},
      {value: "6021", name: "6021 - TRUST COMPANIES (448278 records)"},
      {value: "6022", name: "6022 - STATE COMMERCIAL BANKS (127 records)"},
      {value: "6029", name: "6029 - COMMERCIAL BANKS NEC (172 records)"},
      {value: "6035", name: "6035 - SAVINGS & LOAN ASSOCIATIONS (916 records)"},
      {value: "6036", name: "6036 - SAVINGS INSTITUTIONS-EXCEPT FEDERAL (28 records)"},
      {value: "6061", name: "6061 - CREDIT UNIONS (25280 records)"},
      {value: "6062", name: "6062 - CREDIT UNIONS NOT FEDERALLY CHARTERED (35 records)"},
      {value: "6081", name: "6081 - BRANCHES & AGENCIES OF FOREIGN BANKS (31 records)"},
      {value: "6082", name: "6082 - FOREIGN TRADE & INTERNATIONAL BANKS (38 records)"},
      {value: "6091", name: "6091 - FIDUCIARIES (47 records)"},
      {value: "6099", name: "6099 - ESCROW SERVICE (20743 records)"},
      {value: "6111", name: "6111 - COUNTY GOVT-ADM OF FED CREDIT AGENCIES (101 records)"},
      {value: "6141", name: "6141 - LOANS (57629 records)"},
      {value: "6153", name: "6153 - CREDIT CARD & OTHER CREDIT PLANS (3230 records)"},
      {value: "6159", name: "6159 - MISC BUSINESS CREDIT INSTITUTIONS (511 records)"},
      {value: "6162", name: "6162 - REAL ESTATE LOANS (62958 records)"},
      {value: "6163", name: "6163 - LOAN BROKERAGE (652 records)"},
      {value: "6211", name: "6211 - INVESTMENT SECURITIES (49043 records)"},
      {value: "6221", name: "6221 - COTTON BROKERS (1920 records)"},
      {value: "6231", name: "6231 - BARTER & TRADE EXCHANGE (688 records)"},
      {value: "6282", name: "6282 - FINANCING CONSULTANTS (147776 records)"},
      {value: "6289", name: "6289 - SERVICES ALLIED WITH EXCH OF SEC NEC (229 records)"},
      {value: "6311", name: "6311 - LIFE INSURANCE (UNDERWRITERS) (637 records)"},
      {value: "6321", name: "6321 - HEALTH SYSTEMS EVALUATION CONSULTANTS (99 records)"},
      {value: "6324", name: "6324 - HOSPITALIZATION PLANS-MEDICAL & SURGICAL (2405 records)"},
      {value: "6331", name: "6331 - INSURANCE-PROPERTY & CASUALTY (2485 records)"},
      {value: "6351", name: "6351 - BONDS-BAIL (26574 records)"},
      {value: "6361", name: "6361 - INSURANCE-TITLE (1287 records)"},
      {value: "6371", name: "6371 - PENSION & PROFIT SHARING PLANS (3084 records)"},
      {value: "6399", name: "6399 - REAL ESTATE MAINTENANCE PROTECTION PLANS (235 records)"},
      {value: "6411", name: "6411 - INSURANCE (379555 records)"},
      {value: "6512", name: "6512 - SHOPPING CENTERS & MALLS (89002 records)"},
      {value: "6513", name: "6513 - APARTMENTS (171992 records)"},
      {value: "6514", name: "6514 - TOWNHOUSES (785 records)"},
      {value: "6515", name: "6515 - MOBILE HOMES-PARKS & COMMUNITIES (12529 records)"},
      {value: "6517", name: "6517 - LESSORS OF RAILROAD PROPERTY (4 records)"},
      {value: "6519", name: "6519 - LESSORS OF REAL PROPERTY NEC (1618 records)"},
      {value: "6531", name: "6531 - COOPERATIVES (475516 records)"},
      {value: "6541", name: "6541 - TITLE COMPANIES (24688 records)"},
      {value: "6552", name: "6552 - REAL ESTATE DEVELOPERS (23281 records)"},
      {value: "6553", name: "6553 - CEMETERIES (13659 records)"},
      {value: "6712", name: "6712 - HOLDING COMPANIES (BANK) (3773 records)"},
      {value: "6719", name: "6719 - HOLDING COMPANIES (NON-BANK) (12961 records)"},
      {value: "6722", name: "6722 - MANAGEMENT INVESTMENT OPEN-END (96 records)"},
      {value: "6726", name: "6726 - INSURANCE ANNUITIES (1675 records)"},
      {value: "6732", name: "6732 - EDUCATIONAL SCHOLARSHIP PLANS (746 records)"},
      {value: "6733", name: "6733 - TRUSTEES (1284 records)"},
      {value: "6792", name: "6792 - OIL-CRUDE-PURCHASING (227 records)"},
      {value: "6794", name: "6794 - FRANCHISING (684 records)"},
      {value: "6798", name: "6798 - REAL ESTATE INVESTMENT TRUSTS (1135 records)"},
      {value: "6799", name: "6799 - INVESTORS NEC (6582 records)"},
      {value: "7011", name: "7011 - BED & BREAKFAST ACCOMMODATIONS (122204 records)"},
      {value: "7021", name: "7021 - HOSTELS (4133 records)"},
      {value: "7032", name: "7032 - CAMPS (9164 records)"},
      {value: "7033", name: "7033 - CAMPGROUNDS (14762 records)"},
      {value: "7041", name: "7041 - ORGANIZATION HOTELS ON MEMBERSHIP BASIS (58 records)"},
      {value: "7110", name: "7110 - Soil Preparation Sv (13 records)"},
      {value: "7210", name: "7210 - Crops-planting/protecting (71 records)"},
      {value: "7211", name: "7211 - LAUNDRIES (8536 records)"},
      {value: "7212", name: "7212 - CLEANERS (53091 records)"},
      {value: "7213", name: "7213 - LINEN SUPPLY SERVICE (2731 records)"},
      {value: "7215", name: "7215 - LAUNDRIES-SELF SERVICE (11239 records)"},
      {value: "7216", name: "7216 - DRAPERY & CURTAIN CLEANERS (681 records)"},
      {value: "7217", name: "7217 - CARPET & RUG REPAIRING (44846 records)"},
      {value: "7218", name: "7218 - UNIFORM RENTAL SERVICE (497 records)"},
      {value: "7219", name: "7219 - WEAVING & MENDING (1172 records)"},
      {value: "7220", name: "7220 - Crop Harvesting Services (11 records)"},
      {value: "7221", name: "7221 - PHOTOGRAPHERS-PORTRAIT (51104 records)"},
      {value: "7229", name: "7229 - Crop Harvesting Services (2 records)"},
      {value: "7230", name: "7230 - Crop Preparation For Market (8 records)"},
      {value: "7231", name: "7231 - BEAUTY SALONS (389833 records)"},
      {value: "7240", name: "7240 - Cotton Ginning Services (4 records)"},
      {value: "7241", name: "7241 - BARBERS (46692 records)"},
      {value: "7251", name: "7251 - SHOE & BOOT REPAIRING (5003 records)"},
      {value: "7261", name: "7261 - FUNERAL DIRECTORS (33408 records)"},
      {value: "7291", name: "7291 - TAX RETURN PREPARATION & FILING (78733 records)"},
      {value: "7299", name: "7299 - SINGLES ORGANIZATIONS (141174 records)"},
      {value: "7311", name: "7311 - ADVERTISING-AGENCIES & COUNSELORS (28259 records)"},
      {value: "7312", name: "7312 - ADVERTISING-OUTDOOR (2199 records)"},
      {value: "7313", name: "7313 - ADVERTISING-TELEVISION (2772 records)"},
      {value: "7319", name: "7319 - DISTRIBUTING SERVICE-CIRCULAR & SAMPLE (37725 records)"},
      {value: "7322", name: "7322 - COLLECTION AGENCIES (7181 records)"},
      {value: "7323", name: "7323 - CREDIT REPORTING AGENCIES (1106 records)"},
      {value: "7331", name: "7331 - ADVERTISING-DIRECT MAIL (15680 records)"},
      {value: "7334", name: "7334 - COPYING & DUPLICATING SERVICE (5790 records)"},
      {value: "7335", name: "7335 - PHOTOGRAPHERS-COMMERCIAL (16299 records)"},
      {value: "7336", name: "7336 - ARTISTS-COMMERCIAL (37187 records)"},
      {value: "7338", name: "7338 - RESUME SERVICE (11825 records)"},
      {value: "7342", name: "7342 - PEST CONTROL (55701 records)"},
      {value: "7349", name: "7349 - JANITOR SERVICE (117857 records)"},
      {value: "7352", name: "7352 - DISABLED PERSONS EQUIPMENT & SUPPLIES (760 records)"},
      {value: "7353", name: "7353 - CRANE SERVICE (4122 records)"},
      {value: "7359", name: "7359 - AIRCRAFT CHARTER RENTAL & LEASING SVC (45016 records)"},
      {value: "7361", name: "7361 - EMPLOYMENT AGENCIES & OPPORTUNITIES (46650 records)"},
      {value: "7363", name: "7363 - EMPLOYMENT CONTRACTORS-TEMPORARY HELP (12174 records)"},
      {value: "7371", name: "7371 - COMPUTER SERVICES (41803 records)"},
      {value: "7372", name: "7372 - INSTRUMENTATION SERVICES (5761 records)"},
      {value: "7373", name: "7373 - WEBSITE DESIGN SERVICE (23927 records)"},
      {value: "7374", name: "7374 - INTERNET SERVICE (42634 records)"},
      {value: "7375", name: "7375 - ONLINE SERVICES (1631 records)"},
      {value: "7376", name: "7376 - COMPUTER ROOMS MONITORING & MANAGEMENT (45 records)"},
      {value: "7377", name: "7377 - COMPUTERS-RENTING & LEASING (458 records)"},
      {value: "7378", name: "7378 - COMPUTERS-SERVICE & REPAIR (35711 records)"},
      {value: "7379", name: "7379 - COMPUTERS-NETWORKING (16417 records)"},
      {value: "7381", name: "7381 - SECURITY GUARD & PATROL SERVICE (31759 records)"},
      {value: "7382", name: "7382 - SECURITY SYSTEMS (10948 records)"},
      {value: "7383", name: "7383 - NEWS SERVICE (1264 records)"},
      {value: "7384", name: "7384 - PHOTO FINISHING-RETAIL (8755 records)"},
      {value: "7389", name: "7389 - DRAFTING SERVICES (353032 records)"},
      {value: "7420", name: "7420 - Veterinary Spc Svs (1146 records)"},
      {value: "7421", name: "7421 - Veterinary Spc Svs (1 records)"},
      {value: "7510", name: "7510 - Livestock Services (133 records)"},
      {value: "7511", name: "7511 - Livestock Services (1 records)"},
      {value: "7513", name: "7513 - MOVING-SELF-SERVICE (41725 records)"},
      {value: "7514", name: "7514 - AUTOMOBILE RENTING (25626 records)"},
      {value: "7515", name: "7515 - PASSENGER CAR LEASING (26 records)"},
      {value: "7519", name: "7519 - TRAILER RENTING & LEASING (2820 records)"},
      {value: "7520", name: "7520 - Grooming Svcs Pets (1018 records)"},
      {value: "7521", name: "7521 - PARKING STATIONS & GARAGES (18511 records)"},
      {value: "7522", name: "7522 - Grooming Svcs Pets (24 records)"},
      {value: "7523", name: "7523 - Grooming Svcs Pets (8 records)"},
      {value: "7524", name: "7524 - Grooming Svcs Pets (4 records)"},
      {value: "7532", name: "7532 - AUTOMOBILE BODY-REPAIRING & PAINTING (75477 records)"},
      {value: "7533", name: "7533 - MUFFLERS & EXHAUST SYSTEMS-ENGINE (4548 records)"},
      {value: "7534", name: "7534 - TIRE SERVICE (1983 records)"},
      {value: "7536", name: "7536 - AUTOMOBILE GLASS-SERVICE & INSTALLATION (15142 records)"},
      {value: "7537", name: "7537 - TRANSMISSIONS-AUTOMOBILE (11585 records)"},
      {value: "7538", name: "7538 - AUTOMOBILE REPAIRING & SERVICE (206388 records)"},
      {value: "7539", name: "7539 - AUTOMOBILE RADIATOR REPAIRING (13831 records)"},
      {value: "7542", name: "7542 - CAR WASHING & POLISHING (38126 records)"},
      {value: "7549", name: "7549 - WRECKER SERVICE (53171 records)"},
      {value: "7610", name: "7610 - Farm Labor Contractor (5 records)"},
      {value: "7619", name: "7619 - Farm Labor Contractor (7 records)"},
      {value: "7620", name: "7620 - Farm Management Services (151 records)"},
      {value: "7622", name: "7622 - TELEVISION & RADIO-SERVICE/REPAIR (6206 records)"},
      {value: "7623", name: "7623 - REFRIGERATING EQUIP-COMMERCIAL-SERVICE (10590 records)"},
      {value: "7629", name: "7629 - HEARING AIDS-PARTS & REPAIRING (48565 records)"},
      {value: "7631", name: "7631 - WATCHES-REPAIRING (5974 records)"},
      {value: "7641", name: "7641 - UPHOLSTERERS (20093 records)"},
      {value: "7692", name: "7692 - WELDING (20188 records)"},
      {value: "7694", name: "7694 - OUTBOARD MOTORS-REPAIRING (472 records)"},
      {value: "7699", name: "7699 - CONTRACTORS-EQUIPMENT & SUPLS-REPAIR (116771 records)"},
      {value: "7810", name: "7810 - Landscape Architects (402 records)"},
      {value: "7811", name: "7811 - Landscape Architects (15 records)"},
      {value: "7812", name: "7812 - VIDEO PRODUCTION & TAPING SERVICE (23944 records)"},
      {value: "7819", name: "7819 - VIDEO TAPE DUPLICATION SERVICE (5055 records)"},
      {value: "7820", name: "7820 - Lawn/garden Services (5223 records)"},
      {value: "7821", name: "7821 - Lawn/garden Services (45 records)"},
      {value: "7822", name: "7822 - MOTION PICTURE FILM-DISTRS & EXCHS (560 records)"},
      {value: "7829", name: "7829 - MOTION PICTURE FILM-LIBRARIES (76 records)"},
      {value: "7830", name: "7830 - Orna Shrub, Tree Sv (1496 records)"},
      {value: "7832", name: "7832 - THEATRES-MOVIE (11142 records)"},
      {value: "7833", name: "7833 - DRIVE-IN MOTION PICTURE THEATERS (366 records)"},
      {value: "7839", name: "7839 - Orna Shrub, Tree Sv (3 records)"},
      {value: "7841", name: "7841 - VIDEO TAPES & DISCS-RENTING & LEASING (10737 records)"},
      {value: "7911", name: "7911 - DANCING INSTRUCTION (21185 records)"},
      {value: "7922", name: "7922 - DANCE COMPANIES (19325 records)"},
      {value: "7929", name: "7929 - ENTERTAINMENT BUREAUS (41765 records)"},
      {value: "7933", name: "7933 - BOWLING CENTERS (5927 records)"},
      {value: "7941", name: "7941 - SOCCER CLUBS (7963 records)"},
      {value: "7948", name: "7948 - RACE TRACKS (4784 records)"},
      {value: "7991", name: "7991 - HEALTH CLUBS STUDIOS & GYMNASIUMS (58669 records)"},
      {value: "7992", name: "7992 - GOLF COURSES (20505 records)"},
      {value: "7993", name: "7993 - ARCADES (5927 records)"},
      {value: "7996", name: "7996 - AMUSEMENT PLACES (3503 records)"},
      {value: "7997", name: "7997 - HOCKEY CLUBS (14890 records)"},
      {value: "7999", name: "7999 - PSYCHIC MEDIUMS (136367 records)"},
      {value: "8011", name: "8011 - PHYSICIANS & SURGEONS (1208693 records)"},
      {value: "8021", name: "8021 - DENTISTS (272891 records)"},
      {value: "8031", name: "8031 - OFFICES & CLINICS OF DOCTORS-OSTEOPATHY (40 records)"},
      {value: "8041", name: "8041 - CHIROPRACTORS DC (77057 records)"},
      {value: "8042", name: "8042 - OPTOMETRISTS OD (57253 records)"},
      {value: "8043", name: "8043 - PODIATRISTS (20636 records)"},
      {value: "8049", name: "8049 - PHYSICAL THERAPISTS (668515 records)"},
      {value: "8051", name: "8051 - HEALTH CARE FACILITIES (14605 records)"},
      {value: "8052", name: "8052 - INTERMEDIATE CARE FACILITIES (137 records)"},
      {value: "8059", name: "8059 - HOMES & INSTITUTIONS (28297 records)"},
      {value: "8062", name: "8062 - HOSPITALS (23407 records)"},
      {value: "8063", name: "8063 - MENTAL HEALTH SERVICES (22354 records)"},
      {value: "8069", name: "8069 - BIRTH CENTERS (2213 records)"},
      {value: "8071", name: "8071 - DRUG DETECTION SERVICE & EQUIPMENT (30213 records)"},
      {value: "8072", name: "8072 - LABORATORIES-DENTAL (11701 records)"},
      {value: "8082", name: "8082 - HOME HEALTH SERVICE (66676 records)"},
      {value: "8092", name: "8092 - DIALYSIS (5510 records)"},
      {value: "8093", name: "8093 - SURGICAL CENTERS (7755 records)"},
      {value: "8099", name: "8099 - MEDICAL EMERGENCY TRAINING (77393 records)"},
      {value: "8111", name: "8111 - ATTORNEYS SERVICE BUREAUS (491415 records)"},
      {value: "8119", name: "8119 - Timber Tract Operation (3 records)"},
      {value: "8211", name: "8211 - EDUCATIONAL PROGRAMS (211249 records)"},
      {value: "8221", name: "8221 - SCHOOLS-UNIVERSITIES & COLLEGES ACADEMIC (34059 records)"},
      {value: "8222", name: "8222 - JUNIOR COLLEGES & TECHNICAL INSTITUTES (3543 records)"},
      {value: "8231", name: "8231 - LIBRARIES-PUBLIC (28743 records)"},
      {value: "8243", name: "8243 - COMPUTER TRAINING (2666 records)"},
      {value: "8244", name: "8244 - SCHOOLS-BUSINESS & SECRETARIAL (2149 records)"},
      {value: "8249", name: "8249 - TELEVISION & RADIO BROADCASTING SCHOOLS (4088 records)"},
      {value: "8299", name: "8299 - AIRCRAFT SCHOOLS (70316 records)"},
      {value: "8310", name: "8310 - Forest Product Gathering General Crop Farm (7 records)"},
      {value: "8319", name: "8319 - Forest Product Gathering General Crop Farm (1 records)"},
      {value: "8322", name: "8322 - SOCIAL SERVICE & WELFARE ORGANIZATIONS (323368 records)"},
      {value: "8331", name: "8331 - EMPLOYMENT TRAINING SERVICE (30130 records)"},
      {value: "8351", name: "8351 - CHILD CARE SERVICE (127826 records)"},
      {value: "8361", name: "8361 - RESIDENTIAL CARE HOMES (46730 records)"},
      {value: "8399", name: "8399 - ALCOHOLISM INFORMATION & TREATMENT CTRS (92855 records)"},
      {value: "8412", name: "8412 - NATIONAL MONUMENTS (27097 records)"},
      {value: "8422", name: "8422 - AQUARIUMS-PUBLIC (1775 records)"},
      {value: "8510", name: "8510 - Foresters-Consulting (100 records)"},
      {value: "8611", name: "8611 - ANCSA NATIVE CORPORATIONS (76037 records)"},
      {value: "8621", name: "8621 - PROFESSIONAL ORGANIZATIONS (3963 records)"},
      {value: "8631", name: "8631 - LABOR ORGANIZATIONS (15415 records)"},
      {value: "8641", name: "8641 - NATIVES SERVICE ORGANIZATIONS (69607 records)"},
      {value: "8651", name: "8651 - POLITICAL ORGANIZATIONS (4936 records)"},
      {value: "8661", name: "8661 - CHURCH ORGANIZATIONS (500852 records)"},
      {value: "8699", name: "8699 - ORGANIZATIONS (29175 records)"},
      {value: "8711", name: "8711 - ENGINEERS (82722 records)"},
      {value: "8712", name: "8712 - ARCHITECTS (53177 records)"},
      {value: "8713", name: "8713 - SURVEYORS-LAND (15858 records)"},
      {value: "8721", name: "8721 - ACCOUNTANTS (141890 records)"},
      {value: "8731", name: "8731 - ERGONOMICS (38389 records)"},
      {value: "8732", name: "8732 - ECONOMIC RESEARCH & ANALYSIS (16502 records)"},
      {value: "8733", name: "8733 - FOUNDATION-EDUC PHILANTHROPIC RESEARCH (16569 records)"},
      {value: "8734", name: "8734 - LABORATORIES-TESTING (11285 records)"},
      {value: "8741", name: "8741 - MANAGEMENT SERVICES (37836 records)"},
      {value: "8742", name: "8742 - BUSINESS MANAGEMENT CONSULTANTS (172978 records)"},
      {value: "8743", name: "8743 - LOBBYISTS (9723 records)"},
      {value: "8744", name: "8744 - FACILITIES MANAGEMENT (874 records)"},
      {value: "8748", name: "8748 - FIRE PROTECTION CONSULTANTS (102923 records)"},
      {value: "8999", name: "8999 - SERVICES NEC (105978 records)"},
      {value: "9111", name: "9111 - STATE GOVERNMENT-EXECUTIVE OFFICES (17569 records)"},
      {value: "9120", name: "9120 - Finfish Catching (4 records)"},
      {value: "9121", name: "9121 - GOVERNMENT OFFICES-CITY, VILLAGE & TWP (242562 records)"},
      {value: "9130", name: "9130 - Research Of Horseshoe Crabs (3 records)"},
      {value: "9199", name: "9199 - GOVERNMENT OFFICES-NATIVE AMERICAN (17020 records)"},
      {value: "9210", name: "9210 - Fish Htchy,Preserve (16 records)"},
      {value: "9211", name: "9211 - STATE GOVERNMENT-COURTS (16866 records)"},
      {value: "9221", name: "9221 - POLICE DEPARTMENTS (36751 records)"},
      {value: "9222", name: "9222 - STATE GOVERNMENT-LEGAL COUNSEL (5819 records)"},
      {value: "9223", name: "9223 - STATE GOVT-CORRECTIONAL INSTITUTIONS (5130 records)"},
      {value: "9224", name: "9224 - FIRE DEPARTMENTS (50512 records)"},
      {value: "9229", name: "9229 - CIVIL DEFENSE AGENCIES (3928 records)"},
      {value: "9311", name: "9311 - CITY GOVERNMENT-FINANCE & TAXATION (14745 records)"},
      {value: "9411", name: "9411 - STATE GOVERNMENT-EDUCATION PROGRAMS (1586 records)"},
      {value: "9431", name: "9431 - STATE GOVERNMENT-PUBLIC HEALTH PROGRAMS (7559 records)"},
      {value: "9441", name: "9441 - STATE GOVERNMENT-SOCIAL/HUMAN RESOURCES (13881 records)"},
      {value: "9451", name: "9451 - COUNTY GOVERNMENT-VETERANS AFFAIRS ADMIN (1112 records)"},
      {value: "9511", name: "9511 - STATE GOVERNMENT-ENVIRONMENTAL PROGRAMS (5142 records)"},
      {value: "9512", name: "9512 - STATE GOVERNMENT-CONSERVATION DEPTS (9820 records)"},
      {value: "9531", name: "9531 - FEDERAL GOVERNMENT-HOUSING PROGRAMS (493 records)"},
      {value: "9532", name: "9532 - CITY GOVERNMENT-URBAN PLANNING & DEV (4400 records)"},
      {value: "9611", name: "9611 - CONSUMER PROTECTION AGENCIES (3184 records)"},
      {value: "9621", name: "9621 - FEDERAL GOVERNMENT-TRANSPORTATION PRGRMS (16190 records)"},
      {value: "9631", name: "9631 - CITY GOVT-REGULATION/ADM-COMMS/UTILITIES (5984 records)"},
      {value: "9641", name: "9641 - FEDERAL GOVERNMENT-AGRICULTURAL PROGRAMS (2515 records)"},
      {value: "9651", name: "9651 - STATE GOVERNMENT-LICENSING & INSPECTION (3162 records)"},
      {value: "9661", name: "9661 - STATE GOVT-SPACE RESEARCH & TECHNOLOGY (42 records)"},
      {value: "9710", name: "9710 - Hunting & Fishing Preserves (121 records)"},
      {value: "9711", name: "9711 - FEDERAL GOVERNMENT-NATIONAL SECURITY (15372 records)"},
      {value: "9721", name: "9721 - FEDERAL GOVERNMENT-INTERNATIONAL AFFAIRS (2603 records)"},
      {value: "9999", name: "9999 - NONCLASSIFIED ESTABLISHMENTS (1077306 records)"},
    ],
  },
  VIN: {
    MAKE_WHITE: {
      name: "Vehicle make",
      displayHeader: true,
      inputType: "select",
      //filterType: "basic",
      filterCategory: "Vehicle type",
      items: [
        { value: "acura", name: "acura"},
        { value: "airs", name: "airs"},
        { value: "alfa romeo", name: "alfa romeo"},
        { value: "aluma ltd", name: "aluma ltd"},
        { value: "ambassador", name: "ambassador"},
        { value: "american hauler", name: "american hauler"},
        { value: "american motors", name: "american motors"},
        { value: "apri", name: "apri"},
        { value: "arctic cat", name: "arctic cat"},
        { value: "asse", name: "asse"},
        { value: "aston martin", name: "aston martin"},
        { value: "audi", name: "audi"},
        { value: "baja", name: "baja"},
        { value: "barr", name: "barr"},
        { value: "bear", name: "bear"},
        { value: "bentley", name: "bentley"},
        { value: "big dog motorcy", name: "big dog motorcy"},
        { value: "big tex", name: "big tex"},
        { value: "bigd", name: "bigd"},
        { value: "blub", name: "blub"},
        { value: "blue bird", name: "blue bird"},
        { value: "bmw", name: "bmw"},
        { value: "boat", name: "boat"},
        { value: "bombardier", name: "bombardier"},
        { value: "bounder", name: "bounder"},
        { value: "buell", name: "buell"},
        { value: "buick", name: "buick"},
        { value: "bullet", name: "bullet"},
        { value: "cadillac", name: "cadillac"},
        { value: "can-am", name: "can-am"},
        { value: "cana", name: "cana"},
        { value: "cand", name: "cand"},
        { value: "caon", name: "caon"},
        { value: "carg", name: "carg"},
        { value: "caro", name: "caro"},
        { value: "carrie-craft", name: "carrie-craft"},
        { value: "carry-on trailer", name: "carry-on trailer"},
        { value: "cary", name: "cary"},
        { value: "casi", name: "casi"},
        { value: "cent", name: "cent"},
        { value: "cham", name: "cham"},
        { value: "changzhou nanxi", name: "changzhou nanxi"},
        { value: "cherokee", name: "cherokee"},
        { value: "chevrolet", name: "chevrolet"},
        { value: "chrysler", name: "chrysler"},
        { value: "circ", name: "circ"},
        { value: "cm", name: "cm"},
        { value: "cmtr", name: "cmtr"},
        { value: "cntx", name: "cntx"},
        { value: "coachmen", name: "coachmen"},
        { value: "coas", name: "coas"},
        { value: "cole", name: "cole"},
        { value: "coleman", name: "coleman"},
        { value: "continental", name: "continental"},
        { value: "corn pro", name: "corn pro"},
        { value: "cougar", name: "cougar"},
        { value: "cps", name: "cps"},
        { value: "crosley", name: "crosley"},
        { value: "cunu", name: "cunu"},
        { value: "daewoo", name: "daewoo"},
        { value: "darg", name: "darg"},
        { value: "datsun", name: "datsun"},
        { value: "descr of uti", name: "descr of uti"},
        { value: "dfh", name: "dfh"},
        { value: "diad", name: "diad"},
        { value: "diam", name: "diam"},
        { value: "dodge", name: "dodge"},
        { value: "doolittle", name: "doolittle"},
        { value: "dors", name: "dors"},
        { value: "dresden", name: "dresden"},
        { value: "ducati", name: "ducati"},
        { value: "dutchmen", name: "dutchmen"},
        { value: "eagle", name: "eagle"},
        { value: "east", name: "east"},
        { value: "elite", name: "elite"},
        { value: "elli", name: "elli"},
        { value: "enni", name: "enni"},
        { value: "exiss", name: "exiss"},
        { value: "expl", name: "expl"},
        { value: "ezld", name: "ezld"},
        { value: "ezlo", name: "ezlo"},
        { value: "fair", name: "fair"},
        { value: "faln", name: "faln"},
        { value: "featherlite", name: "featherlite"},
        { value: "ferrari", name: "ferrari"},
        { value: "fiat", name: "fiat"},
        { value: "flagstaff", name: "flagstaff"},
        { value: "fleetwood", name: "fleetwood"},
        { value: "floe", name: "floe"},
        { value: "font", name: "font"},
        { value: "fontaine", name: "fontaine"},
        { value: "ford", name: "ford"},
        { value: "ford national lease", name: "ford national lease"},
        { value: "fore", name: "fore"},
        { value: "forest river", name: "forest river"},
        { value: "freightliner", name: "freightliner"},
        { value: "freightliner co", name: "freightliner co"},
        { value: "frht", name: "frht"},
        { value: "frue", name: "frue"},
        { value: "ftwd", name: "ftwd"},
        { value: "gdan", name: "gdan"},
        { value: "gem", name: "gem"},
        { value: "gene", name: "gene"},
        { value: "genesis", name: "genesis"},
        { value: "genu", name: "genu"},
        { value: "genuine", name: "genuine"},
        { value: "geo", name: "geo"},
        { value: "gmac", name: "gmac"},
        { value: "gmc", name: "gmc"},
        { value: "goos", name: "goos"},
        { value: "great dane", name: "great dane"},
        { value: "gulf stream", name: "gulf stream"},
        { value: "h & h trailer", name: "h & h trailer"},
        { value: "harley-davidson", name: "harley-davidson"},
        { value: "haulmark", name: "haulmark"},
        { value: "hawn", name: "hawn"},
        { value: "hd", name: "hd"},
        { value: "heft", name: "heft"},
        { value: "hino", name: "hino"},
        { value: "holr", name: "holr"},
        { value: "homemade", name: "homemade"},
        { value: "honda", name: "honda"},
        { value: "hornet", name: "hornet"},
        { value: "hummer", name: "hummer"},
        { value: "hurs", name: "hurs"},
        { value: "hyundai", name: "hyundai"},
        { value: "indian", name: "indian"},
        { value: "infiniti", name: "infiniti"},
        { value: "international", name: "international"},
        { value: "interstate", name: "interstate"},
        { value: "intl harvester", name: "intl harvester"},
        { value: "iron", name: "iron"},
        { value: "isuzu", name: "isuzu"},
        { value: "jaguar", name: "jaguar"},
        { value: "jayco", name: "jayco"},
        { value: "jeep", name: "jeep"},
        { value: "john deere", name: "john deere"},
        { value: "johnson aquisit", name: "johnson aquisit"},
        { value: "kara", name: "kara"},
        { value: "karavan", name: "karavan"},
        { value: "kawasaki", name: "kawasaki"},
        { value: "kawk", name: "kawk"},
        { value: "kazu", name: "kazu"},
        { value: "kear", name: "kear"},
        { value: "kenworth", name: "kenworth"},
        { value: "keystone", name: "keystone"},
        { value: "kia", name: "kia"},
        { value: "krvn", name: "krvn"},
        { value: "ktm", name: "ktm"},
        { value: "kw", name: "kw"},
        { value: "kymco", name: "kymco"},
        { value: "kz", name: "kz"},
        { value: "lama", name: "lama"},
        { value: "lamborghini", name: "lamborghini"},
        { value: "land rover", name: "land rover"},
        { value: "laredo", name: "laredo"},
        { value: "lark", name: "lark"},
        { value: "lexington", name: "lexington"},
        { value: "lexus", name: "lexus"},
        { value: "lincoln", name: "lincoln"},
        { value: "lndr", name: "lndr"},
        { value: "load trail", name: "load trail"},
        { value: "lone", name: "lone"},
        { value: "long", name: "long"},
        { value: "long chih", name: "long chih"},
        { value: "look", name: "look"},
        { value: "lotus", name: "lotus"},
        { value: "lufk", name: "lufk"},
        { value: "mack", name: "mack"},
        { value: "magi", name: "magi"},
        { value: "magn", name: "magn"},
        { value: "mari", name: "mari"},
        { value: "maserati", name: "maserati"},
        { value: "maxe", name: "maxe"},
        { value: "maybach", name: "maybach"},
        { value: "mazda", name: "mazda"},
        { value: "mccl", name: "mccl"},
        { value: "mercedes-benz", name: "mercedes-benz"},
        { value: "mercury", name: "mercury"},
        { value: "merz", name: "merz"},
        { value: "mini cooper", name: "mini cooper"},
        { value: "mitsubishi", name: "mitsubishi"},
        { value: "montana", name: "montana"},
        { value: "nissan", name: "nissan"},
        { value: "nomad", name: "nomad"},
        { value: "norr", name: "norr"},
        { value: "oldsmobile", name: "oldsmobile"},
        { value: "open range", name: "open range"},
        { value: "outback", name: "outback"},
        { value: "pace american", name: "pace american"},
        { value: "palomino", name: "palomino"},
        { value: "park", name: "park"},
        { value: "passport", name: "passport"},
        { value: "perk", name: "perk"},
        { value: "peterbilt", name: "peterbilt"},
        { value: "pilg", name: "pilg"},
        { value: "pj trailer", name: "pj trailer"},
        { value: "plat", name: "plat"},
        { value: "plymouth", name: "plymouth"},
        { value: "polaris", name: "polaris"},
        { value: "pontiac", name: "pontiac"},
        { value: "porsche", name: "porsche"},
        { value: "prow", name: "prow"},
        { value: "puma", name: "puma"},
        { value: "qiye", name: "qiye"},
        { value: "r&d", name: "r&d"},
        { value: "ram", name: "ram"},
        { value: "range rover", name: "range rover"},
        { value: "ranger", name: "ranger"},
        { value: "rdkg", name: "rdkg"},
        { value: "renault", name: "renault"},
        { value: "road", name: "road"},
        { value: "roadclipper", name: "roadclipper"},
        { value: "roadmaster", name: "roadmaster"},
        { value: "rockwood", name: "rockwood"},
        { value: "rockwood lite", name: "rockwood lite"},
        { value: "roketa", name: "roketa"},
        { value: "rolls-royce", name: "rolls-royce"},
        { value: "saab", name: "saab"},
        { value: "salem", name: "salem"},
        { value: "saturn", name: "saturn"},
        { value: "scion", name: "scion"},
        { value: "shopmade", name: "shopmade"},
        { value: "shoreland'r", name: "shoreland'r"},
        { value: "skee", name: "skee"},
        { value: "skyline", name: "skyline"},
        { value: "smart", name: "smart"},
        { value: "snowbear", name: "snowbear"},
        { value: "spartan", name: "spartan"},
        { value: "spor", name: "spor"},
        { value: "springdale", name: "springdale"},
        { value: "sprinter", name: "sprinter"},
        { value: "starcraft", name: "starcraft"},
        { value: "stealth", name: "stealth"},
        { value: "sterling", name: "sterling"},
        { value: "subaru", name: "subaru"},
        { value: "sund", name: "sund"},
        { value: "sure-trac", name: "sure-trac"},
        { value: "surveyor", name: "surveyor"},
        { value: "suzuki", name: "suzuki"},
        { value: "tao tao", name: "tao tao"},
        { value: "tech", name: "tech"},
        { value: "tesk", name: "tesk"},
        { value: "tesla", name: "tesla"},
        { value: "texa", name: "texa"},
        { value: "thor", name: "thor"},
        { value: "tigr", name: "tigr"},
        { value: "timpte", name: "timpte"},
        { value: "titan", name: "titan"},
        { value: "tman", name: "tman"},
        { value: "toma", name: "toma"},
        { value: "top hat", name: "top hat"},
        { value: "toph", name: "toph"},
        { value: "toyota", name: "toyota"},
        { value: "tpht", name: "tpht"},
        { value: "trac", name: "trac"},
        { value: "trailstar", name: "trailstar"},
        { value: "transcraft", name: "transcraft"},
        { value: "trav", name: "trav"},
        { value: "trin", name: "trin"},
        { value: "trit", name: "trit"},
        { value: "triton", name: "triton"},
        { value: "triumph", name: "triumph"},
        { value: "trls", name: "trls"},
        { value: "ttnt", name: "ttnt"},
        { value: "txbg", name: "txbg"},
        { value: "txbr", name: "txbr"},
        { value: "txpr", name: "txpr"},
        { value: "ud truck", name: "ud truck"},
        { value: "uhau", name: "uhau"},
        { value: "united trailer", name: "united trailer"},
        { value: "usca", name: "usca"},
        { value: "utility trailer", name: "utility trailer"},
        { value: "vent", name: "vent"},
        { value: "vespa", name: "vespa"},
        { value: "victory", name: "victory"},
        { value: "viking", name: "viking"},
        { value: "vma/pamu", name: "vma/pamu"},
        { value: "volkswagen", name: "volkswagen"},
        { value: "volvo", name: "volvo"},
        { value: "w-w", name: "w-w"},
        { value: "wabash", name: "wabash"},
        { value: "well", name: "well"},
        { value: "wells cargo", name: "wells cargo"},
        { value: "western star tr", name: "western star tr"},
        { value: "wild", name: "wild"},
        { value: "wildcat", name: "wildcat"},
        { value: "wildwood", name: "wildwood"},
        { value: "wilson", name: "wilson"},
        { value: "winn", name: "winn"},
        { value: "workhorse custo", name: "workhorse custo"},
        { value: "wstr", name: "wstr"},
        { value: "ww", name: "ww"},
        { value: "xtre", name: "xtre"},
        { value: "yacht club", name: "yacht club"},
        { value: "yamaha", name: "yamaha"},
        { value: "zinger", name: "zinger"}
      ]
    },
    MAKE: {
      name: "Vehicle make",
      displayHeader: true,
      inputType: "select",
      //filterType: "basic",
      filterCategory: "Vehicle type",
      items: [
        {value: "", index: 0.5, name: "Select make"},
        {value: 'ACURA', index: 0, name: 'Acura' },
        {value: 'AUDI', index: 1, name: 'Audi' },
        {value: 'BMW', index: 2, name: 'Bmw' },
        {value: 'BUICK', index: 3, name: 'Buick' },
        {value: 'CADILLAC', index: 4, name: 'Cadillac' },
        {value: 'CHEVROLET', index: 5, name: 'Chevrolet' },
        {value: 'CHRYSLER', index: 6, name: 'Chrysler' },
        {value: 'DODGE', index: 7, name: 'Dodge' },
        {value: 'FORD', index: 8, name: 'Ford' },
        {value: 'FREIGHTLINER', index: 9, name: 'Freightliner' },
        {value: 'GEO', index: 10, name: 'Geo' },
        {value: 'GMC', index: 11, name: 'Gmc' },
        {value: 'HARLEY-DAVIDSON', index: 12, name: 'Harley-davidson' },
        {value: 'HMDE', index: 13, name: 'Hmde' },
        {value: 'HONDA', index: 15, name: 'Honda' },
        {value: 'HUMMER', index: 16, name: 'Hummer' },
        {value: 'HYUNDAI', index: 17, name: 'Hyundai' },
        {value: 'INFINITI', index: 18, name: 'Infiniti' },
        {value: 'ISUZU', index: 19, name: 'Isuzu' },
        {value: 'JAGUAR', index: 20, name: 'Jaguar' },
        {value: 'JEEP', index: 21, name: 'Jeep' },
        {value: 'KIA', index: 23, name: 'Kia' },
        {value: 'LAND ROVER', index: 24, name: 'Land rover' },
        {value: 'LEXUS', index: 25, name: 'Lexus' },
        {value: 'LINCOLN', index: 26, name: 'Lincoln' },
        {value: 'MAZDA', index: 27, name: 'Mazda' },
        {value: 'MERCEDES-BENZ', index: 28, name: 'Mercedes-benz' },
        {value: 'MERCURY', index: 29, name: 'Mercury' },
        {value: 'MINI', index: 30, name: 'Mini' },
        {value: 'MITSUBISHI', index: 31, name: 'Mitsubishi' },
        {value: 'NISSAN', index: 32, name: 'Nissan' },
        {value: 'OLDSMOBILE', index: 33, name: 'Oldsmobile' },
        {value: 'PLYMOUTH', index: 34, name: 'Plymouth' },
        {value: 'PONTIAC', index: 35, name: 'Pontiac' },
        {value: 'PORSCHE', index: 36, name: 'Porsche' },
        {value: 'RAM', index: 37, name: 'Ram' },
        {value: 'SAAB', index: 38, name: 'Saab' },
        {value: 'SATURN', index: 39, name: 'Saturn' },
        {value: 'SUBARU', index: 41, name: 'Subaru' },
        {value: 'SUZUKI', index: 42, name: 'Suzuki' },
        {value: 'TOYOTA', index: 43, name: 'Toyota' },
        {value: 'VOLKSWAGEN', index: 44, name: 'Volkswagen' },
        {value: 'VOLVO', index: 45, name: 'Volvo' },
        {value: 'YAMAHA', index: 46, name: 'Yamaha' },
      ]
    },
  }
}