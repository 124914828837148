import sharedDict from "./sharedDict.jsx";
import image from "./media/vehicle_sale.svg";

export default {
  dictId: "vinconsumer",
  index: "vinconsumer2",
  type: "VIN",
  path: "/_search/vinconsumer",
  apiUrl: "/api/v2/data/vinconsumer2?db=vin",
  title: "Aged US Vehicle Owners",
  description: "Historical vehicle identificaton numbers database last updated in 2020.",
  overview: "A comprehenseive database of vehicle owners in the United States.  Easily find owners by make, model, year, or with our more traditional geography filters.  Download the vehicle owners address, phone (where available), and email (where available).",
  themeColor: "secondary",
  image,
  geography: {
    AREA_CODE: sharedDict.geography.AREA_CODE,
    CITY: sharedDict.geography.CITY,
    ZIP: sharedDict.geography.ZIP,
    ST: sharedDict.geography.ST,
    nationwide: sharedDict.geography.nationwide
  },
  cols: {
    MYEAR: {
      name: "Vehicle manufacture year",
      displayHeader: true,
      inputType: "range",
      //filterType: "basic",
      filterCategory: "Vehicle type",
      sortItems: "reverse",
      items: [
        {value: 1900, order: 1900, name: 1900},
        {value: 1901, order: 1901, name: 1901},
        {value: 1902, order: 1902, name: 1902},
        {value: 1903, order: 1903, name: 1903},
        {value: 1904, order: 1904, name: 1904},
        {value: 1905, order: 1905, name: 1905},
        {value: 1906, order: 1906, name: 1906},
        {value: 1907, order: 1907, name: 1907},
        {value: 1908, order: 1908, name: 1908},
        {value: 1909, order: 1909, name: 1909},
        {value: 1910, order: 1910, name: 1910},
        {value: 1911, order: 1911, name: 1911},
        {value: 1912, order: 1912, name: 1912},
        {value: 1913, order: 1913, name: 1913},
        {value: 1914, order: 1914, name: 1914},
        {value: 1915, order: 1915, name: 1915},
        {value: 1916, order: 1916, name: 1916},
        {value: 1917, order: 1917, name: 1917},
        {value: 1918, order: 1918, name: 1918},
        {value: 1919, order: 1919, name: 1919},
        {value: 1920, order: 1920, name: 1920},
        {value: 1921, order: 1921, name: 1921},
        {value: 1922, order: 1922, name: 1922},
        {value: 1923, order: 1923, name: 1923},
        {value: 1924, order: 1924, name: 1924},
        {value: 1925, order: 1925, name: 1925},
        {value: 1926, order: 1926, name: 1926},
        {value: 1927, order: 1927, name: 1927},
        {value: 1928, order: 1928, name: 1928},
        {value: 1929, order: 1929, name: 1929},
        {value: 1930, order: 1930, name: 1930},
        {value: 1931, order: 1931, name: 1931},
        {value: 1932, order: 1932, name: 1932},
        {value: 1933, order: 1933, name: 1933},
        {value: 1934, order: 1934, name: 1934},
        {value: 1935, order: 1935, name: 1935},
        {value: 1936, order: 1936, name: 1936},
        {value: 1937, order: 1937, name: 1937},
        {value: 1938, order: 1938, name: 1938},
        {value: 1939, order: 1939, name: 1939},
        {value: 1940, order: 1940, name: 1940},
        {value: 1941, order: 1941, name: 1941},
        {value: 1942, order: 1942, name: 1942},
        {value: 1943, order: 1943, name: 1943},
        {value: 1944, order: 1944, name: 1944},
        {value: 1945, order: 1945, name: 1945},
        {value: 1946, order: 1946, name: 1946},
        {value: 1947, order: 1947, name: 1947},
        {value: 1948, order: 1948, name: 1948},
        {value: 1949, order: 1949, name: 1949},
        {value: 1950, order: 1950, name: 1950},
        {value: 1951, order: 1951, name: 1951},
        {value: 1952, order: 1952, name: 1952},
        {value: 1953, order: 1953, name: 1953},
        {value: 1954, order: 1954, name: 1954},
        {value: 1955, order: 1955, name: 1955},
        {value: 1956, order: 1956, name: 1956},
        {value: 1957, order: 1957, name: 1957},
        {value: 1958, order: 1958, name: 1958},
        {value: 1959, order: 1959, name: 1959},
        {value: 1960, order: 1960, name: 1960},
        {value: 1961, order: 1961, name: 1961},
        {value: 1962, order: 1962, name: 1962},
        {value: 1963, order: 1963, name: 1963},
        {value: 1964, order: 1964, name: 1964},
        {value: 1965, order: 1965, name: 1965},
        {value: 1966, order: 1966, name: 1966},
        {value: 1967, order: 1967, name: 1967},
        {value: 1968, order: 1968, name: 1968},
        {value: 1969, order: 1969, name: 1969},
        {value: 1970, order: 1970, name: 1970},
        {value: 1971, order: 1971, name: 1971},
        {value: 1972, order: 1972, name: 1972},
        {value: 1973, order: 1973, name: 1973},
        {value: 1974, order: 1974, name: 1974},
        {value: 1975, order: 1975, name: 1975},
        {value: 1976, order: 1976, name: 1976},
        {value: 1977, order: 1977, name: 1977},
        {value: 1978, order: 1978, name: 1978},
        {value: 1979, order: 1979, name: 1979},
        {value: 1980, order: 1980, name: 1980},
        {value: 1981, order: 1981, name: 1981},
        {value: 1982, order: 1982, name: 1982},
        {value: 1983, order: 1983, name: 1983},
        {value: 1984, order: 1984, name: 1984},
        {value: 1985, order: 1985, name: 1985},
        {value: 1986, order: 1986, name: 1986},
        {value: 1987, order: 1987, name: 1987},
        {value: 1988, order: 1988, name: 1988},
        {value: 1989, order: 1989, name: 1989},
        {value: 1990, order: 1990, name: 1990},
        {value: 1991, order: 1991, name: 1991},
        {value: 1992, order: 1992, name: 1992},
        {value: 1993, order: 1993, name: 1993},
        {value: 1994, order: 1994, name: 1994},
        {value: 1995, order: 1995, name: 1995},
        {value: 1996, order: 1996, name: 1996},
        {value: 1997, order: 1997, name: 1997},
        {value: 1998, order: 1998, name: 1998},
        {value: 1999, order: 1999, name: 1999},
        {value: 2000, order: 2000, name: 2000},
        {value: 2001, order: 2001, name: 2001},
        {value: 2002, order: 2002, name: 2002},
        {value: 2003, order: 2003, name: 2003},
        {value: 2004, order: 2004, name: 2004},
        {value: 2005, order: 2005, name: 2005},
        {value: 2006, order: 2006, name: 2006},
        {value: 2007, order: 2007, name: 2007},
        {value: 2008, order: 2008, name: 2008},
        {value: 2009, order: 2009, name: 2009},
        {value: 2010, order: 2010, name: 2010},
        {value: 2011, order: 2011, name: 2011},
        {value: 2012, order: 2012, name: 2012},
        {value: 2013, order: 2013, name: 2013},
        {value: 2014, order: 2014, name: 2014},
        {value: 2015, order: 2015, name: 2015},
        {value: 2016, order: 2016, name: 2016},
        {value: 2017, order: 2017, name: 2017},
        {value: 2018, order: 2018, name: 2018},
        {value: 2019, order: 2019, name: 2019},
        {value: 2020, order: 2020, name: 2020},
        {value: 2021, order: 2021, name: 2021},
      ]
    },
    MAKE: {
      name: "Vehicle make",
      displayHeader: true,
      inputType: "select",
      //filterType: "basic",
      filterCategory: "Vehicle type",
      items: [
        {value: "", index: 0.5, name: "Select make"},
        {value: 'ACURA', index: 0, name: 'Acura' },
        {value: 'AUDI', index: 1, name: 'Audi' },
        {value: 'BMW', index: 2, name: 'Bmw' },
        {value: 'BUICK', index: 3, name: 'Buick' },
        {value: 'CADILLAC', index: 4, name: 'Cadillac' },
        {value: 'CHEVROLET', index: 5, name: 'Chevrolet' },
        {value: 'CHRYSLER', index: 6, name: 'Chrysler' },
        {value: 'DODGE', index: 7, name: 'Dodge' },
        {value: 'FORD', index: 8, name: 'Ford' },
        {value: 'FREIGHTLINER', index: 9, name: 'Freightliner' },
        {value: 'GEO', index: 10, name: 'Geo' },
        {value: 'GMC', index: 11, name: 'Gmc' },
        {value: 'HARLEY-DAVIDSON', index: 12, name: 'Harley-davidson' },
        {value: 'HMDE', index: 13, name: 'Hmde' },
        {value: 'HONDA', index: 15, name: 'Honda' },
        {value: 'HUMMER', index: 16, name: 'Hummer' },
        {value: 'HYUNDAI', index: 17, name: 'Hyundai' },
        {value: 'INFINITI', index: 18, name: 'Infiniti' },
        {value: 'ISUZU', index: 19, name: 'Isuzu' },
        {value: 'JAGUAR', index: 20, name: 'Jaguar' },
        {value: 'JEEP', index: 21, name: 'Jeep' },
        {value: 'KIA', index: 23, name: 'Kia' },
        {value: 'LAND ROVER', index: 24, name: 'Land rover' },
        {value: 'LEXUS', index: 25, name: 'Lexus' },
        {value: 'LINCOLN', index: 26, name: 'Lincoln' },
        {value: 'MAZDA', index: 27, name: 'Mazda' },
        {value: 'MERCEDES-BENZ', index: 28, name: 'Mercedes-benz' },
        {value: 'MERCURY', index: 29, name: 'Mercury' },
        {value: 'MINI', index: 30, name: 'Mini' },
        {value: 'MITSUBISHI', index: 31, name: 'Mitsubishi' },
        {value: 'NISSAN', index: 32, name: 'Nissan' },
        {value: 'OLDSMOBILE', index: 33, name: 'Oldsmobile' },
        {value: 'PLYMOUTH', index: 34, name: 'Plymouth' },
        {value: 'PONTIAC', index: 35, name: 'Pontiac' },
        {value: 'PORSCHE', index: 36, name: 'Porsche' },
        {value: 'RAM', index: 37, name: 'Ram' },
        {value: 'SAAB', index: 38, name: 'Saab' },
        {value: 'SATURN', index: 39, name: 'Saturn' },
        {value: 'SUBARU', index: 41, name: 'Subaru' },
        {value: 'SUZUKI', index: 42, name: 'Suzuki' },
        {value: 'TOYOTA', index: 43, name: 'Toyota' },
        {value: 'VOLKSWAGEN', index: 44, name: 'Volkswagen' },
        {value: 'VOLVO', index: 45, name: 'Volvo' },
        {value: 'YAMAHA', index: 46, name: 'Yamaha' },
      ]
    },
    //MODEL: {
    //  name: "Vehicle Model",
    //  displayHeader: true,
    //  inputType: "search",
    //  //filterType: "basic",
    //  filterCategory: "Vehicle type",
    //  items: []
    //},
    PHONE_OCCURRENCE: {
      name: "One phone record per household",
      displayHeader: false,
      inputType: "checkbox",
      //filterType: "basic",
      filterCategory: "Contact preferences",
      items: [
        {index: 0, value: 1, name: "One phone record per household"}
      ]
    },
    ADDR_OCCURRENCE: {
      name: "One record per address",
      displayHeader: false,
      inputType: "checkbox",
      //filterType: "basic",
      filterCategory: "Contact preferences",
      items: [
        {index: 0, value: "1", name: "One record per mailing address", query: [`(ADDR_OCCURRENCE = 1)`]},
      ]
    },
    AREA_CODE: sharedDict.geography.AREA_CODE_Integer,
    COUNTY: sharedDict.geography.COUNTY,
    CITY: sharedDict.geography.CITY,
    ZIP: sharedDict.geography.ZIP,
    ST: sharedDict.geography.ST,
    nationwide: sharedDict.geography.nationwide
  },
  defaultSearchParams: {
    MYEAR: [],
    MAKE: [],
    //MODEL: [],
    PHONE_OCCURRENCE: [],
    ADDR_OCCURRENCE: [],
    AREA_CODE: [],
    COUNTY: [],
    CITY: [],
    ZIP: [],
    ST: [],
    nationwide: []
  },
  keyOrder: {
    MYEAR: 100,
    MAKE: 200,
    MODEL: 300,
    PHONE_OCCURRENCE: 400,
    ADDR_OCCURRENCE: 500,
    AREA_CODE: 600,
    COUNTY: 700,
    CITY: 800,
    ZIP: 900,
    ST: 1000
  },
  previewCols: {
    display: [
      {label: "Year", content: "MYEAR"},
      {label: "Make", content: "MAKE"},
      {label: "Model", content: "MODEL"},
      {label: "First name", content: "FN"},
      {label: "Last name", content: "LN"},
      {label: "Phone", content: "PHONE"}
    ],
    popover: [
      {label: "Year", content: "MYEAR"},
      {label: "Make", content: "MAKE"},
      {label: "Model", content: "MODEL"},
      {label: "VIN", content: "VIN"},
      {label: "First name", content: "FN"},
      {label: "Last name", content: "LN"},
      {label: "Address", content: "FULL_ADDR"},
      {label: "City", content: "CITY"},
      {label: "State", content: "ST"},
      {label: "Zip", content: "ZIP"},
      {label: "Phone", content: "PHONE"},
      {label: "Email", content: "EMAIL"}
    ]
  },
  dlCols: [
    {value: "MYEAR", name: "Model Year", default: true},
    {value: "MAKE", name: "Make", default: true},
    {value: "MODEL", name: "Model", default: true},
    {value: "FN", name: "First name", default: true},
    {value: "LN", name: "Last name", default: true},
    {value: "FULL_ADDR", name: "Address", default: true},
    {value: "CITY", name: "City", default: true},
    {value: "ST", name: "State", default: true},
    {value: "ZIP", name: "Zip code", default: true},
    //{value: "Z4", name: "Zip 4", default: true},
    {value: "PHONE", name: "Phone", default: true},
    {value: "EMAIL", name: "Email (if available)", default: false},
    {value: "VIN", name: "VIN", default: true},
  ],
  selectFilterValidation: [],
  leadsMultiplier: 1,
  access: "50K Rollover",
  summaryStats: {
    totalCount: 119315339
  }
}
