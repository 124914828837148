import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect,
  useParams,
  Switch,
  Route
} from "react-router-dom";
import { db } from "./firebase.jsx";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  CardDeck,
  Card,
  Jumbotron,
  Form,
  Table
} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { Helmet } from "react-helmet";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";

import { sendEmail } from "./email.jsx";
import { SubscribeRecurly as Subscribe } from "./recurlyCheckoutAgain.jsx";
import logo from "./media/logo.png";

import hero_img from "./media/954.jpg";
import hcol1 from "./media/h_col1.png";
import hcol2 from "./media/h_col2.png";
import hcol3 from "./media/h_col3.png";
import VideoDemo from "./videoDemo.jsx";
import bleft from "./media/h_bottom_left.png";
import bright from "./media/h_bottom_right_t.png";
import cards from "./media/card_logos.png";

import bizLeadsImg from "./media/business_sales_leads.jpg";
import conLeadsImg from "./media/consumer_sales_leads.jpg";

// STATIC CONTENT
const Loading = () => {
  return (
    <Container>
      <Row style={{ marginTop: "30px" }}>
        <Col
          className="text-center flex-column d-flex align-items-center justify-content-center"
          style={{ minHeight: "50vh" }}
        >
          <Spinner animation="grow" variant="primary" size="lg" />
          <h2>Getting your account information. Hold tight!</h2>
        </Col>
      </Row>
    </Container>
  );
};

const Topnav = props => {
  let { user, loaded, isApp } = props;

  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img src={logo} alt={process.env.REACT_APP_site_name} />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse>
          <Nav className="mr-auto">
            {!isApp && (
              <Nav.Item className="navbar-text">
                <Nav.Link href="tel:833-222-7919">
                  <i className="fa fa-phone"></i> 833-222-7919
                </Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item className="navbar-text mr-auto">
              <a href="https://www.listshack.support/support" className="nav-link">
                <i className="fa fa-question-circle"></i> Help
              </a>
            </Nav.Item>
          </Nav>
          <Nav className="">
            {user === null && loaded && (
              <Nav.Item>
                <a href="https://www.listshack.support/login">
                  <Button variant="primary">Member login</Button>
                </a>
              </Nav.Item>
            )}
            {user !== null && user !== "" && loaded && (
              <React.Fragment>
                <span id="hw"></span>
                <NavDropdown title={user.email} style={{ zIndex: "1031" }}>
                  <Link className="dropdown-item" to="/search">
                    Search
                  </Link>
                  <Link className="dropdown-item" to="/settings">
                    Settings
                  </Link>
                  <Link className="dropdown-item" to="/downloads">
                    Downloads
                  </Link>
                  <NavDropdown.Divider />
                  <NavDropdown.Item onClick={e => props.signOut(e, user)}>
                    Sign out
                  </NavDropdown.Item>
                </NavDropdown>
              </React.Fragment>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const Secondnav = props => {
  return (
    <Navbar
      expand="lg"
      variant="dark"
      className="bg-info"
      style={{ height: "60px" }}
      id="nav2"
    >
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse>
        <Nav.Item>
          <Link className="nav-link" to="/">
            Welcome
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link className="nav-link" to="/business-sales-leads">
            Business Sales Leads
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link className="nav-link" to="/consumer-sales-leads">
            Consumer Sales Leads
          </Link>
        </Nav.Item>
        {/*
        <Nav.Item>
          <Link className="nav-link" to="/archives">
            Historical data
          </Link>
        </Nav.Item>
        */}
        <Nav.Item>
          <Link className="nav-link" to="/pricing">
            Pricing
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link className="nav-link" to="/faq">
            FAQ
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link className="nav-link" to="/contact">
            Contact
          </Link>
        </Nav.Item>
      </Navbar.Collapse>
    </Navbar>
  );
};

const Footer = props => {
  let { appInterface } = props;
  return (
    <footer id="footer" className="bg-dark" style={{ padding: "50px 0px" }}>
      <Container>
        <Row>
          <Col>
            <a
              className="btn btn-link text-white"
              href={`mailto: ${process.env.REACT_APP_contact_email}`}
              style={{ paddingLeft: "0px" }}
            >
              <i className="fa fa-envelope"></i>{" "}
              {process.env.REACT_APP_contact_email}
            </a>
            <br />
            <a
              className="btn btn-link text-white"
              style={{ paddingLeft: "0px" }}
              href="tel: 833-222-7919"
            >
              <i className="fa fa-phone"></i> 833-222-7919
            </a>
            <p className="text-white">
              <Link className="text-white" to="/privacy">
                Privacy policy
              </Link>{" "}
              |{" "}
              <Link className="text-white" to="/terms">
                Terms of service
              </Link>{" "}
              |{" "}
              <Link className="text-white" to="/ccpa">
                CCPA
              </Link>
            </p>
            <a
              className="text-white"
              href={
                appInterface === "list_shack"
                  ? "/sitemapLs.txt"
                  : appInterface === "list_shack_pro"
                  ? "/sitemap.txt"
                  : appInterface === "listshack_support"
                  ? "/sitemapLss.txt"
                  : "/sitemapLsio.txt"
              }
            >
              Site map
            </a>
          </Col>
          <Col>
            <p className="text-white">All major credit cards accepted</p>
            <img
              alt="Visa, Discover, MasterCard, and American Express"
              src={cards}
              width="300"
              height="auto"
            />
            <hr />
            <Link to="/ccpa_optout" className="text-white">
              <small>Do Not Sell My Personal Information</small>
            </Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

const FooterSmall = () => {
  return (
    <footer className="text-center" style={{ padding: "20px 0px" }}>
      <Container>
        <Row>
          <Col>
            <p
              style={{
                marginBottom: "0px",
                fontSize: ".8em",
                color: "#00000080"
              }}
            >
              <Link to="/privacy" style={{ color: "#00000080" }}>
                Privacy policy
              </Link>
              &nbsp;&#183;&nbsp;
              <Link to="/terms" style={{ color: "#00000080" }}>
                Terms of service
              </Link>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

const Search = (props) => {
  const { handleState } = props;
  useEffect( () => {
    handleState({
      noDistract: true,
      isApp: true
    })
  }, [handleState]);
  return (
    <Container>
      <Row
        style={{
          marginTop: "30px",
          minHeight: "70vh"
        }}
        className="text-center d-flex align-items-center"
      >
        <Col xs={12}>
          <h3 style={{ marginTop: "60px" }}>
            What type of leads would you like?
          </h3>
          <div style={{ marginTop: "60px", marginBottom: "80px" }}>
            <Link to="/search/businesses" className="btn btn-lg btn-primary">
              Business leads
            </Link>
            &nbsp; &nbsp; &nbsp;
            <Link to="search/consumers" className="btn btn-lg btn-primary">
              Consumer leads
            </Link>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

const NoPage = () => {
  console.log("No Page!");
  return (
    <Container>
      <Row
        style={{ minHeight: "70vh", textAlign: "center", marginTop: "40px" }}
      >
        <Col>
          <h3>Uh oh, there's nothing here :( </h3>
          <p className="lead">
            Click the button below to return to civilization.
          </p>
          <Link to="/" className="btn btn-large btn-info">
            Back to civilization
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

const NotFound = () => {
  return (
    <Container>
      <Row
        style={{ minHeight: "70vh", textAlign: "center", marginTop: "40px" }}
      >
        <Col>
          <h3>Uh oh, there's nothing here :( </h3>
          <p className="lead">
            Click the button below to return to civilization.
          </p>
          <Link to="/" className="btn btn-large btn-info">
            Back to civilization
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

const Home = props => {
  if (props.user !== null) {
    return <Redirect to="/search" />;
  } else {
    return (
      <Container fluid>
        <Row
          className="bg-dark text-center text-white d-flex justify-content-center align-items-middle"
          style={{
            backgroundImage: `url(${hero_img})`,
            height: "60vh",
            backgroundPosition: "top",
            backgroundSize: "cover"
          }}
        >
          <Jumbotron
            style={{
              backgroundColor: "rgb(0,0,0,.6)",
              height: "100%",
              width: "100%"
            }}
            className="d-flex justify-content-center align-items-middle flex-column"
            fluid
          >
            <h2>The new way to get sales leads</h2>
            <p className="lead">Starting at $50 per month</p>
            <div>
              <a href="#process" className="btn btn-secondary">Our simplified process</a>
              <a className="btn btn-primary btn-lg" href="https://www.listshack.support/signup">
                Signup Now
              </a>
            </div>
          </Jumbotron>
        </Row>

        <Row
          id="process"
          className="d-flex justify-content-center align-items-middle flex-column text-center"
          style={{ padding: "100px 0px" }}
        >
          <h3>Our simplified process</h3>
          <br />
          <Col className="d-flex justify-content-around align-items-middle flex-row">
            <Card className="border-0">
              <img
                src={hcol1}
                className="shadow"
                alt="It's easy to create an account"
              />
              <Card.Body>
                <h5>1. Create an account</h5>
              </Card.Body>
            </Card>
            <Card className="border-0">
              <img
                src={hcol2}
                className="shadow"
                alt="Pick from the most useful features"
              />
              <Card.Body>
                <h5>2. Pick your filters</h5>
              </Card.Body>
            </Card>
            <Card className="border-0">
              <img
                src={hcol3}
                className="shadow"
                alt="Download your list with a click of a button"
              />
              <Card.Body>
                <h5>3. Download your list</h5>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row
          className="d-flex justify-content-center align-items-middle flex-row text-center bg-light"
          style={{ padding: "50px 0px" }}
        >
          <Col className="d-flex justify-content-around align-items-middle flex-column text-center">
            <h6>Listshack Pro advantages</h6>
            <div className="d-flex flex-row align-items-top justify-content-center">
              <i className="fa fa-check-square-o fa-3x" aria-hidden="true"></i>
              <div>
                <ul className="text-left">
                  <li>Buy direct</li>
                  <li>No contracts</li>
                  <li>Flat monthly rate</li>
                  <li>Instant list access</li>
                  <li>24/7 online access</li>
                  <li>National searches</li>
                  <li>Starting at $50 per month</li>
                </ul>
                <a className="btn btn-primary btn-sm" href="https://www.listshack.support/signup">
                  Sign up now >
                </a>
              </div>
            </div>
          </Col>
          <Col className="d-flex justify-content-center align-items-middle flex-column text-center">
            <h4>See it in action</h4>
            <VideoDemo />
          </Col>
          <Col
            xs={12}
            className="d-flex justify-content-center align-items-middle flex-row"
            style={{ margin: "50px 0px" }}
          >
            <img
              src={bleft}
              alt="Lots of happy business people use Listshackpro.com"
            />
            <img
              src={bright}
              alt="Choose from the most valuable filter for business lists and consumer lists"
            />
          </Col>
        </Row>
      </Container>
    );
  }
};

const BizLeads = () => {
  return (
    <Container>
      <Row>
        <Col xs={12} md={8} style={{ color: "#666e70", marginTop: "30px" }}>
          <h1>Business sales leads</h1>
          <h3>Business Leads</h3>
          <p>
            Get your customized list of business leads today. We have great
            filters and you can download everything with plans starting at
            $50/month. No need to play phone tag with a list broker; sign up
            using our online checkout system and start creating a list just
            right for your needs. Our user-friendly website gives you a
            hassle-free and affordable option without any contract or additional
            fees. &nbsp;Create your custom business leads list using our easy
            online system. We save you time so you can focus on selling!
          </p>
          <h3>Customize Your List</h3>
          <p>
            Use our filters to choose the exact search criteria you need from
            our database of over 14 million businesses. Records include name of
            the business, phone number, owner information (where available),
            address, sales volume, SIC code, and web address (where available).
          </p>
          <h3>Filters</h3>
          <ul>
            <li>Search Industry by SIC Code</li>
            <li>Number of Employees</li>
            <li>Sales Volume</li>
            <li>Years in business</li>
            <li>Square Footage</li>
            <li>State</li>
            <li>County</li>
            <li>Zip Code</li>
            <li>Area Code</li>
          </ul>
          <p>
            <strong>Ideas for Using Business Leads</strong>
          </p>
          <ul>
            <li>
              Create a list of small businesses with 1-9 employees to sell small
              group and individual health insurance policies
            </li>
            <li>
              Create a list of businesses in your area to sell merchant
              processing services
            </li>
            <li>
              Create a list targeted by industry to provide janitorial services
            </li>
            <li>Many, many more!</li>
          </ul>
        </Col>
        <Col xs={12} md={4} className="text-center">
          <img
            src={bizLeadsImg}
            alt="business sales leads"
            className="img-fluid"
          />
          <h4>Sales Leads starting at $50 per month</h4>
          <a className="btn btn-primary btn-sm" href="https://www.listshack.support/signup">
            Sign up now
          </a>
          <VideoDemo />
        </Col>
      </Row>
    </Container>
  );
};

const ConLeads = () => {
  return (
    <Container>
      <Row style={{ marginTop: "30px" }}>
        <Col xs={12} md={8} style={{ color: "#666e70" }}>
          <h1>Consumer sales leads</h1>
          <h3>Consumer Leads</h3>
          <p>
            Get your customized list of consumer leads today. With great filters
            and no add-on fees. you can download everything with plans starting
            at $50/month. No need to play phone tag with a list broker; sign up
            using our online checkout system and start creating a list just
            right for your needs. Our user-friendly website gives you a
            hassle-free and affordable option without any contract or surprises.
          </p>
          <h3>Customize Your List</h3>
          <p>
            Use our filters to choose the exact search criteria you need from
            our database of over 200 million consumers. Records include first
            name, last name, phone number, address, county, age, and estimated
            income.
          </p>
          <h3>Filters</h3>
          <ul>
            <li>Age</li>
            <li>Year and month born</li>
            <li>Estimated income range</li>
            <li>Homeowner status</li>
            <li>Marital status</li>
            <li>Gender</li>
            <li>Property value</li>
            <li>Year built</li>
            <li>Purchase year</li>
            <li>Networth</li>
            <li>Credit rating</li>
            <li>State</li>
            <li>County</li>
            <li>City</li>
            <li>Zip code</li>
            <li>Area code</li>
          </ul>
          <p>
            <strong>Ideas for Using Consumer Leads</strong>
          </p>
          <ul>
            <li>
              Create a list of consumers 65-78 with incomes over $15k/year for
              outbound telemarketing campaigns to sell senior insurance products
              such as Medicare, LTC, and burial insurance.
            </li>
            <li>
              Create a list of homeowners ages with incomes over $75k/year to
              sell custom home remodelling projects
            </li>
            <li>
              Create a list of credit scored homeowners to sell solar panels
            </li>
            <li>Many, many more!</li>
          </ul>
        </Col>
        <Col xs={12} md={4} className="text-center">
          <img
            src={conLeadsImg}
            alt="business sales leads"
            className="img-fluid"
          />
          <h4>Sales Leads starting at $50 per month</h4>
          <a className="btn btn-primary btn-sm" href="https://www.listshack.support/signup">
            Sign up now
          </a>
          <VideoDemo />
        </Col>
      </Row>
    </Container>
  );
};

const Contact = () => {
  return (
    <Container>
      <Row style={{ marginTop: "30px", minHeight: "75vh" }}>
        <Col xs={12} md={6}>
          <h2>Contact us</h2>
          <p>We would love to hear from you!</p>
          <a href="tel:833-222-7919" style={{ fontSize: "1.5em" }}>
            <i className="fa fa-phone"></i> 833-222-7919
          </a>
          <br />
          <br />
          <a
            href={`mailto: ${process.env.REACT_APP_contact_email}`}
            style={{ fontSize: "1.8em" }}
          >
            <i className="fa fa-envelope"></i>{" "}
            {`${process.env.REACT_APP_contact_email}`}
          </a>
          <p>Midvale, UT 84047</p>
        </Col>
      </Row>
    </Container>
  );
};

const Terms = () => {
  return (
    <Container>
      <Row style={{ marginTop: "30px" }}>
        <h1>Terms of Service</h1>
        <p>
          By using the {process.env.REACT_APP_site_name} (“Company”) website
          (“Site”) you agree to the terms and conditions in this agreement.{" "}
          {process.env.REACT_APP_site_name} subscribers, new customers (“you”)
          are subject to these Terms &amp; Conditions.
        </p>
        <h6>1. Allowed uses and restrictions</h6>
        <p>
          Upon payment in full for the services,{" "}
          {process.env.REACT_APP_site_name} grants to the client: a permanent;
          non-exclusive; non-transferable; and non-sublicenceable, license to
          use, edit, modify, copy, distribute and reproduce the intellectual
          property for the client’s own business use.
        </p>

        <p>Clarifications:</p>
        <ul>
          <li>
            You may use the marketing records for legally permissible marketing
            purposes. This includes telemarketing, door knocking, and mailing
            subject to any federal, state, or local laws.
          </li>
          <li>
            You may retain a copy of all records downloaded with unlimited usage
            rights. You may not utilize any type of scraping or data mining
            software or services for the purpose of extracting records from the
            database.
          </li>
          <li>
            You may not sell, license, dispense, or in any other way distribute
            any part of the database to any third party, or use Data in
            establishing an individual’s credit worthiness or eligibility for
            credit, insurance or employment. If you are interested in reselling
            our data please contact sales about our reseller program.
          </li>
          <li>
            You agree to comply with all applicable federal, state, foreign and
            local statutes and regulations, including, but not limited to the
            CAN-SPAM Act and the National Do Not Call Registry. You agree to all
            indemnification clauses stated in this agreement.
          </li>
          <li>
            You may not share your account information with any other users. If
            your account is suspected of abuse it may be suspended or cancelled
            without refund. This determination is at the sole discretion of{" "}
            {process.env.REACT_APP_site_name} and is not subject to appeal.
          </li>
          <li>
            In the event a subscriber is found to be reselling data without a
            valid reseller agreement the subscriber agrees to be liable for
            liquidated damages of $500 per list and an additional five cents per
            record. This is completely avoidable by contacting sales to get
            reseller rights. The terms of the reseller agreement may change at
            any time, with or without notice. Resellers will receive
            notifications about any changes to their terms of service by email
            notification to the email account listed with their registration.
          </li>
        </ul>
        <h6>2. Liability Limitation and Warranty Information</h6>

        <p>
          All data is believed to be accurate as it’s published. The information
          is provided “AS IS”. For the purpose of consumer data it is the
          customers obligation to ensure compliance with Telephone Consumer
          Protection Act (TCPA) rules including compliance with the DNC
          registry. Data is scrubbed against the DNC approximately every two
          weeks, but customers are encouraged to get a free SAN at
          https://telemarketing.donotcall.gov/ and use our online tool to
          maintain compliance. If you require assistance with this process you
          can take advantage of our paid support by emailing us.
        </p>

        <p>
          Note: The courts have ruled the list buyers cannot be legally
          protected from fines or court actions based off the compliance a list
          broker provides. In fact, any marketer can be held liable for
          violating the DNC laws even if they are only calling numbers not
          listed on the DNC
          https://business.ftc.gov/documents/alt129-qa-telemarketers-sellers-about-dnc-provisions-tsr#Compliance.
          For more information regarding this please review
          https://business.ftc.gov/documents/alt129-qa-telemarketers-sellers-about-dnc-provisions-tsr.
        </p>

        <h6>4. Fair Usage and Right to Refuse Service</h6>
        <p>
          Our unlimited plans are for individuals subject to a fair usage
          policy. If you plan on downloading in excess of 50,000 records per
          month please give us a call or email{" "}
          {process.env.REACT_APP_contact_email} and ask about plan enhancements
          to avoid having your account blocked.
        </p>

        <h6>5. Email and Text Message Communications</h6>
        <p>
          If you provide the Site your email address or phone number, we may
          send you administrative messages (such as updated notifications,
          newsletters, and other news) relating to the Services or to respond to
          communications from you. By using our Services and/or not opting out
          of receiving information from Us, you acknowledge and agree that you
          may receive email or text messages on your mobile device from Us or
          other users of the Services. Receiving these messages may cause you to
          incur usage charges or other fees or costs in accordance with your
          wireless or data service plan. Any and all such charges, fees, or
          costs are your sole responsibility. You should consult with your
          wireless carrier to determine what rates, charges, fees, or costs may
          apply. In the event you change or deactivate your mobile telephone
          number, you will update your account information within 48 hours to
          ensure that your messages are not sent to the person who acquires your
          old number.
        </p>

        <h6>6. Cancellation &amp; Refunds</h6>

        <p>
          You can cancel your subscription at any time. All subscriptions are
          non-refundable and no pro-rated refunds will be given. Please be sure
          to cancel at least three business days before the end of your billing
          cycle. Once you cancel you will immediately lose access to your
          subscription. Cancellations must be made online via the member
          dashboard. This refund policy extends to any payments made for
          increased download limits.
        </p>

        <h6>7. Governing Law; Jurisdiction</h6>

        <p>
          The Agreement shall be governed by and construed under the laws of the
          State of Utah, without regard for the principles of conflicts of law
          of that State or any other state. Any litigation or other dispute
          relating to or arising under the Agreement shall only be brought in
          the Utah County District Court of Provo, Utah.
        </p>

        <h6>8. Indemnification</h6>

        <p>
          You agree to be responsible for any failure by any of your
          representatives to comply with any of the provisions of this
          agreement. You agree to indemnify the company and its representatives
          and save them fully harmless from and against any loss, cost, damage,
          expense or liability suffered or incurred by any of them arising as a
          result of or in connection with any failure by you or any of your
          representatives to comply with any provision of this agreement.
        </p>

        <h6>9. Entire Agreement</h6>

        <p>
          This agreement constitutes the entire agreement between the parties
          and supersedes any prior understanding or representation of any kind
          preceding the date of this agreement. There are no other promises,
          conditions, understandings or other agreements, whether oral or
          written, relating to the subject matter of this agreement. This
          agreement may be modified in writing and any such modifications must
          be signed by both the you and the {process.env.REACT_APP_site_name}.
          Additionally, the terms of this agreement extend to any additional
          services provided by {process.env.REACT_APP_site_name}, including but
          not limited to increased download limits.
        </p>
      </Row>
    </Container>
  );
};

const Privacy = () => {
  return (
    <Container>
      <Row style={{ marginTop: "30px" }}>
        <h1>Privacy policy</h1>
        <p>
          Your privacy is very important to us. Accordingly, we have developed
          this Policy in order for you to understand how we collect, use,
          communicate and disclose and make use of personal information. The
          following outlines our privacy policy.
          <br />
          <br />
          Before or at the time of collecting personal information, we will
          identify the purposes for which information is being collected.
          <br />
          <br />
          We will collect and use of personal information solely with the
          objective of fulfilling those purposes specified by us and for other
          compatible purposes, unless we obtain the consent of the individual
          concerned or as required by law.
          <br />
          <br />
          We will only retain personal information as long as necessary for the
          fulfillment of those purposes.
          <br />
          <br />
          We will collect personal information by lawful and fair means and,
          where appropriate, with the knowledge or consent of the individual
          concerned.
          <br />
          <br />
          Personal data should be relevant to the purposes for which it is to be
          used, and, to the extent necessary for those purposes, should be
          accurate, complete, and up-to-date.
          <br />
          <br />
          We will protect personal information by reasonable security safeguards
          against loss or theft, as well as unauthorized access, disclosure,
          copying, use or modification.
          <br />
          <br />
          We will make readily available to customers information about our
          policies and practices relating to the management of personal
          information.
          <br />
          <br />
          We are committed to conducting our business in accordance with these
          principles in order to ensure that the confidentiality of personal
          information is protected and maintained.
          <br />
          <br />
          Our data is sourced from public information, aggregated, and sorted
          for your convenience. Examples of these would include but are not
          limited to white pages, county records, state records, and similar
          sources.
          <br />
          <br />
          We will never never sell a user's signup information without their
          consent.
        </p>
      </Row>
    </Container>
  );
};

const Faq = () => {
  return (
    <Container>
      <Row style={{ marginTop: "30px" }}>
        <h1>FAQ</h1>
        <section className="article-content">
          <h4>Obtaining sales leads and sales lists has never been easier</h4>
          <p className="ques">What is {process.env.REACT_APP_site_name}?</p>
          <p className="text-muted">
            {process.env.REACT_APP_site_name} is an online digital platform that
            connects business owners, marketers, and salespeople with potential
            prospects. We offer an easy to use interface that allows you to
            target potential customers using geography and filters. Our goal is
            to help you get the right type of sales leads that fit the needs and
            wants of your business or organization.
          </p>
          <p className="ques">What type of lists and filters do you offer?</p>
          <p className="text-muted">
            We offer both targeted calling and mailing lists. We also offer
            email lists.
          </p>
          <p className="text-muted">
            Consumer Filters: &nbsp;Age, Year and Month Born, Estimated Income
            Range, Homeowner, Status, Marital Status, Gender, Property Value,
            Property Type, Year Built, Purchase Year, Net Worth, Credit Rating
          </p>
          <p className="text-muted">
            Business Filters: Search Industry by SIC Code, Number of Employees,
            Sales Volume, Years in business , Square Footage
          </p>
          <p className="text-muted">
            Geography: USA, State, Area Code, County, City, Zip
          </p>
          <p className="text-muted">
            Email filters: Has email, Includes Opt-in URL, Opt-in Date, Email
            service provider.{" "}
            <small>
              Emails are compiled emails, and we recommend performing your own
              opt-in process before adding compiled email lists to your
              marketing campaigns.
            </small>
          </p>
          <p className="ques">How soon can I access the data?</p>
          <p className="text-muted">
            Once you pay for your plan you will have instant access and be able
            to download records immediately. &nbsp;
          </p>
          <p className="ques">What format does the data come in?</p>
          <p className="text-muted">
            Files are downloaded as a CSV which will open in most software
            including Microsoft Excel, Open Office, and every dialer we know
            about.{" "}
          </p>
          <p className="ques">Do I have to download your software?</p>
          <p className="text-muted">
            No. {process.env.REACT_APP_site_name} is an online only platform. No
            software is required to obtain our sales leads. All sales leads come
            straight from our site to your computer.
          </p>
          <p className="ques">Am I required to have a SAN for telemarketing?</p>
          <p className="text-muted">
            Yes! Although we regularly scrub our lists against the DNC, that
            will not protect you from fines or other legal action if you do not
            register for a SAN and comply with the provisions of the TCPA.
            &nbsp;In order to be protected from legal action each caller must
            register for a SAN which can be done for free by visiting{" "}
            <a href="https://telemarketing.donotcall.gov/">
              https://telemarketing.donotcall.gov/
            </a>
            . &nbsp;We cannot protect our customers from litigation or fines
            because the TCPA requires that each marketer must be responsible for
            registering for their own SAN and maintaining an internal DNC policy
            and procedure.
          </p>
          <p className="ques">
            What's the best way to use compiled email lists?
          </p>
          <p className="text-muted">
            We have seen that sending personalized, one-to-one, emails is the
            most effective approach for using emails, and those emails are most
            often successful as a followup when someone talks with your or your
            business over the phone, by text message, or visits your website. A
            cold email, to someone that you've never met is easily ignored.
          </p>
          <p className="ques">Am I required to do an email opt-in campaign?</p>
          <p className="text-muted">
            As long as your emails comply with the{" "}
            <a
              href="https://www.ftc.gov/tips-advice/business-center/guidance/can-spam-act-compliance-guide-business"
              target="_blank"
              rel="noopener noreferrer"
            >
              CAN-SPAM
            </a>{" "}
            regulations, you can send emails to the emails that you download
            from {process.env.REACT_APP_site_name}. However, we highly recommend
            doing opt-in campaigns. The emails we provide are not verified, and
            many email service providers, particularly the largest like GMAIL,
            YAHOO, and OUTLOOK, independently monitor email servers for spam.
          </p>
          <p className="ques">
            Are there any limitations on the unlimited plan?
          </p>
          <p className="text-muted">
            We offer an individual unlimited plan which means we provide an
            unlimited amount of data for an individual user. If you plan on
            using more than 50,000 records per month please contact us for more
            information about our other plans.
          </p>
          <p className="ques">Are there any contracts?</p>
          <p className="text-muted">
            Absolutely not! You’re welcome to cancel your membership at any
            time. Billing is on a monthly subscription basis. You must cancel at
            least three business days before the end of your billing cycle or
            you may be charged.
          </p>
          <p className="ques">Where do you get your data?</p>
          <p className="text-muted">
            We aggregate our data from a variety of public sources as well as
            opt-in databases.
          </p>
          <p className="ques">How often is your data updated?</p>
          <p className="text-muted">
            We run major updates quarterly and minor updates monthly.
          </p>
          <p className="ques">
            What information is included for consumer data?
          </p>
          <p className="text-muted">
            First name, last name, phone number, address, county, age, and
            estimated income.
          </p>
          <p className="ques">
            What information is included for business data?
          </p>
          <p className="text-muted">
            Name of the business, phone number, owner information (where
            available), address, sales volume, SIC code, and web address (where
            available).
          </p>
          <p className="ques">How do you handle cancellations and refunds?</p>
          <p className="text-muted">
            You can cancel your subscription at any time. &nbsp;All
            subscriptions are non-refundable and no pro-rated refunds will be
            given. &nbsp;Please be sure to cancel at least three business days
            before the end of your billing cycle. &nbsp;Once you cancel you will
            still be able to enjoy the benefits of your membership through the
            end of your subscription period. &nbsp;If you do not cancel at least
            three business days prior to your renewal you may be billed and that
            charge will be non-refundable.
          </p>
          <p className="ques">How accurate is your data?</p>
          <p className="text-muted">
            Our data runs at the industry average of about 90%. &nbsp;In some
            areas it may run better or worse, but you should expect to have a
            reasonably accurate list.
          </p>
          <p className="ques">Can I resell this data?</p>
          <p className="text-muted">
            Resale of the data is expressly forbidden. &nbsp;If you are
            interested in becoming a reseller you can contact us for more
            information.{" "}
          </p>
        </section>
      </Row>
    </Container>
  );
};

const DesktopDownload = props => {
  let macDownload = process.env.REACT_APP_macDownload;
  let winDownload = process.env.REACT_APP_winDownload;
  return (
    <div className={props.className}>
      <Button
        variant="dark"
        size="lg"
        className="mr-3"
        download={macDownload}
        href={macDownload}
        style={{ minWidth: "250px" }}
      >
        Download for Mac
      </Button>
      <Button
        variant="dark"
        size="lg"
        className="ml-3"
        download={winDownload}
        href={winDownload}
        style={{ minWidth: "250px" }}
      >
        Download for Windows
      </Button>
    </div>
  );
};

const ListShackDesktop = props => {
  let { handleIsApp } = props;

  useEffect(() => {
    handleIsApp(false);
  }, [handleIsApp]);

  return (
    <Container fluid>
      <Row
        className="bg-dark text-center text-white d-flex justify-content-center align-items-middle"
        style={{
          backgroundImage: `url(${hero_img})`,
          height: "60vh",
          backgroundPosition: "top",
          backgroundSize: "cover"
        }}
      >
        <Jumbotron
          style={{
            backgroundColor: "rgb(0,0,0,.6)",
            height: "100%",
            width: "100%"
          }}
          className="d-flex justify-content-center align-items-middle flex-column"
          fluid
        >
          <h2>ListShack Desktop</h2>
          <p className="lead">
            Everything you love about ListShack, right on your desktop computer
          </p>
          <DesktopDownload className="text-center mt-5" />
        </Jumbotron>
      </Row>
      <Row
        className="d-flex justify-content-center align-items-center text-center"
        style={{ padding: "100px 0px", maxWidth: "800px", margin: "0 auto" }}
      >
        <Col xs={12}>
          <h3>ListShack Desktop is Even Easier to Use</h3>
          <p className="lead">
            The Desktop app brings all that you love about ListShack right to
            your desktop computer. Fewer password resets. Less confusion about
            where to login.{" "}
            <b>
              Plus downloads on the Desktop app are unlimited (no deductions
              from your monthly leads) from now until the end of the year!
            </b>{" "}
            Download the desktop app now!
          </p>
          <br />
          <hr />
          <br />
        </Col>
        <Col xs={12} className="mt-5">
          <h3>How to use the Desktop App</h3>
          <p className="lead">
            Using the Desktop App is even easier than logging in online.
          </p>
          <ol
            className="text-left"
            style={{ maxWidth: "500px", margin: "0 auto", fontSize: "1.3rem" }}
          >
            <li>
              Make sure your ListShack subscription is active{" "}
              <a href="https://www.listshack.support/settings/billing">here.</a>
            </li>
            <li>Download the app to your Mac or Windows computer.</li>
            <li>
              Open the app and login to your account.
              <br />
              <small>
                If you've forgotten your password, reset it{" "}
                <a href="https://www.listshack.support/reset_password">here</a>.
              </small>
            </li>
            <li>Download unlimited leads!!!</li>
          </ol>
        </Col>
      </Row>
      <Row
        className="d-flex justify-content-center align-items-middle flex-row text-center bg-light"
        style={{ padding: "75px 0px" }}
      >
        <Col>
          <h2>Download ListShack Desktop Now!</h2>
          <DesktopDownload className="text-center mt-5" />
        </Col>
      </Row>
    </Container>
  );
};

const DatabaseLander = props => {
  const {databases} = props;
  console.log("DatabaseLander props: ", props);
  console.log("databases: ", databases);
  return (
    <Container>
      <Row
        style={{
          marginTop: "30px",
          minHeight: "75vh"
        }}
        className="text-center mb-5"
      >
        <Col>
          <Jumbotron fluid>
            <h1>{props.title}</h1>
            <p className="lead">{props.lead}</p>
          </Jumbotron>
          <h4>{props.subtitle}</h4>
          <div>{props.description}</div>
          <CardDeck>
            {databases &&
              databases.map(database => {
                return (
                  <Card
                    bg={database.themeColor}
                    text="white"
                    style={{ width: "18rem" }}
                    key={database.dictId}
                  >
                    <Card.Header>{database.type}</Card.Header>
                    <Card.Body>
                      <Card.Title>{database.title}</Card.Title>
                      <Card.Text>{database.description}</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <ButtonGroup>
                        <Link
                          to={`/${props.subPath}/${database.dictId}`}
                          className="btn btn-sm btn-light"
                        >
                          Learn more
                        </Link>
                        <Link
                          to={database.path}
                          className="btn btn-sm btn-link text-white"
                        >
                          Search now
                        </Link>
                      </ButtonGroup>
                    </Card.Footer>
                  </Card>
                );
              })}
          </CardDeck>
        </Col>
      </Row>
    </Container>
  );
};

const DatabaseDetails = props => {
  let {databases} = props;
  console.log("databases: ", databases);

  let find = path => {
    let db = databases.find(db => db.dictId === path);
    return db;
  };

  //let { url } = useRouteMatch();
  let { path } = useParams();
  let databasePage = find(path);

  return (
    <Switch>
      <Route exact path={`/${props.landerPath}/:path`}>
        <DatabaseDescription
          dataDict={databasePage}
          landerPath={props.landerPath}
        />
      </Route>
    </Switch>
  );
};

const DatabaseDescription = props => {
  let {dataDict} = props;
  console.log("dataDict: ", dataDict);
  return (
    <React.Fragment>
      <Helmet>
        <title>{dataDict.title}</title>
        <meta name="HandheldFriendly" content="true" />
        <meta name="apple-mobile-web-app-capable" content="YES" />
      </Helmet>
      <Container>
        <Row>
          <Col xs={12} className="mt-4">
            <p>
              <Link to={`/${props.landerPath}`}>
                &lt;&nbsp;View all {props.landerPath} databases
              </Link>
            </p>
          </Col>
          <Col xs={12}>
            <Jumbotron>
              <h1>{dataDict.title}</h1>
              <hr />
              <p className="lead">{dataDict.description}</p>
              <Link
                to={dataDict.path}
                className={`btn btn-${dataDict.themeColor} btn-lg`}
              >
                Search now
              </Link>
            </Jumbotron>
          </Col>
          <Col md={8}>
            <h3>Filters available</h3>
            <ul>
              {Object.values(dataDict.cols).map(col => {
                return <li key={col.name}>{col.name}</li>;
              })}
            </ul>
            <h3>Columns available for download</h3>
            <ul>
              {dataDict.dlCols.map(col => {
                return <li key={col.value}>{col.name}</li>;
              })}
            </ul>
            <h3>Availablilty</h3>
            <p>{dataDict.access}</p>
            <h3>Price per lead</h3>
            <p>${(50 / 50000) * dataDict.leadsMultiplier}</p>
          </Col>
          <Col md={4}>
            <Card
              bg={dataDict.themeColor}
              text="white"
              className="position-sticky"
            >
              <Card.Body>
                <Card.Title>{dataDict.title}</Card.Title>
                <Card.Text>{dataDict.description}</Card.Text>
                <Link to={dataDict.path} className="btn btn-light">
                  Search now
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const Archives = props => {
  const databases = props.databases;

  return (
    <Container>
      <Row
        style={{
          marginTop: "30px",
          minHeight: "75vh"
        }}
        className="text-center mb-5"
      >
        <Col>
          <Jumbotron fluid>
            <h1>
              <i className="fa fa-database" aria-hidden="true"></i>&nbsp;
              {process.env.REACT_APP_site_name} Archives
            </h1>
            <p className="lead">
              Search the {process.env.REACT_APP_site_name} archives for{" "}
              <mark className="font-italic ">unlimited</mark> historical data.
            </p>
          </Jumbotron>
          <h4>
            <span className="text-success text-uppercase">
              Unlimited downloads
            </span>{" "}
            &mdash;{" "}
            <span className="text-danger text-uppercase">
              use at your own risk
            </span>
          </h4>
          <p className="mb-5" style={{ fontSize: ".7em" }}>
            One of our biggest jobs at {process.env.REACT_APP_site_name} is to
            source and update data. That means that as people and businesses
            move, change their phone numbers, pass away, and grow older, much of
            what we do is source the freshest (and most economical) data as
            possible. Nevertheless, Many people and businesses don't change all
            that fast... People don't move, they keep their phone numbers, and
            they may be older, but not that much older. So, we provide our
            archives, all the data that we don't keep fresh, to you for free (or
            nearly free) for all the things that you do with our data that don't
            require the freshest number, the latest DNC (Do Not Call) scrub, or
            the most recent NCOA (National Change of Address) updates. User
            beware be warned, we haven't updated these dataases. We've left off
            the DNC scrub filter, and the age column hasn't been recalcuated (on
            our consumer files). We've not done an NCOA scrub, nor removed
            deceased persons. Help yourself to the data, but proceed with
            caution when using it in your compaigns. This data is great for
            experimenting, it's great if you're calling folks by hand (no
            autodialer so no DNC scrub required), it's great if you're doing
            market research, or you are a researcher tracking trends. Do with it
            what you will, but we aren't responsible if you break Can-Spam, FCC
            Telemarketing regulations (TCPA), or get your email servers
            blacklisted by the email service providers. We also aren't
            responsible for returned mail (and the postage you might have
            spent). We're just trying to be as helpful as we can, so you and
            your business can succeed. Be smart out there.
          </p>
          <CardDeck>
            {databases &&
              databases.map(database => {
                return (
                  <Card
                    bg="dark"
                    text="white"
                    style={{ width: "18rem" }}
                    key={database.database}
                  >
                    <Card.Header>{database.type}</Card.Header>
                    <Card.Body>
                      <Card.Title>{database.title}</Card.Title>
                      <Card.Text>{database.description}</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <ButtonGroup>
                        <Link
                          to={`/archives/${database.database}`}
                          className="btn btn-sm btn-light"
                        >
                          Learn more
                        </Link>
                        <Link
                          to={database.path}
                          className="btn btn-sm btn-link text-white"
                        >
                          Search now
                        </Link>
                      </ButtonGroup>
                    </Card.Footer>
                  </Card>
                );
              })}
          </CardDeck>
        </Col>
      </Row>
    </Container>
  );
};

const ArchivesDetails = props => {
  let databases = props.databases;

  let find = path => {
    let db = databases.find(db => db.database === path);
    return db;
  };

  //let { url } = useRouteMatch();
  let { path } = useParams();
  let archivePage = find(path);

  return (
    <Switch>
      <Route exact path="/archives/:path">
        <ArchiveDescription dataDict={archivePage} />
      </Route>
    </Switch>
  );
};

const ArchiveDescription = props => {
  let dataDict = props.dataDict;

  return (
    <React.Fragment>
      <Helmet>
        <title>{dataDict.title}</title>
        <meta name="HandheldFriendly" content="true" />
        <meta name="apple-mobile-web-app-capable" content="YES" />
      </Helmet>
      <Container>
        <Row>
          <Col xs={12} className="mt-4">
            <p>
              <Link to="/archives">&lt;&nbsp;View all archives</Link>
            </p>
          </Col>
          <Col xs={12}>
            <Jumbotron>
              <h1>{dataDict.title}</h1>
              <p className="lead">
                This is a {process.env.REACT_APP_site_name} historical dataset,
                no longer updated.
              </p>
              <hr />
              <p>{dataDict.description}</p>
              <Link to={dataDict.path} className="btn btn-dark btn-lg">
                Search now
              </Link>
            </Jumbotron>
          </Col>
          <Col md={8}>
            <h3>Filters available</h3>
            <ul>
              {Object.values(dataDict.cols).map(col => {
                return <li key={col.name}>{col.name}</li>;
              })}
            </ul>
            <h3>Columns available for download</h3>
            <ul>
              {dataDict.dlCols.map(col => {
                return <li key={col.value}>{col.name}</li>;
              })}
            </ul>
            <h3>Availablilty</h3>
            <p>{dataDict.access}</p>
            <h3>Price per lead</h3>
            <p>${(50 / 50000) * dataDict.leadsMultiplier}</p>
          </Col>
          <Col md={4}>
            <Card bg="dark" text="white" className="position-sticky">
              <Card.Body>
                <Card.Title>{dataDict.title}</Card.Title>
                <Card.Text>{dataDict.description}</Card.Text>
                <Link to={dataDict.path} className="btn btn-light">
                  Search now
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const CheckActiveStatus = customerDoc => {
  //console.log("customerDoc: ", customerDoc, typeof customerDoc);
  // If they don't have a customerDoc they don't have as subscription and can't have an active subscription, return false
  if (typeof customerDoc === "undefined" || customerDoc === null) {
    return false;
  }
  // If they haven't complete signup, they might have a customerDoc but not a subscription status, return false
  if (
    typeof customerDoc.subscription_status === "undefined" ||
    customerDoc.subscription_status === null
  ) {
    return false;
  }
  // If they have a customerDoc and subscription status, check the subscription status to see if they are cancelled. If they are expired or canceled, they are canceled and not active, return false
  const cancellationStatus = ["canceled", "incomplete_expired", "expired"];
  if (cancellationStatus.includes(customerDoc.subscription_status)) {
    return false;
  }
  // If they've canceled but the subscription hasn't expired, return true
  if (customerDoc.cancel_at_period_end === true) {
    return true;
  }
  // Otherwise we'll consider it an active subscription. Return false
  return true;
};

const CheckCancellationStatus = customerDoc => {
  const cancellationStatus = ["canceled", "incomplete_expired", "expired"];

  let noCustomerDoc =
    typeof customerDoc === "undefined" || customerDoc === null;

  //console.log("noCustomerDoc: ", noCustomerDoc);

  // If they don't have a customerDoc they don't have as subscription and can't have cancelled, return false
  if (noCustomerDoc) {
    console.log("No Customer Doc");
    return false;
  }
  // If they haven't complete signup, they might have a customerDoc but not a subscription id, return false
  let subId = customerDoc.subscription_id
    ? customerDoc.subscription_id
    : customerDoc.subscription_uuid;
  let noSubId =
    typeof subId === "undefined" ? true : customerDoc.subscription_id === null;
  //console.log("noSubStatus: ", noSubStatus);

  if (noSubId) {
    console.log("No subscription id");
    return false;
  }
  // If they have a customerDoc and subscription status, check the subscription status to see if they are cancelled. If they are expired or canceled, they are canceled. return true
  let isCancelledSub = cancellationStatus.includes(
    customerDoc.subscription_status
  )
    ? typeof subId !== "undefined"
    : false;
  //console.log("isCancelledSub: ", isCancelledSub);

  if (isCancelledSub) {
    console.log("This is a cancelled subscription.");
    return true;
  }
  // If they've canceled but the subscription hasn't expired, return true
  if (customerDoc.cancel_at_period_end === true) {
    return true;
  }
  // Otherwise we'll consider it an active subscription. Return false
  console.log("Default return statement");
  return false;
};

const CheckIsPastDue = customerDoc => {
  let pastDueStatus = ["past due", "past_due"];
  let isPastDue = customerDoc
    ? pastDueStatus.includes(customerDoc.subscription_status)
    : false;
  return isPastDue;
};

const ManualAccountChange = props => {
  return (
    <div
      style={props.style ? props.style : null}
      className={props.className ? props.className : null}
    >
      <Card style={{ maxWidth: "500px" }}>
        <Card.Body>
          <h3>Please contact support</h3>
          <p className="lead">
            I'm sorry, this account is not currently eligible for online
            upgrades or new account creation. Please contact support to schedule a quick membership verification call.
            Once your membership is verified, you'll be able to subscribe, resubscribe, or update your account.
          </p>
          <p className="lead">
            {process.env.REACT_APP_site_name} Support
            <br />
            Email:{" "}
            <a href={`mailto:${process.env.REACT_APP_contact_email}`}>
              {process.env.REACT_APP_contact_email}
            </a>
            <br />
            Text "Call Me" to &nbsp;
            <a href={`sms:${process.env.REACT_APP_contact_sms}`}>
              {formatPhoneNumber(process.env.REACT_APP_contact_sms)}
            </a>
          </p>
        </Card.Body>
      </Card>
    </div>
  );
};

const ComparePlansTable = props => {
  console.log("ComparePlansTable props:", props);
  const { plans, selectPlan, user } = props;
  return (
    <Row>
      <Col
        id="pricing"
        xs={12}
        style={{ padding: "15px" }}
        className="text-center d-flex flex-column align-items-center align-content-center justify-content-center"
      >
        {props.title}
        <Table responsive hover className="text-left mt-5 mb-5">
          <thead>
            <tr>
              <th>Feature</th>
              {Object.values(plans).map((plan, index) => (
                <th
                  key={`cpt_th_${index}`}
                  className={!plan.public ? "d-none" : null}
                >
                  <span className="planName">{plan.name}</span>
                  <br />
                  <span className="price">${plan.price / 100}</span>
                  <br />
                  <span className="period">per month</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Leads per month</td>
              {Object.values(plans).map((plan, index) => (
                <td
                  key={`cpt_lpm_${index}`}
                  className={!plan.public ? "d-none" : null}
                >
                  {plan.leads.toLocaleString()}
                </td>
              ))}
            </tr>
            <tr>
              <td>Download limit</td>
              {Object.values(plans).map((plan, index) => (
                <td
                  key={`cpt_dl_${index}`}
                  className={!plan.public ? "d-none" : null}
                >
                  {plan.downloadLimit.toLocaleString()}
                </td>
              ))}
            </tr>
            <tr>
              <td>Team member logins</td>
              {Object.values(plans).map((plan, index) => (
                <td
                  key={`cpt_tml_${index}`}
                  className={!plan.public ? "d-none" : null}
                >
                  {plan.users.toLocaleString()}
                </td>
              ))}
            </tr>
            <tr>
              <td>Search suppressions</td>
              {Object.values(plans).map((plan, index) => (
                <td
                  key={`cpt_ss_${index}`}
                  className={!plan.public ? "d-none" : null}
                >
                  {!plan.searchSuppressions ? (
                    <i className="fa fa-times" aria-hidden="true"></i>
                  ) : (
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    ></i>
                  )}
                </td>
              ))}
            </tr>
            <tr>
              <td>Leads Rollover Each Month</td>
              {Object.values(plans).map((plan, index) => (
                <td
                  key={`cpt_lr_${index}`}
                  className={!plan.public ? "d-none" : null}
                >
                  {!plan.includesRollover ? (
                    <i className="fa fa-times" aria-hidden="true"></i>
                  ) : (
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    ></i>
                  )}
                </td>
              ))}
            </tr>
            <tr>
              <td>&nbsp;</td>
              {Object.values(plans).map((plan, index) => (
                <td className={!plan.public ? "d-none" : null}>
                  <Link
                    to={!user ? "/signup" : "/settings/billing"}
                    key={`cpt_btn_${index}`}
                    onClick={e => {
                      selectPlan(e, index);
                    }}
                    className={
                      !plan.public ? "d-none" : "btn btn-outline-primary"
                    }
                  >
                    Signup
                  </Link>
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

const ComparePlans = props => {
  //console.log("ComparePlans props: ", props);
  const { plans, selectPlan, customerDoc, isCancelled, selectPlanCallback, userDoc } = props;
  //console.log("plans: ", plans);
  let currentPlan = Object.values(plans).find(plan => {
    return plan.planID === props.currentPlan;
  });
  let indexOfCurrentPlan = Object.values(plans).indexOf(currentPlan);
  //console.log("ComparePlans isCancelled: ", isCancelled);
  // Don't allow account change if they've subscribed in the last 30 days
  let prohibitUpgrade;
  if (typeof customerDoc !== "undefined") {
    //console.log(new Date() - new Date(customerDoc.updated_at));
    let updatedAt = customerDoc.updated_at
      ? new Date(customerDoc.updated_at)
      : null;
    prohibitUpgrade = updatedAt
      ? (new Date() - updatedAt) / 10000 < 388800
      : false;
    //console.log("prohibitUpgrade: ", prohibitUpgrade);
  }

  if (prohibitUpgrade) {
    return <ManualAccountChange />;
  }
  return (
    <Container>
      <Row>
        <Col
          xs={12}
          style={{ padding: "15px" }}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          {props.title}
          {props.showComparePlans && (
            <Row className="d-flex flex-row justify-content-center align-items-center">
              {Object.values(plans).map((plan, index) => (
                <Col
                  key={`cp${index}`}
                  xs={6}
                  sm={6}
                  md={4}
                  className={!plan.public ? "d-none" : null}
                >
                  <Card className="mt-3">
                    <Card.Header className="text-center">
                      <Card.Title>{plan.shortname}</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Card.Title>${plan.price / 100} per month</Card.Title>
                      <Card.Text className="text-muted">
                        {plan.leads.toLocaleString()} leads per month
                      </Card.Text>
                      <Card.Text className="text-muted">
                        Up to {plan.downloadLimit.toLocaleString()} leads per
                        download
                      </Card.Text>
                      <Card.Text className="text-muted">
                        Up to {plan.users} team members
                      </Card.Text>
                      <Card.Text className="text-muted">
                        {!plan.searchSuppressions ? (
                          <i className="fa fa-times" aria-hidden="true"></i>
                        ) : (
                          <i
                            className="fa fa-check text-success"
                            aria-hidden="true"
                          ></i>
                        )}{" "}
                        Search suppressions
                      </Card.Text>
                      <Card.Text className="text-muted">
                        {!plan.includesRollover ? (
                          <i className="fa fa-times" aria-hidden="true"></i>
                        ) : (
                          <i
                            className="fa fa-check text-success"
                            aria-hidden="true"
                          ></i>
                        )}{" "}
                        Unused leads rollover each month
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer className="text-center">
                      <Button
                        onClick={e => {
                          selectPlan(index);
                          props.handleComparePlans(false);
                          if (selectPlanCallback) {
                            selectPlanCallback(index);
                          }
                        }}
                        variant={
                          index === indexOfCurrentPlan ? "info" : "primary"
                        }
                        disabled={
                          isCancelled ? false : index === indexOfCurrentPlan
                        }
                      >
                        {isCancelled
                          ? "Select plan"
                          : index === indexOfCurrentPlan
                          ? "My plan"
                          : "Select Plan"}
                      </Button>
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
              <Col xs={12} style={{ padding: "15px" }} className="text-muted">
                Higher volume plans are available upon request. Please send an
                email to {process.env.REACT_APP_contact_email} for more
                information
              </Col>
            </Row>
          )}
          {!props.showComparePlans && (
            <Row>
              <Col xs={12}>
                {userDoc.superVerified &&
                  <Subscribe
                    {...props}
                  />
                }
                {!userDoc.superVerified &&
                  <ManualAccountChange {...props} />
                }
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const CompanyProfile = props => {
  let { userDoc, handleAlerts, apiKey, size, user } = props;
  let blankProfile = { name: "", phone: "", email: user.email };
  let eCompanyProfile =
    userDoc.companyProfile !== undefined
      ? userDoc.companyProfile
      : blankProfile;
  const [companyProfile, setCompanyProfile] = useState(eCompanyProfile);
  const [sentVerification, setSentVerification] = useState(false);
  const [verificationCode, setVerificationCode] = useState("");
  const [verifiedTrue, setVerifiedTrue] = useState(false);
  const verified = userDoc.verified;
  console.log("verified: ", verified);

  const updateCompanyProfile = async (e, profileObject) => {
    e.preventDefault();
    if (profileObject.name === "") {
      handleAlerts("", "Please update the company name.", "warning");
      return false;
    }
    if (profileObject.phone === "") {
      handleAlerts("", "Please update the company phone number.", "warning");
      return false;
    }
    if (profileObject.email === "") {
      handleAlerts("", "Please update the company email.", "warning");
      return false;
    }

    try {
      await userDoc._docRef.update({
        companyProfile: profileObject
      });
      handleAlerts("", "Your company profile has been updated!", "success");
      return true;
    } catch (err) {
      console.log("Error updating company profile: ", err);
    }
  };

  const disableProfileUpdate =
    (userDoc.companyProfile === undefined &&
      JSON.stringify(companyProfile) === JSON.stringify(blankProfile)) ||
    (userDoc.companyProfile !== undefined &&
      JSON.stringify(userDoc.companyProfile) ===
        JSON.stringify(companyProfile));

  return (
    <Container>
      <Row noGutters={true}>
        {!sentVerification && (
          <Col xs={12}>
            {props.heading ? props.heading : null}
            <Form.Group>
              <Form.Label>Company name</Form.Label>
              <Form.Control
                size={size ? size : "lg"}
                type="text"
                placeholder="Enter company name"
                value={companyProfile.name}
                onChange={e =>
                  setCompanyProfile({
                    email: companyProfile.email,
                    name: e.target.value,
                    phone: companyProfile.phone
                  })
                }
              />
              <Form.Text className="text-muted">
                This will be displayed to team members so they know they are
                using the correct account.
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label>Company phone</Form.Label>
              <Form.Control
                size={size ? size : "lg"}
                type="text"
                placeholder="Enter company phone"
                value={companyProfile.phone}
                onChange={e =>
                  setCompanyProfile({
                    email: companyProfile.email,
                    name: companyProfile.name,
                    phone: e.target.value
                  })
                }
              />
              <Form.Text className="text-muted">
                This will be used to contact you via text message about your
                account.
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label>Company email</Form.Label>
              <Form.Control
                size={size ? size : "lg"}
                type="text"
                placeholder="Enter company email"
                value={companyProfile.email}
                onChange={e =>
                  setCompanyProfile({
                    email: e.target.value,
                    name: companyProfile.name,
                    phone: companyProfile.phone
                  })
                }
              />
              <Form.Text className="text-muted">
                This will be used to contact you via email about your account.
              </Form.Text>
            </Form.Group>
            {!verified && (
              <Button
                onClick={async e => {
                  const init = {
                    method: "POST",
                    headers: {
                      authorization: `Bearer ${apiKey}`,
                      "Content-Type": "application/json"
                    },
                    "Transfer-Encoding": "chunked",
                    cache: "default",
                    accept: "application/json",
                    body: JSON.stringify({
                      sms_to: companyProfile.phone
                    })
                  };
                  let url = `${process.env.REACT_APP_api_url}/api/start_verification`;
                  console.log("url: ", url, "init: ", init);

                  try {
                    let updatedProfile = await updateCompanyProfile(
                      e,
                      companyProfile
                    );
                    if (updatedProfile) {
                      await fetch(url, init);
                      setSentVerification(true);
                    }
                  } catch (err) {
                    console.log(
                      "Something went wrong verifying the company: ",
                      err
                    );
                    handleAlerts(
                      "",
                      `Uh oh, we couldn't verify your company: ${err.message}`,
                      "warning"
                    );
                  }
                }}
                disabled={disableProfileUpdate}
                size="lg"
                className="pull-right"
              >
                Verify company profile
              </Button>
            )}
          </Col>
        )}
        {sentVerification && (
          <Col xs={12}>
            <p className="lead">
              Sent verification code to:
              <br />
              {formatPhoneNumber(companyProfile.phone)}
              <br />
              <Button
                variant="link"
                size="sm"
                className="mb-4 d-flex justify-content-center align-items-center"
                onClick={() => setSentVerification(false)}
              >
                Click here to change U.S. cell phone number
              </Button>
            </p>
            {!verifiedTrue && (
              <React.Fragment>
                <Form.Group>
                  <Form.Label>Enter verification code</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    placeholder="123456"
                    value={verificationCode}
                    onChange={e => setVerificationCode(e.target.value)}
                  />
                  <Form.Text className="text-muted">
                    Enter the verification code sent to your phone number.
                  </Form.Text>
                </Form.Group>
                <Button
                  onClick={async e => {
                    e.preventDefault();
                    const init = {
                      method: "POST",
                      headers: {
                        authorization: `Bearer ${apiKey}`,
                        "Content-Type": "application/json"
                      },
                      "Transfer-Encoding": "chunked",
                      cache: "default",
                      accept: "application/json",
                      body: JSON.stringify({
                        verificationCode
                      })
                    };
                    let url = `${process.env.REACT_APP_api_url}/api/verify_account`;
                    console.log("url: ", url, "init: ", init);
                    let response = await fetch(url, init);
                    let verification = await response.json();
                    console.log("verification: ", verification);
                    if (verification.verified) {
                      setVerifiedTrue(true);
                    }
                    if (!verification.verified && verification.expired) {
                      handleAlerts(
                        "",
                        "Your verfication code has expired.  Please send a new one.",
                        "warning"
                      );
                    }
                    if (!verification.verified && !verification.expired) {
                      handleAlerts(
                        "",
                        "Your verfication code is incorrect.  Please try again.",
                        "warning"
                      );
                    }
                  }}
                >
                  Verify Account
                </Button>
                <Button
                  variant="link"
                  onClick={e => {
                    e.preventDefault();
                    const init = {
                      method: "POST",
                      headers: {
                        authorization: `Bearer ${apiKey}`,
                        "Content-Type": "application/json"
                      },
                      "Transfer-Encoding": "chunked",
                      cache: "default",
                      accept: "application/json",
                      body: JSON.stringify({
                        sms_to: companyProfile.phone
                      })
                    };
                    let url = `${process.env.REACT_APP_api_url}/api/start_verification`;
                    console.log("url: ", url, "init: ", init);
                    fetch(url, init);
                  }}
                >
                  Resend code
                </Button>
              </React.Fragment>
            )}
            {verifiedTrue && (
              <h4 className="text-success">
                <i className="fa fa-check" aria-hidden="true"></i>Account
                verified
              </h4>
            )}
          </Col>
        )}

        <Col xs={12} style={{ paddingBottom: "100px" }}>
          {verified && !verifiedTrue && (
            <Button
              onClick={e => updateCompanyProfile(e, companyProfile)}
              disabled={disableProfileUpdate}
              size="lg"
              className="pull-right"
            >
              Update profile
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const QuickStart = props => {
  const [showTeam, setShowTeam] = useState(false);
  let {
    userDoc,
    handleAlerts,
    user,
    handleModalShow,
    plans,
    customerDoc,
    isApp,
    handleIsApp,
    apiKey,
    handleState
  } = props;

  let verified = userDoc
    ? userDoc.verified
      ? userDoc.verified
      : false
    : false;
  isApp = user ? isApp : false;

  useEffect(() => {
    if (!verified) {
      return handleState({
        noDistract: true,
        isApp: false,
        alertShow: false
      });
    }
    return handleIsApp(isApp);
  }, [isApp, handleIsApp, handleState, verified]);

  if (!user) {
    return <Redirect to="/home" />;
  }

  const disableProfileOnboarding =
    userDoc.companyProfile === undefined || verified === false;

  return (
    <Container>
      {!showTeam && (
        <Row className="mt-5 mb-5">
          <CompanyProfile
            heading={
              <div className="text-center">
                <h2 className="text-center">Verify your company profile!</h2>
                <p className="text-muted">
                  Please verify your company profile so that we can stay in
                  touch about your account status.
                </p>
              </div>
            }
            userDoc={userDoc}
            handleAlerts={handleAlerts}
            user={user}
            apiKey={apiKey}
          />
        </Row>
      )}

      {showTeam && (
        <Row className="mt-5 mb-5">
          <TeamMembers
            heading={
              <div className="text-center">
                <h2 className="text-center">Add your team!</h2>
                <p className="text-muted">
                  Quickly and easily add your team members, so you don't have o
                  keep track of someone else's password.
                </p>
              </div>
            }
            userDoc={userDoc}
            handleAlerts={handleAlerts}
            handleModalShow={handleModalShow}
            plan={Object.values(plans).find(
              plan => customerDoc.plan_id === plan.planID
            )}
            apiKey={apiKey}
          />
        </Row>
      )}
      <Row>
        <Col xs={12} style={{ paddingBottom: "100px" }}>
          {!showTeam && (
            <Button
              onClick={e => {
                e.preventDefault();
                setShowTeam(true);
              }}
              disabled={disableProfileOnboarding}
              size="lg"
              className="pull-right"
              variant="link"
            >
              Add your team &nbsp;&gt;
            </Button>
          )}
          {showTeam && (
            <Link to="/search" className="btn-lg btn-link pull-right">
              Search for leads &nbsp;&gt;
            </Link>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const checkIfUser = async email => {
  //returns an array of users that match the email
  let eUsers = await db
    .collection("users")
    .where("email", "==", email)
    .get();
  //console.log("eUsers: ", eUsers.empty);
  return eUsers.empty ? false : eUsers;
};

// returns the id of the User Doc for updating if there is not an active subscription
const checkHasSubscription = async (users, userDoc) => {
  let userDocs = [];
  users.forEach(doc => userDocs.push({ doc: doc.data(), id: doc.id }));
  for (let childUserDoc of userDocs) {
    //console.log("childUserDoc: ", childUserDoc.doc, "userDoc: ", userDoc, "childUserDoc.id: ", childUserDoc.id);
    if (childUserDoc.doc.id === userDoc.id) {
      //the childUser was previously a user of this account
      console.log(
        "The childUserDoc and accountUserDoc ids match, so this user was previously a user of this account."
      );
      return childUserDoc.id;
    } else {
      let childUserSubscriptions = [];
      let subscriptions = await db
        .collection("customers")
        .where("email", "==", childUserDoc.doc.email)
        .get();
      if (!subscriptions.empty) {
        subscriptions.forEach(doc => childUserSubscriptions.push(doc.data()));
        for (let subDoc of childUserSubscriptions) {
          if (subDoc.subscription_status === "active") {
            // this email address already has an active subscription
            console.log("This user already has an active subscription.");
            return false;
          } else {
            // this email address does not have an active subscription
            console.log("This user does not have an active subscription.");
            return childUserDoc.id;
          }
        }
      } else {
        // There are no subscriptions, so should be fine to add this user
        return childUserDoc.id;
      }
    }
  }
};

const addCompanyUser = async (
  userDoc,
  newUser,
  isRemove = false,
  childDocId
) => {
  let nUsers = [];
  if (Array.isArray(userDoc.companyUsers)) {
    userDoc.companyUsers.map(user => {
      if (isRemove && user.email === newUser.email) {
        // Do nothing
        return console.log(`Removing ${newUser.email} from the account.`);
      } else {
        return nUsers.push(user);
      }
    });
  }
  if (!isRemove) {
    nUsers.push(newUser);
  }

  let userDocRef = db.collection("users").doc(userDoc.id);
  try {
    //console.log(newUser);
    let batch = db.batch();
    batch.update(userDocRef, {
      companyUsers: nUsers
    });
    if (!isRemove && childDocId !== undefined) {
      // connect the child users userDoc to the account Doc
      let childUserDocRef = db.collection("users").doc(childDocId);
      batch.update(childUserDocRef, {
        id: userDoc.uid
      });
    }
    if (!isRemove && childDocId === undefined) {
      // create a new child user doc, to be updated when the user logs in
      let childUserDocRef = db.collection("users");
      childUserDocRef.add({
        email: newUser.email.toLowerCase(),
        id: userDoc.uid,
        inviteStatus: "pending"
      });
    }
    await batch.commit();
  } catch (err) {
    console.log("Error updating company users: ", err);
  }
};

const TeamMembers = props => {
  let { userDoc, handleAlerts, plan, handleModalShow, apiKey } = props;
  console.log("TeamMembers props: ", props);
  let emptyUser = { name: "", email: "", role: "download" };
  const [newUser, setNewUser] = useState(emptyUser);
  //console.log(props)
  let companyUsers = (x = []) => {
    return x.length;
  };
  let availableUsers = Math.max(
    plan.users - companyUsers(userDoc.companyUsers),
    0
  );

  const addNewTeammate = async (e, newUser) => {
    e.preventDefault();
    //console.log("newUser: ", newUser, "userDoc: ", userDoc);

    let users = await checkIfUser(newUser.email);
    //console.log("users: ", users);
    let msg = {
      to: newUser.email,
      from: process.env.REACT_APP_contact_email,
      subject: `You've been added to the ${process.env.REACT_APP_site_name} account for ${userDoc.companyProfile.name}`,
      text: `${userDoc.companyProfile.name} has added you to their ${process.env.REACT_APP_site_name} account.  Login from ${window.location.hostname}/login with the email address ${newUser.email} and any password of your choice.  The password that you login with the first time, will be set as your permanent password.  If you need to change your password later, you can do so from ${window.location.origin}/reset_password.`,
      html: `<p><strong>${userDoc.companyProfile.name} has added you to their ${process.env.REACT_APP_site_name} account.</strong></p><p>Login from <a href="${window.location.origin}/login">${window.location.origin}/login</a> with the email address ${newUser.email} and any password of your choice.  The password that you login with the first time, will be set as your permanent password.</p><p>If you need to change your password later, you can do so from <a href="${window.location.origin}/reset_password}">${window.location.origin}/reset_password</a>.</p>`
    };

    if (!users) {
      console.log("No users with this email, adding a new user.");
      addCompanyUser(userDoc, newUser, false);
      handleAlerts(
        "",
        `You have successfully added ${newUser.name} with ${newUser.role} permissions.`,
        "success"
      );
      sendEmail({ msg, handleAlerts, apiKey });
      setNewUser(emptyUser);
      return;
    }
    let userDocId = users ? await checkHasSubscription(users, userDoc) : false;
    console.log("userDocId: ", userDocId);
    if (!userDocId) {
      props.handleAlerts(
        "",
        "Uh oh, this person already has an active subscription and cannot be added to your account.",
        "warning"
      );
      setNewUser(emptyUser);
      return;
    }
    if (userDocId) {
      await addCompanyUser(userDoc, newUser, false, userDocId);
      handleAlerts(
        "",
        `You have successfully added ${newUser.name} with ${newUser.role} permissions.`,
        "success"
      );
      sendEmail({ msg, handleAlerts, apiKey });
      setNewUser(emptyUser);
      return;
    }
  };

  const removeTeammate = (e, eUser) => {
    e.preventDefault();

    let msg = {
      to: eUser.email,
      from: process.env.REACT_APP_contact_email,
      subject: `You've been removed from the ${process.env.REACT_APP_site_name} account for ${userDoc.companyProfile.name}`,
      text: `${userDoc.companyProfile.name} has removed you from their ${process.env.REACT_APP_site_name} account. If you would like to continue using your account login from ${window.location.hostname}/login  and click "Signup" to create your own account.`,
      html: `<p><strong>${userDoc.companyProfile.name} has removed you from their ${process.env.REACT_APP_site_name} account.</strong></p><p> If you'd like to continue using your account login from <a href="${window.location.origin}/login">${window.location.origin}/login</a> with the email address ${eUser.email} and click "Signup" to create your own account.</p>`
    };

    if (eUser.email !== userDoc.email) {
      addCompanyUser(userDoc, eUser, true, "");
      handleAlerts(
        "",
        `You have successfully removed ${eUser.name} with ${eUser.role} permissions.`,
        "success"
      );
      sendEmail({ msg, handleAlerts, apiKey });
      return;
    } else {
      props.handleProps("", "Uh oh, you cannot remove yourself.", "warning");
    }
  };

  const createCompanyUsers = usersArray => {
    let companyUsers = [];
    usersArray.map(user => {
      return companyUsers.push(
        <tr key={`ur_${user.email}`}>
          <td>{user.name}</td>
          <td>{user.email}</td>
          <td>{user.role}</td>
          <td>
            <Button
              variant="link"
              onClick={e => {
                if (
                  window.confirm(
                    `Are you sure you'd like to remove ${user.name} from your account?`
                  )
                ) {
                  removeTeammate(e, user);
                }
              }}
              className="text-info"
              style={{ fontSize: ".8em" }}
            >
              Remove
            </Button>
          </td>
        </tr>
      );
    });
    return companyUsers;
  };

  return (
    <Col xs={12}>
      {props.heading}
      <Table hover borderless={true} responsive size="sm">
        <thead>
          <tr>
            <th>Name</th>
            <th>Email</th>
            <th>Permissions</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Account owner</td>
            <td>{userDoc.email}</td>
            <td>admin</td>
            <td>&nbsp;</td>
          </tr>
          {Array.isArray(userDoc.companyUsers) &&
            createCompanyUsers(userDoc.companyUsers)}
        </tbody>
      </Table>

      <Row noGutters={true} className="mb-5 mt-3">
        <Col xs={12} className="mb-2 text-muted">
          <p>
            You can add up to {availableUsers} additional team members of up to{" "}
            {plan.users} total team members available in your plan. To add even
            more team members,{" "}
            <Button
              variant="link"
              onClick={handleModalShow}
              style={{ padding: "0px" }}
            >
              Upgrade your plan.
            </Button>
          </p>
        </Col>
        <Col>
          <Form.Control
            size="sm"
            type="text"
            placeholder="Enter a name"
            value={newUser.name}
            onChange={e =>
              setNewUser({
                name: e.target.value,
                email: newUser.email,
                role: newUser.role
              })
            }
          />
        </Col>
        <Col>
          <Form.Control
            size="sm"
            type="text"
            placeholder="Enter an email"
            value={newUser.email}
            onChange={e =>
              setNewUser({
                name: newUser.name,
                email: e.target.value,
                role: newUser.role
              })
            }
          />
        </Col>
        <Col>
          <Form.Control
            size="sm"
            as="select"
            value={newUser.role}
            onChange={e =>
              setNewUser({
                name: newUser.name,
                email: newUser.email,
                role: e.target.value
              })
            }
          >
            <option value="download">Download</option>
            <option value="admin">Admin</option>
          </Form.Control>
        </Col>
        <Col>
          <Button
            disabled={
              availableUsers === 0 ||
              (newUser.name === "" || newUser.email === "")
            }
            onClick={e => {
              if (newUser.email === "") {
                return handleAlerts(
                  "",
                  "Please include the email address of the person you would like to add.",
                  "warning"
                );
              }
              if (newUser.email === "") {
                return handleAlerts(
                  "",
                  "Please include the name of the person you would like to add.",
                  "warning"
                );
              }
              if (
                window.confirm(
                  `Are you sure you'd like to add ${newUser.name} to your account with ${newUser.role} permissions?`
                )
              ) {
                addNewTeammate(e, newUser);
              }
            }}
            size="sm"
            variant="secondary"
          >
            Add
          </Button>
        </Col>
      </Row>
    </Col>
  );
};

const NoSubscription = props => {
  let { userDoc } = props;
  return (
    <Container>
      <Row
        style={{
          marginTop: "30px",
          minHeight: "70vh"
        }}
      >
        <Col
          xs={12}
          className="text-center d-flex flex-column align-items-center justify-content-center"
        >
          <h2>
            Uh oh, it doesn't look like you have a subscription with us under
            the email {userDoc.email}
          </h2>
          <p className="lead">Please click the button below to signup!</p>
          <Link className="btn btn-primary btn-lg" to="/signup">
            Signup
          </Link>
          <small className="mt-5">
            If you believe this is a mistake, please email{" "}
            <a href={`mailto:${process.env.REACT_APP_contact_email}`}>
              {process.env.REACT_APP_contact_email}
            </a>{" "}
            and we'll get things squared away for you!
          </small>
        </Col>
      </Row>
    </Container>
  );
};

const NoPermissions = props => {
  let user = props.user;
  return (
    <Container>
      <Row
        style={{
          marginTop: "30px",
          minHeight: "65vh"
        }}
      >
        <Col
          xs={12}
          className="d-flex flex-column align-items-center justify-content-center"
        >
          <h2>Uh oh, you no longer have access to this account.</h2>
          <p className="lead">
            Click the button below to signup for your own subscription! Or
            contact the company account administrator and ask them to restore
            account access.
          </p>
          <Button
            className="btn btn-primary btn-lg"
            onClick={() => {
              console.log("user.uid", user.uid);
              db.runTransaction(async transaction => {
                let currentUsers = await db
                  .collection("users")
                  .where("email", "==", user.email)
                  .get();
                // delete the current user document
                let docData;
                currentUsers.forEach(userDocument => {
                  let docId = userDocument.id;
                  let docRef = db.collection("users").doc(docId);
                  docData = userDocument.data();
                  transaction.delete(docRef);
                });
                docData.email = user.email;
                docData.id = user.uid;
                docData.uid = user.uid;
                docData.inviteStatus = "converted";
                console.log("docData: ", docData);
                // create a user doc for this user
                let nUref = db.collection("users").doc(user.uid);
                transaction.set(nUref, docData);
              }).then(() => {
                console.log(
                  `Successfully converted user ${user.uid} ${user.email}. Sending them to the signup page.`
                );
                window.history.pushState(
                  "string",
                  "Converted signup",
                  "/signup"
                );
                window.location.reload();
              });
            }}
          >
            Signup
          </Button>
          <small className="mt-5">
            If you believe this is a mistake, please email{" "}
            <a href={`mailto:${process.env.REACT_APP_contact_email}`}>
              {process.env.REACT_APP_contact_email}
            </a>{" "}
            and we'll get things squared away for you!
          </small>
        </Col>
      </Row>
    </Container>
  );
};

const NotAdmin = props => {
  const { userDoc } = props;
  if (typeof userDoc.companyProfile !== "undefined") {
    return (
      <div className="text-center">
        <h5>You do not have access to this page</h5>
        <p>
          Please contact your company admin by email at{" "}
          <a href={`mailto:${userDoc.companyProfile.email}`}>
            {userDoc.companyProfile.email}
          </a>{" "}
          or by phone at{" "}
          <a href={`tel:${userDoc.companyProfile.phone}`}>
            {userDoc.companyProfile.phone}
          </a>{" "}
          to request access.
        </p>
      </div>
    );
  }
  return null;
};

const PleaseLogin = props => {
  return (
    <Container>
      <Row
        style={{
          marginTop: "30px",
          minHeight: "65vh"
        }}
      >
        <Col
          xs={12}
          className="d-flex flex-column align-items-center justify-content-center"
        >
          <h2>Please Login to View this Page</h2>
          <p className="lead">
            Click the button below to Login to your account.
          </p>
          <Link to="/login" className="btn btn-primary btn-large">
            Member Login
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

const findPlan = (plans, customerDoc) => {
  if (customerDoc === null || customerDoc === undefined) {
    return null;
  }
  if (customerDoc.plan_id === null || customerDoc.plan_id === undefined) {
    return null;
  }
  return Object.values(plans).find(
    plan => plan.planID.toLowerCase() === customerDoc.plan_id.toLowerCase()
  );
};

const SomethingWentWrong = props => {
  return (
    <Container>
      <Row>
        <Col style={{ height: "80vh" }} className="text-center">
          <img
            src="https://emojipedia-us.s3.dualstack.us-west-1.amazonaws.com/thumbs/160/facebook/230/smiling-face-with-smiling-eyes-and-hand-covering-mouth_1f92d.png"
            alt="Oops"
          />
          <h1>Something went wrong :(</h1>
          <p className="text-muted">
            Usually this means that you just need to refresh the browser, or
            that you no longer have permission to view this page.
          </p>
          <p className="text-muted">
            If you continue to see this error, please send a screenshot of this
            page to{" "}
            <a href="mailto:help@listshack.support">help@listshack.support</a>.
          </p>
          {props.error && (
            <React.Fragment>
              <h4 className="mt-5">Error on {window.location.href}</h4>
              {/*
              <p>
                <code>
                  {JSON.stringify(props.error, null, 2)}
                </code>
              </p>
              */}
              <JSONPretty
                className="text-left"
                id="json-pretty"
                data={props.error}
              ></JSONPretty>
            </React.Fragment>
          )}
        </Col>
      </Row>
    </Container>
  );
};

const UsageTable = props => {
  console.log("UsageTable props: ", props);
  const { userDoc } = props;
  return (
    <Table className="usageTable">
      <tbody>
        <tr>
          <td>
            <p>Total leads per cycle</p>
            <small className="text-muted">
              The number of leads you can download during your monthly billing
              cycle
            </small>
          </td>
          <td>{userDoc.leads.toLocaleString()}</td>
        </tr>
        <tr>
          <td>
            <p>Used this cycle</p>
            <small className="text-muted">
              The number of leads you have used this billing cycle
            </small>
          </td>

          <td>
            {Math.max(
              0,
              userDoc.leads - userDoc.leadsRemaining
            ).toLocaleString()}
          </td>
        </tr>
        <tr>
          <td>
            <p>Leads remaining</p>
            <small className="text-muted">
              The number of leads you can still download this billing cycle
            </small>
          </td>
          <td>{userDoc.leadsRemaining.toLocaleString()}</td>
        </tr>
        <tr>
          <td>
            <p>Per download limit</p>
            <small className="text-muted">
              The number of leads you can download at once
            </small>
          </td>
          <td>{userDoc.downloadLimit.toLocaleString()}</td>
        </tr>
      </tbody>
    </Table>
  );
};

const toDatetime = secs => {
  let t = new Date(1970, 0, 1); // Epoch
  t.setSeconds(secs);
  return t;
};

const ordinal_suffix_of = i => {
  var j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
};

const object_equals = (x, y) => {
  //console.log("object_equals: ", x, y);
  if (x === y) return true;
  // if both x and y are null or undefined and exactly the same

  if (!(x instanceof Object) || !(y instanceof Object)) return false;
  // if they are not strictly equal, they both need to be Objects

  if (x.constructor !== y.constructor) return false;
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.

  for (var p in x) {
    if (!x.hasOwnProperty(p)) continue;
    // other properties were tested using x.constructor === y.constructor

    if (!y.hasOwnProperty(p)) return false;
    // allows to compare x[ p ] and y[ p ] when set to undefined

    if (x[p] === y[p]) continue;
    // if they have the same strict value or identity then they are equal

    if (typeof x[p] !== "object") return false;
    // Numbers, Strings, Functions, Booleans must be strictly equal

    if (!object_equals(x[p], y[p])) return false;
    // Objects and Arrays must be tested recursively
  }

  for (p in y) if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;
  // allows x[ p ] to be set to undefined

  return true;
};

const indexOfArray = (array, item) => {
  for (var i = 0; i < array.length; i++) {
    // This if statement depends on the format of your array
    if (array[i][0] === item[0] && array[i][1] === item[1]) {
        return i;   // Found it
    }
  }
  return -1
}

const toEpoch = ds => {
  //ds should look like "2020-05-30"
  let da = ds.split("-");
  let year = da[0];
  let mon = da[1];
  let day = da[2];
  return Math.round(new Date(year, mon, day).getTime() / 1000);
};

const toEpochISO = ds => {
  return Math.round(new Date(ds).getTime() / 1000);
};

const Ccpa = props => {
  return (
    <Container>
      <Row>
        <Col className="mt-3 mb-5">
          <h3>CCPA Notice for California Residents</h3>
          <p>Last Updated: January 8, 2020</p>
          <p>
            This PRIVACY NOTICE FOR CALIFORNIA RESIDENTS supplements the
            information contained in the Privacy Statement of{" "}
            {process.env.REACT_APP_site_name} and its subsidiaries
            (collectively, “we,” “us,” or “our”) and applies solely to visitors,
            users, and others who reside in the State of California (“consumers”
            or “you”). We adopt this notice to comply with the California
            Consumer Privacy Act of 2018 (“CCPA”) and other California privacy
            laws. Any terms defined in the CCPA have the same meaning when used
            in this notice.
          </p>
          <h4>Information We Collect and Disclose</h4>
          <p>
            We collect information that identifies, relates to, describes,
            references, is capable of being associated with, or could reasonably
            be linked, directly or indirectly, with a particular consumer or
            device (“personal information”). In the preceding twelve (12)
            months, we have collected and disclosed the following categories of
            personal information from consumers:
          </p>
          <Table responsive id="ccpa_disclosures">
            <thead>
              <tr>
                <td>Category</td>
                <td>Collected</td>
                <td>Source</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  A. Identifiers (such as a real name, alias, postal address,
                  unique personal identifier, online identifier and email
                  address).
                </td>
                <td>Yes</td>
                <td>Directly from third party data providers.</td>
              </tr>
              <tr>
                <td>
                  B. Personal information categories listed in the California
                  Customer Records statute – Cal. Civ. Code § 1798.80(e) (such
                  as a name, address, telephone number, education and
                  employment).
                </td>
                <td>Yes</td>
                <td>Directly from third party data providers.</td>
              </tr>
              <tr>
                <td>
                  C. Protected classification characteristics under California
                  or federal law (such as age and gender).
                </td>
                <td>Yes</td>
                <td>Directly from third party data providers.</td>
              </tr>
              <tr>
                <td>
                  D. Commercial information (such as records of personal
                  property, products or services purchased, obtained, or
                  considered, or other purchasing or consuming histories or
                  tendencies).
                </td>
                <td>Yes</td>
                <td>Directly from third party data providers.</td>
              </tr>
              <tr>
                <td>
                  E. Biometric information (such as genetic, physiological and
                  biological characteristics, or identifying information, such
                  as, fingerprints, faceprints, voiceprints, iris or retina
                  scans, keystroke, gait, or other physical patters and sleep
                  data. Please Note: we do collect some general health and
                  exercise data categories).
                </td>
                <td>No</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  F. Internet or other similar network activity (such as
                  browsing history, search history, information on a consumer’s
                  interaction with a website, application, or advertisement).
                </td>
                <td>No</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>G. Geolocation data (such as physical location).</td>
                <td>Yes</td>
                <td>Directly from third party data providers.</td>
              </tr>
              <tr>
                <td>
                  H. Sensory data (such as audio, electronic, visual, thermal,
                  olfactory, or similar information).
                </td>
                <td>No</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  I. Professional or employment-related information (such as
                  current job history).
                </td>
                <td>Yes</td>
                <td>Directly from third party data providers.</td>
              </tr>
              <tr>
                <td>
                  J. Non-public education information per the Family Educational
                  Rights and Privacy Act – 20 U.S.C. Section 1232g, 34 C.F.R.
                  Part 99 (such as education records directly related to a
                  student maintained by an educational institution or party
                  acting on its behalf, such as grades, transcripts, class
                  lists, student schedules, student identification codes,
                  student financial information, or student disciplinary
                  records).
                </td>
                <td>No</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  K. Inferences drawn from other personal information (such as a
                  profile reflecting a person’s preferences, characteristics,
                  psychological trends, predispositions, behavior and
                  attitudes).
                </td>
                <td>Yes</td>
                <td>Directly from third party data providers.</td>
              </tr>
            </tbody>
          </Table>
          <h4>Use of Personal Information</h4>
          <p>
            We use and disclose the personal information we collect for our
            commercial and business purposes, as further described in this
            Privacy Policy. These commercial and business purposes include,
            without limitation:
          </p>
          <ul>
            <li>
              Our commercial purposes include marketing, helping businesses find
              new customers, developing insights as to consumer interests and
              behaviors, keeping contact information up to date and relevant,
              enabling relevant and personalized marketing messages.
            </li>
            <li>
              Our business purposes as identified in the CCPA include improving
              our services.
            </li>
          </ul>
          <h4>Sharing of Personal Information</h4>
          <p>
            {process.env.REACT_APP_site_name} may disclose your personal
            information to a third party for a business purpose. When we
            disclose personal information for a business purpose, we enter a
            contract that describes the purpose and requires the recipient to
            both keep that personal information confidential and not use it for
            any purpose except performing the contract.
          </p>
          <p>
            We share your personal information with the following categories of
            third parties:
          </p>
          <ul>
            <li>Subsidiaries and affiliates.</li>
            <li>Contractors and service providers.</li>
            <li>
              Third parties to whom you or your agents authorize us to disclose
              your personal information in connection with products or services
              we provide to you.
            </li>
          </ul>

          <h4>Disclosures of Personal Information for a Business Purpose</h4>
          <p>
            In the preceding 12 months, {process.env.REACT_APP_site_name} has
            disclosed the following categories of personal information for a
            business purpose to the parties identified above:
          </p>
          <ul>
            <li>Category A: Identifiers.</li>
            <li>
              Category B: California Customer Records personal information
              categories.
            </li>
            <li>
              Category C: Protected classification characteristics under
              California or federal law.
            </li>
            <li>Category D: Commercial information.</li>
            <li>Category G: Geolocation data.</li>
            <li>Category I: Professional or employment-related information.</li>
            <li>
              Category K: Inferences drawn from other personal information.
            </li>
          </ul>
          <h4>Sales of Personal Information</h4>
          <p>
            In the preceding (12) twelve months, we have sold personal
            information from all the following categories:
          </p>
          <ul>
            <li>Category A: Identifiers.</li>
            <li>
              Category B: California Customer Records personal information
              categories.
            </li>
            <li>
              Category C: Protected classification characteristics under
              California or federal law.
            </li>
            <li>Category D: Commercial information.</li>
            <li>Category G: Geolocation data.</li>
            <li>Category I: Professional or employment-related information.</li>
            <li>
              Category K: Inferences drawn from other personal information.
            </li>
          </ul>
          <p>
            In the preceding twelve (12) months, we have sold your personal
            information to the following categories of third parties:
          </p>
          <ul>
            <li>Automotive Companies</li>
            <li>Business Services/Agency</li>
            <li>Consumer Services Companies</li>
            <li>Consumer Packaged Goods Companies</li>
            <li>Educational Institutions &amp; Companies</li>
            <li>Energy and Utility Companies</li>
            <li>Financial Services Companies</li>
            <li>Food &amp; Beverage Companies</li>
            <li>Health Product Companies</li>
            <li>Insurance Companies</li>
            <li>Manufacturing Companies</li>
            <li>Marketing and Research Companies</li>
            <li>Media and Publishing Companies</li>
            <li>Not for Profit Organizations</li>
            <li>Public or Government Entities</li>
            <li>Public Institutions</li>
            <li>Retail Companies</li>
            <li>Technology/Computer Software Companies</li>
            <li>Telecommunications Companies</li>
            <li>Travel, Leisure &amp; Entertainment Companies</li>
            <li>Other Companies not Categorized.</li>
          </ul>
          <p>
            We will honor requests from consumers to “opt-out” of the collection
            and disclosure of their personal information. Your Rights and
            Choices section below provides instructions on how to opt-out of our
            collection and disclosure of personal information.
          </p>
          <h4>Your Rights</h4>
          <p>
            The CCPA provides consumers (California residents) with specific
            rights regarding their personal information. You have the right to
            request that we disclose certain information to you about our
            collection and use of your personal information over the past 12
            months. Once we receive and confirm your verifiable consumer
            request, we will disclose to you:
          </p>
          <ol>
            <li>
              The categories of personal information we collected about you.
            </li>
            <li>
              The categories of sources for the personal information we
              collected about you.
            </li>
            <li>
              Our business or commercial purpose for collecting or selling that
              personal information.
            </li>
            <li>
              The categories of third parties with whom we share that personal
              information.
            </li>
            <li>
              The specific pieces of personal information we collected about
              you.
            </li>
          </ol>
          <p>
            You also have the right to request that we opt-out the sale of your
            personal information and delete all the personal information that we
            have collected and retained about you, subject to certain enumerated
            CCPA exceptions. Once we receive and confirm your verifiable
            consumer request, we will delete (and direct our service providers
            to delete) your personal information from our records, unless a
            specific CCPA exception applies.
          </p>
          <p>
            We may deny your deletion request if retaining the information is
            necessary for us or our service providers to:
          </p>
          <ul>
            <li>
              Complete the transaction for which we collected the personal
              information, provide a good or service that you requested, take
              actions reasonably anticipated within the context of our ongoing
              business relationship with you, or otherwise perform our contract
              with you.
            </li>
            <li>
              Detect security incidents, protect against malicious, deceptive,
              fraudulent, or illegal activity, or prosecute those responsible
              for such activities.
            </li>
            <li>
              Debug products to identify and repair errors that impair existing
              intended functionality.
            </li>
            <li>
              Exercise free speech, ensure the right of another consumer to
              exercise their free speech rights, or exercise another right
              provided for by law.
            </li>
            <li>
              Comply with the California Electronic Communications Privacy Act
              (Cal. Penal Code §1546 seq.).
            </li>
            <li>
              Engage in public or peer-reviewed scientific, historical, or
              statistical research in the public interest that adheres to all
              other applicable ethics and privacy laws, when the information's
              deletion may likely render impossible or seriously impair the
              research's achievement, if you previously provided informed
              consent.
            </li>
            <li>
              Enable solely internal uses that are reasonably aligned with
              consumer expectations based on your relationship with us.
            </li>
            <li>Comply with a legal obligation.</li>
            <li>
              Make other internal and lawful uses of that information that are
              compatible with the context in which you provided it.
            </li>
          </ul>

          <h4>Exercising Access, Data Portability, and Deletion Rights</h4>
          <p>
            To exercise the access, data portability, and deletion rights
            described above, please submit a verifiable consumer request to us
            by visiting <Link to="/ccpa_optout">here</Link>. Only you or a
            person that you have designated as an authorized agent to act on
            your behalf may make a verifiable consumer request related to your
            personal information. In order for an authorized agent to be
            verified, you must provide the authorized agent with signed, written
            permission to make such requests (which we may ask to review) or a
            power of attorney. We may also follow up with you to verify your
            identity before processing the authorized agent’s request. As a
            parent or legal guardian, you may also make a verifiable consumer
            request on behalf of your minor child.
          </p>
          <p>
            You may only make a verifiable consumer request for access or data
            portability twice within a 12-month period. The verifiable consumer
            request must: (a) provide all required information that allows us to
            reasonably verify you are the person about whom we collected
            personal information or an authorized representative, and (b)
            describe your request with clear and precise detail that allows us
            to properly understand, evaluate, and respond to it. We cannot
            respond to your request or provide you with personal information if
            we cannot verify your identity or authority to make the request and
            confirm the personal information relates to you. Making a verifiable
            consumer request does not require you to create an account with us.
            We will only use personal information provided in a verifiable
            consumer request to verify the requestor’s identity or authority to
            make the request.
          </p>
          <h4>Response Timing and Format</h4>
          <p>
            We will respond to a verifiable consumer request within 45 days of
            its receipt. If we require more time (up to 90 days), we will inform
            you of the reason and extension period in writing. We will deliver
            our written response to you electronically (via email). Any
            disclosures we provide will only cover the 12-month period preceding
            the verifiable consumer request’s receipt. The response we provide
            will also explain the reasons we cannot comply with a request, if
            applicable. For data portability requests, we will select a format
            to provide your personal information that is readily useable and
            should allow you to transmit the information from one entity to
            another entity without hindrance.
          </p>
          <p>
            We do not charge a fee to process or respond to your verifiable
            consumer request unless it is excessive, repetitive, or manifestly
            unfounded. If we determine that the request warrants a fee, we will
            tell you why we made that decision and provide you with a cost
            estimate before completing your request.
          </p>
          <h4>Non-Discrimination</h4>
          <p>
            We will not discriminate against you for exercising any of your CCPA
            rights. Unless permitted by the CCPA, we will not:
          </p>
          <ul>
            <li>Deny you goods or services.</li>
            <li>
              Charge you different prices or rates for goods or services,
              including through granting discounts or other benefits, or
              imposing penalties.
            </li>
            <li>
              Provide you a different level or quality of goods or services.
            </li>
            <li>
              Suggest that you may receive a different price or rate for goods
              or services or a different level or quality of goods or services.
            </li>
          </ul>
          <h4>Financial Incentives</h4>
          <p>
            {process.env.REACT_APP_site_name} does NOT offer any financial
            incentives or payments to consumers as compensation, for the
            collection of personal information, the sale of personal
            information, or the deletion of personal information.
          </p>
          <h4>Minors</h4>
          <p>
            We do not knowingly collect, maintain or disclose identifiable
            information on anyone younger than 18 to third parties for use in
            marketing solicitations. Limited information related to minors
            younger than 18 is used in creating additional insights solely
            related to the adult parent in the household of the minor
            individual, however, we do not disclose any identifiable information
            about the minor as part of the insights created and included within
            the adult record (For example, we only create a flag that denotes
            “presence of children” and “child age ranges”). We do not knowingly
            collect, maintain or sell personal information about consumers
            younger than 18 for the purpose of marketing or advertising directly
            to the minor. If we become aware that we have collected personal
            information from a child under the age of 18, we will delete that
            information from our records.
          </p>

          <h4>Changes to Our Privacy Notice</h4>
          <p>
            {process.env.REACT_APP_site_name} reserves the right to amend this
            privacy notice at our discretion and at any time. When we make
            changes to this privacy notice, we will post the updated notice on
            the Site and update the notice’s effective date. Your continued use
            of our Site following the posting of changes constitutes your
            acceptance of such changes.
          </p>
          <h4>Contact Us</h4>
          <p>
            If you have questions about this privacy policy, please contact:
          </p>
          <p>
            ListShack
            <br />
            Attn: Legal
            <br />
            help@listshack.support
            <br />
            1042 for Union Boulevard #1122
            <br />
            Midvale, UT 84047
          </p>
        </Col>
      </Row>
    </Container>
  );
};

const CcpaOptout = props => {
  const { handleAlerts } = props;
  const [email, setEmail] = useState("");
  const [completedForm, setCompletedForm] = useState(false);

  useScript(
    `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_recaptcha_site_key}`,
    () => {
      //select and remove the recaptcha bit from teh dom.
      let rc = document.querySelector(".grecaptcha-badge").parentNode;
      if (rc) {
        document.body.removeChild(rc);
      }
    }
  );

  if (completedForm) {
    return <Redirect to="/home" />;
  }

  const handleValidTraffic = email => {
    //console.log("email: ", email);
    //send email
    handleAlerts(
      "",
      `An email has been sent to ${email} with further instructions.`,
      "success"
    );
    setEmail("");
    setCompletedForm(true);
  };

  return (
    <Container>
      <Row style={{ minHeight: "70vh", marginTop: "40px" }}>
        <Col>
          <h3>California Consumer Privacy Act (CCPA) Request</h3>
          <p className="">
            {process.env.REACT_APP_site_name} allows California consumers to
            access and control the personal information under the California
            Consumer Privacy Act of 2018 as follows:
          </p>
          <ul>
            <li>Know what information is being collected by category.</li>
            <li>
              Know if personal information is sold to a third party and the
              types of those third parties by category.
            </li>
            <li>Access the categories of personal information.</li>
            <li>
              Opt out of the sale of personal information and request data
              deletion.
            </li>
          </ul>
          <p>
            Please provide your email below and you will receive an email that
            will provide further instructions.
          </p>
          <Form>
            <Form.Group>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={e => {
                  e.preventDefault();
                  console.log(e.target.value);
                  setEmail(e.target.value);
                }}
              />
              <Form.Text className="text-muted">
                This email will only be used to send you further instructions.
              </Form.Text>
            </Form.Group>
          </Form>
          <ButtonGroup>
            <Link to="/" className="btn btn-light">
              Never mind
            </Link>
            <Button
              variant="primary"
              size="sm"
              onClick={async e => {
                e.preventDefault();
                let isValid;
                const handleRejection = () => {
                  handleAlerts(
                    "",
                    "I'm sorry, there was a problem processing your request.  Please contact customer service.",
                    "warning"
                  );
                  setEmail("");
                  setCompletedForm(true);
                };

                await window.grecaptcha.ready(async function() {
                  await window.grecaptcha
                    .execute(process.env.REACT_APP_recaptcha_site_key, {
                      action: "ccpa"
                    })
                    .then(async function(response) {
                      // Add your logic to submit to your backend server here.
                      const init = {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json"
                        },
                        "Transfer-Encoding": "chunked",
                        cache: "default",
                        accept: "application/json",
                        body: JSON.stringify({ email, response })
                      };
                      try {
                        let response = await fetch(
                          `${process.env.REACT_APP_api_url}/api/v2/request_ccpa_optout`,
                          init
                        );

                        isValid = await response.json();
                        console.log("isValid: ", isValid);
                        if (isValid) {
                          return handleValidTraffic(email);
                        }
                        return handleRejection();
                      } catch (err) {
                        console.log("Error processing request: ", err);
                        handleRejection();
                      }
                    });
                });
              }}
            >
              Request further instructions
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </Container>
  );
};

const useScript = (url, cleanup) => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      cleanup();
      document.body.removeChild(script);
    };
  }, [url, cleanup]);
};

const formatPhoneNumber = phoneNumberString => {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

const removeItemOnce = (arr, value) => {
  var index = arr.indexOf(value);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

const removeItemAll = (arr, value) => {
  var i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
};

const mountRecaptcha = url => {
  const script = document.createElement("script");
  script.src = url
    ? url
    : `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_recaptcha_site_key}`;
  script.async = true;
  document.body.appendChild(script);
  return script;
};

const unMountRecaptcha = script => {
  try {
    let r = document.querySelector(".grecaptcha-badge");
    let rc;
    if (r) {
      rc = r.parentNode;
    }
    if (rc) {
      document.body.removeChild(rc);
    }
    if (script) {
      document.body.removeChild(script);
    }
  } catch(err) {
    console.log("Error unMountRecaptcha: ", err);
  }
  
};

const getThemeColor = (themeClass) => {
  let map ={
    primary: "#007bff",
    secondary: "#6c757d",
    success: "#28a745",
    info: "#17a2b8",
    light: "#f8f9fa",
    dark: "#343a40",
    warning: "#ffc107",
    danger: "#dc3545",
    link: "#007bff"
  }
  return map[themeClass] ? map[themeClass] : "red"
}

const makeid = (length) => {
    var result           = [];
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result.push(characters.charAt(Math.floor(Math.random() *
 charactersLength)));
   }
   return result.join('');
}

const santizeFileName = (filename) => {
  return `${filename.replace(/\s/g, "_").replace( /[<>\[\]:;,'"\/\\|?*#]+/g, '_' )}_${Math.round(new Date().getTime() / 1000)}.csv`;
}

export {
  Loading,
  Topnav,
  Secondnav,
  Footer,
  FooterSmall,
  Search,
  NotFound,
  NoPage,
  Home,
  BizLeads,
  ConLeads,
  Contact,
  Terms,
  Privacy,
  Faq,
  ListShackDesktop,
  DatabaseLander,
  DatabaseDetails,
  Archives,
  ArchivesDetails,
  ComparePlansTable,
  ManualAccountChange,
  ComparePlans,
  CheckActiveStatus,
  CheckCancellationStatus,
  CheckIsPastDue,
  CompanyProfile,
  QuickStart,
  TeamMembers,
  NoPermissions,
  NotAdmin,
  PleaseLogin,
  NoSubscription,
  findPlan,
  SomethingWentWrong,
  UsageTable,
  toDatetime,
  ordinal_suffix_of,
  object_equals,
  indexOfArray,
  Ccpa,
  CcpaOptout,
  useScript,
  formatPhoneNumber,
  removeItemOnce,
  removeItemAll,
  mountRecaptcha,
  unMountRecaptcha,
  toEpoch,
  toEpochISO,
  getThemeColor,
  makeid,
  santizeFileName
};
