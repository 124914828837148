import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import {
  Switch,
  Route,
  Link,
  Redirect,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { Container, Row, Col, Navbar, Nav, Button } from "react-bootstrap";
import AutoComplete from "./autoComplete.jsx";
import { Document, Page, pdfjs } from "react-pdf";

import cancelImg from "./media/cancel.png";
import upgradeImg from "./media/upgrade.png";
import changeCardImg from "./media/changeCard.png";
import nosubscriptionImg from "./media/nosubscription.png";
import searchSuppressions from "./media/searchSuppressions.png";
import addAnotherPaymentMethod from "./media/addAnotherPaymentMethod.png";
import chooseAnotherWayToPay from "./media/chooseAnotherWayToPay.png";
import addCardDetails from "./media/addAnotherCard.png";
import setDefaultCard from "./media/setDefaultCard.png";
import courtOrderPdf from "./media/COURT_ORDER.pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const SupportCenter = (props) => {
  return (
    <Switch>
      <Route exact path="/support/:id/">
        <Article {...props} />
      </Route>
      <Route exact path="/support/">
        <Redirect to="/support/welcome/" />
      </Route>
    </Switch>
  );
};

SupportCenter.propTypes = {
  handleIsApp: PropTypes.func,
};

function Article(props) {
  let { url } = useRouteMatch();
  let { id } = useParams();
  let article = find(id);

  useEffect(() => {
    if (props.handleState) {
      props.handleState({
        isApp: false,
        noDistract: true,
      });
    }
    return () => {
      if (props.handleState) {
        props.handleState({ noDistract: false });
      }
    };
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <title>{article.name}</title>
        <meta name="HandheldFriendly" content="true" />
        <meta name="apple-mobile-web-app-capable" content="YES" />
      </Helmet>
      <Container fluid={true}>
        <Navbar
          fixed="bottom"
          bg="dark"
          variant="dark"
          className="d-flex justify-content-between"
        >
          <Nav.Link
            className="d-block d-sm-none"
            style={{ color: "white", textAlign: "left" }}
          >
            <div>
              <i className="fa fa-envelope"></i>{" "}
              {process.env.REACT_APP_contact_email}
              <br />
              <small>Send email</small>
            </div>

            <div className="mt-2">
              <i className="fa fa-mobile"></i> (909) 703-5114
              <br />
              <small>Send text</small>
            </div>
          </Nav.Link>
          <Nav.Link
            style={{ color: "white", textAlign: "left" }}
            href={`mailto:${process.env.REACT_APP_contact_email}`}
            className="d-none d-sm-block"
          >
            <i className="fa fa-envelope"></i>{" "}
            {process.env.REACT_APP_contact_email}
            <br />
            <small>Send an email</small>
          </Nav.Link>
          <Nav.Link
            style={{ color: "white", textAlign: "right" }}
            href="tel:909-703-5114"
            className="d-none d-sm-block"
          >
            <i className="fa fa-mobile"></i> (909) 703-5114
            <br />
            <small>Send text message</small>
          </Nav.Link>
          <Nav.Link
            style={{
              color: "white",
              textAlign: "center",
              paddingRight: "75px",
            }}
            href="tel:833-222-7919"
          >
            <div className="d-none d-sm-block">
              <br />
              <small>Chat online</small>
              <br />
              <br />
            </div>
          </Nav.Link>
        </Navbar>
      </Container>
      <Container style={{ paddingBottom: "400px" }}>
        <Row className="justify-content-md-center">
          <Col xs={12} md={{ span: 8, offset: 2 }} className="text-center">
            {article.id !== "welcome" && (
              <Link to="/support/welcome/" className="pull-left btn btn-link">
                &lt; Back to all articles
              </Link>
            )}
            <AutoComplete
              style={{
                margin: "0 auto",
                marginTop: "30px",
                marginBottom: "50px",
              }}
              className="col-xs-12 col-sm-8 col-md-6"
              suggestions={Articles}
              placeholder="Search help articles"
              helpText="Search for helpful tutorials and videos"
              path="/support/"
            />
            <h3>{article.name}</h3>
            <div
              className="help-content"
              style={{ margin: "40px 0px 75px 0px", textAlign: "left" }}
            >
              {article.content}
            </div>

            <Col xs={12} className="text-left">
              {article.id !== "welcome" && <h6>Related articles</h6>}
              {article.friends.map((id) => (
                <Link to={`/support/${id}/`} key={id}>
                  <h5>{find(id).name}</h5>
                  <p className="text-muted">{find(id).excerpt}</p>
                  <Button variant="light" size="sm" className="mb-4">
                    <small>Learn more</small>
                  </Button>
                </Link>
              ))}
            </Col>
          </Col>
        </Row>
        <Switch>
          <Route path={`${url}/:id/`}>
            <Article />
          </Route>
        </Switch>
      </Container>
    </React.Fragment>
  );
}

const PDFDOC = (props) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div className="d-flex flex-column align-items-center justify-content-center text-center">
      <div
        className="d-flex flex-row justify-content-center mt-2"
        style={{ width: "100%" }}
      >
        <Button
          variant="link"
          className="mr-2"
          onClick={() => {
            setPageNumber(pageNumber - 1 >= 1 ? pageNumber - 1 : 1);
          }}
        >
          <i className="fa fa-chevron-left fa-2x"></i>
        </Button>
        <Document
          file={props.file}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(error) =>
            console.log("Error while loading document! " + error.message)
          }
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <Button
          variant="link"
          className="ml-2"
          onClick={() => {
            setPageNumber(
              pageNumber + 1 <= numPages ? pageNumber + 1 : numPages
            );
          }}
        >
          <i className="fa fa-chevron-right  fa-2x"></i>
        </Button>
      </div>
      <div
        className="d-flex flex-row justify-content-center mt-2"
        style={{ width: "100%" }}
      >
        <p>
          Page {pageNumber} of {numPages}
        </p>
      </div>
    </div>
  );
};

// Articles is an array of objects.  While the number of articles is small
// it's intended to be static code.  If it grows, the articles can be easily
// moved to a firestore collection of documents.
const Articles = [
  {
    id: "welcome",
    name: "Welcome to the support center",
    friends: [
      "upgrade-subscription",
      "reactivate-subscription",
      "reset-password",
      "save-and-reuse-searches",
      "share-searches",
      "open-csv-files",
      "change-email",
      "cancel-subscription",
      "change-card",
      "signup-new-interface",
      "failed-to-fetch",
      "no-subscription",
      "search-on-map",
      "turning-65-lists",
      "csv-is-blank",
      "returned-wrong-leads",
      "use-compiled-emails",
      "search-suppressions",
      "returned-duplicate-leads",
      "enhanced-security",
      "who-owns-list-shack",
    ],
    content: (
      <React.Fragment>
        <p className="text-muted text-center">
          Find answers to commonly asked questions, watch video tutorials, and
          get help from {process.env.REACT_APP_site_name} customer support.
        </p>
      </React.Fragment>
    ),
  },
  {
    id: "open-csv-files",
    name: "How to open CSV files",
    friends: ["reset-password", "signup-new-interface", "cancel-subscription"],
    excerpt:
      "Watch a quick video tutorial to learn how to open CSV files on your computer.",
    content: (
      <React.Fragment>
        <p>
          CSV or .csv files are easily opened by any spreadsheet software,
          including Microsoft Excel, Google Sheets, Numbers, and Open Office.
          CSV is also compatible with many CRM and online platforms.
        </p>
        <p>
          After downloading a CSV file from {process.env.REACT_APP_site_name},
          find the file on your computer, usually in the downloads folder(or
          available at the bottom of the browser if using Chrome), and double
          click the file. The file will then be automatically opened by your
          default spreadsheet software (usually Microsoft Excel for Windows
          users or Numbers for Mac OS users.)
        </p>
        <p>
          <b>If the file won't open. </b>
          If the file fails to open when you double click the file, ensure that
          you have spreadsheet software available on your computer. If you do
          not have Excel, Numbers, or Open Office available, we recommend using
          Google Sheets, free cloud spreadsheet software available to anyone
          with a gmail email address. Simply open up your Google Drive in your
          browser, drag and drop the CSV file from your computer into the
          browser, wait for the file to upload, and double click the file to
          open it in Google Sheets.
        </p>
        <p>
          <b>If the file appears as a single line of text. </b>
          Sometimes, your computer may attempt to open a CSV file in a text
          editor such as TextEdit (Mac OS) or WordPad (Windows). If this is the
          case, then you'll need to open your spreadsheet software separately,
          and import the file into the spreadsheet software as follows:
        </p>
        <p>
          <i>Microsoft Excel:</i>
        </p>
        <ol>
          <li>Open Microsoft excel</li>
          <li>Click file > import > csv and click "Import".</li>
          <li>Select the file, and click "Open".</li>
          <li>Select "Delimited" and click "Next".</li>
          <li>Uncheck "Tab" and check "comma" and click "Finish".</li>
          <li>
            Select the cell where you'd like the data to be imported into and
            click "OK".
          </li>
        </ol>
      </React.Fragment>
    ),
  },
  {
    id: "search-on-map",
    name: `How to find leads on a map`,
    friends: [
      "turning-65-lists",
      "save-and-reuse-searches",
      "search-suppressions",
    ],
    excerpt:
      "Watch a quick video tutorial to learn how to search for leads on a map.",
    content: (
      <React.Fragment>
        <p>
          It's super easy to search for business or consumer leads on a map.{" "}
          {process.env.REACT_APP_site_name} offers two map based databases, one
          for consumers and another for businesses. To get started, select
          "Maps" in the sidebar.
        </p>
        <div
          dangerouslySetInnerHTML={{
            __html: `<div style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe src="https://www.loom.com/embed/2a8d26658e9641589de25c3899135008" frameborder="0" webkitallowfullscreen mozallowfullscreen allowFullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>`,
          }}
        />
        <ol className="mt-4">
          <li>Select whether you'd like Business or Consumer leads.</li>
          <li>
            Allow {process.env.REACT_APP_site_name} to access your location to
            quickly see the leads that are available near your location.
          </li>
          <li>
            To change the area you'd like to search type the name of a city,
            county, zip code, or state in the search box on the top right.
            Select a suggestion with your mouse or use the keyboard to select a
            suggestion and press ENTER.
          </li>
          <li>
            To refine the area in which you'd like to search, move the map by
            zooming in with the map controls or your mouse. Click the "Search
            this area" button.
          </li>
          <li>
            To see the number of leads available check the Leads Count box on
            the top right.
          </li>
          <li>
            Under the leads count click the "Filters" to view the current
            filters. Click the "Edit" button to add or remove filters from your
            search.
          </li>
          <li>
            {process.env.REACT_APP_site_name} will display 100 pins on the map
            that match your search filters. Click a pin to view the record.
          </li>
          <li>
            To download a CSV file of the search, click the Green "Download"
            button. Confirm your map and filters, preview the CSV, and checkout.
            Checkout will require you to add a search name and indicate the
            number of leads to download.
          </li>
        </ol>
        <p>
          <b>
            If your city or county search from the search bar do not show leads
            on the map.
          </b>{" "}
          <br />
          If pins are not displayed on the map after you search by city or
          county, you may need to zoom in or out and click the "Search this
          area" button. If after panning or zooming and clicking "Search this
          area", there are not leads available, then you may need to adjust your
          filters.
        </p>
        <p>
          <b>Map settings</b> <br />
          Click the map settings icon on the bottom left of the map to adjust
          the map sensitivity. Increased map sensitivity will display the
          "Search this area" button to update your search count and search map
          with less panning or zooming.
        </p>
      </React.Fragment>
    ),
  },
  {
    id: "reset-password",
    name: "How to reset your password",
    friends: ["change-email", "signup-new-interface", "upgrade-subscription"],
    excerpt: "Step-by-step instructions for resetting your password",
    content: (
      <React.Fragment>
        <p>
          <b>If you are logged in:</b>
        </p>
        <ol>
          <li>
            Navigate to the{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              noreferrer
              href="https://www.listshack.support/settings"
            >
              settings
            </a>{" "}
            page.
          </li>
          <li>Click "Reset password".</li>
          <li>Reenter your current password.</li>
          <li>
            Enter your new password. Confirm your new password. Click "Change
            password".
          </li>
        </ol>
        <br />
        <p>
          <b>If you are not logged in:</b>
        </p>
        <ol>
          <li>
            Navigate to the{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              noreferrer
              href="https://www.listshack.support/reset_password"
            >
              password reset
            </a>{" "}
            page.
          </li>
          <li>
            Enter the email associated with your account and click "Send reset
            link".
          </li>
        </ol>
        <p>
          <i>
            A link will be sent to email we have on file. Click the link to be
            directed to a page where you can reset your password.
          </i>
        </p>
      </React.Fragment>
    ),
  },
  {
    id: "change-email",
    name: "How to change or update your account email",
    friends: ["change-card", "signup-new-interface", "reset-password"],
    excerpt: `Step-by-step instructions for changing the email associated with your ${process.env.REACT_APP_site_name} account`,
    content: (
      <React.Fragment>
        <p style={{ margin: "0 auto", display: "inline-block" }}>
          Changing the email on your account can only be done by the account
          holder, not even a {process.env.REACT_APP_site_name} employee can
          change the email associated with your account. To change the email on
          your account follow the following steps:
        </p>
        <ol>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              noreferrer
              href="https://www.listshack.support/login"
            >
              Login
            </a>{" "}
            to your account.
          </li>
          <li>Click the menu at the top.</li>
          <li>Click settings, then "Update email".</li>
          <li>Enter your current account password.</li>
          <li>Enter the new email address and click "Update email".</li>
        </ol>
        <p>
          <i>
            If you have any problems with your account after you update your
            email, please contact {process.env.REACT_APP_contact_email} and
            include the old and new email addresses in your email.
          </i>
        </p>
      </React.Fragment>
    ),
  },
  {
    id: "signup-new-interface",
    name: `How to access your ${process.env.REACT_APP_site_name} account on the new interface`,
    friends: ["open-csv-files", "reset-password", "upgrade-subscription"],
    excerpt:
      "Detailed instructions for logging in or signing up for the new interface for the first time",
    content: (
      <React.Fragment>
        <p>We're really excited to introduce our new interface!</p>
        <p>
          <b>Existing subscribers. </b>
          If you were billed for an existing subscription in the month of
          October 2019, your subscription has been automatically ported over to
          the new interface. Simply login with the email address associated with
          your account and your existing password.
        </p>
        <p>
          <b>Cancelled subscribers. </b>If you cancelled your subscription prior
          to October 2019, disputed a payment, or your billing failed during the
          month of October 2019, your previous account was not ported over to
          the new interface. Just{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            noreferrer
            href="https://www.listshack.support/signup"
          >
            signup for a new account
          </a>
          .
        </p>
        <p>
          We understand that there may be a learning curve for those that were
          accustomed to the old interface. We apologize for the inconvenience.
          If you've attempted to login to the old interface, you'll notice that
          counts and downloads no longer function, and you'll need to login to
          the new interface to retrieve counts and download leads.
        </p>
        <p>
          If you have any issues downloading leads or accessing your account on
          the new interface, please take a screenshot and send it to{" "}
          {process.env.REACT_APP_contact_email}.
        </p>
      </React.Fragment>
    ),
  },
  {
    id: "upgrade-subscription",
    name: `How to upgrade your ${process.env.REACT_APP_site_name} subscription`,
    friends: ["change-email", "reset-password", "signup-new-interface"],
    excerpt: `Step-by-step instructions for upgrading your ${process.env.REACT_APP_site_name} subscription.`,
    image: upgradeImg,
    content: (
      <React.Fragment>
        <p>
          <b>
            You can upgrade your subscription anytime from the{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              noreferrer
              href="https://www.listshack.support/settings/billing"
            >
              Billing page
            </a>
            :
          </b>
        </p>
        <ol>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              noreferrer
              href="https://www.listshack.support/login"
            >
              Login
            </a>{" "}
            to your account.
          </li>
          <li>Click the menu at the top.</li>
          <li>Click settings, then billing</li>
          <li>Click "Change plan".</li>
          <li>
            Select a new plan, ensure that the card on file is correct and click
            "Change plan"
          </li>
        </ol>
        <img src={upgradeImg} alt="upgrade your account here" />
      </React.Fragment>
    ),
  },
  {
    id: "change-card",
    name: "How to change the card on file",
    friends: ["upgrade-subscription", "reset-password", "change-email"],
    excerpt: `Step-by-step instructions for channging the card your ${process.env.REACT_APP_site_name} subscription is billed to.`,
    image: changeCardImg,
    content: (
      <React.Fragment>
        <p>
          <b>
            You can change the card your {process.env.REACT_APP_site_name}{" "}
            subscription is billed to anytime from the{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              noreferrer
              href="https://www.listshack.support/settings/billing"
            >
              Billing page
            </a>
            :
          </b>
        </p>
        <ol>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              noreferrer
              href="https://www.listshack.support/login"
            >
              Login
            </a>{" "}
            to your account.
          </li>
          <li>Click the menu at the top.</li>
          <li>Click settings, then billing</li>
          <li>Click "Add another payment method".</li>
          <img
            src={addAnotherPaymentMethod}
            alt="change your card on file by clicking here"
          />
          <li>
            Select an existing payment method or click "Choose another way to
            pay".
          </li>
          <img
            src={chooseAnotherWayToPay}
            alt="change your card on file by clicking here"
          />
          <li>
            Add the details for the new card and click "Add payment method"
          </li>
          <img
            src={addCardDetails}
            alt="change your card on file by clicking here"
          />
          <li>
            Click "Set default" on the card that you'd like to use for your
            monthly subscription.
          </li>
          <img
            src={setDefaultCard}
            alt="change your card on file by clicking here"
          />
        </ol>
      </React.Fragment>
    ),
  },
  {
    id: "cancel-subscription",
    name: `How to cancel your ${process.env.REACT_APP_site_name} subscription`,
    friends: ["open-csv-files", "reset-password", "signup-new-interface"],
    excerpt: `Step-by-step instructions for cancelling your ${process.env.REACT_APP_site_name} subscription.`,
    image: cancelImg,
    content: (
      <React.Fragment>
        <p>
          <b>
            You can cancel your subscription anytime from the{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              noreferrer
              href="https://www.listshack.support/settings/billing"
            >
              Billing page
            </a>
            :
          </b>
        </p>
        <ol>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              noreferrer
              href="https://www.listshack.support/login"
            >
              Login
            </a>{" "}
            to your account.
          </li>
          <li>Click the menu at the top.</li>
          <li>Click settings, then billing</li>
          <li>
            Click "Cancel subscription", and confirm that you'd like to cancel
          </li>
        </ol>

        <img src={cancelImg} alt="cancel your account here" />
        <p className="text-muted">
          <i>Notes about account cancellations:</i>
        </p>
        <ul>
          <li>
            Accounts with grandfathered plans will not be able to resubscribe
            with the same plan type. Resubscribers are only able to select from
            the currently available plans.
          </li>
          <li>
            Refunds are not given for cancellations that occur after the monthly
            suscription payment. You will, however, continue to have access to{" "}
            {process.env.REACT_APP_site_name} until the end of the billing
            period.
          </li>
        </ul>
      </React.Fragment>
    ),
  },
  {
    id: "failed-to-fetch",
    name: "Why did my search return failed to fetch?",
    friends: ["open-csv-files", "reset-password", "signup-new-interface"],
    excerpt: "How to handle the failed to fetch error when searching for leads",
    image: cancelImg,
    content: (
      <React.Fragment>
        <p>
          The most commmon reason for the <i>failed to fetch</i> error is that
          the query or search that you are performing is taking too long to
          complete. By default, searches that take longer than 60 seconds return
          the failed to fetch error, and you can simply change the search and
          try again.
        </p>
        <p>
          Here are some tips for changing your search to avoid the{" "}
          <i>failed to fetch</i> error:
        </p>
        <ul>
          <li>
            Use the "Get count" or "Refresh count" links where possible to check
            the count of the search you're doing before clicking "Preview" to
            see if the search returns a count before it attempts to get a
            preview of the leads.
          </li>
          <li>
            Narrow the geography of your search. For example, rather than
            searching an entire state, try to search a county, zip code, or
            city.
          </li>
          <li>
            Only use the filters that you care about. For example, rather than
            checking all of the birth months (January - December) leave all the
            birth months unchecked.
          </li>
          <li>
            Remove some filters from your search and click "Get count" or
            "Refresh count" to see if there are filters that are causing the
            search to take a long time.
          </li>
        </ul>
        <p>
          Unfortunately, we don't currently have an automatic way to handle the{" "}
          <i>failed to fetch</i> error. Please take a screenshot of the search
          selected box and email it to {process.env.REACT_APP_contact_email}. An
          engineer will review the search and determine whether the search can
          be simplified, run the search manually, or provide you feedback about
          why the search cannot be run.
        </p>
      </React.Fragment>
    ),
  },
  {
    id: "no-subscription",
    name: `Why can't my subscription be found on ${process.env.REACT_APP_site_name}?`,
    friends: ["open-csv-files", "reset-password", "signup-new-interface"],
    excerpt: `Some of the reasons why your subscription may not be found on ${process.env.REACT_APP_site_name}`,
    content: (
      <React.Fragment>
        <img src={nosubscriptionImg} alt="Uh oh we can't find a subscription" />
        <p>
          In order to download leads on {process.env.REACT_APP_site_name}, you
          must have an active billing subscription tied to your account's email
          address. The most commmon reason for {process.env.REACT_APP_site_name}{" "}
          being unable to find your subscription is a mispelling in the email
          address associated with your account. Try to logout and then log back
          in with the correct email address.
        </p>
        <p>
          Some other reasons why {process.env.REACT_APP_site_name} may not be
          able to find your subscription are as follows:
        </p>
        <ul>
          <li>
            Your account may be past due and the subscription was deleted after
            multiple attempts to bill the card on file. Please sign up for a new
            subscription to continue using {process.env.REACT_APP_site_name}.
          </li>
          <li>
            You cancelled the account and the account is no longer active. Per
            our terms of service, after you cancel your account you'll have
            access to the account until the end of the billing cycle. Once the
            billing cycle has elapsed, you'll need to signup for a new
            subscription to continue using {process.env.REACT_APP_site_name}.
          </li>
          <li>
            In rare cases, your account may have been unsuccessfully ported over
            from the previous interface. If you don't believe there is a billing
            error, and your email address you used to login to{" "}
            {process.env.REACT_APP_site_name} matches the email address you used
            to signup for an account, please email
            {process.env.REACT_APP_contact_email}, and the support team will
            port your account over manually, and you'll be able to continue
            using {process.env.REACT_APP_site_name}.
          </li>
        </ul>
        <p>
          If you you have any questions, please don't hesitate to email{" "}
          {process.env.REACT_APP_contact_email} to get help using your{" "}
          {process.env.REACT_APP_site_name} account.
        </p>
      </React.Fragment>
    ),
  },
  {
    id: "turning-65-lists",
    name: "How to get turning 65 lists?",
    friends: ["open-csv-files", "reset-password", "signup-new-interface"],
    excerpt: `${process.env.REACT_APP_site_name} provides turning 65 lists to thousands of insurance agents across the country. See how easy it is to find consumers turning 65 for your business.`,
    content: (
      <React.Fragment>
        <div className="d-flex justify-content-center mb-5">
          <iframe
            title="Video demo of how to get turning 65 lists"
            width="560"
            height="315"
            src="https://www.youtube-nocookie.com/embed/jQq7IeDdN0U"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <p>
          {process.env.REACT_APP_site_name} provides turning 65 lists to
          thousands of insurance agents across the country. See how easy it is
          to find consumers turning 65 for your business.
        </p>
      </React.Fragment>
    ),
  },
  {
    id: "csv-is-blank",
    name: "What should I do if my csv is blank?",
    friends: ["open-csv-files", "reset-password", "signup-new-interface"],
    excerpt: `${process.env.REACT_APP_site_name} provides credits for CSV files that are returned blank.  Simply click the Report file link next to the Download CSV button on the downloads page.`,
    content: (
      <React.Fragment>
        <p>
          Occasionally, CSV files are returned blank after you download them to
          your computer. This can be caused for various reasons, such as an
          invalid search or server or connectivity errors. If your CSV file is
          returned blank, please try the following steps:
        </p>
        <ol>
          <li>
            Navigate to the{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              noreferrer
              href="https://www.listshack.support/downloads"
            >
              downloads page
            </a>
            , locate the correct file by search name, and download your file
            again. Check if it is blank.
          </li>
          <li>
            If the file is still blank, click the "Report file" link next to the
            "Download Csv" button, select "The file was blank", and click
            "Report file".
          </li>
          <li>
            {process.env.REACT_APP_site_name} will automatically check if the
            file is blank on our servers. If it is, then you'll automatically be
            credit for the leads, and you should redo your search and download
            the file again from the{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              noreferrer
              href="https://www.listshack.support/search"
            >
              search page
            </a>
            .
          </li>
        </ol>
      </React.Fragment>
    ),
  },
  {
    id: "returned-wrong-leads",
    name: "My download returned the wrong leads?",
    friends: ["open-csv-files", "csv-is-blank", "reset-password"],
    excerpt: `${process.env.REACT_APP_site_name} provides credits for CSV files that are occasionally returned with the wrong leads.  Simply click the Report file link next to the download CSV button on the downloads page.`,
    content: (
      <React.Fragment>
        <p>
          Occasionally, {process.env.REACT_APP_site_name} may return leads that
          do not match your search criteria. Usually, this is caused by
          submitting an invalid search. If your CSV file doesn't match the
          search that you submitted for download, please try the following
          steps:
        </p>
        <ol>
          <li>
            Navigate to the{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              noreferrer
              href="https://www.listshack.support/downloads"
            >
              downloads page
            </a>
            , locate the correct file by search name, and download your file
            again. Check if it is the correct leads.
          </li>
          <li>
            If the file is incorrect, click the "Report file" link next to the
            "Download Csv" button, select "The leads didn't match my search",
            and click "Report file".
          </li>
          <li>
            {process.env.REACT_APP_site_name} will automatically check if the
            leads returned match some of the parameters that you selected. If
            the search doesn't match the leads criteria, then you'll
            automatically be credit for the leads, and you should redo your
            search and download the file again from the{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              noreferrer
              href="https://www.listshack.support/search"
            >
              search page
            </a>
            .
          </li>
        </ol>
      </React.Fragment>
    ),
  },
  {
    id: "use-compiled-emails",
    name: "What are compiled email lists?",
    friends: ["open-csv-files", "csv-is-blank", "reset-password"],
    excerpt:
      "Compiled email lists are emails that have been aggregated and sourced from various websites and online sources and where the owner of the email address has not given you or your business express permission to email them promotional content.",
    content: (
      <React.Fragment>
        <p>
          Compiled email lists are emails that have been aggregated and sourced
          from various websites and online sources and where the owner of the
          email address has not given you or your business express permission to
          email them promotional content.
        </p>
        <h3>What can I do with compiled email lists?</h3>
        <p>
          As long as you are complying with{" "}
          <a
            href="https://www.ftc.gov/tips-advice/business-center/guidance/can-spam-act-compliance-guide-business"
            target="_blank"
            rel="noopener noreferrer"
          >
            CAN-SPAM
          </a>{" "}
          regulations, you are free to send emails to compiled email lists. To
          summarize the Can-Spam regulations, you cannot send emails with
          explicit, sexual, or illegal drug content. If you are sending
          promotional emails, you must include your companies physical address
          and allow the recipient to permanently opt-out of receiving future
          emails from you. While this may seem straight forward, here are some
          additional words of caution and best practices for using compiled
          email lists:
        </p>
        <h5>Words of caution</h5>
        <ul>
          <li>
            Send opt-in emails, before adding them to your company's permanent
            email list. It only takes a few people marking your email as spam
            for the major email service providers like Gmail, Yahoo, and
            Outlook, to black list your email server or email address and
            thereby automatically putting all your emails into spam in the
            future.
          </li>
          <li>
            Don't add compiled emails directly to a Mail Chimp, Constant
            Contact, or other email campaign software until the owner of the
            email address has opted in. Doing so may violated their terms of
            service, and if too many of your emails get marked as spam, they may
            ban or lock you out of your account.
          </li>
        </ul>
        <h5>Best practices</h5>
        <ul>
          <li>
            One-to-one emails are more likely to get opened. While it's very
            tempting to write up a flashy promotional email without anyone's
            name at the top, the most effective emails are personal, one-to-one
            communication, especially if the person your emailing has never met
            you or your business before.
          </li>
          <li>
            Emails is great way to follow-up with people you're already working
            with. Send follow-up emails shortly after starting a conversation
            with folks, and make it easy for them to get back in touch with you.
          </li>
        </ul>
      </React.Fragment>
    ),
  },
  {
    id: "returned-duplicate-leads",
    name: "My download returned the duplicate leads?",
    friends: ["search-suppressions", "csv-is-blank", "returned-wrong-leads"],
    excerpt: `Follow these 3 simple tips for avoiding duplicate downloads while using ${process.env.REACT_APP_site_name}.`,
    content: (
      <React.Fragment>
        <p>
          Occasionally, {process.env.REACT_APP_site_name} may return duplicate
          leads. Usually, this is caused by using the same search more than
          once. If you receive duplicate leads ensure that you've not used the
          same search before downloading your list. For customers looking to
          avoid duplicate leads, here are few recommendations:
        </p>
        <ol>
          <li>
            Start by searching within a larger geography, for example, a whole
            area code, state, or county.
          </li>
          <li>
            Once you've identified the total count of the leads that you're
            looking for in that geography, click the "Save as default" link
            under the "Search selected" box.
          </li>
          <li>
            Next, narrow your search to a group of smaller geographies, for
            example, all the counties in State, or all the Zip codes in a state
            or county. Download each list separately to avoid exceeding your per
            download limit and avoid getting duplicate leads.
          </li>
        </ol>
        <p>
          Pay attention to the Checkout tab when you're downloading leads.{" "}
          {process.env.REACT_APP_site_name} will warn you when you're about to
          download leads from a search that you've previously downloaded leads
          from. If you'd rather take the work out of keeping track of your
          previous searches, upgrade your account to a plan that includes
          "Search suppressions", and {process.env.REACT_APP_site_name} will
          automatically prevent you from getting duplicate downloads from that
          search.
        </p>
      </React.Fragment>
    ),
  },
  {
    id: "search-suppressions",
    name: "How does search suppressions prevent duplicate downloads?",
    friends: [
      "returned-duplicate-leads",
      "csv-is-blank",
      "returned-wrong-leads",
    ],
    excerpt: `${process.env.REACT_APP_site_name} search suppressions feature automatically tracks which searches you've downloaded before and warns you when you're about to download them again. Plans with the search suppressions feature allow you to automatically suppress the leads that you've already downloaded from the search, so you don't get duplicate leads.`,
    content: (
      <React.Fragment>
        <p>
          {process.env.REACT_APP_site_name} search suppressions feature
          automatically tracks which searches you've downloaded before and warns
          you when you're about to download them again. Click on the warning to
          view the name of the search, date the search was completed, and a link
          to the csv created from the download.
        </p>
        <p>
          Plans with the search suppressions feature allow you to automatically
          suppress the leads that you've already downloaded from the search, so
          you don't get duplicate leads. This feature is especially useful for
          those downloaded a high volume of leads and don't want to go to the
          extra work of dividing their search into smaller segments to avoid
          duplicates.
        </p>
        <p>
          Plans without the search suppressions feature always receive a warning
          before they download dupicate leads. Follow the steps described in{" "}
          <a href="/help/returned-duplicate-leads/">this article</a> to segment
          your search and avoid duplicates manually.
        </p>
        <p>
          <i>
            Fine print: Search suppressions does not keep track of the
            individual phone numbers, names, and addresses of the leads that
            you've downloaded, rather just the search that you used to find the
            leads. As such it won't replace the need for an in-house do not call
            list that your company may be required to maintain to stay compliant
            with U.S. federal telemarketing regulations.
          </i>
        </p>
        <img
          src={searchSuppressions}
          alt="Search suppressions on the checkout tab"
        />
      </React.Fragment>
    ),
  },
  {
    id: "reactivate-subscription",
    name: "Reactivate your subscription",
    friends: ["enhanced-security", "reset-password", "who-owns-list-shack"],
    excerpt: `${process.env.REACT_APP_site_name} allows you to easily reactivate your subscription by first Logging in and then clicking Suscribe button.  Contact our support team for details about getting a credit if you've already paid  this month.`,
    content: (
      <React.Fragment>
        <p>
          If you can no longer login and access your membership, you'll need to
          reactivate your subscription as follows:
        </p>
        <ol>
          <li>
            Login to your account from{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              noreferrer
              href="https://www.listshack.support/login"
            >
              here
            </a>
            .
          </li>
          <li>Click the "Subscribe" button.</li>
          <li>Ensure that you've selected the proper plan</li>
          <li>Enter your payment information</li>
          <li>Click Subscribe</li>
          <li>
            Enter your company profile information and verify your account with
            a Cell Phone number.
          </li>
        </ol>

        <h5>Concerned with billing?</h5>
        <p>
          <i>
            If you signed up for an account on listshack.com or listshackpro.com
            from March 1 - November 9, 2020 and you can no longer access that
            account, please contact our support team by sending an email or
            chatting with us. We will ensure that you receive access to the
            leads that you've paid for and help you ensure that you are billed
            properly moving forward.
          </i>
        </p>
      </React.Fragment>
    ),
  },
  {
    id: "enhanced-security",
    name: "Are you keeping my account safe?",
    excerpt: "Read about our renewed commitment to enhanced account security.",
    friends: [
      "reactivate-subscription",
      "who-owns-list-shack",
      "reset-password",
    ],
    content: (
      <React.Fragment>
        <h3>Our renewed commitment to security</h3>
        <p>
          These last few months, we've renewed our commitment to security and
          keepying your account safe. We've reviewed, improved, or changed
          everything from implementing account verification, improving fraud
          detection, and even diversifying and improving the vendors that we use
          to provide our services. All of this is intended to keep your account
          secure.
        </p>
        <h3>Why do you need enhanced security?</h3>
        <p>
          {process.env.REACT_APP_site_name}'s domains, listshack.com and
          listshackpro.com, were compromised on or around March 1, 2020. During
          the last eight months, the perpetrators put up an unauthorized site
          and have sent out quite a bit of confusing communication. They also
          revoked our access to our servers and many of our historical
          databases. As a result, we moved to backup servers and in the process
          upgraded many of our systems to improve the user interface and make
          huge speed improvements (searches are over 10 times faster!). We've
          also been investing in our legal team to regain the lost assets
          through the courts.
        </p>
        <h3>Our big win in court</h3>
        <p>
          On October 16, 2020, our legal team landed a big win for the company
          when the Utah Fourth District Court issued its ruling, which you can
          read <a to="/help/court-order/">here</a> or{" "}
          <a href="/COURT_ORDER.pdf" target="_blank">
            here
          </a>
          . On November 9, the domains were returned to our control, and we're
          continuing to work with the other vendors to regain control of some of
          our other compromised (and now legacy) assets.
        </p>
        <h3>What we've learned</h3>
        <p>
          Now that we've been able to regain control of the domains, we've taken
          several important steps to ensure that this doesn't happen again,
          including:
        </p>
        <ul>
          <li>
            Permanently transferred the domains to a domain registrar account
            that includes 2-factor authentication.
          </li>
          <li>Locked our domains from transfer to another registrar.</li>
          <li>
            Updated our merchant processor and standarized the Credit Card
            Statement. The charge on your credit card statement should read as
            follows (or be very close to this):{" "}
            <code>LISTSHACK.IO IND UNLTD</code>. In some limited cases this
            statement may also say <code>LORD ABBEY</code>.
          </li>
        </ul>
        <p>
          Ultimately, this is a lesson that we hope we only ever learn once, and
          we're doing all we can to keep accounts save and protected from those
          who would use them for unauthorized and illegal purposes.
        </p>
      </React.Fragment>
    ),
  },
  {
    id: "who-owns-list-shack",
    name: `Who owns ${process.env.REACT_APP_site_name}?`,
    excerpt: `After a several month transition, Lord Abbey LLC purchased the domains listshack.com and listshackpro.com from its previous owner.`,
    friends: ["enhanced-security", "reactivate-subscription", "reset-password"],
    content: (
      <React.Fragment>
        <p>
          Around November 1, 2019, Lord Abbey LLC began offering a new interface
          for {process.env.REACT_APP_site_name} after the site had been
          neglected, mismanaged, and unable to provide quality services for 4 -
          5 months. On January 17, Lord Abbey formally purchased the domains
          listshack.com and listshackpro.com.
        </p>
        <p>
          Throughout this transition, Lord Abbey has offered refunds and credits
          to those who used the site with the previous owner, improved search
          speeds, refreshed and renewed the databases, and offered several new
          features to improve the user experience.
        </p>
        <p>
          <i>
            If you have any questions or concerns, please reach out to our
            support team. We are more than happy to help and answer your
            questions.
          </i>
        </p>
      </React.Fragment>
    ),
  },
  //{
  //  id: "court-order",
  //  name: `Court Order Fourth Judicial District Court Utah`,
  //  excerpt: `Read the courts ruling regarding our domains and why all the confusion these last months.`,
  //  friends: ["enhanced-security", "reactivate-subscription", "reset-password"],
  //  content: <PDFDOC file={courtOrderPdf} />
  //},
  {
    id: "save-and-reuse-searches",
    name: `Save and reuse searches on ${process.env.REACT_APP_site_name}`,
    excerpt: `It's now even easier to save, reuse, and share searches on ListShack! Just click the the Save button on the Search Preview box. Name your search, and click "Save". To reuse the search, you either tap the "Search selected" header to view all your saved searches for that database.`,
    friends: ["share-searches", "search-suppressions", "turning-65-lists"],
    content: (
      <React.Fragment>
        <p className="mb-4">
          It's now even easier to save, reuse, and share searches on ListShack!
        </p>
        <div
          dangerouslySetInnerHTML={{
            __html: `<div style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe src="https://www.loom.com/embed/0149aa608eea441ba6565e05fce592f1" frameborder="0" webkitallowfullscreen mozallowfullscreen allowFullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>`,
          }}
        />

        <p className="mt-4">To save a search:</p>
        <ol>
          <li>Enter the geography and filters for your search.</li>
          <li>
            Click the "Save" button on the header of the Search Preview box.
            This will open a modal.
          </li>
          <li>Type a name of your search so it's easy to identify later.</li>
          <li>Click "Save" at the bottom of the modal.</li>
        </ol>
        <p>To reuse a search:</p>
        <ol>
          <li>
            Navigate to the{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              noreferrer
              href="https://www.listshack.support/searches"
            >
              Saved Searches page
            </a>{" "}
            or click the "Search Selected" text on the Search Preview box.
          </li>
          <li>Click on the name of the search that you'd like to reuse.</li>
          <li>
            Click "Reuse search". You will be taken to the appropriate page and
            the search will automatically populate the appropriate geography and
            filters.
          </li>
        </ol>
      </React.Fragment>
    ),
  },
  {
    id: "share-searches",
    name: `Share searches on ${process.env.REACT_APP_site_name}`,
    excerpt: `It's share searches on ListShack! Just find your saved search and click the "Share" button.  Add the emails of the recipients, customize the message, and click "Send".`,
    friends: [
      "save-and-reuse-searches",
      "search-suppressions",
      "turning-65-lists",
    ],
    content: (
      <React.Fragment>
        <p className="mb-4">Share your searches with friends and colleagues.</p>
        <div
          dangerouslySetInnerHTML={{
            __html: `<div style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe src="https://www.loom.com/embed/dbc4820f319740429f23d20f7716d5b7" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>`,
          }}
        />
        <p className="mt-4">To share a search:</p>
        <ol>
          <li>
            Navigate to the{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              noreferrer
              href="https://www.listshack.support/saved_searches"
            >
              Saved Searches page
            </a>{" "}
            or click the "Search Selected" text on the Search Preview box to
            display all the saved searches for that database.
          </li>
          <li>Click on the name of the search that you'd like to share.</li>
          <li>Click "Share search".</li>
          <li>
            Add the email addresses of those you'd like to share the search
            with, customize the message, and click "Send".
          </li>
          <li>
            Once received, those that you've shared the search with will be able
            to either login and reuse your search in their own account, or they
            can signup and use the search for themselves.
          </li>
        </ol>
      </React.Fragment>
    ),
  },
];

function find(id) {
  return Articles.find((p) => p.id === id);
}

export { SupportCenter, Articles };
