import React, {useState, useEffect} from "react";
import {
  Table,
  Button,
  Image,
  Form,
  ButtonGroup
} from "react-bootstrap";
import {
  ArrowUpRightSquare,
  ChevronLeft,
  ChevronRight
} from "react-bootstrap-icons";
import {getFirstSentence, createArticle} from "./interfaceDigests.jsx";
import withBulkSelect from "./withBulkSelect.jsx";
import {db, firebase} from "./firebase.jsx";

const getForumBeeStuff = async (params, callback) => {
  //console.log("user: ", user);
  console.log("params: ", params);
  let {apiKey} = params;
  const init = {
    method: "POST",
    headers: {
      authorization: `Bearer ${apiKey}`,
      "Content-Type": "application/json"
    },
    "Transfer-Encoding": "chunked",
    cache: "default",
    accept: "application/json",
    body: JSON.stringify(params)
  };
  //console.log("init: ", init);
  let response = await fetch(
    `${process.env.REACT_APP_api_url}/api/forumbee/posts`,
    init
  );
  let posts = await response.json();
  console.log("forumbee posts data: ", posts);
  if (callback) {
    callback(null, posts);
  }
  return await posts;
};

const ImportForumBee = props => {
  console.log("ImportForumBee props: ", props);
  const {apiToken, forumUrl, apiKey, handleBulkSelectState, BulkHeader, SelectItem, selectedData, userDoc, user, integration, handleAlerts} = props;
  const [posts, setPosts] = useState({data:[]});

  // Fetch parameters
  const [limit, setLimit] = useState(25);
  const [offset, setOffset] = useState(0);
  const [textFormat, setTextFormat] = useState("plain-truncate-500");
  const [sort, setSort] = useState("posted");
  const [excludeEvents, setExcludeEvents] = useState(1);
  const [postType, setPostType] = useState("articles");
  const [search, setSearch] = useState("");

  useEffect( () => {
    getForumBeeStuff({
      apiToken,
      forumUrl,
      apiKey,
      limit,
      offset,
      textFormat,
      sort,
      excludeEvents,
      postType
    }, (error,posts) => {
      if (error) {
        console.log("Error getting forum bee stuff: ", error);
      }
      if (posts) {
        if (!posts.data) {
          posts.data = [];
        }
        setPosts(posts)
      }
    })
  }, [limit, offset, sort, excludeEvents, postType, search, apiKey, apiToken, forumUrl, textFormat]);

  let hasPosts = posts.data ? posts.data.length > 0 ? true : false : false;
  let hasPagination = posts.paging ? true : false;
  return(
    <React.Fragment>
      {selectedData.length > 0 &&
        <div className="d-flex flex-row justify-content-end">
          <Button
            onClick={() => {
              let bulkData = posts.data.map( d => {
                let nd={};
                if (selectedData.includes(d.postKey) ) {
                  nd._created = Date.parse(d.posted)/1000;
                  nd.title = d.title ? d.title : "No Title :(";
                  nd.content = d.text ? d.text : "No content :(";
                  nd.contentType = "text";
                  nd.preview = getFirstSentence(d.text ? d.text : "No preview :(");
                  nd.link = `${forumUrl}${d.url}`;
                  nd.imgUrl = d.hasMedia
                    ? d.media.data.map( m => {
                      if (m.mediaType !== 2) {
                        return m.mediaUrl
                      }
                      return undefined;
                    }).filter( s => typeof s !== "undefined")
                    : [];
                  return nd;
                }
                return undefined;
              }).filter( nd => typeof nd !== "undefined");
              console.log("bulkdData: ", bulkData);
              createArticle({
                bulkData,
                aid: userDoc.id,
                uid: user.uid,
                category: ["forumbee"]
              }, (error, summary) => {
                if (error) {
                  console.log("Error importing forumbee content: ", error);
                }
                if (summary) {
                  console.log("Successfully imported forumbee content: ", summary);
                  // update the integration with the ids of the articles that were imported
                  let allIds = [...integration.importedContent, ...selectedData];
                  let uniqueIds = [...new Set(allIds)];
                  integration._docRef.update({importedContent: uniqueIds});
                  db.collection("digests").doc(userDoc.id).update({
                    categories: firebase.firestore.FieldValue.arrayUnion("forumbee")
                  });
                  handleAlerts("", `Yay, you imported ${selectedData.length} new items!`, "success");
                  console.log("Successfully updated integration document: ", uniqueIds);
                  handleBulkSelectState({selectedData: []});
                }
              })
            }}
          >
            Import {selectedData.length} Items
          </Button>
        </div>
      }
      <Form.Label>Select Content Type</Form.Label>
      <div className="d-flex flex-row justify-content-start flex-wrap mb-2">
        {["topics", "replies", "discussions", "questions", "ideas", "issues", "articles", "events", "events-upcoming"].map( type => {
          return(
            <Button
              key={`ptbtn_${type}`}
              className="m-1"
              size="sm"
              variant={postType === type ? "secondary" : "outline-secondary"}
              onClick={() => setPostType(type)}
            >
              {type}
            </Button>
          )
        })}
      </div>
      <Table responsive>
        <thead>
          <tr>
            <th>
              <BulkHeader
                data={posts.data}
                idField="postKey"
                totalItems={posts.paging ? posts.paging.more ? posts.limit * posts.paging.currentPage : posts.paging.count : null}
              />
            </th>
            <th>Image</th>
            <th>Post</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {hasPosts && posts.data.map( (post,i) => {
            //console.log("post: ", post);
            return(
              <tr key={`p_${i}`}>
                <td>
                  <SelectItem
                    id={post.postKey}
                    className={integration.importedContent.includes(post.postKey) ? "text-warning" : "text-primary"}
                  />
                </td>
                <td>
                  {post.hasMedia && post.media.data[0].mediaType !== 2 &&
                    <div style={{width: "50px", height: "50px"}}>
                      <Image thumbnail
                        src={ post.media.data[0].mediaUrl }
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "cover"
                        }}
                      />
                    </div>
                  }
                  {/*post.hasMedia && post.media.data[0].mediaType === 2 &&
                    <div dangerouslySetInnerHTML={{__html: post.media.data[0].mediaUrl}} />
                  */}
                  {!post.hasMedia && <span>&nbsp;</span>}
                </td>
                <td>
                  {post.title}&nbsp;<a target="_blank" rel="noopener noreferrer" href={`${forumUrl}${post.url}`}><ArrowUpRightSquare /></a><br />
                  <small className="text-italic">{getFirstSentence(post.text)}</small>
                </td>
                <td style={{minWidth: "120px", fontSize: ".8rem"}}>{new Date(Date.parse(post.posted)).toLocaleString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric"
                  })}</td>
              </tr>
            )
          })}
          {!hasPosts &&
            <tr>
              <td colSpan="">No content from forumbee available matching this criteria.</td>
            </tr>
          }
        </tbody>
      </Table>
      {hasPagination &&
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex flex-row align-items-center justify-content-start">
            <small style={{width: "100px"}} className="mr-2 text-muted">Items {posts.paging.offset} - {posts.paging.nextOffset ? posts.paging.nextOffset : posts.paging.count}</small>
            <Form.Control
              style={{maxWidth: "100px"}}
              as="select"
              onChange={e => setLimit(e.target.value)}
              value={limit}
            >
              {[5,10,25,50,100,250,500,1000].map(v => {
                return(
                  <option key={`po_${v}`}>{v}</option>
                )
              })}
            </Form.Control>
          </div>
          <ButtonGroup>
            <Button
              variant="link"
              onClick={() => setOffset(posts.paging.nextOffset)}
            >
              <ChevronLeft />
            </Button>
            <Button
              variant="link"
              className="text-dark"
            >
              {posts.paging.currentPage} of {posts.paging.pages ? posts.paging.pages : posts.paging.currentPage}
            </Button>
            <Button
              variant="link"
              onClick={() => setOffset(posts.paging.nextOffset)}
            >
              <ChevronRight />
            </Button>
          </ButtonGroup>
        </div>
      }
    </React.Fragment>

  )
}

export default withBulkSelect(ImportForumBee);
