import React, { useState, useEffect } from "react";
import { renderToString } from "react-dom/server";
import { Switch, Route, Link, useParams } from "react-router-dom";
import { Collection, CollectionContext } from "./firebaseView";
import AutoComplete from "./autoComplete";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { Helmet } from "react-helmet";
import MarkdownView from "react-showdown";
import { ArrowLeft } from "react-bootstrap-icons";

import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/functions";
import { getDoc } from "./firebase";
import { Articles } from "./supportCenter";
import { createArticle } from "./interfaceDigests";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_firebase_apiKey_smry,
  authDomain: process.env.REACT_APP_firebase_authDomain_smry,
  projectId: process.env.REACT_APP_firebase_projectId_smry,
};

const smryLinkFirebaseApp = firebase.initializeApp(firebaseConfig, "smryLink");
const smrydb = smryLinkFirebaseApp.firestore();

const SmrylinkRouter = (props) => {
  let { accountId, blogpath, view } = props;
  let [articles, setArticles] = useState(null);
  let [category, setCategory] = useState(view);
  return (
    <Collection
      colRef={smrydb}
      collection="content"
      permissions={["read"]}
      where={[
        { field: "aid", operand: "==", term: accountId },
        { field: "category", operand: "array-contains-any", term: category },
        { field: "archived", operand: "!=", term: true },
      ]}
      orderBy={[
        { field: "archived" },
        { field: "data.publishedOn", order: "desc" },
      ]}
      listen={false}
    >
      <CollectionContext.Consumer>
        {(context) => {
          return (
            <Switch>
              <Route exact path={`${blogpath}/:id/`}>
                <Article {...props} db={smrydb} articles={context.docs} />
              </Route>
              <Route exact path={`${blogpath}/`}>
                <ArticlesIndex
                  {...props}
                  db={smrydb}
                  articles={context.docs}
                  category={category}
                  setCategory={setCategory}
                />
              </Route>
            </Switch>
          );
        }}
      </CollectionContext.Consumer>
    </Collection>
  );
};

const ArticlesIndex = (props) => {
  let {
    articles,
    db,
    blogpath,
    showTableOfContents,
    showSearch,
    indexHeading,
    indexLead,
  } = props;
  console.log("articles: ", articles);
  if (!articles) {
    return null;
  }
  return (
    <Container fluid={showTableOfContents ? true : false}>
      <Row>
        <Col
          xs={12}
          className={`text-center ${!showSearch ? "py-5 mb-5" : "pt-5"}`}
        >
          <h1>{indexHeading || "Resources"}</h1>
          <p className="lead">
            {indexLead || "Tips for Better Sales & Marketing"}
          </p>
        </Col>
        {showSearch && (
          <Col className="text-center pb-5">
            <AutoComplete
              className="col-xs-12 col-sm-8 col-md-6"
              style={{ margin: "0 auto" }}
              items={articles}
              placeholder="Search help articles"
              path={blogpath}
              //onSelect={(item) => {
              //  console.log("item: ", item);
              //  window.location.href = `${blogpath}/${item.data.slug}/`;
              //}}
              searchKey="searchString"
              suggestions={articles.map((a) => ({
                name: a.data.title,
                searchString: `${a.data.title} ${a.data.preview} ${
                  a.data.slug
                } ${a.category.join(" ")} ${a.data.content}`,
                meta: a.data.preview,
                id: a.data.slug || a._docId,
              }))}
            />
          </Col>
        )}
      </Row>
      <Row>
        {showTableOfContents && (
          <Col xs={12} md={4} lg={3} xl={2} className="d-none d-md-block">
            <Card
              className="sticky-top overflow-auto"
              style={{ maxHeight: "100vh" }}
            >
              <Card.Body>
                <Card.Title>Table of Contents</Card.Title>
                <Card.Text>
                  {articles.map((a) => {
                    return (
                      <Link
                        key={a._docId}
                        to={`${blogpath}/${a.data.slug || a._docId}/`}
                        className="text-dark"
                        style={{ textDecoration: "none" }}
                      >
                        <p className="text-muted mb-2">{a.data.title}</p>
                      </Link>
                    );
                  })}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        )}
        <Col
          xs={12}
          md={showTableOfContents ? 8 : 12}
          lg={showTableOfContents ? 9 : 12}
          xl={showTableOfContents ? 10 : 12}
          className="mt-5"
        >
          <Row>
            <Col xs="12" className="mb-5 pb-5">
              {articles.map((a) => {
                return (
                  <Link
                    key={a._docId}
                    to={`${blogpath}/${a.data.slug || a._docId}/`}
                    className="text-dark"
                    style={{ textDecoration: "none" }}
                  >
                    <BlogHeader
                      article={a}
                      className="bg-light p-md-0 border mb-5"
                      style={{ overflow: "hidden", minHeight: "55vh" }}
                      cta={
                        <div>
                          <Button variant="outline-dark">Read</Button>
                        </div>
                      }
                    />
                  </Link>
                );
              })}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

function Article(props) {
  let { articles, db, blogpath, view } = props;
  let { id } = useParams();
  let [article, setArticle] = useState(null);
  //console.log("article: ", article, "id: ", id, "articles: ", articles);
  const fetchArticle = async () => {
    // Find the article by slug
    let a = await getDoc({
      ref: db.collection("content").where("data.slug", "==", id),
    });
    // If article isn't found by slug try to find it by docId
    if (!a) {
      a = await getDoc({
        ref: db.collection("content").doc(id),
      });
    }
    setArticle(a);
  };

  useEffect(() => {
    if (!article || article.data.slug !== id) {
      let a = articles.find((p) => p.data.slug === id);
      //console.log("a: ", a);
      if (a) {
        setArticle(a);
      } else {
        fetchArticle();
      }
    }
  });

  if (!article) {
    return null;
  }
  // Add the friend cards at the bottom of the article
  let friends = {};
  // For each category the article has find the other articles that also have that category
  let tagsThatArentView = article.category.filter(
    (f) => ![...view].includes(f)
  );
  //console.log("tagsThatArentView: ", tagsThatArentView);
  tagsThatArentView.map((c) => {
    articles.forEach((a) => {
      if (
        a.category.some((r) => article.category.includes(r)) &&
        a._docId !== article._docId
      ) {
        // Only include the friend article if it's not already in the
        friends[a._docId] = a;
      }
    });
  });

  return (
    <React.Fragment>
      <Helmet>
        <title>{article.data.title}</title>
        <meta name="HandheldFriendly" content="true" />
        <meta name="apple-mobile-web-app-capable" content="YES" />
      </Helmet>
      <Container style={{ paddingBottom: "100px" }} className="smryLinkBlog">
        <Row>
          <Col xs={12} className="text-center p-3">
            {article.id !== "all" && (
              <Link
                to={`${blogpath}/`}
                className="pull-left btn btn-link text-dark"
                style={{ fontSize: "17" }}
              >
                <ArrowLeft size={24} /> Back to all articles
              </Link>
            )}
          </Col>
          <Col xs={12} className="mb-4">
            <BlogHeader article={article} />
          </Col>
          <Col className="text-left">
            <FormatContent article={article} />
          </Col>
          {Object.values(friends).length > 0 && (
            <Col xs={12} className="mt-5">
              <h5 className="mb-3">Read More Like This</h5>
              <Row>
                {Object.values(friends).map((friend, i) => {
                  if (i > 2) {
                    return null;
                  }
                  return (
                    <Link
                      key={friend._docId}
                      to={`${blogpath}/${friend.data.slug}/`}
                      className="text-dark"
                      style={{ textDecoration: "none", width: "100%" }}
                    >
                      <BlogHeader
                        article={friend}
                        className="bg-light p-5 p-md-0 pl-md-5 border mb-5"
                        style={{
                          overflow: "hidden",
                          minHeight: "55vh",
                          width: "100%",
                        }}
                        cta={
                          <div>
                            <Button variant="outline-dark">Read</Button>
                          </div>
                        }
                      />
                    </Link>
                  );
                })}
              </Row>
            </Col>
          )}
        </Row>
      </Container>
    </React.Fragment>
  );
}

const BlogHeader = ({ article, className, style, cta }) => {
  let hasImage = article.data.imgUrl.length > 0;
  return (
    <Row
      className={`${className ? className : ""} smryBlogHeader flex-fit`}
      style={{ minHeight: "40vh", ...style }}
    >
      <Col
        xs={{ span: 12, order: "last" }}
        sm={{ span: 12, order: "last" }}
        md={hasImage ? { span: 6, order: "first" } : 12}
        lg={hasImage ? { span: 6, order: "first" } : 12}
        xl={hasImage ? { span: 6, order: "first" } : 12}
        className="d-flex flex-column justify-content-center p-3"
      >
        <h2>{article.data.title}</h2>
        <p className="lead">{article.data.preview}</p>
        {cta && cta}
      </Col>
      {hasImage && (
        <Col
          xs={{ span: 12, order: "first" }}
          sm={{ span: 12, order: "first" }}
          md={{ span: 6, order: "last" }}
          lg={{ span: 6, order: "last" }}
          xl={{ span: 6, order: "last" }}
          className="smryBlogImgContainer p-0 d-flex flex-column flex-fit"
          style={{}}
        >
          <img
            src={article.data.imgUrl[0]}
            alt={article.data.title}
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        </Col>
      )}
    </Row>
  );
};

const FormatContent = ({ article }) => {
  //console.log("FormatContent: ", article);
  if (article.data.contentType === "md") {
    return (
      <MarkdownView
        markdown={article.data.content}
        options={{ tables: true, emoji: true }}
      />
    );
  }
  if (article.data.contentType === "html") {
    return <div dangerouslySetInnerHTML={{ __html: article.data.content }} />;
  }
  if (article.data.contentType === "text") {
    return <p>{article.data.content}</p>;
  }
};

const MigrateArticlesToSmrylink = ({ Articles }) => {
  return (
    <Button
      onClick={() => {
        console.log("Articles: ", Articles);
        let bulkData = [];
        let articleCount = 0;
        for (let article of Articles) {
          let formattedArticle = {
            content: renderToString(article.content),
            contentType: "html",
            preview: article.excerpt,
            title: article.name,
            slug: article.id,
            publishedOn: new Date("2020-03-01"),
            relatedSlugs: article.friends,
          };
          //console.log("formattedArticle: ", formattedArticle);
          articleCount++;
          bulkData.push(formattedArticle);
        }
        //console.log("bulkData: ", bulkData);
        createArticle({
          bulkData,
          uid: "w4vKu1k5sLS8gkC27ZifDsVGuks2",
          aid: "w4vKu1k5sLS8gkC27ZifDsVGuks2",
          category: ["blog", "help", "help-published"],
          db: smrydb,
        });
      }}
    >
      Migrate to Smry Link
    </Button>
  );
};

export default SmrylinkRouter;
