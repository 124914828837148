import React from "react";
import ReactDOM from "react-dom";
import App from "./AppListshackStatic";
import plans from "./plans";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import * as serviceWorker from "./serviceWorker";
//import firebaseApp from './firebase.jsx';
import "font-awesome/css/font-awesome.min.css";

ReactDOM.render(
  <App
    plans={plans}
    staticRoutes={[
      "/home",
      "/pricing",
      "/compare_plans",
      "/faq",
      "/contact",
      "/privacy",
      "/terms",
      "/support/",
      "/emails",
      "/listshack_desktop",
      "/ccpa"
    ]}
    headwayConfig={{
      selector: "#hw",
      account: "J0PbKJ"
    }}
  />,
  document.getElementById("root")
);

serviceWorker.unregister();
