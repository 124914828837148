import React, {useState, useEffect} from "react";
import {
  Table,
  Button,
  Image,
  Form,
  ButtonGroup
} from "react-bootstrap";
import {
  ArrowUpRightSquare,
  ChevronLeft,
  ChevronRight
} from "react-bootstrap-icons";
import {getFirstSentence, createArticle, htmlToPlain} from "./interfaceDigests.jsx";
import withBulkSelect from "./withBulkSelect.jsx";
import {db, firebase} from "./firebase.jsx";

const ImportWordPress = props => {
  console.log("ImportForumBee props: ", props);
  const {blogUrl, handleBulkSelectState, BulkHeader, SelectItem, selectedData, userDoc, user, integration, handleAlerts, fetchFromApi} = props;
  const [posts, setPosts] = useState({data:[]});

  // Fetch parameters
  const [perPage, setPerPage] = useState(25);
  const [offset, setOffset] = useState(0);
  const [orderby, setOrderby] = useState("");
  const [order, setOrder] = useState("");
  const [postType, setPostType] = useState("posts");
  const [search, setSearch] = useState("");

	const pt2e = {
		posts: "/api/wordpress/posts"
	};

  useEffect( () => {
    fetchFromApi({
      body: {
				url: blogUrl,
				options: {
					perPage,
					offset,
					orderby,
					order
				}
			},
			endpoint: pt2e[postType]
    }, (error,posts) => {
      if (error) {
        console.log("Error getting forum bee stuff: ", error);
      }
      if (posts) {
        if (!posts.data) {
          posts.data = [];
        }
        setPosts(posts)
      }
    })
  }, [perPage, offset, orderby, order, postType, search, blogUrl]);

  let hasPosts = posts.data ? posts.data.length > 0 ? true : false : false;
  let hasPagination = posts.data.length === perPage ? true : false;
  return(
    <React.Fragment>
      {selectedData.length > 0 &&
        <div className="d-flex flex-row justify-content-end">
          <Button
            onClick={() => {
              let bulkData = posts.data.map( d => {
                let nd={};
                if (selectedData.includes(d.id) ) {
                  nd._created = Date.parse(d.date)/1000;
                  nd.title = d.title ? d.title.rendered.trim() : "No Title :(";
                  nd.content = d.content ? d.content.rendered.trim() : "No content :(";
                  nd.contentType = "html";
                  nd.preview = getFirstSentence(d.excerpt ? htmlToPlain(d.excerpt.rendered.trim() ) : "No preview :(");
                  nd.link = `${d.link}`;
                  nd.imgUrl = d.contentImages
                    ? d.contentImages
                    : [];
									nd.externalId = d.id ? d.id : null;
                  return nd;
                }
                return undefined;
              }).filter( nd => typeof nd !== "undefined");
              console.log("bulkdData: ", bulkData);
							const { hostname } = new URL(blogUrl);
              createArticle({
                bulkData,
                aid: userDoc.id,
                uid: user.uid,
                category: ["wordpress", hostname]
              }, (error, summary) => {
                if (error) {
                  console.log("Error importing forumbee content: ", error);
                }
                if (summary) {
                  console.log("Successfully imported forumbee content: ", summary);
                  // update the integration with the ids of the articles that were imported
                  let allIds = [...integration.importedContent, ...selectedData];
                  let uniqueIds = [...new Set(allIds)];
                  integration._docRef.update({importedContent: uniqueIds});
                  db.collection("digests").doc(userDoc.id).update({
                    categories: firebase.firestore.FieldValue.arrayUnion("forumbee")
                  });
                  handleAlerts("", `Yay, you imported ${selectedData.length} new items!`, "success");
                  console.log("Successfully updated integration document: ", uniqueIds);
                  handleBulkSelectState({selectedData: []});
                }
              })
            }}
          >
            Import {selectedData.length} Items
          </Button>
        </div>
      }
      <Form.Label>Select Content Type</Form.Label>
      <div className="d-flex flex-row justify-content-start flex-wrap mb-2">
        {["posts"].map( type => {
          return(
            <Button
              key={`ptbtn_${type}`}
              className="m-1"
              size="sm"
              variant={postType === type ? "secondary" : "outline-secondary"}
              onClick={() => setPostType(type)}
            >
              {type}
            </Button>
          )
        })}
      </div>
      <Table responsive>
        <thead>
          <tr>
            <th>
              <BulkHeader
                data={posts.data}
                idField="id"
                totalItems={ null }
              />
            </th>
            <th>Image</th>
            <th>Post</th>
            <th>Created</th>
          </tr>
        </thead>
        <tbody>
          {hasPosts && posts.data.map( (post,i) => {
            //console.log("post: ", post);
            return(
              <tr key={`p_${i}`}>
                <td>
                  <SelectItem
                    id={post.id}
                    className={integration.importedContent.includes(post.id) ? "text-warning" : "text-primary"}
                  />
                </td>
                <td>
                  {post.contentImages && 
                    <div style={{width: "50px", height: "50px"}}>
                      <Image thumbnail
                        src={ post.contentImages[0] }
                        style={{
                          width: "50px",
                          height: "50px",
                          objectFit: "cover"
                        }}
                      />
                    </div>
                  }
                  {/*post.hasMedia && post.media.data[0].mediaType === 2 &&
                    <div dangerouslySetInnerHTML={{__html: post.media.data[0].mediaUrl}} />
                  */}
                  {!post.contentImages && <span>&nbsp;</span>}
                </td>
                <td>
                  <span dangerouslySetInnerHTML={{__html: post.title.rendered}} />&nbsp;<a target="_blank" rel="noopener noreferrer" href={`${post.link}`}><ArrowUpRightSquare /></a><br />
                  <small className="text-italic" dangerouslySetInnerHTML={{__html: post.excerpt.rendered}} />
                </td>
                <td style={{minWidth: "120px", fontSize: ".8rem"}}>{new Date(Date.parse(post.date)).toLocaleString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric"
                  })}</td>
              </tr>
            )
          })}
          {!hasPosts &&
            <tr>
              <td colSpan="">No content from wordpress available matching this criteria.</td>
            </tr>
          }
        </tbody>
      </Table>
      {hasPagination &&
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="d-flex flex-row align-items-center justify-content-start">
            <small style={{width: "100px"}} className="mr-2 text-muted">Items {offset + 1} - {offset + perPage}</small>
            <Form.Control
              style={{maxWidth: "100px"}}
              as="select"
              onChange={e => setPerPage(e.target.value)}
              value={perPage}
            >
              {[5,10,25,50,100,250,500,1000].map(v => {
                return(
                  <option key={`po_${v}`}>{v}</option>
                )
              })}
            </Form.Control>
          </div>
          <ButtonGroup>
            <Button
              variant="link"
              onClick={() => setOffset(offset - perPage)}
							disabeld={ offset-perPage <= 0}
            >
              <ChevronLeft />
            </Button>
            <Button
              variant="link"
              className="text-dark"
            >
              {offset + 1} to {offset + perPage}
            </Button>
            <Button
              variant="link"
              onClick={() => setOffset(offset + perPage)}
            >
              <ChevronRight />
            </Button>
          </ButtonGroup>
        </div>
      }
    </React.Fragment>

  )
}

export default withBulkSelect(ImportWordPress);
