import React from "react";
import sharedDict from "./sharedDict.jsx";
import { Link } from "react-router-dom";
import image from "./media/business_shop.svg";

export default {
  dictId: "bizteladdr",
  index: "bizdec2018_3",
  type: "Business",
  apiUrl: "/api/v2/data/bizdec2018_3?db=bizDec2018",
  path: "/search/businesses",
  title: "US Businesses",
  subText: <small>US business telemarketing and direct mail database. For business emails search <Link to="/search/business_emails">here</Link>.</small>,
  description: "ListShack's business telemarketing and direct mail database.",
  overview: "A comprehensive US businesses databases.  Use this databases to search for busineses by category, sales volume, and number of employess.  Download name, owner name (where available), address, phone number, and web address (where available).",
  themeColor: "info",
  image,
  map: {
    latitude: "LAT",
    longitude: "LONG",
    location: "LOCATION",
    searchTypes: {
      region: "ST",
      postcode: "ZIP",
      place: "CITY"
    }
  },
  geography: {
    AREA_CODE: sharedDict.geography.AREA_CODE,
    COUNTY: sharedDict.geography.COUNTY,
    CITY: sharedDict.geography.CITY,
    ZIP: sharedDict.geography.ZIP,
    ST: sharedDict.geography.ST,
    nationwide: sharedDict.geography.nationwide
  },
  cols: {
    SIC: {
      name: 'SIC code',
      displayHeader: true,
      inputType: "search",
      items: [],
    },
    NUM_EMPLOYEES: {
      name: "Number of employees",
      displayHeader: true,
      inputType: "checkbox",
      items: [
        {index: 0, value: "1 to 4", name: "1 to 4"},
        {index: 1, value: "5 to 9", name: "5 to 9"},
        {index: 2, value: "10 to 19", name: "10 to 19"},
        {index: 3, value: "20 to 49", name: "20 to 49"},
        {index: 4, value: "50 to 99", name: "50 to 99"},
        {index: 5, value: "100 to 249", name: "100 to 249"},
        {index: 6, value: "250 to 499", name: "250 to 499"},
        {index: 7, value: "500 to 999", name: "500 to 999"},
        {index: 8, value: "1,000 to 4,999", name: "1,000 to 4,999"},
        {index: 9, value: "5,000 to 9,999", name: "5,000 to 9,999"},
      ]
    },
    SALES_VOL: {
      name: "Sales volume",
      displayHeader: true,
      inputType: "checkbox",
      items: [
        {index: 0, value: "LESS THAN $500,000", name: "LESS THAN $500,000"},
        {index: 1, value: "$500,000 TO $1 MILLION", name: "$500,000 TO $1 MILLION"},
        {index: 2, value: "$1 TO 2.5 MILLION", name: "$1 TO 2.5 MILLION"},
        {index: 3, value: "$2.5 TO 5 MILLION", name: "$2.5 TO 5 MILLION"},
        {index: 4, value: "$5 TO 10 MILLION", name: "$5 TO 10 MILLION"},
        {index: 5, value: "$10 TO 20 MILLION", name: "$10 TO 20 MILLION"},
        {index: 6, value: "$20 TO 50 MILLION", name: "$20 TO 50 MILLION"},
        {index: 7, value: "$50 TO 100 MILLION", name: "$50 TO 100 MILLION"},
        {index: 8, value: "$100 TO 500 MILLION", name: "$100 TO 500 MILLION"},
        {index: 9, value: "$500 MILLION TO $1 BILLION", name: "$500 MILLION TO $1 BILLION"},
        {index: 10, value: "Over $1 BILLION", name: "Over $1 BILLION"},
      ]
    },
    WEB_ADDRESS: {
      name: "Web address",
      displayHeader: true,
      inputType: "select",
      formText: "The website address may be incomplete. Selecting businesses without a web address does not guarantee that the business will not have a web address.",
      items: [
        {index: 0, value: "", name: "No preference"},
        {index: 1, query: `(WEB_ADDRESS <>"")`, value: "WWA", name: "Businesses with web addresses"},
        {index: 2, query: '(WEB_ADDRESS="")', value: "WOWA", name: "Businesses without web addresses"}
      ]
    },
    phonePref: {
      name: "Contact Preferences",
      displayHeader: true,
      inputType: "checkbox",
      formText: "Click or tap to add this filter if you'd like all records to have a phone number.",
      items: [
        {index: 0, value: "0", name: "Must have Phone number", query: [`(PHONE <> '')`]}
      ]
    },
    addrPref: {
      name: "Address",
      displayHeader: false,
      inputType: "checkbox",
      formText: "Click or tap to add this filter if you'd like all records to have an address.",
      items: [
        {index: 1, value: "1", name: "Must have Address", query: [`(ADDR <> '')`]}
      ]
    },
    ownerPref: {
      name: "Business Contact",
      displayHeader: false,
      inputType: "checkbox",
      formText: "Click or tap to add this filter if you'd like all records to an owner or business contact name.",
      items: [
        {index: 2, value: "2", name: "Must have Business Contact", query: [`(FULL_NAME <> '')`]},
      ]
    },
    titlePref : {
      name: "Title",
      displayHeader: true,
      inputType: "select",
      formText: "We do a cursory search on titles to match them up to specific types of positions.  Please double check the preview to make sure you are matching the correct people.",
      items: [
        {index: 0, value: "", name: "No Preference"},
        {index: 1, value: "owner", name: "Owner", query: [`(FULL_NAME like '% owner%')`]},
        {index: 2, value: "ceo", name: "CEO", query: [`(FULL_NAME like '% ceo%')`]},
        //{index: 3, value: "founder", name: "Founder", query: [`(FULL_NAME like '% founder%')`]}, // Results not useful
        {index: 4, value: "Partner", name: "Partner", query: [`(FULL_NAME like '% partner%')`]},
        {index: 5, value: "manager", name: "Manager", query: [`(FULL_NAME like '% manager%')`]},
        {index: 6, value: "director", name: "Director", query: [`(FULL_NAME like '% director%')`]},
        //{index: 7, value: "sales", query: [`(FULL_NAME like '% sales%')`], name: "Sales"}, // Results not useful
        {index: 8, value: "technical", name: "Technical", query: [`(FULL_NAME like '% techn%') or (FULL_NAME like '% information%') or (FULL_NAME like '% engineer%')`]},
        {index: 9, value: "president", name: "President", query: [`(FULL_NAME like '% president%')`]},
        {index: 10, value: "officer", query: [`(FULL_NAME like '% officer%')`, `(FULL_NAME like '% executive%')`], name: "Executives and officers"},
        {index: 11, value: "marketing", name: "Marketing", query: [`(FULL_NAME like '% marketing%') or (FULL_NAME like '% CMO%')`]},
        //{index: 12, value: "legal", name: "Legal", query: [`(FULL_NAME like '% counsel%') or (FULL_NAME like '% legal%') or (FULL_NAME like '% attorney%')`]}, // Results not useful
        //{index: 13, value: "education", query: [`(FULL_NAME like '% educat%') or (FULL_NAME like '% teacher%') or (FULL_NAME like '% learning%') or (FULL_NAME like '% professor%') or (FULL_NAME like '% counselor%') or (FULL_NAME like '% alumni%') or (FULL_NAME like '% dean%') or (FULL_NAME like '% student%')`], name: "Learning and education", meta: "Includes teachers, professors, deans, and administrators"}, // Results not useful
        {index: 14, value: "accounting", query: [`(FULL_NAME like '% accounting%') or (FULL_NAME like '% accountant%') or (FULL_NAME like '% controller%') or (FULL_NAME like '% financ%') or (FULL_NAME like '% cfo%') or (FULL_NAME like '% treasurer %')`], name: "Finance and accounting (all)", meta: "Includes acccounting, finance, controllers, and treasurers"},
        {index: 15, value: "hr", query: [`(FULL_NAME like '% human%') or (FULL_NAME like '% recruit%') or (FULL_NAME like '% personnel%')`], name: "Human resources", meta: "Includes human resources, recruiting, and personnel"},
        //{index: 16, value: "medical", query: [`(FULL_NAME like '% medical%') or (FULL_NAME like '% doctor%') or (FULL_NAME like '% nurs%') or (FULL_NAME like '% physician%') or (FULL_NAME like '% surge%')`], name: "Medical professionals and staff", meta: "Includes doctors, surgeons, nurses, and physicians"}, // Results not useful
        {index: 17, value: "finance", query: [`(FULL_NAME like '% financ%') or (FULL_NAME like '% cfo%') or (FULL_NAME like '% treasurer %')`], name: "Finance"}
      ]
    },
    AREA_CODE: sharedDict.geography.AREA_CODE,
    COUNTY: sharedDict.geography.COUNTY,
    CITY: sharedDict.geography.CITY,
    ZIP: sharedDict.geography.ZIP,
    ST: sharedDict.geography.ST,
    nationwide: sharedDict.geography.nationwide
  },
  keyOrder: {
    ZIP: 100,
    CITY: 200,
    COUNTY: 300,
    AREA_CODE: 400,
    ST: 500,
    SIC: 600,
    NUM_EMPLOYEES: 800,
    SALES_VOL: 900,
    WEB_ADDRESS: 1000,
    phonePref: 1100,
    addrPref: 1200,
    ownerPref: 1300,
    titlePref: 1400,
    nationwide: 1500
  },
  defaultSearchParams: {
    SIC: [],
    NUM_EMPLOYEES: [],
    SALES_VOL: [],
    WEB_ADDRESS: [],
    phonePref: [],
    addrPref: [],
    ownerPref: [],
    titlePref: [],
    AREA_CODE: [],
    COUNTY: [],
    CITY: [],
    ZIP: [],
    ST: [],
    nationwide: []
  },
  previewCols: {
    display: [
      {label: "Name", content: "CO_NAME"},
      {label: "Address", content: "ADDR"},
      {label: "City", content: "CITY"},
      {label: "Zip", content: "ZIP"},
      {label: "Phone", content: "PHONE"}],
    popover: [
      {label: "Contact name", content: "FULL_NAME"},
      {label: "Address", content: "ADDR"},
      {label: "City", content: "CITY"},
      {label: "State", content: "ST"},
      {label: "Zip", content: "ZIP"},
      {label: "Phone", content: "PHONE"},
      {label: "Website", content: "WEB_ADDRESS"}
    ]
  },
  dlCols: [
    {value: "CO_NAME", name: "Business name", default: true},
    {value: "PHONE", name: "Phone", default: true},
    {value: "FULL_NAME", name: "Owner", default: true},
    {value: "ADDR", name: "Address", default: true},
    {value: "CITY", name: "City", default: true},
    {value: "ST", name: "State", default: true},
    {value: "ZIP", name: "Zip", default: true},
    {value: "COUNTY", name: "County", default: true},
    {value: "SALES_VOL", name: "Sales volume", default: true},
    {value: "SIC_6", name: "SIC code", default: true},
    {value: "NUM_EMPLOYEES", name: "Number of employees", default: true},
    {value: "WEB_ADDRESS", name: "Web address (where available)", default: true},
  ],
  selectFilterValidation: ["NUM_EMPLOYEES", "SALES_VOL"],
  leadsMultiplier: 1,
  access: "Any subscription",
  summaryStats: {
    dateReleased: "2021-11-19"
  }
}
