import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Form, ListGroup } from "react-bootstrap";

class AutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: "",
      suggestions: []
    };
    //this.items = [
    //  {value: 'D', name: 'David'},
    //  {value: "Da", name: 'Damien'},
    //  {value: "Sa", name: 'Sara'},
    //  {value: "Ja", name: 'Jane'},
    //  //"David", "Damien", "Sara", "Jane"
    //] // For testing
    //console.log(props);
    this.selectSuggestion = this.selectSuggestion.bind(this);
  }

  onSearchChanged(e) {
    const value = e.target.value;
    let props = this.props;
    //console.log("value: ", value);
    let suggestions = [];
    if (value.length > 1) {
      let items = this.props.suggestions;
      //console.log("items: ", items);
      let regex = new RegExp(`.*${value}.*`, "i");
      suggestions = items.sort().filter(v => {
        return regex.test(props.searchKey ? v[props.searchKey] : v.name);
      });
      //console.log("suggestions: ", suggestions);
      this.setState(() => ({ suggestions }));
    }
  }

  selectSuggestion(e, suggestion) {
    e.preventDefault();
    let props = this.props;
    if (props.onSelect) {
      props.onSelect(suggestion);
    }
    this.setState({ searchText: "", suggestions: "" });
  }

  render() {
    let suggestions = this.state.suggestions;
    const searchText = this.state.searchText;
    let showSuggestions = searchText.length > 0 && suggestions.length > 0;
    let props = this.props;
    //console.log("AutoComplete props: ", props);
    return (
      <div
        style={props.style ? props.style : {}}
        className={props.className ? props.className : ""}
      >
        <Form.Control
          type="text"
          placeholder={this.props.placeholder}
          onChange={e => {
            this.onSearchChanged(e);
            this.setState({ searchText: e.target.value });
          }}
          value={this.state.searchText}
          autoComplete="off"
          size={props.size ? props.size : "lg"}
          className="text-center"
        />
        <Form.Text className="text-muted">{this.props.helpText}</Form.Text>
        {showSuggestions && (
          <ListGroup
            style={{
              width: "94.5%",
              zIndex: "1000",
              position: "absolute"
            }}
          >
            {suggestions.map((suggestion, i) => (
              <ListGroup.Item
                key={i}
                variant="light"
                onClick={e => this.selectSuggestion(e, suggestion)}
                action
              >
                {props.suggestion && props.suggestion(suggestion)}
                {!props.suggestion && (
                  <Link to={`${this.props.path}/${suggestion.id}`}>
                    {suggestion.name}
                    <p style={{ fontSize: ".8em", margin: "0px" }}>
                      {suggestion.meta ? suggestion.meta : null}
                    </p>
                  </Link>
                )}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </div>
    );
  }
}

AutoComplete.propTypes = {
  suggestions: PropTypes.array,
  placeholder: PropTypes.string,
  helpText: PropTypes.string,
  path: PropTypes.string
};

export default AutoComplete;
