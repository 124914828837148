const listShackPlans = {
  0: {
    shortname: "Individual unlimited",
    descriptor: {
      name: "ListShack.io*Ind Unltd",
      phone: "833-222-7919",
      url: "ListShack.io"
    },
    name: "Individual unlimited",
    description: "Up to 50,000 leads per month 5,000 per download",
    price: 5000,
    planID: process.env.REACT_APP_plan_ind_unlimited.toLowerCase(),
    leads: 50000,
    downloadLimit: 5000,
    includesRollover: false,
    users: 1,
    searchSuppressions: false,
    public: true
  },
  1: {
    shortname: "Team unlimited",
    descriptor: {
      name: "ListShack.io*Tm Unltd",
      phone: "833-222-7919",
      url: "ListShack.io"
    },
    description:
      "Unlimited searches, up to 50,000 leads per month, and 10 team members",
    name: "Team unlimited",
    price: 9900,
    planID: process.env.REACT_APP_plan_team_unlimited.toLowerCase(),
    leads: 50000,
    downloadLimit: 10000,
    includesRollover: false,
    users: 10,
    searchSuppressions: true,
    public: true
  },
  2: {
    shortname: "50K Rollover",
    descriptor: {
      name: "ListShack.io*50k Rlvr",
      phone: "833-222-7919",
      url: "ListShack.io"
    },
    name: "50,000 leads with rollover",
    description: "50,000 sales leads per month with rollover",
    price: 19900,
    planID: process.env.REACT_APP_plan_50k_rollover.toLowerCase(),
    leads: 50000,
    downloadLimit: 50000,
    includesRollover: true,
    users: 50,
    searchSuppressions: true,
    public: true
  },
  3: {
    shortname: "250K Rollover",
    descriptor: {
      name: "ListShack.io*250k Rlvr",
      phone: "833-222-7919",
      url: "ListShack.io"
    },
    description: "250,000 sales leads per month with rollover",
    name: "250,000 leads with rollover",
    price: 29900,
    planID: process.env.REACT_APP_plan_250K_rollover.toLowerCase(),
    leads: 250000,
    downloadLimit: 100000,
    includesRollover: true,
    users: 75,
    searchSuppressions: true,
    public: false
  },
  4: {
    shortname: "500K Rollover",
    descriptor: {
      name: "ListShack.io*500k Rlvr",
      phone: "833-222-7919",
      url: "ListShack.io"
    },
    description: "500,000 sales leads per month with rollover",
    name: "500,000 leads with rollover",
    price: 49900,
    planID: process.env.REACT_APP_plan_500K_rollover.toLowerCase(),
    leads: 500000,
    downloadLimit: 200000,
    includesRollover: true,
    users: 100,
    searchSuppressions: true,
    public: false
  },
  5: {
    shortname: "Call center unlimited",
    descriptor: {
      name: "ListShack * CC Unlmtd",
      phone: "833-222-7919",
      url: "ListShack.io"
    },
    description: "Unlimited leads for call centers",
    name: "Unlimited leads for call centers",
    price: 99900,
    planID: process.env.REACT_APP_plan_CC_unlimited.toLowerCase(),
    leads: 1000000000,
    downloadLimit: 200000,
    includesRollover: false,
    users: 150,
    searchSuppressions: true,
    public: false
  },
  6: {
    shortname: "Search & Save Free trial",
    descriptor: {
      name: "ListShack * Free",
      phone: "833-222-7919",
      url: "ListShack.io"
    },
    description: "Unlimited searches and previews plus 100 free leads",
    name: "Free trial",
    price: 0,
    planID: process.env.REACT_APP_plan_free_trial.toLowerCase(),
    leads: 0,
    leadsRemaining: 0,
    downloadLimit: 100,
    includesRollover: false,
    users: 0,
    searchSuppressions: false,
    public: false
  },
  7: {
    shortname: "October 1 Million Leads",
    descriptor: {
      name: "ListShack.io*Ind Unltd",
      phone: "833-222-7919",
      url: "ListShack.io"
    },
    description: "1 million leads for your first month!",
    name: "October Promo",
    price: 5000,
    planID: process.env.REACT_APP_plan_oct_promo.toLowerCase(),
    leads: 50000,
    leadsRemaining: 1000000,
    downloadLimit: 5000,
    includesRollover: false,
    users: 0,
    searchSuppressions: false,
    public: false
  },
  8: {
    shortname: "November All You Can Call",
    descriptor: {
      name: "ListShack.io*Ind Unltd",
      phone: "833-222-7919",
      url: "ListShack.io"
    },
    description: "50K Leads per month, 5K per download.",
    name: "November Promo",
    price: 5000,
    planID: process.env.REACT_APP_plan_nov_promo.toLowerCase(),
    leads: 50000,
    leadsRemaining: 50000,
    downloadLimit: 5000,
    includesRollover: false,
    users: 0,
    searchSuppressions: false,
    public: false
  },
  9: {
    shortname: "Legacy Annual Unlimited",
    description: "500K Leads per month, 200K per download.",
    name: "Legacy Annual Unlimited",
    price: 180000,
    term: "year",
    planID: process.env.REACT_APP_plan_ann_leg_unlimited.toLowerCase(),
    leads: 500000,
    leadsRemaining: 500000,
    downloadLimit: 200000,
    includesRollover: true,
    users: 25,
    searchSuppressions: true,
    public: false
  },
  10: {
    shortname: "Annual - Individual Unlimited",
    description: "Up to 50,000 leads per month 5,000 per download",
    name: "Annual Individual Unlimited",
    price: 60000,
    term: "year",
    planID: process.env.REACT_APP_plan_ann_ind.toLowerCase(),
    leads: 50000,
    leadsRemaining: 50000,
    downloadLimit: 5000,
    includesRollover: false,
    users: 1,
    searchSuppressions: false,
    public: false
  },
  11: {
    shortname: "Annual - Team unlimited",
    description:
      "Unlimited searches, up to 50,000 leads per month, and 10 team members",
    name: "Annual Team unlimited",
    price: 118800,
    term: "year",
    planID: process.env.REACT_APP_plan_ann_team.toLowerCase(),
    leads: 50000,
    leadsRemaining: 50000,
    downloadLimit: 10000,
    includesRollover: false,
    users: 10,
    searchSuppressions: true,
    public: false
  },
  12: {
    shortname: "Annual - 50K Rollover",
    name: "50,000 leads with rollover",
    description: "50,000 sales leads per month with rollover",
    price: 238800,
    term: "year",
    planID: process.env.REACT_APP_plan_ann_50k.toLowerCase(),
    leads: 50000,
    leadsRemaining: 50000,
    downloadLimit: 50000,
    includesRollover: true,
    users: 50,
    searchSuppressions: true,
    public: false
  }
};

export default listShackPlans;
