import React from "react";
import { Alert, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const AppAlerts = props => {
  const {
    alertShow,
    alertVariant,
    alertHeading,
    alertText,
    handleState,
    loaded,
    isFreeTrial,
    hideFreeTrial
  } = props;

  return (
    <React.Fragment>
      {alertShow && (
        <Alert
          variant={alertVariant || "info"}
          style={{
            marginBottom: "0px",
            zIndex: "1030"
          }}
          className="sticky-top"
        >
          <Button
            onClick={() => handleState({ alertShow: false })}
            variant="link"
            style={{
              position: "absolute",
              top: "0",
              right: "0"
            }}
          >
            &times;
          </Button>
          {alertHeading && <Alert.Heading>{alertHeading}</Alert.Heading>}
          <p style={{ marginBottom: "0px" }}>{alertText}</p>
        </Alert>
      )}
      {loaded && isFreeTrial && !hideFreeTrial &&(
        <Alert variant="primary" className="text-center mb-0">
          Your using the free trial! Subscribe if you'd like to download
          more leads.{" "}
          <Link
            to="/settings/billing"
            style={{ marginLeft: "20px" }}
            className="btn btn-outline-primary"
          >
            Subscribe now
          </Link>
        </Alert>
      )}
    </React.Fragment>
  );
}

export default AppAlerts;
