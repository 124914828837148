import React from "react";
import PropTypes from "prop-types";
import { Button, ListGroup } from "react-bootstrap";

// The selectedParams objects coming into this component are shaped as follows:
//
//      selectedParams: {
//        dataDictKey1: [
//          {value: "something", name: "Friendly somthing"},
//          {value: "something", name: "Friendly somthing"}
//        ],
//        dataDictKey2: [
//          {value: "something", name: "Friendly somthing"},
//          {value: "something", name: "Friendly somthing"}
//        ]

class SelectedParams extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  renderParamKey() {
    const { dict, selectedParams } = this.props;
    const dictKeys = Object.keys(selectedParams);
    //console.log("dictKeys: ", dictKeys);
    let renderedKeys = [];
    let paramKey;
    let addedParamHeader = false;
    let pki = 0;
    for (paramKey of dictKeys) {
      //console.log(dictKeys);
      if (selectedParams[paramKey].length > 0) {
        // Let's add a nice header to let folks know what they are searching for
        if (!addedParamHeader) {
          renderedKeys.push(
            <React.Fragment key={paramKey + "Frag" + pki}>
              <h5
                key={this.props.paramHeader + "h5" + pki}
                style={{ display: "inline-block" }}
              >
                {this.props.paramHeader}
              </h5>
              {this.props.reset && (
                <Button
                  key={paramKey + "_button" + pki}
                  variant="link"
                  size="sm"
                  className="pull-right"
                  onClick={e => this.props.reset(e)}
                >
                  Reset
                </Button>
              )}
            </React.Fragment>
          );
          addedParamHeader = true;
        }
        // If the query type from the data dictionary will be a list of items, we should make it easy to empty the whole array, so let's create a header for the list and give them a reset button.  We'll do this by checking the data dictionary for the 'displayHeader' key. */}
        //console.log("dict: ", dict, "paramKey: ", paramKey, "dict[paramKey]: ", dict[paramKey]);
        if (dict[paramKey] ? dict[paramKey]["displayHeader"] : true) {
          renderedKeys.push(
            <React.Fragment key={paramKey + "frag"}>
              <h6 key={paramKey + "header" + pki}>{dict[paramKey] ? dict[paramKey]["name"] : paramKey}</h6>
              <ListGroup
                style={{ width: "100%" }}
                key={paramKey + "_ListGroup" + pki}
              >
                {this.renderParam(paramKey)}
              </ListGroup>
            </React.Fragment>
          );
        } else {
          renderedKeys.push(
            <ListGroup
              style={{ width: "100%" }}
              key={paramKey + "_ListGroup" + pki}
            >
              {this.renderParam(paramKey)}
            </ListGroup>
          );
        }
      }
    }
    pki += 1;
    return renderedKeys;
  }

  renderParam(key) {
    const { selectedParams } = this.props;

    const params = selectedParams[key];

    return params.map(param => (
      <ListGroup.Item
        key={key + "_" + param.value}
        className="text-muted"
        style={{
          padding: ".35rem .5rem",
          lineHeight: "8px",
          border: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%"
        }}
      >
        {this.props.displayOnMap && (
          <Button
            variant="link"
            onClick={e => this.props.displayOnMap(e, key, param)}
            style={{ padding: ".35rem .5rem", lineHeight: "8px" }}
          >
            <i className="fa fa-map-marker" aria-hidden="true"></i>&nbsp;
            {param.name ? param.name : param}
          </Button>
        )}
        {!this.props.displayOnMap && (
          <span style={{ lineHeight: "1" }}>
            {param.name ? param.name : param}
          </span>
        )}
        {this.props.removeParam && (
          <Button
            variant="link"
            onClick={e =>
              this.props.removeParam(this.props.dictKey, key, param)
            }
            style={{
              padding: ".35rem .5rem",
              lineHeight: "8px",
              float: "right"
            }}
          >
            &times;
          </Button>
        )}
      </ListGroup.Item>
    ));
  }

  render() {
    //console.log("SelectedParams this.props: ", this.props);
    const selectedParameters = this.renderParamKey();

    return <React.Fragment>{selectedParameters}</React.Fragment>;
  }
}

SelectedParams.propTypes = {
  selectedParams: PropTypes.object,
  dict: PropTypes.object.isRequired,
  reset: PropTypes.func,
  displayOnMap: PropTypes.func,
  removeParam: PropTypes.func,
  dictKey: PropTypes.string,
  paramHeader: PropTypes.string
};

export default SelectedParams;
