import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import VideoDemo from "./videoDemo.jsx";

class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      plan: 0
    };
  }

  render() {
    
    let {plan} = this.state;
    let plans = this.props.plans;
    let plansLinks = [];
    for (let i of Object.keys(this.props.plans)) {
      if (this.state.selectedPlan !== i && plans[i]["public"]) {
        plansLinks.push(
          <Button
            variant="outline-primary"
            onClick={e => {
              e.preventDefault();
              this.setState({plan: i});
            }}
            key={plans[i]["price"]}
            className="mt-3"
          >
            {plans[i]["name"]}
          </Button>
        );
      }
    }
    console.log("Pricing this.props: ", this.props, "plansLinks: ", plansLinks);
    return (
      <Container>
        <Row>
          <Col
            xs={12}
            md={4}
            className="text-center"
            style={{ padding: "5px" }}
          >
            <VideoDemo />
            <h4>
              {plans[plan].description} for ${plans[plan].price / 100} per month
            </h4>
            <h5>Cancel anytime</h5>
            <br />
            <h6>Other plans</h6>
            <div className="text-left">{plansLinks}</div>
            <div className="mt-5">
              <Link to="/compare_plans" className="btn btn-link">
                Compare all plans
              </Link>
              <br />
              <small className="text-muted">
                Higher volume plans are available upon request. Send an email to{" "}
                {process.env.REACT_APP_contact_email} for more information.
              </small>
            </div>
          </Col>
          <Col
            xs={12}
            md={4}
            className="text-center mt-5"
            style={{ padding: "5px" }}
          >
            <h2>Welcome to the new ListShack!</h2>
            <ul className="text-left">
              <li>
                <b>Same great data!</b>
              </li>
              <li>
                <b>Same great price!</b>
              </li>
              <li>
                <b>New high usage plans available!</b>
              </li>
              <li>Works with all dialers, CRMs, and spreadsheet software</li>
              <li>Unused credits roll over</li>
              <li>No extra charge for filters</li>
              <li>Instant access</li>
              <li>No contract</li>
              <li>Cancel anytime</li>
              <li>Over 200 million consumers</li>
              <li>Over 14 million businesses</li>
            </ul>
            <br />
            <Row>
              <div className="text-left col-6">
                <strong>Consumer Filters</strong>
                <ul>
                  <li>Age</li>
                  <li>Month of Birth</li>
                  <li>Estimated Income</li>
                  <li>Homeowner Status</li>
                  <li>Marital Status</li>
                  <li>Gender</li>
                  <li>Property Type</li>
                  <li>Property Value</li>
                  <li>Year Built</li>
                  <li>Purchase Year</li>
                  <li>Purchase Month</li>
                  <li>Credit Rating</li>
                  <li>Net Worth</li>
                  <li>State</li>
                  <li>Area Code</li>
                  <li>County</li>
                  <li>City</li>
                  <li>Zip</li>
                </ul>
                <br />
              </div>
              <div className="text-left col-6">
                <strong>Business Filters </strong>
                <ul>
                  <li>Industry Type</li>
                  <li>Number of Employees</li>
                  <li>Sales Volume</li>
                  <li>State</li>
                  <li>Area Code</li>
                  <li>County</li>
                  <li>City</li>
                  <li>Zip</li>
                </ul>
              </div>
            </Row>
          </Col>
          <Col
            xs={12}
            md={4}
            className="text-center"
            style={{ padding: "5px" }}
          >
            <Card>
              <Card.Header>Ready to get started?</Card.Header>
              <Card.Body>
                <Card.Title className="">Plan</Card.Title>
                <span>{plans[plan]["shortname"]}</span>
                <br />
                <span className="text-muted">{plans[plan]["description"]}</span>
                <br />
                <span
                  className="text-left"
                  style={{ textDecoration: "underline" }}
                >
                  Price
                </span>
                <br />
                <span style={{ fontSize: "1.6em" }}>
                  ${plans[plan]["price"] / 100}
                </span>
                <br />
                <span>per month</span>
                <br />
                <br />

                <Link to="/signup" className="btn btn-primary">
                  Subscribe now
                </Link>
                <br />
                <Link
                  to="/compare_plans"
                  className="btn btn-link btn-sm text-muted"
                >
                  Compare plans
                </Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

Pricing.propTypes = {
  user: PropTypes.object,
  plans: PropTypes.object.isRequired,
  selectPlan: PropTypes.func.isRequired
};

export default Pricing;
