import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { firebaseApp } from "./firebase.jsx";
import { Form, Button } from "react-bootstrap";

class ChangePass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accountEmail: "",
      wasChanged: false,
      curPass: "",
      newPass: "",
      conPass: "",
      isAuthenticated: false
    };
    //console.log(this.props);
    this.reauthenticate = this.reauthenticate.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  handleInputChange(e) {
    const target = e.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    //console.log(name, ": ", value);

    this.setState({
      [name]: value
    });
  }

  async reauthenticate(e) {
    e.preventDefault();
    let user = firebaseApp.auth();
    try {
      await user.signInWithEmailAndPassword(
        this.state.accountEmail,
        this.state.currPass
      );
      console.log(`Successfully reauthenticated`);
      this.props.handleAlerts(e, `You're in! Nice work. :)`, "success");
      await this.setState({ isAuthenticated: true, newEmail: "" });
      if (this.props.handleIsAuthenticated) {
        await this.props.handleIsAuthenticated(e, true);
      }
    } catch (error) {
      console.log("Uh oh, that didn't work :(", error);
      this.props.handleAlerts(
        e,
        `Uh oh, that didn't work :( ${console.message}`,
        "warning"
      );
    }
  }

  async changePassword(e) {
    e.preventDefault();
    let user = firebaseApp.auth().currentUser;
    try {
      await user.updatePassword(this.state.conPass);
      console.log("Password updated successfully");
      this.props.handleAlerts(e, "Password updated successfully!", "success");
      await this.setState({ currPass: "", newPass: "", wasChanged: true });
    } catch (error) {
      console.log("Password was not updated: ", error);
      this.props.handleAlerts(
        e,
        `Password was not updated: ${error.message}`,
        "warning"
      );
      await this.setState({ currPass: "", newPass: "" });
    }
  }

  render() {
    if (!this.state.wasChanged) {
      let newPass = this.state.newPass;
      let conPass = this.state.conPass;
      let goodEmail =
        (this.state.accountEmail !== "" &&
          this.state.accountEmail.includes("@")) ||
        this.props.user.email !== null;
      //console.log("goodEmail: ", goodEmail);

      return (
        <Form
          style={{
            maxWidth: "410px",
            margin: "0 auto",
            padding: "10px",
            paddingTop: "15%"
          }}
        >
          <h3>Change password</h3>
          {!this.state.isAuthenticated && (
            <React.Fragment>
              {!this.props.user && (
                <React.Fragment>
                  <Form.Group>
                    <Form.Label>Current account email</Form.Label>
                    <Form.Control
                      type="email"
                      name="accountEmail"
                      value={this.state.accountEmail}
                      onChange={this.handleInputChange}
                    />
                  </Form.Group>

                  {goodEmail && (
                    <p className="text-success">Email looks good.</p>
                  )}
                  {!goodEmail && (
                    <p className="text-danger">
                      Uh oh, that doesn't look like an email address.
                    </p>
                  )}
                </React.Fragment>
              )}
              <Form.Group>
                <Form.Label>Current password</Form.Label>
                <Form.Control
                  type="password"
                  name="currPass"
                  placeholder="Enter your current password"
                  value={this.state.currPass}
                  onChange={this.handleInputChange}
                />
                <br />
                <Button
                  variant="secondary"
                  disabled={!(this.state.currPass !== "" && goodEmail)}
                  onClick={this.reauthenticate}
                >
                  Submit
                </Button>
              </Form.Group>
            </React.Fragment>
          )}
          {this.state.isAuthenticated && (
            <Form.Group>
              <Form.Label>New account password</Form.Label>

              <Form.Control
                name="newPass"
                type="password"
                placeholder="New password"
                value={this.state.newPass}
                onChange={this.handleInputChange}
              />

              <Form.Control
                name="conPass"
                type="password"
                placeholder="Confirm password"
                value={this.state.conPass}
                onChange={this.handleInputChange}
              />
              {conPass === newPass && newPass !== "" && (
                <p className="text-success">Yay, your passwords match :)</p>
              )}
              {newPass !== "" && conPass !== newPass && (
                <p className="text-success">
                  Uh oh, your passwords don't match :(
                </p>
              )}
              <br />
              <Button
                variant="secondary"
                disabled={!(conPass === newPass && newPass !== "")}
                onClick={this.changePassword}
              >
                Change password
              </Button>
            </Form.Group>
          )}
        </Form>
      );
    } else {
      return (
        <Redirect
          to={{
            pathname: "/search"
          }}
        />
      );
    }
  }

  componentDidMount() {
    if (this.props.noDistract) {
      this.props.handleState({ noDistract: true });
    }
    if (this.props.isAuthenticated) {
      this.setState({ isAuthenticated: this.props.isAuthenticated });
    }
    if (this.props.user) {
      this.setState({ accountEmail: this.props.user.email });
    }
  }

  componentWillUnmount() {
    if (this.props.noDistract) {
      this.props.handleState({ noDistract: false });
    }
  }
}

ChangePass.propTypes = {
  user: PropTypes.object,
  noDistract: PropTypes.bool,
  handleState: PropTypes.func,
  handleAlerts: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  handleIsAuthenticated: PropTypes.func
};

export default ChangePass;
