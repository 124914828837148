import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "./scrollToTop.jsx";
import { Container, Row, Col } from "react-bootstrap"
import Routes from "./routes_lsstatic";
import { Topnav, Secondnav, Footer } from "./interfaceLsStatic";

const App = props => {
  const {isApp} = props;
  return(
    <Router>
      <ScrollToTop>
        <Topnav
					user={null}
					isApp={isApp}
				/>
        <Secondnav />
        <Container fluid id="appListshack" style={{padding: "0px"}}>
          <Row noGutters={true} style={{minHeight: "100vh"}}>
            <Col>
							<Routes {...props} user={null} />
            </Col>
          </Row>
        </Container>
        <Footer {...props} />
      </ScrollToTop>
    </Router>
  )
}

export default App;
