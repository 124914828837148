import React, { useEffect, useState } from "react";
import {
  Link,
  NavLink,
  Redirect,
  useParams,
  Switch,
  Route,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  CardDeck,
  Card,
  Jumbotron,
  Form,
  Table,
  Modal,
  ListGroup,
} from "react-bootstrap";
import { ArrowLeft, ArrowRight } from "react-bootstrap-icons";
import { CSVLink } from "react-csv";

import logo from "./media/logo_list_shack.png";
import hero_img from "./media/954.jpg";
import hcol1 from "./media/h_col1.png";
import hcol2 from "./media/h_col2.png";
import hcol3 from "./media/h_col3.png";
import VideoDemo from "./videoDemo.jsx";
import bleft from "./media/h_bottom_left.png";
import bright from "./media/h_bottom_right_t.png";
import cards from "./media/card_logos.png";

import bizLeadsImg from "./media/business_sales_leads.jpg";
import conLeadsImg from "./media/consumer_sales_leads.jpg";
import serverStatus from "./media/server_status.svg";
import webSearch from "./media/web_search.svg";
import filter from "./media/filter.svg";
import spreadsheet from "./media/spreadsheet.svg";
import progressTracking from "./media/progress_tracking.svg";
import mapDark from "./media/map_dark.svg";
import cityLife from "./media/city_life.svg";
import mapLight from "./media/map.svg";
import prioritize from "./media/prioritize.svg";
import pinLocation from "./media/pin_location.svg";
import locationSearch from "./media/location_search.svg";
import spreadsheets from "./media/spreadsheets.svg";
import search from "./media/search.svg";
import mouseArrow from "./media/mouse_arrow.svg";
import savings from "./media/savings.svg";
import smartphone from "./media/smartphone.svg";

import { useScript } from "./interfaceListShackPro";

const Topnav = (props) => {
  let { user, loaded, isApp } = props;

  return (
    <Navbar expand="lg">
      <Container>
        <Navbar.Brand>
          <Link to="/">
            <img src={logo} alt={process.env.REACT_APP_site_name} />
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse>
          <Nav className="mr-auto">
            <Nav.Item className="navbar-text">
              <Nav.Link href="tel:833-222-7919">
                <i className="fa fa-phone"></i> 833-222-7919
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="navbar-text mr-auto">
              <Link to="/help/" className="nav-link">
                <i className="fa fa-question-circle"></i> Help
              </Link>
            </Nav.Item>
          </Nav>
          <Nav className="">
            <Nav.Item>
              <a href="https://www.listshack.support/login">
                <Button variant="primary">Member login</Button>
              </a>
            </Nav.Item>
          </Nav>
          <div className="d-block d-md-none">
            <NavLinks />
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const Secondnav = (props) => {
  return (
    <Navbar
      expand="md"
      variant="dark"
      className="bg-info d-none d-md-block"
      style={{ height: "50px" }}
      id="nav2"
    >
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse>
        <NavLinks />
      </Navbar.Collapse>
    </Navbar>
  );
};

const NavLinks = props => {
  return(
    <React.Fragment>
        <NavDropdown
          title="Databases"
          id="basic-nav-dropdown"
          style={{ zIndex: "1030" }}
        >
          <Link to="/consumer-databases/" className="dropdown-item text-dark">
            US Consumers
          </Link>
          <Link to="/business-databases/" className="dropdown-item text-dark">
            US Businesses
          </Link>
          <Link to="/vin-databases/" className="dropdown-item text-dark">
            US Vehicle Owners
          </Link>
          <NavDropdown.Divider />
          <Link to="/databases/" className="dropdown-item text-dark">
            View all
          </Link>
        </NavDropdown>
        <NavDropdown
          title="List Products"
          id="basic-nav-dropdown2"
          style={{ zIndex: "1029" }}
        >
          <Link to="/consumer-sales-leads/" className="dropdown-item text-dark">
            Consumer Sales Leads
          </Link>
          <Link to="/business-sales-leads/" className="dropdown-item text-dark">
            Business Sales Leads
          </Link>
          {/*
          <Link className="dropdown-item text-dark">
            Telemarketing Lists
          </Link>
          <Link className="dropdown-item text-dark">
            Mailing Lists
          </Link>
          <Link className="dropdown-item text-dark">
            Email Lists
          </Link>
          <Link className="dropdown-item text-dark">
            Text Messaging Lists
          </Link>
          */}
        </NavDropdown>
        <NavDropdown
          title="Email Marketing"
          id="basic-nav-dropdown1"
          style={{ zIndex: "1028" }}
        >
          <Link
            to="/databases/consumerJan2020/"
            className="dropdown-item text-dark"
          >
            Consumer Email Lists
          </Link>
          <Link to="/databases/bizEmails/" className="dropdown-item text-dark">
            Business Email Lists
          </Link>
          <NavDropdown.Divider />
          <Link to="/email-databases/" className="dropdown-item text-dark">
            View all email
          </Link>
        </NavDropdown>
        <NavDropdown
          title="Search Tools"
          id="basic-nav-dropdown3"
          style={{ zIndex: "1027" }}
        >
          <Link to="/geography-search/" className="dropdown-item text-dark">
            Geography Search
          </Link>
          <Link to="/map-search/" className="dropdown-item text-dark">
            Map Search
          </Link>
          {/*
          <Link className="dropdown-item text-dark">
            Directory Search
          </Link>
          */}
          <NavDropdown.Divider />
          <Link to="/search-tools/" className="dropdown-item text-dark">
            View all search tools
          </Link>
        </NavDropdown>
        <Nav.Item>
          <Link className="nav-link" to="/listcrm/">
            ListCrm
          </Link>
        </Nav.Item>
        {/*
        <Nav.Item>
          <Link className="nav-link">
            Data Enrichment
          </Link>
        </Nav.Item>
        */}
        <Nav.Item>
          <Link className="nav-link" to="/resources/">
            Resources
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link className="nav-link" to="/faq/">
            FAQ
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link className="nav-link" to="/compare_plans/">
            Pricing
          </Link>
        </Nav.Item>
    </React.Fragment>
  )
}

const Footer = (props) => {
  let { appInterface } = props;
  return (
    <footer id="footer" className="bg-dark" style={{ padding: "50px 0px" }}>
      <Container>
        <Row>
          <Col>
            <a
              className="btn btn-link text-white"
              href={`mailto: ${process.env.REACT_APP_contact_email}`}
              style={{ paddingLeft: "0px" }}
            >
              <i className="fa fa-envelope"></i>{" "}
              {process.env.REACT_APP_contact_email}
            </a>
            <br />
            <a
              className="btn btn-link text-white"
              style={{ paddingLeft: "0px" }}
              href="tel: 833-222-7919"
            >
              <i className="fa fa-phone"></i> 833-222-7919
            </a>
            <p className="text-white">
              <Link className="text-white" to="/privacy/">
                Privacy policy
              </Link>{" "}
              |{" "}
              <Link className="text-white" to="/terms/">
                Terms of service
              </Link>{" "}
              |{" "}
              <Link className="text-white" to="/ccpa/">
                CCPA
              </Link>
            </p>
            <a className="text-white" href={"/sitemapLsStatic.txt"}>
              Site map
            </a>
          </Col>
          <Col>
            <p className="text-white">All major credit cards accepted</p>
            <img
              alt="Visa, Discover, MasterCard, and American Express"
              src={cards}
              width="300"
              height="auto"
            />
            <hr />
            <Link to="/ccpa_optout/" className="text-white">
              <small>Do Not Sell My Personal Information</small>
            </Link>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

const SubscribeInfo = (props) => {
  return (
    <Container>
      <Row style={{ height: "40vh" }}>
        <Col className="d-flex flex-column justify-content-center align-items-center text-center">
          <Container>
            <Row>
              <Col>
                <h2>Access our exclusive pricing.</h2>
                <p className="lead">We are the best deal in town, bar none.</p>
                <Link to="/compare_plans/" className="text-dark">
                  <h4>
                    <u>Compare our prices</u>
                  </h4>
                </Link>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row style={{ height: "40vh" }}>
        <Col className="d-flex flex-column justify-content-center align-items-center text-center">
          <Container>
            <Row>
              <Col>
                <h2>Become a member.</h2>
                <p className="lead">
                  We pick and choose who we work with. Sign up for a free trial
                  and schedule your 1-time member verification.
                </p>
                <Link to="/who-we-work-with/" className="text-dark">
                  <h4>
                    <u>Who we work with</u>
                  </h4>
                </Link>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row style={{ height: "40vh" }}>
        <Col className="d-flex flex-column justify-content-center align-items-center text-center">
          <Container>
            <Row>
              <Col>
                <h2>More questions? Say Hello.</h2>
                <p className="lead">
                  Read our <Link to="/faq/">FAQs</Link>, Check out our{" "}
                  <a
                    href="https://www.listshack.support/support"
                    target="_blank"
                  >
                    Support Center
                  </a>
                  , find a useful <Link to="/resources/">resource</Link>, or
                  chat with us below.
                </p>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <Row>
        <Col className="py-5 d-flex flex-column align-items-center justify-content-center">
          <Container>
            <Row>
              <Col>
                <Jumbotron className="text-center">
                  <a
                    className="btn btn-primary btn-lg"
                    href="https://www.listshack.support/signup"
                  >
                    Get Started For Free
                  </a>
                  <p className="lead mt-3">
                    Then schedule your 1-time membership verification for
                    unlimited access.
                  </p>
                </Jumbotron>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

const Home = (props) => {
  return (
    <Container fluid>
      <Row
        className="bg-dark text-white text-center text-dark d-flex justify-content-center align-items-middle"
        style={{
          backgroundImage: `url(${hero_img})`,
          height: "50vh",
          backgroundPosition: "top",
          backgroundSize: "cover",
        }}
      >
        <Jumbotron
          style={{
            backgroundColor: "rgb(0,0,0,.6)",
            height: "100%",
            width: "100%",
          }}
          className="d-flex justify-content-center align-items-center flex-column text-white"
          fluid
        >
          <h1 style={{ fontSize: "28px", maxWidth: "600px" }}>
            The home of{" "}
            <u>
              <i>Unlimited</i>
            </u>{" "}
            sales leads
          </h1>
          <p className="lead mb-5">Starting at $50 per month</p>
          <div>
            <a
              className="btn btn-primary btn-lg"
              href="https://www.listshack.support/signup"
            >
              Start Free Trial
            </a>
          </div>
        </Jumbotron>
      </Row>
      <Row
        //style={{ height: "455vh" }}
        className="mt-5 pb-0 mb-0"
      >
        <Col
          className="text-center p-5 d-flex flex-row justify-content-center"
          xs={12}
        >
          <div
            className="sticky-top d-flex flex-column justify-content-center align-items-center text-center"
            //style={{ height: "75vh", maxWidth: "800px" }}
            style={{ maxWidth: "800px" }}
          >
            <h1 className="mb-5 text-primary text-center">
              Our mission is to help sales people, marketers, and small business
              owners get more clients
            </h1>
          </div>
        </Col>
        <Col
          className="border-left text-center py-5 d-flex flex-column justify-content-between bg-light"
          xs={12}
        >
          <h2 className="text-center">We help by providing</h2>
          <Row className="justify-content-center">
            {[
              {
                text: "A comprehensive database of sales leads.",
                subtext: "Available 24 hours a day",
                cta: "View our databases",
                ctaLink: "/databases/",
                img: serverStatus,
              },
              {
                text: "Easy to use search tools.",
                subtext:
                  "ListShack makes lists easy with its amazing search tools",
                cta: "See our search tools",
                ctaLink: "/search-tools/",
                img: webSearch,
              },
              {
                text: "All the filters you need.",
                subtext: "Target your ideal customer.",
                cta: "Check our filters",
                ctaLink: "/search-filters/",
                img: filter,
              },
              {
                text: "The only CRM available for managing a marketing list.",
                subtext: "Scrub duplicates, append, and enhance.",
                cta: "Use ListCrm for Free",
                ctaLink: "/listcrm/",
                img: progressTracking,
                visible: true,
              },
              {
                text: "Formatted marketing lists.",
                subtext:
                  "Download CSV files for telemarketing, direct mail, or email campaigns.",
                cta: "See sample list",
                ctaLink: "/sample-list/",
                img: spreadsheet,
              },
            ].map((s, i) => {
              if (s.visible === false) {
                return null;
              }
              return (
                <Col
                  className="p-2 border rounded"
                  //style={
                  //  i === 0
                  //    ? { marginTop: "10vh", marginBottom: "250px" }
                  //    : i === 4
                  //    ? { paddingBottom: "20vh" }
                  //    : { marginBottom: "250px" }
                  //}
                  xs={12}
                  sm={6}
                  md={4}
                >
                  <img src={s.img} width="200px" height="auto" />
                  <h2>{s.text}</h2>
                  <p className="lead">{s.subtext}</p>
                  <Link to={s.ctaLink}>{s.cta}</Link>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col
          className="mb-5 bg-dark d-flex flex-row align-items-center justify-content-around"
          style={{ paddingTop: "100px", paddingBottom: "100px" }}
        >
          <Card className="bg-dark text-center border-0 py-5">
            <Card.Body>
              <span className="text-light">Customers</span>
              <br />
              <span className="text-info" style={{ fontSize: "40px" }}>
                2.4k+
              </span>
              <br />
              <span className="text-light">served</span>
            </Card.Body>
          </Card>
          <Card className="bg-dark text-center border-0 py-5">
            <Card.Body>
              <span className="text-light">Records</span>
              <br />
              <span className="text-info" style={{ fontSize: "40px" }}>
                451mm+
              </span>
              <br />
              <span className="text-light">available</span>
            </Card.Body>
          </Card>
          <Card className="bg-dark text-center border-0 py-5">
            <Card.Body>
              <span className="text-light">Leads downloaded</span>
              <br />
              <span className="text-info" style={{ fontSize: "40px" }}>
                9mm+
              </span>
              <br />
              <span className="text-light">each month</span>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col className="py-5 d-flex flex-column align-items-center justify-content-center">
          <Container>
            <Row>
              <Col>
                <Jumbotron className="text-center">
                  <h1 className="text-primary">
                    Don't just take our word for it.
                    <br />
                    The proof is in the product.
                  </h1>
                  <Link to="/watch-demo/" className="text-dark">
                    <h4>
                      <u>Watch a demo</u>
                    </h4>
                  </Link>
                </Jumbotron>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
      <SubscribeInfo />
      <Row
        className="d-flex justify-content-center align-items-middle flex-column text-center bg-light"
        style={{ padding: "100px 0px" }}
      >
        <Container>
          <Row>
            <Col className="d-flex justify-content-around align-items-middle flex-row">
              <p className="lead text-dark text-left">
                <small>Dear Customer,</small>
                <br /> <br />
                Welcome to {process.env.REACT_APP_site_name}. Our mission is
                simple: to help salespeople, marketers, and small businesses get
                more clients. We do this by providing a comprehensive database
                of sales leads available 24 hours a day, seven days a week, with
                the <Link to="/search-filters/"> filters</Link> you need. We
                have consumer, business, and vehicle owner leads. Check out all
                of our databases <Link to="/databases/">here</Link>. You can
                search by state, county, zip code, or area code. Your sales
                leads can be used as a telemarketing list or mailing list
                depending on the criteria that you select.
                <br /> <br />
                We recently added the ability to{" "}
                <Link to="/email-databases/">search for emails</Link> and to{" "}
                <Link to="/map-search/">search on a map</Link>, just as if you
                were searching for your favorite restaurant or looking up a
                friends address on Google Maps.
                <br /> <br />
                Thanks to our wonderful members who share their experience using
                our service with their friends and colleagues. We've added a
                full service{" "}
                <Link to="/listcrm/">CRM</Link>{" "}
                that will allow you to track your leads and
                customers all the way through your sales process. And we'll soon
                be adding a directory search and unlimited B2B leads.
                <br /> <br />
                We save you time so you can focus on selling.
                <br /> <br />
                <small>
                  <i>The {process.env.REACT_APP_site_name} Team</i>
                </small>
              </p>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

const BizLeads = () => {
  return (
    <Container>
      <Row>
        <Col xs={12} md={8} style={{ color: "#666e70", marginTop: "30px" }}>
          <h1>Business sales leads</h1>
          <h3>Business Leads</h3>
          <p>
            Get your customized list of business leads today. We have great
            filters and you can download everything with plans starting at
            $50/month. No need to play phone tag with a list broker; sign up
            using our online checkout system and start creating a list just
            right for your needs. Our user-friendly website gives you a
            hassle-free and affordable option without any contract or additional
            fees. &nbsp;Create your custom business leads list using our easy
            online system. We save you time so you can focus on selling!
          </p>
          <h3>Customize Your List</h3>
          <p>
            Use our filters to choose the exact search criteria you need from
            our database of over 14 million businesses. Records include name of
            the business, phone number, owner information (where available),
            address, sales volume, SIC code, and web address (where available).
          </p>
          <h3>Filters</h3>
          <ul>
            <li>Search Industry by SIC Code</li>
            <li>Number of Employees</li>
            <li>Sales Volume</li>
            <li>Years in business</li>
            <li>Square Footage</li>
            <li>State</li>
            <li>County</li>
            <li>Zip Code</li>
            <li>Area Code</li>
          </ul>
          <p>
            <strong>Ideas for Using Business Leads</strong>
          </p>
          <ul>
            <li>
              Create a list of small businesses with 1-9 employees to sell small
              group and individual health insurance policies
            </li>
            <li>
              Create a list of businesses in your area to sell merchant
              processing services
            </li>
            <li>
              Create a list targeted by industry to provide janitorial services
            </li>
            <li>Many, many more!</li>
          </ul>
        </Col>
        <Col xs={12} md={4} className="text-center">
          <img
            src={bizLeadsImg}
            alt="business sales leads"
            className="img-fluid"
          />
          <h4>Sales Leads starting at $50 per month</h4>
          <a
            className="btn btn-primary btn-sm"
            href="https://www.listshack.support/signup"
          >
            Sign up now
          </a>
          <VideoDemo />
        </Col>
      </Row>
    </Container>
  );
};

const ConLeads = () => {
  return (
    <Container>
      <Row style={{ marginTop: "30px" }}>
        <Col xs={12} md={8} style={{ color: "#666e70" }}>
          <h1>Consumer sales leads</h1>
          <h3>Consumer Leads</h3>
          <p>
            Get your customized list of consumer leads today. With great filters
            and no add-on fees. you can download everything with plans starting
            at $50/month. No need to play phone tag with a list broker; sign up
            using our online checkout system and start creating a list just
            right for your needs. Our user-friendly website gives you a
            hassle-free and affordable option without any contract or surprises.
          </p>
          <h3>Customize Your List</h3>
          <p>
            Use our filters to choose the exact search criteria you need from
            our database of over 200 million consumers. Records include first
            name, last name, phone number, address, county, age, and estimated
            income.
          </p>
          <h3>Filters</h3>
          <ul>
            <li>Age</li>
            <li>Year and month born</li>
            <li>Estimated income range</li>
            <li>Homeowner status</li>
            <li>Marital status</li>
            <li>Gender</li>
            <li>Property value</li>
            <li>Year built</li>
            <li>Purchase year</li>
            <li>Networth</li>
            <li>Credit rating</li>
            <li>State</li>
            <li>County</li>
            <li>City</li>
            <li>Zip code</li>
            <li>Area code</li>
          </ul>
          <p>
            <strong>Ideas for Using Consumer Leads</strong>
          </p>
          <ul>
            <li>
              Create a list of consumers 65-78 with incomes over $15k/year for
              outbound telemarketing campaigns to sell senior insurance products
              such as Medicare, LTC, and burial insurance.
            </li>
            <li>
              Create a list of homeowners ages with incomes over $75k/year to
              sell custom home remodelling projects
            </li>
            <li>
              Create a list of credit scored homeowners to sell solar panels
            </li>
            <li>Many, many more!</li>
          </ul>
        </Col>
        <Col xs={12} md={4} className="text-center">
          <img
            src={conLeadsImg}
            alt="business sales leads"
            className="img-fluid"
          />
          <h4>Sales Leads starting at $50 per month</h4>
          <a
            className="btn btn-primary btn-sm"
            href="https://www.listshack.support/signup"
          >
            Sign up now
          </a>
          <VideoDemo />
        </Col>
      </Row>
    </Container>
  );
};

const ComparePlansTable = (props) => {
  console.log("ComparePlansTable props:", props);
  const { plans, selectPlan, user } = props;
  return (
    <Row>
      <Col
        id="pricing"
        xs={12}
        style={{ padding: "15px" }}
        className="text-center d-flex flex-column align-items-center align-content-center justify-content-center"
      >
        {props.title}
        <Table responsive hover className="text-left mt-5 mb-5">
          <thead>
            <tr>
              <th>Feature</th>
              {Object.values(plans).map((plan, index) => (
                <th
                  key={`cpt_th_${index}`}
                  className={!plan.public ? "d-none" : null}
                >
                  <span className="planName">{plan.name}</span>
                  <br />
                  <span className="price">${plan.price / 100}</span>
                  <br />
                  <span className="period">per month</span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Consumer Leads per month</td>
              {Object.values(plans).map((plan, index) => (
                <td
                  key={`cpt_lpm_${index}`}
                  className={!plan.public ? "d-none" : null}
                >
                  {plan.leads.toLocaleString()}
                </td>
              ))}
            </tr>
            <tr>
              <td>Business Leads per month</td>
              {Object.values(plans).map((plan, index) => (
                <td
                  key={`cpt_lpm_${index}`}
                  className={!plan.public ? "d-none" : null}
                >
                  Unlimited
                </td>
              ))}
            </tr>
            <tr>
              <td>Download limit</td>
              {Object.values(plans).map((plan, index) => (
                <td
                  key={`cpt_dl_${index}`}
                  className={!plan.public ? "d-none" : null}
                >
                  {plan.downloadLimit.toLocaleString()}
                </td>
              ))}
            </tr>
            <tr>
              <td>Team member logins</td>
              {Object.values(plans).map((plan, index) => (
                <td
                  key={`cpt_tml_${index}`}
                  className={!plan.public ? "d-none" : null}
                >
                  {plan.users.toLocaleString()}
                </td>
              ))}
            </tr>
            <tr>
              <td>Search suppressions</td>
              {Object.values(plans).map((plan, index) => (
                <td
                  key={`cpt_ss_${index}`}
                  className={!plan.public ? "d-none" : null}
                >
                  {!plan.searchSuppressions ? (
                    <i className="fa fa-times" aria-hidden="true"></i>
                  ) : (
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    ></i>
                  )}
                </td>
              ))}
            </tr>
            <tr>
              <td>Leads Rollover Each Month</td>
              {Object.values(plans).map((plan, index) => (
                <td
                  key={`cpt_lr_${index}`}
                  className={!plan.public ? "d-none" : null}
                >
                  {!plan.includesRollover ? (
                    <i className="fa fa-times" aria-hidden="true"></i>
                  ) : (
                    <i
                      className="fa fa-check text-success"
                      aria-hidden="true"
                    ></i>
                  )}
                </td>
              ))}
            </tr>
            <tr>
              <td>&nbsp;</td>
              {Object.values(plans).map((plan, index) => (
                <td className={!plan.public ? "d-none" : null}>
                  <Link
                    to={!user ? "/signup" : "/settings/billing"}
                    key={`cpt_btn_${index}`}
                    onClick={(e) => {
                      selectPlan(e, index);
                    }}
                    className={
                      !plan.public ? "d-none" : "btn btn-outline-primary"
                    }
                  >
                    Signup
                  </Link>
                </td>
              ))}
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

const Contact = () => {
  return (
    <Container>
      <Row style={{ marginTop: "30px", minHeight: "75vh" }}>
        <Col xs={12} md={6}>
          <h2>Contact us</h2>
          <p>We would love to hear from you!</p>
          <a href="tel:833-222-7919" style={{ fontSize: "1.5em" }}>
            <i className="fa fa-phone"></i> 833-222-7919
          </a>
          <br />
          <br />
          <a
            href={`mailto: ${process.env.REACT_APP_contact_email}`}
            style={{ fontSize: "1.8em" }}
          >
            <i className="fa fa-envelope"></i>{" "}
            {`${process.env.REACT_APP_contact_email}`}
          </a>
          <p>Midvale, UT 84047</p>
        </Col>
      </Row>
    </Container>
  );
};

const Terms = () => {
  return (
    <Container>
      <Row style={{ marginTop: "30px" }}>
        <h1>Terms of Service</h1>
        <p>
          By using the {process.env.REACT_APP_site_name} (“Company”) website
          (“Site”) you agree to the terms and conditions in this agreement.{" "}
          {process.env.REACT_APP_site_name} subscribers, new customers (“you”)
          are subject to these Terms &amp; Conditions.
        </p>
        <h6>1. Allowed uses and restrictions</h6>
        <p>
          Upon payment in full for the services,{" "}
          {process.env.REACT_APP_site_name} grants to the client: a permanent;
          non-exclusive; non-transferable; and non-sublicenceable, license to
          use, edit, modify, copy, distribute and reproduce the intellectual
          property for the client’s own business use.
        </p>

        <p>Clarifications:</p>
        <ul>
          <li>
            You may use the marketing records for legally permissible marketing
            purposes. This includes telemarketing, door knocking, and mailing
            subject to any federal, state, or local laws.
          </li>
          <li>
            You may retain a copy of all records downloaded with unlimited usage
            rights. You may not utilize any type of scraping or data mining
            software or services for the purpose of extracting records from the
            database.
          </li>
          <li>
            You may not sell, license, dispense, or in any other way distribute
            any part of the database to any third party, or use Data in
            establishing an individual’s credit worthiness or eligibility for
            credit, insurance or employment. If you are interested in reselling
            our data please contact sales about our reseller program.
          </li>
          <li>
            You agree to comply with all applicable federal, state, foreign and
            local statutes and regulations, including, but not limited to the
            CAN-SPAM Act and the National Do Not Call Registry. You agree to all
            indemnification clauses stated in this agreement.
          </li>
          <li>
            You may not share your account information with any other users. If
            your account is suspected of abuse it may be suspended or cancelled
            without refund. This determination is at the sole discretion of{" "}
            {process.env.REACT_APP_site_name} and is not subject to appeal.
          </li>
          <li>
            In the event a subscriber is found to be reselling data without a
            valid reseller agreement the subscriber agrees to be liable for
            liquidated damages of $500 per list and an additional five cents per
            record. This is completely avoidable by contacting sales to get
            reseller rights. The terms of the reseller agreement may change at
            any time, with or without notice. Resellers will receive
            notifications about any changes to their terms of service by email
            notification to the email account listed with their registration.
          </li>
        </ul>
        <h6>2. Liability Limitation and Warranty Information</h6>

        <p>
          All data is believed to be accurate as it’s published. The information
          is provided “AS IS”. For the purpose of consumer data it is the
          customers obligation to ensure compliance with Telephone Consumer
          Protection Act (TCPA) rules including compliance with the DNC
          registry. Data is scrubbed against the DNC approximately every two
          weeks, but customers are encouraged to get a free SAN at
          https://telemarketing.donotcall.gov/ and use our online tool to
          maintain compliance. If you require assistance with this process you
          can take advantage of our paid support by emailing us.
        </p>

        <p>
          Note: The courts have ruled the list buyers cannot be legally
          protected from fines or court actions based off the compliance a list
          broker provides. In fact, any marketer can be held liable for
          violating the DNC laws even if they are only calling numbers not
          listed on the DNC
          https://business.ftc.gov/documents/alt129-qa-telemarketers-sellers-about-dnc-provisions-tsr#Compliance.
          For more information regarding this please review
          https://business.ftc.gov/documents/alt129-qa-telemarketers-sellers-about-dnc-provisions-tsr.
        </p>

        <h6>4. Fair Usage and Right to Refuse Service</h6>
        <p>
          Our unlimited plans are for individuals subject to a fair usage
          policy. If you plan on downloading in excess of 50,000 records per
          month please give us a call or email{" "}
          {process.env.REACT_APP_contact_email} and ask about plan enhancements
          to avoid having your account blocked.
        </p>

        <h6>5. Email and Text Message Communications</h6>
        <p>
          If you provide the Site your email address or phone number, we may
          send you administrative messages (such as updated notifications,
          newsletters, and other news) relating to the Services or to respond to
          communications from you. By using our Services and/or not opting out
          of receiving information from Us, you acknowledge and agree that you
          may receive email or text messages on your mobile device from Us or
          other users of the Services. Receiving these messages may cause you to
          incur usage charges or other fees or costs in accordance with your
          wireless or data service plan. Any and all such charges, fees, or
          costs are your sole responsibility. You should consult with your
          wireless carrier to determine what rates, charges, fees, or costs may
          apply. In the event you change or deactivate your mobile telephone
          number, you will update your account information within 48 hours to
          ensure that your messages are not sent to the person who acquires your
          old number.
        </p>

        <h6>6. Cancellation &amp; Refunds</h6>

        <p>
          You can cancel your subscription at any time. All subscriptions are
          non-refundable and no pro-rated refunds will be given. Please be sure
          to cancel at least three business days before the end of your billing
          cycle. Once you cancel you will immediately lose access to your
          subscription. Cancellations must be made online via the member
          dashboard. This refund policy extends to any payments made for
          increased download limits.
        </p>

        <h6>7. Governing Law; Jurisdiction</h6>

        <p>
          The Agreement shall be governed by and construed under the laws of the
          State of Utah, without regard for the principles of conflicts of law
          of that State or any other state. Any litigation or other dispute
          relating to or arising under the Agreement shall only be brought in
          the Utah County District Court of Provo, Utah.
        </p>

        <h6>8. Indemnification</h6>

        <p>
          You agree to be responsible for any failure by any of your
          representatives to comply with any of the provisions of this
          agreement. You agree to indemnify the company and its representatives
          and save them fully harmless from and against any loss, cost, damage,
          expense or liability suffered or incurred by any of them arising as a
          result of or in connection with any failure by you or any of your
          representatives to comply with any provision of this agreement.
        </p>

        <h6>9. Entire Agreement</h6>

        <p>
          This agreement constitutes the entire agreement between the parties
          and supersedes any prior understanding or representation of any kind
          preceding the date of this agreement. There are no other promises,
          conditions, understandings or other agreements, whether oral or
          written, relating to the subject matter of this agreement. This
          agreement may be modified in writing and any such modifications must
          be signed by both the you and the {process.env.REACT_APP_site_name}.
          Additionally, the terms of this agreement extend to any additional
          services provided by {process.env.REACT_APP_site_name}, including but
          not limited to increased download limits.
        </p>
      </Row>
    </Container>
  );
};

const Privacy = () => {
  return (
    <Container>
      <Row style={{ marginTop: "30px" }}>
        <h1>Privacy policy</h1>
        <p>
          Your privacy is very important to us. Accordingly, we have developed
          this Policy in order for you to understand how we collect, use,
          communicate and disclose and make use of personal information. The
          following outlines our privacy policy.
          <br />
          <br />
          Before or at the time of collecting personal information, we will
          identify the purposes for which information is being collected.
          <br />
          <br />
          We will collect and use of personal information solely with the
          objective of fulfilling those purposes specified by us and for other
          compatible purposes, unless we obtain the consent of the individual
          concerned or as required by law.
          <br />
          <br />
          We will only retain personal information as long as necessary for the
          fulfillment of those purposes.
          <br />
          <br />
          We will collect personal information by lawful and fair means and,
          where appropriate, with the knowledge or consent of the individual
          concerned.
          <br />
          <br />
          Personal data should be relevant to the purposes for which it is to be
          used, and, to the extent necessary for those purposes, should be
          accurate, complete, and up-to-date.
          <br />
          <br />
          We will protect personal information by reasonable security safeguards
          against loss or theft, as well as unauthorized access, disclosure,
          copying, use or modification.
          <br />
          <br />
          We will make readily available to customers information about our
          policies and practices relating to the management of personal
          information.
          <br />
          <br />
          We are committed to conducting our business in accordance with these
          principles in order to ensure that the confidentiality of personal
          information is protected and maintained.
          <br />
          <br />
          Our data is sourced from public information, aggregated, and sorted
          for your convenience. Examples of these would include but are not
          limited to white pages, county records, state records, and similar
          sources.
          <br />
          <br />
          We will never never sell a user's signup information without their
          consent.
        </p>
      </Row>
    </Container>
  );
};

const Faq = () => {
  return (
    <Container>
      <Row style={{ marginTop: "30px" }}>
        <h1>FAQ</h1>
        <section className="article-content">
          <h4>Obtaining sales leads and sales lists has never been easier</h4>
          <p className="ques">What is {process.env.REACT_APP_site_name}?</p>
          <p className="text-muted">
            {process.env.REACT_APP_site_name} is an online digital platform that
            connects business owners, marketers, and salespeople with potential
            prospects. We offer an easy to use interface that allows you to
            target potential customers using geography and filters. Our goal is
            to help you get the right type of sales leads that fit the needs and
            wants of your business or organization.
          </p>
          <p className="ques">What type of lists and filters do you offer?</p>
          <p className="text-muted">
            We offer both targeted calling and mailing lists. We also offer
            email lists.
          </p>
          <p className="text-muted">
            Consumer Filters: &nbsp;Age, Year and Month Born, Estimated Income
            Range, Homeowner, Status, Marital Status, Gender, Property Value,
            Property Type, Year Built, Purchase Year, Net Worth, Credit Rating
          </p>
          <p className="text-muted">
            Business Filters: Search Industry by SIC Code, Number of Employees,
            Sales Volume, Years in business , Square Footage
          </p>
          <p className="text-muted">
            Geography: USA, State, Area Code, County, City, Zip
          </p>
          <p className="text-muted">
            Email filters: Has email, Includes Opt-in URL, Opt-in Date, Email
            service provider.{" "}
            <small>
              Emails are compiled emails, and we recommend performing your own
              opt-in process before adding compiled email lists to your
              marketing campaigns.
            </small>
          </p>
          <p className="ques">How soon can I access the data?</p>
          <p className="text-muted">
            Once you pay for your plan you will have instant access and be able
            to download records immediately. &nbsp;
          </p>
          <p className="ques">What format does the data come in?</p>
          <p className="text-muted">
            Files are downloaded as a CSV which will open in most software
            including Microsoft Excel, Open Office, and every dialer we know
            about.{" "}
          </p>
          <p className="ques">Do I have to download your software?</p>
          <p className="text-muted">
            No. {process.env.REACT_APP_site_name} is an online only platform. No
            software is required to obtain our sales leads. All sales leads come
            straight from our site to your computer.
          </p>
          <p className="ques">Am I required to have a SAN for telemarketing?</p>
          <p className="text-muted">
            Yes! Although we regularly scrub our lists against the DNC, that
            will not protect you from fines or other legal action if you do not
            register for a SAN and comply with the provisions of the TCPA.
            &nbsp;In order to be protected from legal action each caller must
            register for a SAN which can be done for free by visiting{" "}
            <a href="https://telemarketing.donotcall.gov/">
              https://telemarketing.donotcall.gov/
            </a>
            . &nbsp;We cannot protect our customers from litigation or fines
            because the TCPA requires that each marketer must be responsible for
            registering for their own SAN and maintaining an internal DNC policy
            and procedure.
          </p>
          <p className="ques">
            What's the best way to use compiled email lists?
          </p>
          <p className="text-muted">
            We have seen that sending personalized, one-to-one, emails is the
            most effective approach for using emails, and those emails are most
            often successful as a followup when someone talks with your or your
            business over the phone, by text message, or visits your website. A
            cold email, to someone that you've never met is easily ignored.
          </p>
          <p className="ques">Am I required to do an email opt-in campaign?</p>
          <p className="text-muted">
            As long as your emails comply with the{" "}
            <a
              href="https://www.ftc.gov/tips-advice/business-center/guidance/can-spam-act-compliance-guide-business"
              target="_blank"
              rel="noopener noreferrer"
            >
              CAN-SPAM
            </a>{" "}
            regulations, you can send emails to the emails that you download
            from {process.env.REACT_APP_site_name}. However, we highly recommend
            doing opt-in campaigns. The emails we provide are not verified, and
            many email service providers, particularly the largest like GMAIL,
            YAHOO, and OUTLOOK, independently monitor email servers for spam.
          </p>
          <p className="ques">
            Are there any limitations on the unlimited plan?
          </p>
          <p className="text-muted">
            We offer an individual unlimited plan which means we provide an
            unlimited amount of data for an individual user. If you plan on
            using more than 50,000 records per month please contact us for more
            information about our other plans.
          </p>
          <p className="ques">Are there any contracts?</p>
          <p className="text-muted">
            Absolutely not! You’re welcome to cancel your membership at any
            time. Billing is on a monthly subscription basis. You must cancel at
            least three business days before the end of your billing cycle or
            you may be charged.
          </p>
          <p className="ques">Where do you get your data?</p>
          <p className="text-muted">
            We aggregate our data from a variety of public sources as well as
            opt-in databases.
          </p>
          <p className="ques">How often is your data updated?</p>
          <p className="text-muted">
            We run major updates quarterly and minor updates monthly.
          </p>
          <p className="ques">
            What information is included for consumer data?
          </p>
          <p className="text-muted">
            First name, last name, phone number, address, county, age, and
            estimated income.
          </p>
          <p className="ques">
            What information is included for business data?
          </p>
          <p className="text-muted">
            Name of the business, phone number, owner information (where
            available), address, sales volume, SIC code, and web address (where
            available).
          </p>
          <p className="ques">How do you handle cancellations and refunds?</p>
          <p className="text-muted">
            You can cancel your subscription at any time. &nbsp;All
            subscriptions are non-refundable and no pro-rated refunds will be
            given. &nbsp;Please be sure to cancel at least three business days
            before the end of your billing cycle. &nbsp;Once you cancel you will
            still be able to enjoy the benefits of your membership through the
            end of your subscription period. &nbsp;If you do not cancel at least
            three business days prior to your renewal you may be billed and that
            charge will be non-refundable.
          </p>
          <p className="ques">How accurate is your data?</p>
          <p className="text-muted">
            Our data runs at the industry average of about 90%. &nbsp;In some
            areas it may run better or worse, but you should expect to have a
            reasonably accurate list.
          </p>
          <p className="ques">Can I resell this data?</p>
          <p className="text-muted">
            Resale of the data is expressly forbidden. &nbsp;If you are
            interested in becoming a reseller you can contact us for more
            information.{" "}
          </p>
        </section>
      </Row>
    </Container>
  );
};

const DesktopDownload = (props) => {
  let macDownload = process.env.REACT_APP_macDownload;
  let winDownload = process.env.REACT_APP_winDownload;
  return (
    <div className={props.className}>
      <Button
        variant="dark"
        size="lg"
        className="mr-3"
        download={macDownload}
        href={macDownload}
        style={{ minWidth: "250px" }}
      >
        Download for Mac
      </Button>
      <Button
        variant="dark"
        size="lg"
        className="ml-3"
        download={winDownload}
        href={winDownload}
        style={{ minWidth: "250px" }}
      >
        Download for Windows
      </Button>
    </div>
  );
};

const ListShackDesktop = (props) => {
  let { handleIsApp } = props;

  useEffect(() => {
    handleIsApp(false);
  }, [handleIsApp]);

  return (
    <Container fluid>
      <Row
        className="bg-dark text-center text-white d-flex justify-content-center align-items-middle"
        style={{
          backgroundImage: `url(${hero_img})`,
          height: "60vh",
          backgroundPosition: "top",
          backgroundSize: "cover",
        }}
      >
        <Jumbotron
          style={{
            backgroundColor: "rgb(0,0,0,.6)",
            height: "100%",
            width: "100%",
          }}
          className="d-flex justify-content-center align-items-middle flex-column"
          fluid
        >
          <h2>ListShack Desktop</h2>
          <p className="lead">
            Everything you love about ListShack, right on your desktop computer
          </p>
          <DesktopDownload className="text-center mt-5" />
        </Jumbotron>
      </Row>
      <Row
        className="d-flex justify-content-center align-items-center text-center"
        style={{ padding: "100px 0px", maxWidth: "800px", margin: "0 auto" }}
      >
        <Col xs={12}>
          <h3>ListShack Desktop is Even Easier to Use</h3>
          <p className="lead">
            The Desktop app brings all that you love about ListShack right to
            your desktop computer. Fewer password resets. Less confusion about
            where to login.{" "}
            <b>
              Plus downloads on the Desktop app are unlimited (no deductions
              from your monthly leads) from now until the end of the year!
            </b>{" "}
            Download the desktop app now!
          </p>
          <br />
          <hr />
          <br />
        </Col>
        <Col xs={12} className="mt-5">
          <h3>How to use the Desktop App</h3>
          <p className="lead">
            Using the Desktop App is even easier than logging in online.
          </p>
          <ol
            className="text-left"
            style={{ maxWidth: "500px", margin: "0 auto", fontSize: "1.3rem" }}
          >
            <li>
              Make sure your ListShack subscription is active{" "}
              <a href="https://www.listshack.support/settings/billing">here.</a>
            </li>
            <li>Download the app to your Mac or Windows computer.</li>
            <li>
              Open the app and login to your account.
              <br />
              <small>
                If you've forgotten your password, reset it{" "}
                <a href="https://www.listshack.support/reset_password">here</a>.
              </small>
            </li>
            <li>Download unlimited leads!!!</li>
          </ol>
        </Col>
      </Row>
      <Row
        className="d-flex justify-content-center align-items-middle flex-row text-center bg-light"
        style={{ padding: "75px 0px" }}
      >
        <Col>
          <h2>Download ListShack Desktop Now!</h2>
          <DesktopDownload className="text-center mt-5" />
        </Col>
      </Row>
    </Container>
  );
};

const DatabaseLander = (props) => {
  const { dicts } = props;
  console.log("DatabaseLander props: ", props);
  console.log("databases: ", dicts);
  return (
    <Container>
      <Row
        style={{
          marginTop: "30px",
          minHeight: "75vh",
        }}
        className="text-center mb-5"
      >
        <Col>
          <Jumbotron fluid>
            <h1>{props.title}</h1>
            <p className="lead">{props.lead}</p>
          </Jumbotron>
          <h4>{props.subtitle}</h4>
          <div>{props.description}</div>
          <CardDeck>
            {dicts &&
              dicts.map((database) => {
                return (
                  <Card
                    bg={database.themeColor}
                    text="white"
                    style={{ width: "18rem" }}
                    key={database.dictId}
                  >
                    <Card.Header>{database.type}</Card.Header>
                    <Card.Body>
                      <Card.Title>{database.title}</Card.Title>
                      <Card.Text>{database.description}</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <ButtonGroup>
                        <Link
                          to={`/${props.subPath}/${database.dictId}`}
                          className="btn btn-sm btn-light"
                        >
                          Learn more
                        </Link>
                        <Link
                          to={database.path}
                          className="btn btn-sm btn-link text-white"
                        >
                          Search now
                        </Link>
                      </ButtonGroup>
                    </Card.Footer>
                  </Card>
                );
              })}
          </CardDeck>
        </Col>
      </Row>
    </Container>
  );
};

const Ccpa = (props) => {
  return (
    <Container>
      <Row>
        <Col className="mt-3 mb-5">
          <h3>CCPA Notice for California Residents</h3>
          <p>Last Updated: January 8, 2020</p>
          <p>
            This PRIVACY NOTICE FOR CALIFORNIA RESIDENTS supplements the
            information contained in the Privacy Statement of{" "}
            {process.env.REACT_APP_site_name} and its subsidiaries
            (collectively, “we,” “us,” or “our”) and applies solely to visitors,
            users, and others who reside in the State of California (“consumers”
            or “you”). We adopt this notice to comply with the California
            Consumer Privacy Act of 2018 (“CCPA”) and other California privacy
            laws. Any terms defined in the CCPA have the same meaning when used
            in this notice.
          </p>
          <h4>Information We Collect and Disclose</h4>
          <p>
            We collect information that identifies, relates to, describes,
            references, is capable of being associated with, or could reasonably
            be linked, directly or indirectly, with a particular consumer or
            device (“personal information”). In the preceding twelve (12)
            months, we have collected and disclosed the following categories of
            personal information from consumers:
          </p>
          <Table responsive id="ccpa_disclosures">
            <thead>
              <tr>
                <td>Category</td>
                <td>Collected</td>
                <td>Source</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  A. Identifiers (such as a real name, alias, postal address,
                  unique personal identifier, online identifier and email
                  address).
                </td>
                <td>Yes</td>
                <td>Directly from third party data providers.</td>
              </tr>
              <tr>
                <td>
                  B. Personal information categories listed in the California
                  Customer Records statute – Cal. Civ. Code § 1798.80(e) (such
                  as a name, address, telephone number, education and
                  employment).
                </td>
                <td>Yes</td>
                <td>Directly from third party data providers.</td>
              </tr>
              <tr>
                <td>
                  C. Protected classification characteristics under California
                  or federal law (such as age and gender).
                </td>
                <td>Yes</td>
                <td>Directly from third party data providers.</td>
              </tr>
              <tr>
                <td>
                  D. Commercial information (such as records of personal
                  property, products or services purchased, obtained, or
                  considered, or other purchasing or consuming histories or
                  tendencies).
                </td>
                <td>Yes</td>
                <td>Directly from third party data providers.</td>
              </tr>
              <tr>
                <td>
                  E. Biometric information (such as genetic, physiological and
                  biological characteristics, or identifying information, such
                  as, fingerprints, faceprints, voiceprints, iris or retina
                  scans, keystroke, gait, or other physical patters and sleep
                  data. Please Note: we do collect some general health and
                  exercise data categories).
                </td>
                <td>No</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  F. Internet or other similar network activity (such as
                  browsing history, search history, information on a consumer’s
                  interaction with a website, application, or advertisement).
                </td>
                <td>No</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>G. Geolocation data (such as physical location).</td>
                <td>Yes</td>
                <td>Directly from third party data providers.</td>
              </tr>
              <tr>
                <td>
                  H. Sensory data (such as audio, electronic, visual, thermal,
                  olfactory, or similar information).
                </td>
                <td>No</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  I. Professional or employment-related information (such as
                  current job history).
                </td>
                <td>Yes</td>
                <td>Directly from third party data providers.</td>
              </tr>
              <tr>
                <td>
                  J. Non-public education information per the Family Educational
                  Rights and Privacy Act – 20 U.S.C. Section 1232g, 34 C.F.R.
                  Part 99 (such as education records directly related to a
                  student maintained by an educational institution or party
                  acting on its behalf, such as grades, transcripts, class
                  lists, student schedules, student identification codes,
                  student financial information, or student disciplinary
                  records).
                </td>
                <td>No</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td>
                  K. Inferences drawn from other personal information (such as a
                  profile reflecting a person’s preferences, characteristics,
                  psychological trends, predispositions, behavior and
                  attitudes).
                </td>
                <td>Yes</td>
                <td>Directly from third party data providers.</td>
              </tr>
            </tbody>
          </Table>
          <h4>Use of Personal Information</h4>
          <p>
            We use and disclose the personal information we collect for our
            commercial and business purposes, as further described in this
            Privacy Policy. These commercial and business purposes include,
            without limitation:
          </p>
          <ul>
            <li>
              Our commercial purposes include marketing, helping businesses find
              new customers, developing insights as to consumer interests and
              behaviors, keeping contact information up to date and relevant,
              enabling relevant and personalized marketing messages.
            </li>
            <li>
              Our business purposes as identified in the CCPA include improving
              our services.
            </li>
          </ul>
          <h4>Sharing of Personal Information</h4>
          <p>
            {process.env.REACT_APP_site_name} may disclose your personal
            information to a third party for a business purpose. When we
            disclose personal information for a business purpose, we enter a
            contract that describes the purpose and requires the recipient to
            both keep that personal information confidential and not use it for
            any purpose except performing the contract.
          </p>
          <p>
            We share your personal information with the following categories of
            third parties:
          </p>
          <ul>
            <li>Subsidiaries and affiliates.</li>
            <li>Contractors and service providers.</li>
            <li>
              Third parties to whom you or your agents authorize us to disclose
              your personal information in connection with products or services
              we provide to you.
            </li>
          </ul>

          <h4>Disclosures of Personal Information for a Business Purpose</h4>
          <p>
            In the preceding 12 months, {process.env.REACT_APP_site_name} has
            disclosed the following categories of personal information for a
            business purpose to the parties identified above:
          </p>
          <ul>
            <li>Category A: Identifiers.</li>
            <li>
              Category B: California Customer Records personal information
              categories.
            </li>
            <li>
              Category C: Protected classification characteristics under
              California or federal law.
            </li>
            <li>Category D: Commercial information.</li>
            <li>Category G: Geolocation data.</li>
            <li>Category I: Professional or employment-related information.</li>
            <li>
              Category K: Inferences drawn from other personal information.
            </li>
          </ul>
          <h4>Sales of Personal Information</h4>
          <p>
            In the preceding (12) twelve months, we have sold personal
            information from all the following categories:
          </p>
          <ul>
            <li>Category A: Identifiers.</li>
            <li>
              Category B: California Customer Records personal information
              categories.
            </li>
            <li>
              Category C: Protected classification characteristics under
              California or federal law.
            </li>
            <li>Category D: Commercial information.</li>
            <li>Category G: Geolocation data.</li>
            <li>Category I: Professional or employment-related information.</li>
            <li>
              Category K: Inferences drawn from other personal information.
            </li>
          </ul>
          <p>
            In the preceding twelve (12) months, we have sold your personal
            information to the following categories of third parties:
          </p>
          <ul>
            <li>Automotive Companies</li>
            <li>Business Services/Agency</li>
            <li>Consumer Services Companies</li>
            <li>Consumer Packaged Goods Companies</li>
            <li>Educational Institutions &amp; Companies</li>
            <li>Energy and Utility Companies</li>
            <li>Financial Services Companies</li>
            <li>Food &amp; Beverage Companies</li>
            <li>Health Product Companies</li>
            <li>Insurance Companies</li>
            <li>Manufacturing Companies</li>
            <li>Marketing and Research Companies</li>
            <li>Media and Publishing Companies</li>
            <li>Not for Profit Organizations</li>
            <li>Public or Government Entities</li>
            <li>Public Institutions</li>
            <li>Retail Companies</li>
            <li>Technology/Computer Software Companies</li>
            <li>Telecommunications Companies</li>
            <li>Travel, Leisure &amp; Entertainment Companies</li>
            <li>Other Companies not Categorized.</li>
          </ul>
          <p>
            We will honor requests from consumers to “opt-out” of the collection
            and disclosure of their personal information. Your Rights and
            Choices section below provides instructions on how to opt-out of our
            collection and disclosure of personal information.
          </p>
          <h4>Your Rights</h4>
          <p>
            The CCPA provides consumers (California residents) with specific
            rights regarding their personal information. You have the right to
            request that we disclose certain information to you about our
            collection and use of your personal information over the past 12
            months. Once we receive and confirm your verifiable consumer
            request, we will disclose to you:
          </p>
          <ol>
            <li>
              The categories of personal information we collected about you.
            </li>
            <li>
              The categories of sources for the personal information we
              collected about you.
            </li>
            <li>
              Our business or commercial purpose for collecting or selling that
              personal information.
            </li>
            <li>
              The categories of third parties with whom we share that personal
              information.
            </li>
            <li>
              The specific pieces of personal information we collected about
              you.
            </li>
          </ol>
          <p>
            You also have the right to request that we opt-out the sale of your
            personal information and delete all the personal information that we
            have collected and retained about you, subject to certain enumerated
            CCPA exceptions. Once we receive and confirm your verifiable
            consumer request, we will delete (and direct our service providers
            to delete) your personal information from our records, unless a
            specific CCPA exception applies.
          </p>
          <p>
            We may deny your deletion request if retaining the information is
            necessary for us or our service providers to:
          </p>
          <ul>
            <li>
              Complete the transaction for which we collected the personal
              information, provide a good or service that you requested, take
              actions reasonably anticipated within the context of our ongoing
              business relationship with you, or otherwise perform our contract
              with you.
            </li>
            <li>
              Detect security incidents, protect against malicious, deceptive,
              fraudulent, or illegal activity, or prosecute those responsible
              for such activities.
            </li>
            <li>
              Debug products to identify and repair errors that impair existing
              intended functionality.
            </li>
            <li>
              Exercise free speech, ensure the right of another consumer to
              exercise their free speech rights, or exercise another right
              provided for by law.
            </li>
            <li>
              Comply with the California Electronic Communications Privacy Act
              (Cal. Penal Code §1546 seq.).
            </li>
            <li>
              Engage in public or peer-reviewed scientific, historical, or
              statistical research in the public interest that adheres to all
              other applicable ethics and privacy laws, when the information's
              deletion may likely render impossible or seriously impair the
              research's achievement, if you previously provided informed
              consent.
            </li>
            <li>
              Enable solely internal uses that are reasonably aligned with
              consumer expectations based on your relationship with us.
            </li>
            <li>Comply with a legal obligation.</li>
            <li>
              Make other internal and lawful uses of that information that are
              compatible with the context in which you provided it.
            </li>
          </ul>

          <h4>Exercising Access, Data Portability, and Deletion Rights</h4>
          <p>
            To exercise the access, data portability, and deletion rights
            described above, please submit a verifiable consumer request to us
            by visiting <Link to="/ccpa_optout/">here</Link>. Only you or a
            person that you have designated as an authorized agent to act on
            your behalf may make a verifiable consumer request related to your
            personal information. In order for an authorized agent to be
            verified, you must provide the authorized agent with signed, written
            permission to make such requests (which we may ask to review) or a
            power of attorney. We may also follow up with you to verify your
            identity before processing the authorized agent’s request. As a
            parent or legal guardian, you may also make a verifiable consumer
            request on behalf of your minor child.
          </p>
          <p>
            You may only make a verifiable consumer request for access or data
            portability twice within a 12-month period. The verifiable consumer
            request must: (a) provide all required information that allows us to
            reasonably verify you are the person about whom we collected
            personal information or an authorized representative, and (b)
            describe your request with clear and precise detail that allows us
            to properly understand, evaluate, and respond to it. We cannot
            respond to your request or provide you with personal information if
            we cannot verify your identity or authority to make the request and
            confirm the personal information relates to you. Making a verifiable
            consumer request does not require you to create an account with us.
            We will only use personal information provided in a verifiable
            consumer request to verify the requestor’s identity or authority to
            make the request.
          </p>
          <h4>Response Timing and Format</h4>
          <p>
            We will respond to a verifiable consumer request within 45 days of
            its receipt. If we require more time (up to 90 days), we will inform
            you of the reason and extension period in writing. We will deliver
            our written response to you electronically (via email). Any
            disclosures we provide will only cover the 12-month period preceding
            the verifiable consumer request’s receipt. The response we provide
            will also explain the reasons we cannot comply with a request, if
            applicable. For data portability requests, we will select a format
            to provide your personal information that is readily useable and
            should allow you to transmit the information from one entity to
            another entity without hindrance.
          </p>
          <p>
            We do not charge a fee to process or respond to your verifiable
            consumer request unless it is excessive, repetitive, or manifestly
            unfounded. If we determine that the request warrants a fee, we will
            tell you why we made that decision and provide you with a cost
            estimate before completing your request.
          </p>
          <h4>Non-Discrimination</h4>
          <p>
            We will not discriminate against you for exercising any of your CCPA
            rights. Unless permitted by the CCPA, we will not:
          </p>
          <ul>
            <li>Deny you goods or services.</li>
            <li>
              Charge you different prices or rates for goods or services,
              including through granting discounts or other benefits, or
              imposing penalties.
            </li>
            <li>
              Provide you a different level or quality of goods or services.
            </li>
            <li>
              Suggest that you may receive a different price or rate for goods
              or services or a different level or quality of goods or services.
            </li>
          </ul>
          <h4>Financial Incentives</h4>
          <p>
            {process.env.REACT_APP_site_name} does NOT offer any financial
            incentives or payments to consumers as compensation, for the
            collection of personal information, the sale of personal
            information, or the deletion of personal information.
          </p>
          <h4>Minors</h4>
          <p>
            We do not knowingly collect, maintain or disclose identifiable
            information on anyone younger than 18 to third parties for use in
            marketing solicitations. Limited information related to minors
            younger than 18 is used in creating additional insights solely
            related to the adult parent in the household of the minor
            individual, however, we do not disclose any identifiable information
            about the minor as part of the insights created and included within
            the adult record (For example, we only create a flag that denotes
            “presence of children” and “child age ranges”). We do not knowingly
            collect, maintain or sell personal information about consumers
            younger than 18 for the purpose of marketing or advertising directly
            to the minor. If we become aware that we have collected personal
            information from a child under the age of 18, we will delete that
            information from our records.
          </p>

          <h4>Changes to Our Privacy Notice</h4>
          <p>
            {process.env.REACT_APP_site_name} reserves the right to amend this
            privacy notice at our discretion and at any time. When we make
            changes to this privacy notice, we will post the updated notice on
            the Site and update the notice’s effective date. Your continued use
            of our Site following the posting of changes constitutes your
            acceptance of such changes.
          </p>
          <h4>Contact Us</h4>
          <p>
            If you have questions about this privacy policy, please contact:
          </p>
          <p>
            ListShack
            <br />
            Attn: Legal
            <br />
            help@listshack.support
            <br />
            1042 for Union Boulevard #1122
            <br />
            Midvale, UT 84047
          </p>
        </Col>
      </Row>
    </Container>
  );
};

const CcpaOptout = (props) => {
  const { handleAlerts } = props;
  const [email, setEmail] = useState("");
  const [completedForm, setCompletedForm] = useState(false);

  useScript(
    `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_recaptcha_site_key}`,
    () => {
      //select and remove the recaptcha bit from teh dom.
      let rc = document.querySelector(".grecaptcha-badge").parentNode;
      if (rc) {
        document.body.removeChild(rc);
      }
    }
  );

  if (completedForm) {
    return <Redirect to="/home/" />;
  }

  const handleValidTraffic = (email) => {
    //console.log("email: ", email);
    //send email
    handleAlerts(
      "",
      `An email has been sent to ${email} with further instructions.`,
      "success"
    );
    setEmail("");
    setCompletedForm(true);
  };

  return (
    <Container>
      <Row style={{ minHeight: "70vh", marginTop: "40px" }}>
        <Col>
          <h3>California Consumer Privacy Act (CCPA) Request</h3>
          <p className="">
            {process.env.REACT_APP_site_name} allows California consumers to
            access and control the personal information under the California
            Consumer Privacy Act of 2018 as follows:
          </p>
          <ul>
            <li>Know what information is being collected by category.</li>
            <li>
              Know if personal information is sold to a third party and the
              types of those third parties by category.
            </li>
            <li>Access the categories of personal information.</li>
            <li>
              Opt out of the sale of personal information and request data
              deletion.
            </li>
          </ul>
          <p>
            Please provide your email below and you will receive an email that
            will provide further instructions.
          </p>
          <Form>
            <Form.Group>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => {
                  e.preventDefault();
                  console.log(e.target.value);
                  setEmail(e.target.value);
                }}
              />
              <Form.Text className="text-muted">
                This email will only be used to send you further instructions.
              </Form.Text>
            </Form.Group>
          </Form>
          <ButtonGroup>
            <Link to="/" className="btn btn-light">
              Never mind
            </Link>
            <Button
              variant="primary"
              size="sm"
              onClick={async (e) => {
                e.preventDefault();
                let isValid;
                const handleRejection = () => {
                  handleAlerts(
                    "",
                    "I'm sorry, there was a problem processing your request.  Please contact customer service.",
                    "warning"
                  );
                  setEmail("");
                  setCompletedForm(true);
                };

                await window.grecaptcha.ready(async function () {
                  await window.grecaptcha
                    .execute(process.env.REACT_APP_recaptcha_site_key, {
                      action: "ccpa",
                    })
                    .then(async function (response) {
                      // Add your logic to submit to your backend server here.
                      const init = {
                        method: "POST",
                        headers: {
                          "Content-Type": "application/json",
                        },
                        "Transfer-Encoding": "chunked",
                        cache: "default",
                        accept: "application/json",
                        body: JSON.stringify({ email, response }),
                      };
                      try {
                        let response = await fetch(
                          `${process.env.REACT_APP_api_url}/api/v2/request_ccpa_optout`,
                          init
                        );

                        isValid = await response.json();
                        console.log("isValid: ", isValid);
                        if (isValid) {
                          return handleValidTraffic(email);
                        }
                        return handleRejection();
                      } catch (err) {
                        console.log("Error processing request: ", err);
                        handleRejection();
                      }
                    });
                });
              }}
            >
              Request further instructions
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </Container>
  );
};

const DatabasesOverview = (props) => {
  const { dicts } = props;
  return (
    <Container>
      <Row>
        <Col xs={12} className="my-5 py-5">
          <h1>ListShack Databases</h1>
          <p className="lead">
            ListShack provides several comprehensive databases available for
            searching consumers, businesses, and vehicle owners. Use these
            databases to create telemarketing, direct mail, and email marketing
            lists as well as to enhance your existing marketing lists.
          </p>
          <Nav variant="pills" className="my-3">
            <Nav.Item>
              <NavLink
                to="/databases/"
                className="nav-link"
                activeClassName="active"
              >
                All
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                to="/consumer-databases/"
                className="nav-link"
                activeClassName="active"
              >
                Consumers
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                to="/business-databases/"
                className="nav-link"
                activeClassName="active"
              >
                Businesses
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                to="/vin-databases/"
                className="nav-link"
                activeClassName="active"
              >
                Vehicles
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                to="/email-databases/"
                className="nav-link"
                activeClassName="active"
              >
                Email
              </NavLink>
            </Nav.Item>
          </Nav>
        </Col>
        {dicts.map((d, i) => {
          return (
            <Col
              xs={12}
              className={`${
                i !== dicts.length - 1 ? "border-bottom" : ""
              } p-5 mb-5`}
            >
              <img src={d.image} width="300" height="auto" />
              <h2>{d.title}</h2>
              <p className="lead">{d.description}</p>
              <p>{d.overview}</p>
              <Link
                to={`/databases/${d.dictId}`}
                className="btn btn-info btn-lg"
              >
                Learn More
              </Link>
            </Col>
          );
        })}
      </Row>
      <FeatureBrowse
        next="/search-tools"
        nextText="View Search Tools"
        back="/home"
        backText="Home"
        className="btn btn-info"
      />
    </Container>
  );
};

const DatabaseRouter = (props) => {
  let { dicts } = props;

  const find = (dictid) => {
    let db = dicts.find((db) => db.dictId === dictid);
    return db;
  };

  //let { url } = useRouteMatch();
  let { dictid } = useParams();
  let dict = find(dictid);

  return (
    <Switch>
      <Route exact path="/databases/:dictid">
        <DatabaseDescription dataDict={dict} />
      </Route>
    </Switch>
  );
};

const DatabaseDescription = (props) => {
  let { dataDict } = props;

  return (
    <React.Fragment>
      <Helmet>
        <title>{dataDict.title}</title>
        <meta name="HandheldFriendly" content="true" />
        <meta name="apple-mobile-web-app-capable" content="YES" />
      </Helmet>
      <Container>
        <Row>
          <Col xs={12} className="mt-4">
            <p>
              <Link to="/databases/">&lt;&nbsp;View all databases</Link>
            </p>
          </Col>
          <Col xs={12}>
            <Jumbotron>
              <h1>{dataDict.title}</h1>
              <p className="lead">{dataDict.description}</p>
              <hr />
              <p>{dataDict.overview}</p>
              <a
                href={`https://www.listshack.support${dataDict.path}`}
                className={`btn btn-${dataDict.themeColor} btn-lg`}
              >
                Search now
              </a>
            </Jumbotron>
          </Col>
          <Col xs={12} md={8} className="mb-5 pb-5">
            <h3>Filters available</h3>
            <ul>
              {Object.values(dataDict.cols).map((col) => {
                return <li key={col.name}>{col.name}</li>;
              })}
            </ul>
            <h3>Columns available for download</h3>
            <ul>
              {dataDict.dlCols.map((col) => {
                return <li key={col.value}>{col.name}</li>;
              })}
            </ul>
            {/*
            <h3>Availablilty</h3>
            <p>{dataDict.access}</p>
            <h3>Price per lead</h3>
            <p>${(50 / 50000) * dataDict.leadsMultiplier}</p>
            */}
          </Col>
          <Col xs={12} md={4}>
            <Card bg={dataDict.themeColor} text="white" className="sticky-top">
              <Card.Body>
                <Card.Title>{dataDict.title}</Card.Title>
                <Card.Text>{dataDict.description}</Card.Text>
                <a
                  href={`https://www.listshack.support${dataDict.path}`}
                  className="btn btn-light"
                >
                  Search now
                </a>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

const GeographySearch = (props) => {
  return (
    <Container>
      <Row>
        <Col className="my-5 py-5">
          <img src={locationSearch} width="300" height="auto" />
          <h1>Geography Search</h1>
          <p className="lead">
            ListShack makes it super simple to find and download leads via
            geography. Just select a geography type such as city, state, zip, or
            nationwide.
          </p>
          <GeoDemo variant="info" size="lg" />
        </Col>
      </Row>
      {[
        {
          title: "Search for any US Geography",
          subtitle: "City, State, Zip Code, or County",
          img: cityLife,
        },
        {
          title: "Add Search Filters",
          subtitle: "Add filters to target the perfect prospect",
          img: prioritize,
        },
        {
          title: "Preview leads",
          subtitle:
            "See exactly how your leads will look on a spreadsheet.  Hover to see more information.",
          img: spreadsheets,
        },
        {
          title: "Checkout and Download to CSV",
          subtitle: "Download your desired number of leads into a csv file.",
          img: spreadsheet,
        },
      ].map((s, i) => {
        return (
          <Row className="my-5 py-5">
            <Col xs={12} md={5}>
              <img src={s.img} width="300" height="auto" />
            </Col>
            <Col xs={12} md={7}>
              <h2>{s.title}</h2>
              <p className="lead">{s.subtitle}</p>
            </Col>
          </Row>
        );
      })}
      <Row>
        <Col>
          <Jumbotron className="text-center">
            <h1 className="text-primary">
              Don't just take our word for it.
              <br />
              The proof is in the product.
            </h1>
            <GeoDemo
              buttonText={
                <h4>
                  <u>Watch a demo</u>
                </h4>
              }
              variant="link"
              className="text-dark"
            />
          </Jumbotron>
        </Col>
      </Row>
      <SubscribeInfo />
    </Container>
  );
};

const MapSearch = (props) => {
  return (
    <Container>
      <Row>
        <Col className="my-5 py-5">
          <img src={mapDark} width="300" height="auto" />
          <h1>Map Search</h1>
          <p className="lead">
            ListShack makes it super simple to find leads on a map, just as if
            you were using your favorite maps tool like Google Maps or MapQuest.
          </p>
          <MapDemo variant="info" size="lg" />
        </Col>
      </Row>
      {[
        {
          title: "Search for any US Geography",
          subtitle: "City, State, Zip Code, or County",
          img: cityLife,
        },
        {
          title: "Zoom in and Zoom out",
          subtitle: "Zoom in for fewer leads, Zoom out for more leads",
          img: mapLight,
        },
        {
          title: "Add and Remove Filters",
          subtitle: "Add filters to target the perfect prospect",
          img: prioritize,
        },
        {
          title: "Preview leads right on the map",
          subtitle:
            "Tap or click a pin to view the name, address, and phone number",
          img: pinLocation,
        },
        {
          title: "Download to CSV",
          subtitle:
            "Download all of the leads available on the map into a csv file.",
          img: spreadsheet,
        },
      ].map((s, i) => {
        return (
          <Row className="my-5 py-5">
            <Col xs={12} md={5}>
              <img src={s.img} width="300" height="auto" />
            </Col>
            <Col xs={12} md={7}>
              <h2>{s.title}</h2>
              <p className="lead">{s.subtitle}</p>
            </Col>
          </Row>
        );
      })}
      <Row>
        <Col>
          <Jumbotron className="text-center">
            <h1 className="text-primary">
              Don't just take our word for it.
              <br />
              The proof is in the product.
            </h1>
            <MapDemo
              buttonText={
                <h4>
                  <u>Watch a demo</u>
                </h4>
              }
              variant="link"
              className="text-dark"
            />
          </Jumbotron>
        </Col>
      </Row>
      <SubscribeInfo />
    </Container>
  );
};

const GeoDemo = (props) => {
  const { variant, className, style, size, buttonText } = props;
  const [showModal, setShowModal] = useState(false);
  return (
    <React.Fragment>
      <Button
        variant={variant ? variant : "link"}
        size={size ? size : "md"}
        className={className ? className : ""}
        style={style ? style : null}
        onClick={() => setShowModal(true)}
      >
        {buttonText ? buttonText : "Watch Demo"}
      </Button>
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Body>
          <Modal.Title>
            How to Search for Leads by Geography using ListShack
          </Modal.Title>
          <React.Fragment>
            <p>
              It's super easy to search for leads by Geography.{" "}
              {process.env.REACT_APP_site_name} offers geography searches by
              City, State, Zipcode, and Nationwide for all databases. We offer
              county searches for some of our consumer and business databases.
            </p>
            <div
              dangerouslySetInnerHTML={{
                __html: `<div style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe src="https://www.loom.com/embed/55c100236efb4de09d1678ea8b76549d" frameborder="0" webkitallowfullscreen mozallowfullscreen allowFullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>`,
              }}
            />
            <ol className="mt-4">
              <li>Select whether you'd like Business or Consumer leads.</li>
              <li>
                Select the geography type that you'd like to search by such as
                City, State, Zip, or Nationwide.
              </li>
              <li>
                Click Filters and then add or remove your filters. Each database
                will have it's own unique filters that you can use to target
                your perfect prospect.
              </li>
              <li>
                To see the number of leads available check the Leads Count box
                on the top right. Click "Refresh Count" after adding a filter to
                see how manay leads are available.
              </li>
              <li>
                Click Preview, to view a preview of the leads as they would
                appear on a spreadsheet. Also double check the count. You can
                always go back to filters or geography if you need more or less
                leads.
              </li>
              <li>
                Click Checkout. Add a name for your search and the number of
                leads that you would like. To download a CSV file of the search,
                click the Green "Download" button.
              </li>
            </ol>
          </React.Fragment>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" size="sm" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

const MapDemo = (props) => {
  const { variant, className, style, size, buttonText } = props;
  const [showModal, setShowModal] = useState(false);
  return (
    <React.Fragment>
      <Button
        variant={variant ? variant : "link"}
        size={size ? size : "md"}
        className={className ? className : ""}
        style={style ? style : null}
        onClick={() => setShowModal(true)}
      >
        {buttonText ? buttonText : "Watch Demo"}
      </Button>
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Body>
          <Modal.Title>
            How to Search for Leads on a Map using ListShack
          </Modal.Title>
          <React.Fragment>
            <p>
              It's super easy to search for business or consumer leads on a map.{" "}
              {process.env.REACT_APP_site_name} offers two map based databases,
              one for consumers and another for businesses. To get started,
              select "Maps" in the sidebar.
            </p>
            <div
              dangerouslySetInnerHTML={{
                __html: `<div style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe src="https://www.loom.com/embed/2a8d26658e9641589de25c3899135008" frameborder="0" webkitallowfullscreen mozallowfullscreen allowFullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>`,
              }}
            />
            <ol className="mt-4">
              <li>Select whether you'd like Business or Consumer leads.</li>
              <li>
                Allow {process.env.REACT_APP_site_name} to access your location
                to quickly see the leads that are available near your location.
              </li>
              <li>
                To change the area you'd like to search type the name of a city,
                county, zip code, or state in the search box on the top right.
                Select a suggestion with your mouse or use the keyboard to
                select a suggestion and press ENTER.
              </li>
              <li>
                To refine the area in which you'd like to search, move the map
                by zooming in with the map controls or your mouse. Click the
                "Search this area" button.
              </li>
              <li>
                To see the number of leads available check the Leads Count box
                on the top right.
              </li>
              <li>
                Under the leads count click the "Filters" to view the current
                filters. Click the "Edit" button to add or remove filters from
                your search.
              </li>
              <li>
                {process.env.REACT_APP_site_name} will display 100 pins on the
                map that match your search filters. Click a pin to view the
                record.
              </li>
              <li>
                To download a CSV file of the search, click the Green "Download"
                button. Confirm your map and filters, preview the CSV, and
                checkout. Checkout will require you to add a search name and
                indicate the number of leads to download.
              </li>
            </ol>
            <p>
              <b>
                If your city or county search from the search bar do not show
                leads on the map.
              </b>{" "}
              <br />
              If pins are not displayed on the map after you search by city or
              county, you may need to zoom in or out and click the "Search this
              area" button. If after panning or zooming and clicking "Search
              this area", there are not leads available, then you may need to
              adjust your filters.
            </p>
            <p>
              <b>Map settings</b> <br />
              Click the map settings icon on the bottom left of the map to
              adjust the map sensitivity. Increased map sensitivity will display
              the "Search this area" button to update your search count and
              search map with less panning or zooming.
            </p>
          </React.Fragment>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" size="sm" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

const ListCrmDemo = (props) => {
  const { variant, className, style, size, buttonText } = props;
  const [showModal, setShowModal] = useState(false);
  return (
    <React.Fragment>
      <Button
        variant={variant ? variant : "link"}
        size={size ? size : "md"}
        className={className ? className : ""}
        style={style ? style : null}
        onClick={() => setShowModal(true)}
      >
        {buttonText ? buttonText : "Watch Demo"}
      </Button>
      <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
        <Modal.Body>
          <Modal.Title>
            How to Search for Leads on a Map using ListShack
          </Modal.Title>
          <React.Fragment>
            <p>
              ListCrm allows you manage and track all your leads without having
              to download them into a spreadsheet.
            </p>
            <div
              dangerouslySetInnerHTML={{
                __html: `<div style="position: relative; padding-bottom: 62.5%; height: 0;"><iframe src="https://www.loom.com/embed/d39c08ca2572416bae6cb4485172c1c9" frameborder="0" webkitallowfullscreen mozallowfullscreen allowFullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe></div>`,
              }}
            />
            <p className="mt-4">
              First, ListCrm is laid out just like a spreadsheet that you might
              see in Excel or Google Sheets. With a few cool enhancements:
            </p>
            <ul>
              <li>
                Hover over the column to see the column buttons. You can click
                the arrows to sort the list by that column. Click it again to
                reverse the order of the sort. Click it a third time to remove
                the sort.{" "}
              </li>
              <li>Drag and drop the columns to reorder them.</li>
              <li>Click the X to remove a column from your view.</li>
              <li>
                Click the Plus button to manage the columns that are visible.
              </li>
              <li>
                You can easily track the status of each record in your list with
                the status column. Adding a new status will automatically create
                a new tab, so your list stays organized by status.
              </li>
              <li>
                Click the notes button next to each record to keep track of
                detailed notes for each person.
              </li>
              <li>
                Click a phone number to call the number using your computer.
              </li>
              <li>
                Click an email address to send an email to that address using
                the default email client on your computer.
              </li>
              <li>Hover over an Age and an Address to see more details.</li>
              <li>
                Click any where on the row, to open up the side canvas for the
                record where you can change or update the information in the
                list.
              </li>
              <li>
                Finally, you can easily download your list to a Spreadsheet by
                clicking the Download button. Or if you'd like to combine two
                lists, you can click the Upload button to upload more records
                into your list.
              </li>
            </ul>
            <p>
              Stay tuned, we'll be adding the ability to append email, phone,
              and address information, the ability to do a DNC Scrub or Email
              Opt In scrub, and the ability to check for duplicates. We'll also
              be adding team management features, like assigning a record or a
              list to a team member.
            </p>
          </React.Fragment>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" size="sm" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

const FeatureBrowse = (props) => {
  const { next, back, nextText, backText, className, style } = props;
  return (
    <Container>
      <Row>
        <Col
          xs={12}
          className="my-3 py-3 d-flex flex-row justify-content-between align-items-center"
        >
          <Link
            to={back}
            className={className ? className : ""}
            style={style ? style : null}
          >
            <ArrowLeft className="mr-2" size={20} />
            {backText}
          </Link>
          <Link
            to={next}
            className={className ? className : ""}
            style={style ? style : null}
          >
            {nextText}
            <ArrowRight className="ml-2" size={20} />
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

const SearchTools = (props) => {
  return (
    <Container>
      <Row>
        <Col xs={12} className="py-5 my-5">
          <img src={webSearch} width="300" height="auto" />
          <h1>Search Tools</h1>
          <p className="lead">
            ListShack's search tools make finding your next customer a cinch.
          </p>
          <p>
            Just ten years ago anyone could pick up the phone book and call
            someone or some business in your local area. Times have changed.
            Google has replaced the yellow pages and cell phones have replaced
            the white pages. So what do we do now? ListShack provides simple,
            online tools for searching and finding your next prospect.
          </p>
        </Col>
      </Row>
      {[
        {
          title: "Geography Search",
          subtitle:
            "ListShack makes it super simple to find and download leads via geography. Just select a geography type such as city, state, zip, or nationwide.",
          cta: "Learn More",
          ctaLink: "/geography-search",
          description: "",
          img: locationSearch,
        },
        {
          title: "Map Search",
          subtitle:
            "ListShack makes it super simple to find leads on a map, just as if you were using your favorite maps tool like Google Maps or MapQuest.",
          cta: "Learn More",
          ctaLink: "/geography-search",
          description: "",
          img: mapDark,
        },
        {
          title: "Directory Search",
          subtitle:
            "ListShack makes it super simple to find and download leads with a basic directory search, just as if you were googling someone by name, except without the ads.",
          cta: "Learn More",
          ctaLink: "/directory-search",
          description: "",
          img: search,
          public: false,
        },
      ].map((s, i) => {
        if (s.public === false) {
          return null;
        }
        return (
          <Row className="my-5 py-5">
            <Col xs={12} md={6}>
              <h2>{s.title}</h2>
              <p className="lead">{s.subtitle}</p>
              <Link to={s.ctaLink} className="btn btn-info">
                {s.cta}
              </Link>
            </Col>
            <Col xs={12} md={6}>
              <img src={s.img} width="300" height="auto" alt={s.title} />
            </Col>
          </Row>
        );
      })}
      <FeatureBrowse
        next="/search-filters"
        nextText="View Search Filters"
        back="/databases"
        backText="View Databases"
        className="btn btn-info"
      />
      <SubscribeInfo />
    </Container>
  );
};

const SearchFilters = (props) => {
  const { dicts } = props;
  return (
    <Container>
      <Row>
        <Col xs={12} className="my-5 py-5">
          <img src={filter} width="300" height="auto" />
          <h1>Search Filters</h1>
          <p className="lead">
            ListShack's search filters allow you to find your perfect prospect
          </p>
        </Col>
        <Col xs={12} className="border-top my-5 py-5">
          <h2>Learn about search filters</h2>
          <p>
            Search filters allow you to narrow down your search results so you
            get a more targeted prospect. For example, if you are telemarketing
            in the State of California, there are more than 20 million people
            available. By adding a filter for "DNC scrubbed", you'll narrow your
            search results down to only those people that are not on the Do Not
            Call list. You can use many filtes to narrow down your search
            results, and each of ListShack's databases has unique filters
            available depending on the data that is collected. Other common
            filters include searching for consumers between the Age of 60 and
            65, or searching for businesses under the category Real Estate
            Agents, or searching for vehicle owners of Fords.
          </p>
          <p className="lead">
            Check out each of our databases to make sure that ListShack has the
            filters you need to target your prospect.
          </p>
          <Table responsive size="sm" striped hover>
            <thead>
              <tr>
                <th>Database name</th>
                <th>Description</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              {dicts.map((d) => {
                return (
                  <tr>
                    <td>{d.title}</td>
                    <td>{d.description}</td>
                    <td>
                      <Link to={`/databases/${d.dictId}`}>View filters</Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          <h2>Common Search Filters</h2>
          <p>
            At ListShack, we see many folks that are looking or searching for
            similar types of leads, usually because our customers sell similar
            products. Here are some common search filters we see often:
          </p>
          <Table responseive striped hover>
            <thead>
              <tr>
                <th>List Type</th>
                <th>Description</th>
                <th>Link</th>
              </tr>
            </thead>
            <tbody>
              {[
                {
                  text: "Turning 65 Lists",
                  description:
                    "Often used by insurance agents looking to sell medicare supplement insurance.",
                  link: "https://www.listshack.support/share/search?savedSearch=6rCsinhKbOSMA6dwVtwZ&uid=huLMp8CdsQTyOXrrIVmVdi2CtSO2",
                  tips: "Try adding a birth month to your search so you can customize your messaging or telemarketing script.",
                },
                {
                  text: "Roofing or Home Improvement leads",
                  description:
                    "Often used by contractors looking sell home repair services.",
                  link: "https://www.listshack.support/share/search?savedSearch=Hh0e2giUxW1xEDD3lNXy&uid=huLMp8CdsQTyOXrrIVmVdi2CtSO2",
                  tips: "Try adjusting a purchase year to your search depending on the types of customers that you usually service.",
                },
                {
                  text: "Homeowners",
                  description:
                    "Often used by many types of businesses looking to target more stable consumers for insurance, home repairs, landscaping, real estate, and many other industries",
                  link: "https://www.listshack.support/share/search?savedSearch=X9xwxqDfBLsfLcZqFdrg&uid=huLMp8CdsQTyOXrrIVmVdi2CtSO2",
                  tips: "Try adding a more targeted geographic filter as well as adding age and incomne filters based on the products you are selling.",
                },
                {
                  text: "Real Estate Agents",
                  description:
                    "Often used by real estate investors looking to sell to or collaborate with Real Estate Agents.",
                  link: "https://www.listshack.support/share/search?savedSearch=0eq35WoOdlbtGvrzMgjc&uid=huLMp8CdsQTyOXrrIVmVdi2CtSO2",
                  tips: "Try adding a more targeted geographic filter.",
                },
              ].map((l, i) => {
                return (
                  <tr>
                    <td>{l.text}</td>
                    <td>
                      <p>{l.description}</p>
                      <p className="bg-light text-weight-light text-italic">
                        <small>Tip: {l.tips}</small>
                      </p>
                    </td>
                    <td>
                      <a
                        href={l.link}
                        target="_blank"
                        className="btn btn-info btn-sm"
                      >
                        Try search
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>
      <FeatureBrowse
        next="/listcrm/"
        nextText="View ListCrm"
        back="/search-tools/"
        backText="View Search Tools"
        className="btn btn-info"
      />
      <SubscribeInfo />
    </Container>
  );
};

const SampleList = (props) => {
  const { dicts } = props;
  return (
    <Container>
      <Row>
        <Col xs={12} className="py-5 my-5">
          <img src={spreadsheet} width="300" height="auto" />
          <h1>Sample Marketing List</h1>
          <p className="lead">
            ListShack's marketing lists come in CSV format, universally ready
            for excel, google sheets, and every auto dialier that we know of.
          </p>
          <p>
            Stop worrying about whether your list will be compatible with the
            other software that you use. ListShack's leads come in CSV format,
            so that virtually any software can import and integrate with
            ListShack. The first row of each filec contains a header describing
            the data beneath it. Records without a particular column are left
            blank. Check out our sample lists below, so you can be sure that
            ListShack will be compatible.
          </p>
        </Col>
      </Row>
      <Row>
        <Col className="py-5 my-5">
          <h2>Download a Sample List</h2>
          <p>
            Each ListShack Database allows you download different fields such as
            Name, Address, Phone, or Email. We do our best to give you as much
            information as possible while respecting privacy. For example, we
            provide the birth month and age, but do not provide the birth date
            so that the information cannot be used for bank or financial fraud.
          </p>
          <Table responseive striped hover>
            <thead>
              <tr>
                <th>Database</th>
                <th>Description</th>
                <th>Sample List</th>
              </tr>
            </thead>
            <tbody>
              {dicts.map((d, i) => {
                return (
                  <tr>
                    <td>{d.title}</td>
                    <td>{d.description}</td>
                    <td>
                      <CSVLink
                        data={[
                          d.dlCols.map((h) => h.value),
                          d.dlCols.map((h) => `Example ${h.name}`),
                        ]}
                        download={`sample_${d.title.replaceAll(" ", "_")}`}
                      >
                        Download sample
                      </CSVLink>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      </Row>

      <FeatureBrowse
        next="/compare_plans/"
        nextText="Pick a Plan"
        back="/listcrm/"
        backText="View ListCrm"
        className="btn btn-info"
      />
      <SubscribeInfo />
    </Container>
  );
};

const WeWorkWith = (props) => {
  return (
    <Container>
      <Row>
        <Col className="my-5 py-5">
          <h1>Who We Work With</h1>
          <p className="lead">Check out who we work with!</p>
          <ListGroup variant="flush">
            {[
              "Independent Insurance Agents",
              "Insurance Agencies",
              "Insurance Franchisees",
              "Health Insurers",
              "General Contractors",
              "Roofers",
              "Landscapers",
              "Marketers",
              "Advertisers",
              "Universities",
              "Software Companies",
              "Direct Marketers",
              "Direct Sellers",
              "Network Marketers",
              "Advertising Agencies",
              "Marketing Agencies",
              "Financial Advisors",
              "Collection Agencies",
              "Auto Warranty",
              "Auto Dealerships",
              "Direct Mailers",
              "Telemarketers",
              "Email Marketers",
              "Real Estate Agents",
              "Real Estate Investors",
              "Tax Advisors",
              "Management Groups",
              "Non Profits",
            ].map((l) => {
              return <ListGroup.Item>{l}</ListGroup.Item>;
            })}
          </ListGroup>
          <p className="mt-4">
            Not seeing your industry or company on our list? Chat with us below
            and we'll make sure it's a good fit.
          </p>
        </Col>
      </Row>
      <SubscribeInfo />
    </Container>
  );
};

const WatchDemo = (props) => {
  return (
    <Container>
      <Row className="my-5 py-5">
        <Col xs={12}>
          <h1>Watch a Demo</h1>
          <p className="lead">Check out ListShack in action!</p>
        </Col>
        <Col xs={12} md={4}>
          <GeoDemo
            buttonText={
              <Card
                style={{
                  textDecoration: "none",
                  height: "300px",
                  width: "300px",
                }}
                className="bg-info p-3 text-center text-white"
              >
                <img src={locationSearch} width="200" height="auto" />
                <h3>Watch Geography Search Demo</h3>
              </Card>
            }
          />
        </Col>
        <Col xs={12} md={4}>
          <MapDemo
            buttonText={
              <Card
                style={{
                  textDecoration: "none",
                  height: "300px",
                  width: "300px",
                }}
                className="bg-info p-3 text-center text-white"
              >
                <img src={mapDark} width="200" height="auto" />
                <h3>Watch Map Search Demo</h3>
              </Card>
            }
          />
        </Col>
        <Col xs={12} md={4}>
          <ListCrmDemo
            buttonText={
              <Card
                style={{
                  textDecoration: "none",
                  height: "300px",
                  width: "300px",
                }}
                className="bg-info p-3 text-center text-white"
              >
                <img src={progressTracking} width="200" height="auto" />
                <h3>Watch ListCrm Demo</h3>
              </Card>
            }
          />
        </Col>
      </Row>
      <SubscribeInfo />
    </Container>
  );
};

const ListCrm = (props) => {
  return (
    <Container>
      <Row>
        <Col className="my-5 py-5">
          <img src={progressTracking} width="300" height="auto" />
          <h1>ListCrm</h1>
          <p className="lead">
            Unlimited lead tracking with the CRM purpose built for managing a
            marketing list.
          </p>
          <a
            className="btn btn-info btn-lg"
            href="https://www.listshack.support/signup?next=/listcrm"
          >
            Use ListCrm for Free
          </a>
        </Col>
      </Row>
      {[
        {
          title: "Unlimited lead tracking for FREE",
          subtitle:
            "Track the most important information, like Phone, Email, Address, and Social accounts. Unlimited contacts for FREE.",
          img: savings,
          width: 200,
        },
        {
          title: "Easy to manage",
          subtitle:
            "Drag and drop columns to reorder. Easily add notes. Track lead status with easy to use tabs.",
          img: mouseArrow,
          width: 125,
        },
        {
          title: "Click to Call and Email",
          subtitle:
            "Click a phone number on your computer or mobile device to call. Click an email to send an email.",
          img: smartphone,
          width: 100,
        },
        {
          title: "Upload from and Download to CSV",
          subtitle:
            "Upload a CSV to track all your leads in the same place. Download your information directly to a CSV.",
          img: spreadsheet,
        },
      ].map((s, i) => {
        return (
          <Row className="my-5 py-5">
            <Col xs={12} md={5}>
              <img src={s.img} width={s?.width || "300"} height="auto" />
            </Col>
            <Col xs={12} md={7}>
              <h2>{s.title}</h2>
              <p className="lead">{s.subtitle}</p>
            </Col>
          </Row>
        );
      })}
      <FeatureBrowse
        next="/sample-list/"
        nextText="View Sample List"
        back="/search-filters/"
        backText="View Search Filters"
        className="btn btn-info"
      />
      <Row>
        <Col>
          <Jumbotron className="text-center">
            <h1 className="text-primary">
              Don't just take our word for it.
              <br />
              The proof is in the product.
            </h1>
            <ListCrmDemo
              buttonText={
                <h4>
                  <u>Watch a demo</u>
                </h4>
              }
              variant="link"
              className="text-dark"
            />
          </Jumbotron>
        </Col>
      </Row>
      <SubscribeInfo />
    </Container>
  );
};

export {
  Topnav,
  Secondnav,
  Footer,
  Home,
  BizLeads,
  ConLeads,
  ComparePlansTable,
  Contact,
  Terms,
  Privacy,
  Faq,
  DesktopDownload,
  ListShackDesktop,
  DatabaseLander,
  DatabaseDescription,
  Ccpa,
  CcpaOptout,
  DatabasesOverview,
  DatabaseRouter,
  GeographySearch,
  MapSearch,
  SearchTools,
  SearchFilters,
  SampleList,
  WeWorkWith,
  WatchDemo,
  ListCrm,
};

// These components aren't used now, but may be used later.
const Archives = (props) => {
  const databases = props.databases;

  return (
    <Container>
      <Row
        style={{
          marginTop: "30px",
          minHeight: "75vh",
        }}
        className="text-center mb-5"
      >
        <Col>
          <Jumbotron fluid>
            <h1>
              <i className="fa fa-database" aria-hidden="true"></i>&nbsp;
              {process.env.REACT_APP_site_name} Archives
            </h1>
            <p className="lead">
              Search the {process.env.REACT_APP_site_name} archives for{" "}
              <mark className="font-italic ">unlimited</mark> historical data.
            </p>
          </Jumbotron>
          <h4>
            <span className="text-success text-uppercase">
              Unlimited downloads
            </span>{" "}
            &mdash;{" "}
            <span className="text-danger text-uppercase">
              use at your own risk
            </span>
          </h4>
          <p className="mb-5" style={{ fontSize: ".7em" }}>
            One of our biggest jobs at {process.env.REACT_APP_site_name} is to
            source and update data. That means that as people and businesses
            move, change their phone numbers, pass away, and grow older, much of
            what we do is source the freshest (and most economical) data as
            possible. Nevertheless, Many people and businesses don't change all
            that fast... People don't move, they keep their phone numbers, and
            they may be older, but not that much older. So, we provide our
            archives, all the data that we don't keep fresh, to you for free (or
            nearly free) for all the things that you do with our data that don't
            require the freshest number, the latest DNC (Do Not Call) scrub, or
            the most recent NCOA (National Change of Address) updates. User
            beware be warned, we haven't updated these dataases. We've left off
            the DNC scrub filter, and the age column hasn't been recalcuated (on
            our consumer files). We've not done an NCOA scrub, nor removed
            deceased persons. Help yourself to the data, but proceed with
            caution when using it in your compaigns. This data is great for
            experimenting, it's great if you're calling folks by hand (no
            autodialer so no DNC scrub required), it's great if you're doing
            market research, or you are a researcher tracking trends. Do with it
            what you will, but we aren't responsible if you break Can-Spam, FCC
            Telemarketing regulations (TCPA), or get your email servers
            blacklisted by the email service providers. We also aren't
            responsible for returned mail (and the postage you might have
            spent). We're just trying to be as helpful as we can, so you and
            your business can succeed. Be smart out there.
          </p>
          <CardDeck>
            {databases &&
              databases.map((database) => {
                return (
                  <Card
                    bg="dark"
                    text="white"
                    style={{ width: "18rem" }}
                    key={database.database}
                  >
                    <Card.Header>{database.type}</Card.Header>
                    <Card.Body>
                      <Card.Title>{database.title}</Card.Title>
                      <Card.Text>{database.description}</Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <ButtonGroup>
                        <Link
                          to={`/archives/${database.database}`}
                          className="btn btn-sm btn-light"
                        >
                          Learn more
                        </Link>
                        <Link
                          to={database.path}
                          className="btn btn-sm btn-link text-white"
                        >
                          Search now
                        </Link>
                      </ButtonGroup>
                    </Card.Footer>
                  </Card>
                );
              })}
          </CardDeck>
        </Col>
      </Row>
    </Container>
  );
};
